<div class="inner-header col-md-12">
    <a class="btn-toggle" (click)="toggleSideBar()">
        <i class="fa fa-bars"></i>
    </a>
    <span class="pull-right">
        <a class="security-btn text-underline-hover me-3" [routerLink]="['/security']"><i class="fa fa-shield"></i> Security</a>
        <a class="logout-btn text-underline-hover" (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a>
    </span>
</div>

