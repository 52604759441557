import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ResellerService {
  api: string = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  getAllResellers(requestParams: object) {
    return this.httpClient.post(this.api + '/get-all-resellers', requestParams).toPromise();
  }

  getAllResellersForExport(requestParams: object) {
    return this.httpClient.post(this.api + '/get-all-resellers-for-export', requestParams).toPromise();
  }

  addReseller(requestParams: object) {
    return this.httpClient.post(this.api + '/add-reseller', requestParams).toPromise();
  }

  editReseller(requestParams: object) {
    return this.httpClient.post(this.api + '/edit-reseller', requestParams).toPromise();
  }

  getDetails(resellerId: string) {
    return this.httpClient.get(this.api + '/get-reseller-details/' + resellerId).toPromise();
  }

  getUsersList(requestParams: object) {
    return this.httpClient.post(this.api + '/get-reseller-users-list', requestParams).toPromise();
  }

  updateLogo(data: any) {
    return this.httpClient.post(this.api + '/upload-reseller-company-logo' , data).toPromise();
  }

  updateSignedContract(data: any) {
    return this.httpClient.post(this.api + '/upload-reseller-signed-doc' , data).toPromise();
  }

  checkResellerIdUniqueness(data: any) {
    return this.httpClient.post(this.api + '/check-widget-id-uniqueness' , data).toPromise();
  }

  checkResellerUrlUniqueness(data: any) {
    return this.httpClient.post(this.api + '/check-url-uniqueness' , data).toPromise();
  }
}
