import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { UserService } from '@app/services/user.service';
import { ExportService } from '@app/services/export.service';
import { NGXLogger } from 'ngx-logger';
import { MatOption } from '@angular/material/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.css']
})
export class InvitationsComponent implements OnInit, OnDestroy {
  title: string;
  primaryColor: string;
  pageHeading: string;
  companyName: string;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  invitations: [];
  fromShowing: any;
  toShowing: any;
  selectedColumns: any;
  selectedNotificationType: any;
  selectedSort: any;
  searchString: string;
  columns = [
    {id: 1, key: 'invited_user', name: 'invited_user', label: 'Invited User', sort: 0, hide: 0},
    {id: 2, key: 'invited_date', name: 'invited_date', label: 'Invited Date', sort: 0, hide: 0},
    {id: 3, key: 'invited_by', name: 'invited_by', label: 'Invited By', sort: 0, hide: 0},
    {id: 4, key: 'status', name: 'status', label: 'Invitation Status', sort: 0, hide: 0}
  ];
  notificationTypeList = [
    { id: 1, label: 'New User Notification', value: 'New User Notification', class: 'dot dot-new-user-notification' },
    { id: 2, label: 'New Company Notification', value: 'New Company Notification', class: 'dot dot-new-company-notification' },
  ];
  @ViewChild('allSelectedNotificationType') private allSelectedNotificationType: MatOption;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger,
    private userService: UserService,
    private exportService: ExportService
  ) {
    this.pageHeading = 'All Invitations';
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.searchString = '';
    this.selectedNotificationType = [...this.notificationTypeList.map(item => item.value), 0];
    this.selectedColumns = [
      {id: 1, key: 'invited_user', name: 'invited_user', label: 'Invited User', sort: 0, hide: 0},
      {id: 2, key: 'invited_date', name: 'invited_date', label: 'Invited Date', sort: 0, hide: 0},
      {id: 3, key: 'invited_by', name: 'invited_by', label: 'Invited By', sort: 0, hide: 0},
      {id: 4, key: 'status', name: 'status', label: 'Invitation Status', sort: 0, hide: 0}
    ];
    this.selectedSort = {key: 'Created Date', value: -1};
  }

  ngOnInit(): void {
    this.route.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
        this.companyName = data.franchisee.companyName;
        this.title = `All Invitations | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllInvitations(this.pageNo);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  getAllInvitations(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedNotificationType: this.selectedNotificationType,
      selectedSort: this.selectedSort,
    };
    this.userService.getAllInvitations(requestParams).then((invitationsListResponse: any) => {
      this.logger.info('INFO: -> UserinvitationsListComponent -> this.userService.getinvitationsByEmail -> invitationsListResponse',
      invitationsListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'invitations List refreshed.');
      this.invitations = invitationsListResponse.invitations;
      if (invitationsListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < invitationsListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        invitationsListResponse.totalCount);
      }
      this.totalCount = invitationsListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> UserinvitationsListComponent -> this.userService.getinvitationsByEmail -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.pageHeading, error.error);
    });
  }

  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllInvitations(this.pageNo);
    }
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllInvitations(this.pageNo);
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  clearSearch() {
    this.searchString = '';
    this.getAllInvitations(this.pageNo);
  }

  onChangeSearchText() {
    this.getAllInvitations(this.pageNo);
  }

  changeNotificationTypeFilter() {
    this.getAllInvitations(this.pageNo);
  }

  toggleAllTypeNotificationType() {
    if (this.allSelectedNotificationType.selected) {
      this.selectedNotificationType = [...this.notificationTypeList.map(item => item.value), 0];
    } else {
      this.selectedNotificationType = [];
    }
    this.changeNotificationTypeFilter();
  }

  notificationTypeTouchPerOne() {
    if (this.allSelectedNotificationType.selected) {
      this.allSelectedNotificationType.deselect();
    }
    if (this.selectedNotificationType.length === this.notificationTypeList.length) {
      this.allSelectedNotificationType.select();
    }
    this.changeNotificationTypeFilter();
  }

  resetNotificationTypeSelection() {
    this.allSelectedNotificationType.select();
    this.selectedNotificationType = [...this.notificationTypeList.map(item => item.value), 0];
    this.changeNotificationTypeFilter();
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  exportAction(option) {
    const fileName = 'all_invitations';
    const exportData = [];
    this.invitations.forEach(item => {
      const tempJson = {};
      this.selectedColumns.forEach(column => {
        if (typeof item[column.key] === 'object') {
          tempJson[column.label] = item[column.key][column.key1] &&
            item[column.key][column.key1] !== 'Not Available' ? item[column.key][column.key1] : '';
        } else {
          tempJson[column.label] = item[column.key] && item[column.key] !== 'Not Available' ? item[column.key] : '';
        }
      });
      exportData.push(tempJson);
    });
    if (option === 'excel') {
      this.exportService.exportAsExcelFile(exportData, fileName);
    } else if (option === 'csv') {
      this.exportService.exportToCsvFile(exportData, fileName);
    } else if (option === 'doc') {
      this.exportService.exportToDocFile(exportData, fileName, this.pageHeading, this.companyName);
    }
  }

  refreshData() {
    this.getAllInvitations(this.currentPage);
  }
}
