import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { LogService } from '@app/services/log.service';
import { PopupService } from '@app/services/popup.service';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import * as dayjs from 'dayjs';
import { environment } from '@env/environment';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { MapService } from '@services/map.service';

@Component({
  selector: 'app-event-logs',
  templateUrl: './event-logs.component.html',
  styleUrls: ['./event-logs.component.css']
})
export class EventLogsComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;

  searchString: string;
  selectedCategory: any;
  selectedType: any;
  selectedColumns: any;
  selectedSort: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  eventLogs: [];
  fromShowing: any;
  toShowing: any;

  categoriesList = [
    {id: 1, label: 'Debug', value: 'Debug', key: 'debug', class: 'dot dot-debug-category'},
    {id: 2, label: 'Error', value: 'Error', key: 'error', class: 'dot dot-error-category'},
    {id: 3, label: 'Info', value: 'Info', key: 'info', class: 'dot dot-info-category'},
    {id: 4, label: 'Verbose', value: 'Verbose', key: 'verbose', class: 'dot dot-verbose-category'},
    {id: 5, label: 'Warning', value: 'Warning', key: 'warning', class: 'dot dot-warning-category'},
  ];

  typesList = [
    {id: 1, label: 'Account', value: 'Account', key: 'account', class: 'dot dot-account-type'},
    {id: 2, label: 'Admin', value: 'Admin', key: 'admin', class: 'dot dot-admin-type'},
    {id: 3, label: 'Dev', value: 'Dev', key: 'dev', class: 'dot dot-dev-type'},
    {id: 4, label: 'Login', value: 'Login', key: 'login', class: 'dot dot-login-type'},
    {id: 5, label: 'Logout', value: 'Logout', key: 'logout', class: 'dot dot-logout-type'},
    {id: 6, label: 'Notification', value: 'Notification', key: 'notification', class: 'dot dot-notification-type'},
    {id: 7, label: 'Pay', value: 'Pay', key: 'pay', class: 'dot dot-pay-type'},
    {id: 8, label: 'Plan', value: 'Plan', key: 'plan', class: 'dot dot-plan-type'},
    {id: 8, label: 'Profile', value: 'Profile', key: 'profile', class: 'dot dot-profile-type'},
  ];
  columns = [
    {id: 0, name: 'createdDate', label: 'Created Date', sort: -1, hide: 0, width: '150px'},
    {id: 1, name: 'category', label: 'Category', sort: 0, hide: 0, width: '60px'},
    {id: 2, name: 'type', label: 'Type', sort: 0, hide: 0, width: '60px'},
    {id: 3, name: 'email', label: 'Email', sort: 0, hide: 0},
    {id: 4, name: 'accountNumber', label: 'Account No', sort: 0, hide: 0, width: '85px'},
    {id: 5, name: 'host', label: 'Host', sort: 0, hide: 0, width: '85px'},
    {id: 6, name: 'ipAddress', label: 'Ip Address', sort: 0, hide: 0, width: '85px'},
    {id: 7, name: 'browser', label: 'Browser', sort: 0, hide: 0, width: '85px'},
    {id: 8, name: 'message', label: 'Message', sort: 0, hide: 0},
    {id: 9, name: 'whatToDo', label: 'What to Do', sort: 0, hide: 0},
    {id: 10, name: 'whereItHappened', label: 'Where it happened', sort: 0, hide: 0},
  ];
  ranges = {
    Today: [dayjs().startOf('day'), dayjs().endOf('day')],
    Yesterday: [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').endOf('day')],
    'Last 7 Days': [dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')],
    'Last 30 Days': [dayjs().subtract(29, 'days').startOf('day'), dayjs().endOf('day')],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  };
  dateRange = {
    start: dayjs().subtract(29, 'days').startOf('day'),
    end: dayjs().endOf('day'),
  };
  selectedDateRange = {
    start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
    end: dayjs().endOf('day').format('MM-DD-YYYY'),
  };
  maxDate = dayjs();
  activeIndex: number;
  selectedGraphType: string;
  public lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [],
    labels: []
  };
  // public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    aspectRatio: 3,
    scales: {
      x: {},
      y: {
        min: 0,
        beginAtZero: true
      }
    }
  };
  public lineChartLegend = true;

  latitude = environment.GOOGLE_MAP.CENTER_LAT;
  longitude = environment.GOOGLE_MAP.CENTER_LNG;
  mapStyle = [{
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [{
      color: '#444444'
    }]
  }, {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [{
      color: '#f2f2f2'
    }]
  }, {
    featureType: 'poi',
    elementType: 'all',
    stylers: [{
      visibility: 'off'
    }]
  }, {
    featureType: 'road',
    elementType: 'all',
    stylers: [{
      saturation: -100
    }, {
      lightness: 45
    }]
  }, {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [{
      visibility: 'simplified'
    }]
  }, {
    featureType: 'road.arterial',
     elementType: 'labels.icon',
      stylers: [{
        visibility: 'off'
      }]
    }, {
      featureType: 'transit',
      elementType: 'all',
      stylers: [{
        visibility: 'off'
      }]
    }, {
      featureType: 'water',
      elementType: 'all',
      stylers: [{
        color: '#46bcec'
      }, {
        visibility: 'on'
      }]
    }];
  markers = [];
  totalCountForMapPlot: number;
  totalCountOfPlottedRecords: number;
  @ViewChildren('eventLogTabs') eventLogTabs: QueryList<MatTabGroup>;
  @ViewChild('allSelectedCategories') private allSelectedCategories: MatOption;
  @ViewChild('allSelectedType') private allSelectedType: MatOption;
  googleMapOptions: google.maps.MapOptions;
  isGoogleApiReady$: Observable<boolean>;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService,
    private titleService: Title,
    private logService: LogService,
    private commonUtility: CommonUtilityService,
    private popupService: PopupService,
    private logger: NGXLogger
  ) {
    this.pageHeading = 'Event Logs';
    this.searchString = '';
    this.selectedType = [...this.typesList.map(item => item.value), 0];
    this.selectedCategory = [...this.categoriesList.map(item => item.value), 0];
    this.selectedColumns = [
      {id: 0, name: 'createdDate', label: 'Created Date', sort: -1, hide: 0},
      {id: 1, name: 'category', label: 'Category', sort: 0, hide: 0},
      {id: 2, name: 'type', label: 'Type', sort: 0, hide: 0},
      {id: 3, name: 'email', label: 'Email', sort: 0, hide: 0},
      {id: 4, name: 'accountNumber', label: 'Account No', sort: 0, hide: 0},
      {id: 6, name: 'ipAddress', label: 'Ip Address', sort: 0, hide: 0},
      {id: 8, name: 'message', label: 'Message', sort: 0, hide: 0},
    ];
    this.selectedSort = {key: 'Created Date', value: -1};
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.activeIndex = 0;

    this.dateRange = {
      start: dayjs().subtract(29, 'days').startOf('day'),
      end: dayjs().endOf('day'),
    };
    this.selectedDateRange = {
      start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
      end: dayjs().endOf('day').format('MM-DD-YYYY'),
    };
    this.selectedGraphType = 'day';
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `Email Logs | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllEventLogs(this.pageNo);
    this.generateGraph();
    this.generateGeoMap();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  // #region SearchRelatedFun
  clearSearch() {
    this.searchString = '';
    this.getAllEventLogs(this.pageNo);
    this.generateGraph();
    this.generateGeoMap();
  }

  onChangeSearchText() {
    this.getAllEventLogs(this.pageNo);
    this.generateGraph();
    this.generateGeoMap();
  }
  // #endregion

  // #region CategoriesFilterFun
  changeCategoryFilter() {
    this.getAllEventLogs(this.pageNo);
    this.generateGraph();
    this.generateGeoMap();
  }

  openCategoriesInfo() {
    this.popupService.openEventLogCategoryInfoPopup();
  }

  toggleAllCategorySelection() {
    if (this.allSelectedCategories.selected) {
      this.selectedCategory = [...this.categoriesList.map(item => item.value), 0];
    } else {
      this.selectedCategory = [];
    }
    this.changeCategoryFilter();
  }

  categoryTouchPerOne() {
    if (this.allSelectedCategories.selected) {
      this.allSelectedCategories.deselect();
    }
    if (this.selectedCategory.length === this.categoriesList.length) {
        this.allSelectedCategories.select();
    }
    this.changeCategoryFilter();
  }

  resetCategorySelection() {
    this.allSelectedCategories.select();
    this.selectedCategory =
        [...this.categoriesList.map(item => item.value), 0];
    this.changeCategoryFilter();
  }

  changesTypeFilter() {
    this.getAllEventLogs(this.pageNo);
    this.generateGraph();
    this.generateGeoMap();
  }

  openTypeInfo() {
    this.popupService.openEventLogTypeInfoPopup();
  }

  toggleAllTypeSelection() {
    if (this.allSelectedType.selected) {
      this.selectedType = [...this.typesList.map(item => item.value), 0];
    } else {
      this.selectedType = [];
    }
    this.changesTypeFilter();
  }

  typeTouchPerOne() {
    if (this.allSelectedType.selected) {
      this.allSelectedType.deselect();
    }
    if (this.selectedType.length === this.typesList.length) {
        this.allSelectedType.select();
    }
    this.changesTypeFilter();
  }

  resetTypeSelection() {
    this.allSelectedType.select();
    this.selectedType =
        [...this.typesList.map(item => item.value), 0];
    this.changesTypeFilter();
  }
  // #endregion

  // #region TableFunctions
  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllEventLogs(this.pageNo);
    }
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllEventLogs(this.pageNo);
  }
  // #endregion

  datesUpdated(event) {
    if (event && event.startDate && event.endDate) {
      this.selectedDateRange.start = dayjs(event.startDate).format('MM-DD-YYYY');
      this.selectedDateRange.end = dayjs(event.endDate).format('MM-DD-YYYY');
      this.getAllEventLogs(this.pageNo);
      this.generateGraph();
      this.generateGeoMap();
    }
  }

  onTabChange(event: any){
    this.activeIndex = event.index;
    this.eventLogTabs.forEach(tab => {
      tab.realignInkBar();
   });
  }

  // #region MainFUnctions
  getAllEventLogs(pageNo: number): void {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedCategory: this.selectedCategory,
      selectedType: this.selectedType,
      selectedSort: this.selectedSort,
      selectedDateRange: this.selectedDateRange
    };
    this.logService.getAllEventLogs(requestParams).then((eventLogsListResponse: any) => {
      this.logger.info('INFO: -> EventLogsComponent -> this.logService.getAllEventLogs -> eventLogsListResponse', eventLogsListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'Event logs List refreshed.');
      this.eventLogs = eventLogsListResponse.eventLogs;
      if (eventLogsListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < eventLogsListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        eventLogsListResponse.totalCount);
      }
      this.totalCount = eventLogsListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> EventLogsComponent -> this.logService.getAllEventLogs -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  private generateGraph(): void {
    const requestParams = {
      searchText: this.searchString,
      selectedCategory: this.selectedCategory,
      selectedType: this.selectedType,
      selectedDateRange: this.selectedDateRange,
      selectedGraphType: this.selectedGraphType
    };
    this.logService.getEventLogsGraphPlots(requestParams).then((response: any) => {
      this.logger.info('INFO: -> EventLogsComponent -> this.logService.getEventLogsGraphPlots -> response', response);
      this.lineChartData = {
        datasets: response.data.map((data, index) => (
          Object.assign(data, response.colors[index], {
            tension: 0.5,
            fill: true
          }))
        ),
        labels: response.labels
      };
    }).catch(error => {
      this.logger.error('DEBUG: -> EventLogsComponent -> this.logService.getEventLogsGraphPlots -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails('Generate Graph', error.error);
    });
  }

  private generateGeoMap(): void {
    this.googleMapOptions = {
      zoom: environment.GOOGLE_MAP.ZOOM,
      center: {
        lat: environment.GOOGLE_MAP.CENTER_LAT,
        lng: environment.GOOGLE_MAP.CENTER_LNG,
      }
    };
    this.isGoogleApiReady$ = this.mapService.getGoogleMapsApi()
      .pipe(
        map(() => true),
        catchError((error) => {
          this.logger.error('ERROR: -> EventLogsComponent -> OnInit -> generateGeoMap -> getGoogleMapsApi -> error', error);
          return of(false);
        }),
        takeUntil(this.componentDestroyed$)
      );
    const requestParams = {
      searchText: this.searchString,
      selectedCategory: this.selectedCategory,
      selectedType: this.selectedType,
      selectedDateRange: this.selectedDateRange
    };
    this.logService.getEventLogsMapPlots(requestParams).then((response: any) => {
      this.logger.info('INFO: -> EventLogsComponent -> this.logService.getEventLogsMapPlots -> response', response);
      this.totalCountOfPlottedRecords = response.totalCountOfPlottedRecords;
      this.totalCountForMapPlot = response.totalCount;
      this.markers = response.markers.map((marker) => (
        {
          position: { lat: marker.latitude, lng: marker.longitude },
          title: marker.label
        }
      ));
    }).catch(error => {
      this.logger.error('ERROR: -> EventLogsComponent -> this.logService.getEventLogsMapPlots -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails('Generate Map', error.error);
    });
  }
  // #endregion

  changeGraphType(type: string) {
    this.selectedGraphType = type;
    this.generateGraph();
  }

  refreshData() {
    this.getAllEventLogs(this.currentPage);
    this.generateGeoMap();
    this.generateGraph();
  }
}
