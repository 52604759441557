import { Component, OnDestroy, OnInit } from '@angular/core';
import { Franchisee } from '@app/models/franchisee';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { FranchiseeService } from '@app/services/franchisee.service';
import { MultiFactorService } from '@app/services/multi-factor.service';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-multi-factor-setup-popup',
  templateUrl: './multi-factor-setup-popup.component.html',
  styleUrls: ['./multi-factor-setup-popup.component.css']
})
export class MultiFactorSetupPopupComponent implements OnInit, OnDestroy {
  logo: string = environment.CBK_LOGO_LIGHT_IMAGE;
  franchiseeCode: string;
  companyName: string;
  currentState = 'Get Started';
  states: string[] = ['Get Started', 'Security Code', 'Recovery Email', 'Verify Email', 'Completed'];
  manualKeysVisibility = false;

  // inputs
  manualKey: string;
  qrCode: string;
  email: string;

  securityCode: string;
  recoveryEmail: string;
  verificationCode: string;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    public modal: NgbActiveModal,
    private franchiseeService: FranchiseeService,
    private ngxService: NgxUiLoaderService,
    private multiFactorService: MultiFactorService,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.franchiseeService.getFranchiseeDetails
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((franchiseDetails: Franchisee) => {
        this.companyName = franchiseDetails.companyName;
        this.franchiseeCode = franchiseDetails.franchiseeCode;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  changeState(currentState: string, type: number): void {
    this.ngxService.start();
    // type 0 means back
    // type 1 means next
    const currentIndex = this.states.indexOf(currentState);
    if (type === 1) {
      switch (currentIndex) {
        case 1:
          this.validateSecurityCode(currentIndex);
          break;
        case 2:
          this.addRecoveryEmail(currentIndex);
          break;
        case 3:
          this.verifyRecoveryEmail(currentIndex);
          break;
        default:
          this.currentState = this.states[currentIndex + 1];
          break;
      }
    } else if (type === 0) {
      this.currentState = this.states[currentIndex - 1];
    }
    this.ngxService.stop();
    this.ngxService.stopBackground();
  }

  // stage functions
  switchManualOption(): void {
    this.manualKeysVisibility = !this.manualKeysVisibility;
  }

  validateSecurityCode(currentIndex: number) {
    if (this.securityCode) {
      this.multiFactorService.validateSecurityCode(this.manualKey, this.securityCode).then(response => {
        this.logger.info('INFO: -> MultiFactorSetupPopupComponent -> validateSecurityCode -> response', response);
        this.currentState = this.states[currentIndex + 1];
      }).catch(error => {
        this.logger.info('ERROR: -> MultiFactorSetupPopupComponent -> validateSecurityCode -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails('Security Code', error.error);
      });
    } else {
      this.commonUtility.displayErrorToast('Security Code', 'Please enter a valid security code');
    }
  }

  addRecoveryEmail(currentIndex: number) {
    if (this.recoveryEmail) {
      this.multiFactorService.addRecoveryEmail(this.recoveryEmail).then(response => {
        this.logger.info('INFO: -> MultiFactorSetupPopupComponent -> addRecoveryEmail -> response', response);
        this.currentState = this.states[currentIndex + 1];
      }).catch(error => {
        this.logger.error('ERROR: -> MultiFactorSetupPopupComponent -> addRecoveryEmail -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails('Recovery Email', error.error);
      });
    } else {
      this.commonUtility.displayErrorToast('Recovery Email', 'Please enter a valid recovery email');
    }
  }

  verifyRecoveryEmail(currentIndex: number) {
    if (this.verificationCode) {
      this.multiFactorService.verifyRecoveryEmail(this.verificationCode).then(response => {
        this.logger.info('INFO: -> MultiFactorSetupPopupComponent -> verifyRecoveryEmail -> response', response);
        this.currentState = this.states[currentIndex + 1];
      }).catch(error => {
        this.logger.error('ERROR: -> MultiFactorSetupPopupComponent -> verifyRecoveryEmail -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails('Verification Code', error.error);
      });
    } else {
      this.commonUtility.displayErrorToast('Verify Email', 'Please enter a valid verification code');
    }
  }

  closePopupAndRefreshTab(currentState: string) {
    this.modal.close();
    if (currentState === 'Completed') {
      window.location.reload();
    }
  }

}
