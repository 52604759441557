import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RateLimitResponse } from '@app/models/rate-limit-response';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable } from 'rxjs';
import { RateLimitService } from './rate-limit.service';

@Injectable({
  providedIn: 'root'
})
export class RateLimitResolverService {
  constructor(
    private router: Router,
    private rateLimitService: RateLimitService,
    private logger: NGXLogger
  ) { }

  resolve(): Promise<object> | Observable<never> {
    return this.rateLimitService.getRateLimit().then((response: RateLimitResponse) => {
      this.logger.info('INFO: -> RateLimitResolverService -> this.rateLimitService.getRateLimit -> response', response);
      this.rateLimitService.changeRateLimit(response);
      return response;
    }).catch(error => {
      this.logger.error('ERROR: -> RateLimitResolverService -> error', error);
      this.router.navigate(['/invalid-link']);
      return EMPTY;
    });
  }
}
