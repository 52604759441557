<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 login-content-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}</h5>
            </div>
        </div>
        <div class="ibox col-md-12 login-content-filters-container">
            <div class="ibox-title">
                <form [formGroup]="loginContentForm" (ngSubmit)="saveLoginContent()">
                    <div class="pt-2 pb-2">
                        <div formControlName="html" [ngxSummernote]="config" (blur)="onBlur()"
                            (mediaDelete)="onDelete()" (summernoteInit)="summernoteInit()">
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-primary me-2" type="button" (click)="restoreContentToHtml()">Restore to
                            Default</button>
                        <button class="btn btn-primary me-2" type="button" (click)="getContent()">Restore to Live</button>
                        <button class="btn btn-primary me-2" type="submit" [disabled]="!loginContentForm.valid">Save
                            Changes</button>
                    </div>
                </form>
                <ngx-ui-loader [loaderId]="'login-content-loader'" *ngIf="primaryColor" fgsColor="{{primaryColor}}"
    pbColor="{{primaryColor}}"></ngx-ui-loader>
            </div>
        </div>
    </div>
</div>
