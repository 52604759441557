import { Component } from '@angular/core';

@Component({
  selector: 'app-franchisee',
  templateUrl: './franchisee.component.html',
  styleUrls: ['./franchisee.component.css']
})
export class FranchiseeComponent {

  constructor() { }

}
