<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 account-container">
    <div class="row white-bg">
        <div class="ibox col-md-12 account-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <div class="mb-3 account-filter-group">
                            <label for="search"  matTooltip="You can search by CompanyName, SubscriptionOwnerEmail or AccountNumber fields">Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString"
                                placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="version" matTooltip="You can select multiple PlanNames">Plan Name</label>
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="selectedPlans" multiple
                                            placeholder="Select Plan"
                                    (valueChange)="changePlanFilter($event)">
                                    <mat-option *ngFor="let item of plansList" [value]="item.value">{{item.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="version" matTooltip="You can select multiple Status">Status</label>
                            <mat-form-field appearance="outline">
                                <mat-select [(ngModel)]="selectedStatus" multiple
                                            placeholder="Select Status"
                                            (valueChange)="changeStatusFilter($event)">
                                    <mat-option *ngFor="let item of statusList" [value]="item.value">{{item.label}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <div class="mb-3 account-filter-group">
                            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                            <mat-select [compareWith]="compareFn" class="form-control"
                                        panelClass="mat-select-autowidth-panel-class"
                                        (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-1 col-lg-1">

                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 text-end">
                        <div class="mb-3 account-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage" (selectionChange)="changeDisplayPerPageItem($event)">
                              <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}</mat-option>
                            </mat-select>&nbsp; account(s)
                          </div>
                          <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} entries
                          </p>
                          <pagination-controls id="all-accounts" (pageChange)="getAllAccounts($event)" responsive="true"
                          previousLabel="Previous"
                          nextLabel="Next"
                          maxSize="3"
                          ></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row account-result-container white-bg mt-2">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <div class="account-list-box">
                    <table class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover" id="account-table">
                        <thead>
                            <tr>
                            <ng-container *ngFor="let column of columns; let i = index">
                                <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting" [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}" (click)="sortBy(column.label)">{{column.label}}</th>
                            </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let account of accounts | paginate: { id: 'all-accounts', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                                <td *ngIf="checkColumnVisibility(columns[0])"><a *ngIf="account.accountNumber" [routerLink]="['/accounts/account-details', account.accountNumber]" routerLinkActive="router-link-active" >{{account.accountNumber}}</a><span *ngIf="!account.accountNumber" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[1])"><a *ngIf="account.email">{{account.email}}</a><span *ngIf="!account.email" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[2])"><a *ngIf="account.subscriptionOwnerEmail">{{account.subscriptionOwnerEmail}}</a><span *ngIf="!account.subscriptionOwnerEmail" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[3])"><a *ngIf="account.companyName">{{account.companyName}}</a><span *ngIf="!account.companyName" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[4])"><span *ngIf="account.status"><span class="acs-{{account.status}}-status list-view-status" matTooltip="{{account.status}}"></span>{{account.status}}</span><span *ngIf="!account.status" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[5])"><span class="badge badge-{{account.planClassName}}">{{account.planName}}</span></td>
                                <td *ngIf="checkColumnVisibility(columns[6])"><span *ngIf="account.franchisee?.code && account.franchisee?.name">{{account.franchisee.code}} - {{account.franchisee.name}}</span><span *ngIf="!account.franchisee?.code || !account.franchisee?.name" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[7])"><span *ngIf="account.closingDate">{{account.closingDate}}</span><span *ngIf="!account.closingDate" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[8])"><span *ngIf="account.accepted">{{account.accepted}}</span><span *ngIf="!account.accepted" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[9])"><div class="subscription-status"><span class="payment-status-{{account.paymentStatus.className}}">{{account.paymentStatus.status}} </span><span *ngIf="account.paymentStatus.status !== 'Trial Expired'" class="label-yellow-date">{{account.paymentStatus.date}}</span></div></td>
                                <td *ngIf="checkColumnVisibility(columns[10])"><span *ngIf="account.createdDate">{{account.createdDate}}</span><span *ngIf="!account.createdDate" class="not-available">Not Available</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
