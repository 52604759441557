<div class="update-accountant-status-box p-2">
    <div class="row">
        <div class="col-md-12">
            <form [formGroup]="updateStatusForm" role="form" (ngSubmit)="updateStatusSubmit()"  novalidate>
                <div class="modal-body">
                    <div class="form-actions">
                        <div class="mb-3">
                            <label for="">Select Status <span class="required">*</span></label>
                            <select name="status" class="form-control" formControlName="accountantStatus"
                                (change)="changeStatus()">
                                <option *ngFor="let status of accountantStatusOptions" [ngValue]="status.value">
                                    {{ status.name }}
                                </option>
                            </select>
                            <div *ngIf="
                                  updateStatusFC.accountantStatus.invalid &&
                                  (updateStatusFC.accountantStatus.dirty ||
                                    updateStatusFC.accountantStatus.touched)
                                " class="invalid-feedback">
                                <div *ngIf="updateStatusFC.accountantStatus.errors.required">
                                    Status is required.
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label>Select Pricing Level
                                <span class="required">*</span></label>
                            <select name="userType" class="form-control" formControlName="userType">
                                <option *ngFor="let item of userTypeOptions | sortBy : 'id'" [ngValue]="item.name">
                                    {{item.label}}
                                </option>
                            </select>
                            <div *ngIf="
                                  updateStatusFC.userType.invalid &&
                                  (updateStatusFC.userType.dirty ||
                                    updateStatusFC.userType.touched)
                                " class="alert alert-danger">
                                <div *ngIf="updateStatusFC.userType.errors.required">
                                    Pricing Level is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="submit" [disabled]="!updateStatusForm.valid">
                        Save Change
                    </button>
                </div>
            </form>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="'update-status-loader'" *ngIf="primaryColor" fgsColor="{{primaryColor}}"
    pbColor="{{primaryColor}}"></ngx-ui-loader>
</div>
