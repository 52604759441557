import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { UserService } from '@app/services/user.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-developer-settings',
  templateUrl: './developer-settings.component.html',
  styleUrls: ['./developer-settings.component.css']
})
export class DeveloperSettingsComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;
  developerPortalAccess: boolean;
  pageHeading: string;
  primaryColor: string;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private commonUtility: CommonUtilityService,
    private ngxService: NgxUiLoaderService
  ) {
    this.ngxService.startLoader('developer-settings-loader');
    this.pageHeading = 'Change Developer Portal Access';
    this.developerPortalAccess = false;
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
      });
    if (this.userEmail) {
      this.userService.checkUserIsExists(this.userEmail).then((userDetails: any) => {
        this.developerPortalAccess = userDetails?.decoDeveloperAccess;
      }).catch(error => {
        this.commonUtility.displayErrorToast(this.pageHeading, error.error);
      });
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, 'User Email is missing.');
    }
    this.ngxService.stopLoader('developer-settings-loader');
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  changeDeveloperPortalAccess() {
    const params = {
      userEmail: this.userEmail,
      developerPortalAccess: this.developerPortalAccess
    };
    this.userService.updateDeveloperPortalAccess(params).then((developerResponse: any) => {
      this.commonUtility.displaySuccessToast(this.pageHeading, developerResponse.message);
    }).catch(error => {
      this.commonUtility.displayErrorToastWithTechDetails(this.pageHeading, error.error);
    });
  }

}
