import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { UserService } from '@app/services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-change-pricing-level',
  templateUrl: './change-pricing-level.component.html',
  styleUrls: ['./change-pricing-level.component.css']
})
export class ChangePricingLevelComponent implements OnInit {
  userEmail: string;
  currentPricingLevel: string;
  changePricingForm: UntypedFormGroup;
  pageHeading: string;
  userTypeOptions = [];
  constructor(
    private modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private utilityService: CommonUtilityService,
    private userService: UserService,
    private router: Router,
    private logger: NGXLogger
  ) {
    this.pageHeading = 'Change Pricing Level';
    this.userTypeOptions = [
      { id: 1, name: 'Customers - Default', label: 'Customers - Default', class: 'dot-customers---default' },
      { id: 2, name: 'Customers - Default 2024', label: 'Customers - Default 2024', class: 'dot-customers---default-2024' },
      { id: 3, name: 'Customers - Early Adopters', label: 'Customers - Early Adopters', class: 'dot-customers---early-adopters' }
      // { id: 7, name: 'Partner - Reseller', label: 'Partner - Reseller', class: 'dot-partner---reseller' }
    ];
  }

  ngOnInit(): void {
    this.changePricingForm = this.fb.group({
      userEmail: [this.userEmail, [Validators.required]],
      pricingLevel: [this.currentPricingLevel ? this.currentPricingLevel : '', [Validators.required]]
    });
  }

  get changePricingFormControl() {
    return this.changePricingForm.controls;
  }

  cancel() {
    this.modal.dismiss();
  }

  changePricingLevel() {
    if (this.changePricingForm.valid) {
      this.userService.changeUserPricingLevel(this.changePricingForm.value).then((response: any) => {
        this.logger.info('INFO: -> ChangePricingLevelComponent -> this.userService.changeUserPricingLevel -> response', response);
        this.utilityService.displaySuccessToast(this.pageHeading, response.message);
        this.modal.close();
        this.router.navigate(['/users/all'], { queryParams: { search: this.userEmail }});
      }).catch((error: any) => {
        this.logger.error('ERROR: -> ChangePricingLevelComponent -> this.userService.changeUserPricingLevel -> error', error);
        this.utilityService.displayErrorToastWithTechDetails(this.pageHeading, error?.error?.error);
      });
    } else {
      this.utilityService.displayErrorToast(this.pageHeading, 'Please fill all the required field.');
    }
  }

}
