import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { SyncEngineService } from '@app/services/sync-engine.service';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sync-engine',
  templateUrl: './sync-engine.component.html',
  styleUrls: ['./sync-engine.component.css']
})
export class SyncEngineComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;

  searchString: string;
  selectedStatus: any;
  selectedColumns: any;
  selectedSort: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  processes: [];
  fromShowing: any;
  toShowing: any;

  statusList = [
    {id: 1, label: 'Pending', value: 'pending', key: 'pending', class: 'dot-pending-status'},
    {id: 2, label: 'Success', value: 'success', key: 'success', class: 'dot-success-status'},
    {id: 3, label: 'Processing', value: 'processing', key: 'processing', class: 'dot-processing-status'},
    {id: 4, label: 'Failed', value: 'failed', key: 'failed', class: 'dot-failed-status'}
  ];
  columns = [
    {id: 1, name: 'createdDate', label: 'Created Date', sort: -1, hide: 0},
    {id: 2, name: 'source', label: 'Source', sort: 0, hide: 0},
    {id: 3, name: 'status', label: 'Status', sort: 0, hide: 0},
    {id: 4, name: 'tries', label: 'Number of Tries', sort: 0, hide: 0},
    {id: 5, name: 'eventType', label: 'Event Type', sort: 0, hide: 0},
    {id: 6, name: 'subscriptionId', label: 'Subscription Id', sort: 0, hide: 0},
    {id: 7, name: 'customerId', label: 'Customer Id', sort: 0, hide: 0},
    {id: 8, name: 'customerName', label: 'Customer Name', sort: 0, hide: 0},
  ];
  @ViewChild('allSelectedStatus') private allSelectedStatus: MatOption;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private syncEngineService: SyncEngineService,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger
  ) {
    this.pageHeading = 'Sync Engine Processes';
    this.searchString = '';
    this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    this.selectedColumns = [
      {id: 1, name: 'createdDate', label: 'Created Date', sort: -1, hide: 0},
      {id: 2, name: 'source', label: 'Source', sort: 0, hide: 0},
      {id: 3, name: 'status', label: 'Status', sort: 0, hide: 0},
      {id: 4, name: 'tries', label: 'Number of Tries', sort: 0, hide: 0},
      {id: 5, name: 'eventType', label: 'Event Type', sort: 0, hide: 0},
      {id: 6, name: 'subscriptionId', label: 'Subscription Id', sort: 0, hide: 0},
      {id: 7, name: 'customerId', label: 'Customer Id', sort: 0, hide: 0},
      {id: 8, name: 'customerName', label: 'Customer Name', sort: 0, hide: 0},
    ];
    this.selectedSort = {key: 'Created Date', value: -1};
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
  }

  ngOnInit(): void {
    this.route.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `Sync Engine | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllProcesses(this.pageNo);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  clearSearch() {
    this.searchString = '';
    this.getAllProcesses(this.pageNo);
  }

  onChangeSearchText() {
    this.getAllProcesses(this.pageNo);
  }

  changeStatusFilter() {
    this.getAllProcesses(this.pageNo);
  }

  toggleAllTypeStatus() {
    if (this.allSelectedStatus.selected) {
      this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    } else {
      this.selectedStatus = [];
    }
    this.changeStatusFilter();
  }

  statusTouchPerOne() {
    if (this.allSelectedStatus.selected) {
      this.allSelectedStatus.deselect();
    }
    if (this.selectedStatus.length === this.statusList.length) {
        this.allSelectedStatus.select();
    }
    this.changeStatusFilter();
  }

  resetStatusSelection() {
    this.allSelectedStatus.select();
    this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    this.getAllProcesses(this.pageNo);
  }

  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllProcesses(this.pageNo);
    }
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllProcesses(this.pageNo);
  }

  getAllProcesses(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedStatus: this.selectedStatus,
      selectedSort: this.selectedSort,
    };
    this.syncEngineService.getAllProcesses(requestParams).then((processesListResponse: any) => {
      this.logger.info('INFO: -> SyncEngineComponent -> this.syncEngineService.getAllProcesses -> processesListResponse',
      processesListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'Sync Engine Processes List refreshed.');
      this.processes = processesListResponse.processes;
      if (processesListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < processesListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        processesListResponse.totalCount);
      }
      this.totalCount = processesListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> SyncEngineComponent -> this.syncEngineService.getAllProcesses -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  refreshData() {
    this.getAllProcesses(this.currentPage);
  }

}
