import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-event-log-category-info',
  templateUrl: './event-log-category-info.component.html',
  styleUrls: ['./event-log-category-info.component.css']
})
export class EventLogCategoryInfoComponent {

  constructor(
    public modal: NgbActiveModal
  ) { }

}
