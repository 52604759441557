<div class="p-2">
    <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <div class="mb-3 account-filter-group">
                <label for="search"  matTooltip="You can search by CompanyName, SubscriptionOwnerEmail or AccountNumber fields">Search</label>
                <input type="text" class="form-control margin-list-box" id="searchinput"
                    (keyup)="onChangeSearchText()" [(ngModel)]="searchString"
                    placeholder="Search...">
                <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                    (click)="clearSearch()"></span>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
            <mat-select [compareWith]="compareFn" class="form-control"
                        panelClass="mat-select-autowidth-panel-class"
                        (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
            </mat-select>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3"></div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-end">
            <div class="mb-3 account-filter-group item-per-page-option">
                Display &nbsp;<mat-select [(ngModel)]="itemsPerPage" (selectionChange)="changeDisplayPerPageItem($event)">
                  <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}</mat-option>
                </mat-select>&nbsp; notification(s)
            </div>
            <p class="mb-0">
            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} notifications
            </p>
            <pagination-controls id="all-notifications" (pageChange)="getAllNotifications($event)" responsive="true"
              previousLabel="Previous"
              nextLabel="Next"
              maxSize="3"
            ></pagination-controls>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="responsive table table-striped table-bordered no-footer hover" id="all-notifications">
                <thead>
                    <tr>
                    <ng-container *ngFor="let column of columns; let i = index">
                        <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting" [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}" (click)="sortBy(column.label)">{{column.label}}</th>
                    </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let notification of notifications | paginate: { id: 'all-notifications', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                        <td *ngIf="checkColumnVisibility(columns[0])"><span *ngIf="notification.status" class="badge badge-{{notification.notificationType}}">{{notification.status}}</span><span *ngIf="!notification.status" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[1])"><span *ngIf="notification.message">{{notification.message}}</span><span *ngIf="!notification.message" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[2])"><span *ngIf="notification.tenant">{{notification.tenant}}</span><span *ngIf="!notification.tenant" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[3])"><span *ngIf="notification.createdDate">{{notification.createdDate}}</span><span *ngIf="!notification.createdDate" class="not-available">Not Available</span></td>
                    </tr>
                    <tr *ngIf="notifications && notifications.length === 0">
                        <td colspan="4" class="not-available text-center">No notification found at this time.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
