import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  apiUrl = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient,
  ) { }

  getAllEmailLogs(requestBody: object): Promise<any> {
    return this.httpClient.post<any>(this.apiUrl + '/get-all-email-logs', requestBody).toPromise();
  }

  getEmailLogsGraphPlots(requestBody: object): Promise<any> {
    return this.httpClient.post<any>(this.apiUrl + '/get-email-logs-graph-plots', requestBody).toPromise();
  }

  getAllEventLogs(requestBody: object): Promise<any> {
    return this.httpClient.post<any>(this.apiUrl + '/get-all-event-logs', requestBody).toPromise();
  }

  getEventLogsGraphPlots(requestBody: object): Promise<any> {
    return this.httpClient.post<any>(this.apiUrl + '/get-event-logs-graph-plots', requestBody).toPromise();
  }

  getEventLogsMapPlots(requestBody: object): Promise<any> {
    return this.httpClient.post<any>(this.apiUrl + '/get-event-logs-map-plots', requestBody).toPromise();
  }

  getAllSMLogs(requestBody: object): Promise<any> {
    return this.httpClient.post<any>(this.apiUrl + '/get-all-sm-logs', requestBody).toPromise();
  }
}
