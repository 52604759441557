import { Component, OnDestroy, OnInit } from '@angular/core';
import { Franchisee } from '@app/models/franchisee';
import { FranchiseeService } from '@app/services/franchisee.service';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-inner-footer',
  templateUrl: './inner-footer.component.html',
  styleUrls: ['./inner-footer.component.css']
})
export class InnerFooterComponent implements OnInit, OnDestroy {

  copyrightTerms: string;
  copyrightTermsOn: boolean;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private franchiseeService: FranchiseeService,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.franchiseeService.getFranchiseeDetails
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((franchiseDetails: Franchisee) => {
        this.logger.info('INFO: -> LoginComponent -> .subscribe -> franchiseDetails', franchiseDetails);
        this.copyrightTermsOn = franchiseDetails.copyrightTermsOn;
        this.copyrightTerms = franchiseDetails.copyrightTerms;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
