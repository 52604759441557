import { Injectable } from '@angular/core';
import { MultiFactorLoginPopupComponent } from '@app/components/multi-factor-login-popup/multi-factor-login-popup.component';
import { ChangeBillingStatusComponent } from '@app/components/private/accounts/change-billing-status/change-billing-status.component';
import { ExtendTrialComponent } from '@app/components/private/accounts/extend-trial/extend-trial.component';
import { EmailLogStatusInfoComponent } from '@app/components/private/logs/email-log-status-info/email-log-status-info.component';
import { EventLogCategoryInfoComponent } from '@app/components/private/logs/event-log-category-info/event-log-category-info.component';
import { EventLogTypeInfoComponent } from '@app/components/private/logs/event-log-type-info/event-log-type-info.component';
import { ComposeMessageComponent } from '@app/components/private/messages/compose-message/compose-message.component';
import { MultiFactorDisablePopupComponent } from
    '@app/components/private/security/multi-factor-disable-popup/multi-factor-disable-popup.component';
import { MultiFactorSetupPopupComponent } from
    '@app/components/private/security/multi-factor-setup-popup/multi-factor-setup-popup.component';
import { ChangePricingLevelComponent } from '@app/components/private/users/change-pricing-level/change-pricing-level.component';
import { ExtendPlaygroundTrialComponent } from '@app/components/private/users/extend-playground-trial/extend-playground-trial.component';
import { User } from '@app/models/user';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    private modalService: NgbModal,
    private config: NgbModalConfig,
    private ngxService: NgxUiLoaderService) {
      config.backdrop = 'static';
      config.keyboard = false;
  }

  openExtendTrialPopup(accountNumber: string) {
    this.ngxService.start();
    const ref = this.modalService.open(ExtendTrialComponent, { size: 'lg' }).componentInstance;
    ref.accountNumber = accountNumber;
    this.ngxService.stop();
  }

  openBillingStatusPopup(accountNumber: string) {
    this.ngxService.start();
    const ref = this.modalService.open(ChangeBillingStatusComponent, { size: 'lg' }).componentInstance;
    ref.accountNumber = accountNumber;
    this.ngxService.stop();
  }

  openExtendPlaygroundTrialPopup(userEmail: string) {
    this.ngxService.start();
    const ref = this.modalService.open(ExtendPlaygroundTrialComponent, { size: 'lg' }).componentInstance;
    ref.userEmail = userEmail;
    this.ngxService.stop();
  }

  openEmailLogStatusInfoPopup() {
    this.ngxService.start();
    this.modalService.open(EmailLogStatusInfoComponent, { size: 'lg' });
    this.ngxService.stop();
  }

  openEventLogCategoryInfoPopup() {
    this.ngxService.start();
    this.modalService.open(EventLogCategoryInfoComponent, { size: 'lg' });
    this.ngxService.stop();
  }

  openEventLogTypeInfoPopup() {
    this.ngxService.start();
    this.modalService.open(EventLogTypeInfoComponent, { size: 'lg' });
    this.ngxService.stop();
  }

  openComposeMessagePopup(userEmail: string) {
    this.ngxService.start();
    const ref = this.modalService.open(ComposeMessageComponent, { size: 'lg' }).componentInstance;
    ref.userEmail = userEmail;
    this.ngxService.stop();
  }

  openChangePricingLevelPopup(userEmail: string, currentPricingLevel: string) {
    this.ngxService.start();
    const ref = this.modalService.open(ChangePricingLevelComponent, { size: 'lg' }).componentInstance;
    ref.userEmail = userEmail;
    ref.currentPricingLevel = currentPricingLevel;
    this.ngxService.stop();
  }

  openLoginMFAPopup(user: User) {
    this.ngxService.start();
    const ref = this.modalService.open(MultiFactorLoginPopupComponent, { size: 'lg' }).componentInstance;
    ref.user = user;
    this.ngxService.stop();
  }

  openEnableMFAPopup(qrCode: string, manualKey: string, email: string) {
    this.ngxService.start();
    const ref = this.modalService.open(MultiFactorSetupPopupComponent, { size: 'lg' }).componentInstance;
    ref.qrCode = qrCode;
    ref.manualKey = manualKey;
    ref.email = email;
    this.ngxService.stop();
  }

  openDisableMFAPopup() {
    this.ngxService.start();
    this.modalService.open(MultiFactorDisablePopupComponent, { size: 'lg' });
    this.ngxService.stop();
  }
}


