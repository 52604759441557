<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-md-12">
    <h2>Monitoring</h2>
    <ol class="breadcrumb">
      <li>
        <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
      </li>
      <li class="active">
        Monitoring
      </li>
    </ol>
  </div>
</div>
<div class="health-status-heading">
  <div class="health-status-text">
    <h5>Health Status</h5>
  </div>
  <div>
    <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()"><i class="fa fa fa-refresh"></i></a>
  </div>
</div>
<div class="col-md-12 monitoring-container">
  <div class="table-content-wrapper">
    <ag-grid-angular class="ag-theme-balham"
                     [gridOptions]="gridOptions"
                     [modules]="gridModules">
    </ag-grid-angular>
  </div>
</div>

