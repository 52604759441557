import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  catToolURL: string = environment.CAT_URL;
  constructor(private httpClient: HttpClient) {}

  getAccountsCountBasedOnType(accountType: string) {
    let serviceUrl = '';
    switch (accountType) {
      case 'PAID':
        serviceUrl = this.catToolURL + '/get_total_paid_clients';
        break;
      case 'PAST_DUE':
        serviceUrl = this.catToolURL + '/get_total_past_due_clients';
        break;
      case 'CANCELED':
        serviceUrl = this.catToolURL + '/get_total_cancelled_clients';
        break;
      case 'UN_BILLED':
        serviceUrl = this.catToolURL + '/get_total_un_billed_clients';
        break;
      case 'TRIAL_EXPIRES':
        serviceUrl = this.catToolURL + '/get_total_trial_expires_clients';
        break;
      case 'TRIAL_EXPIRED':
        serviceUrl = this.catToolURL + '/get_total_trial_expired_clients';
        break;
      case 'SUSPENDED':
        serviceUrl = this.catToolURL + '/get_total_suspended_clients';
        break;
      case 'PROCESSING':
        serviceUrl = this.catToolURL + '/get_total_processing_clients';
        break;
      default:
        break;
    }
    return this.httpClient.get<any>(serviceUrl).toPromise();
  }

  getAccountsCountBasedOnPaymentStatus(): Observable<{
    count: { subscriptionStatusCounts: [{ _id: string; count: number }] };
  }> {
    const serviceUrl = `${this.catToolURL}/get-total-accounts-based-payment`;
    return this.httpClient.get<{
      count: { subscriptionStatusCounts: [{ _id: string; count: number }] };
    }>(serviceUrl);
  }

  getTrialAccountsList(type: string) {
    let serviceUrl = '';
    if (type === 'NEXT_WEEK') {
      serviceUrl = this.catToolURL + '/get_client_is_going_to_trial_expired';
    } else if (type === 'LAST_WEEK') {
      serviceUrl = this.catToolURL + '/get_client_has_trial_expired_last_week';
    }
    return this.httpClient.get<any>(serviceUrl).toPromise();
  }
}
