<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 partner-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}</h5>
            </div>
        </div>
        <div class="ibox col-md-12 partner-box p-0">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-md-12 partner-padding">
                        <form class="check-user-form"
                            [formGroup]="emailCheckForm" (ngSubmit)="checkEmail()" novalidate>
                            <div class="mb-3">
                                <label for="email">Email address <span class="required">*</span></label>
                            </div>
                            <div class="mb-3 ps-2">
                                <input type="email" name="email" class="form-control" formControlName="email" id="email"
                                    placeholder="name@example.com">
                                <div *ngIf="checkUserForm.email.invalid && (checkUserForm.email.dirty || checkUserForm.email.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="checkUserForm.email.errors.required">Email address is required.</div>
                                    <div *ngIf="checkUserForm.email.errors.email">Please enter a valid email address.
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 ps-2">
                                <button type="submit" class="btn btn-primary"
                                [disabled]="!emailCheckForm.valid">OK</button>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-12 partner-padding" *ngIf="submitted">
                        <div class="btn-group">
                            <button (click)="openSection('VIEW-PROFILE')" [disabled]="!enableViewProfileButton"
                                class="btn btn-primary">
                                View Profile
                            </button>
                            <button (click)="openSection('EDIT-PROFILE')" [disabled]="!enableProfileButton"
                                class="btn btn-primary">
                                {{ btnText }}
                            </button>
                            <button class="btn btn-primary" [disabled]="!enableStatusButton"
                                (click)="openSection('UPDATE-STATUS')">
                                Update Status
                            </button>
                            <button class="btn btn-primary" [disabled]="!enableRockStartSettingsButton"
                                (click)="openSection('ROCKSTAR-SETTINGS')">
                                Rockstar Settings
                            </button>
                            <button class="btn btn-primary" [disabled]="!enableDeveloperSettingsButton"
                                (click)="openSection('DEVELOPER-SETTINGS')">
                                Developer Settings
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row white-bg mt-2 partner-section-box" *ngIf="submitted && (viewProfileSection || editProfileSection || updateStatusSection || rockstarSettingSection || developerSettingSection)">
        <div class="ibox col-md-12">
            <div class="ibox-title text-center">
                <h5 *ngIf="viewProfileSection">Profile Details of {{checkUserForm.email.value}}</h5>
                <h5 *ngIf="editProfileSection">{{btnText}} of {{checkUserForm.email.value}}</h5>
                <h5 *ngIf="updateStatusSection">Change External Accountant Status for {{checkUserForm.email.value}}</h5>
                <h5 *ngIf="rockstarSettingSection">Rockstar Settings for {{checkUserForm.email.value}}</h5>
                <h5 *ngIf="developerSettingSection">Developer Settings for {{checkUserForm.email.value}}</h5>
            </div>
        </div>
        <div class="ibox col-md-12 partner-section-result">
            <div *ngIf="viewProfileSection">
                <app-user-details [(userEmail)]="userEmail"></app-user-details>
            </div>
            <div *ngIf="editProfileSection">
                <app-add-user [(userEmail)]="userEmail" [(userExists)]="userExists"></app-add-user>
            </div>
            <div *ngIf="updateStatusSection">
                <app-change-partner-status [(userEmail)]="userEmail"></app-change-partner-status>
            </div>
            <div *ngIf="rockstarSettingSection">
                <app-rockstar-settings [(userEmail)]="userEmail"></app-rockstar-settings>
            </div>
            <div *ngIf="developerSettingSection">
                <app-developer-settings [(userEmail)]="userEmail"></app-developer-settings>
            </div>
        </div>
    </div>
</div>
