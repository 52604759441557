import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { User } from '@app/models/user';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  catToolURL: string = environment.CAT_URL;
  private userDetails = new BehaviorSubject<User>(null);
  getUser = this.userDetails.asObservable();

  constructor(
    private httpClient: HttpClient
  ) {

  }

  changeUserDetails(user: User) {
    this.userDetails.next(user);
  }

  getLoggedInUserDetails(): Promise<any> {
    return this.httpClient.get<User>(this.catToolURL + '/get-logged-in-user-details').toPromise();
  }

  getUserDetails(email: string): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/get-user-details', {email}).toPromise();
  }

  checkUserIsExists(email: string): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/check-user-exists', {email}).toPromise();
  }

  getAllUsers(requestBody: object): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/get-all-users', requestBody).toPromise();
  }

  getAllUsersForExport(requestBody: object): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/get-all-users-for-export', requestBody).toPromise();
  }

  getAllSubscriptionOwners(requestBody: object): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/get-all-subscription-owners', requestBody).toPromise();
  }

  getAllSubscriptionOwnersForExport(requestBody: object): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/get-all-subscription-owners-for-export', requestBody).toPromise();
  }

  extendPlaygroundTrial(requestBody: object): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/extend-playground-trial', requestBody).toPromise();
  }

  updateDeveloperPortalAccess(requestBody: object): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/update-developer-portal-access', requestBody).toPromise();
  }

  getAllNotifications(requestBody: object): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/get-all-notifications', requestBody).toPromise();
  }

  getNotificationsByEmail(requestBody: object): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/get-notifications-list-by-email', requestBody).toPromise();
  }

  getAllInvitations(requestBody: object): Promise<any> {
    return this.httpClient.post(this.catToolURL + '/get-all-invitations', requestBody).toPromise();
  }

  getPlaygroundDetails(userEmail: string) {
    return this.httpClient.post(this.catToolURL + '/get-playground-details-by-email', {userEmail}).toPromise();
  }

  getUserProfilePic(userEmail: string) {
    return this.httpClient.post(this.catToolURL + '/get-user-profile-image-by-email', {userEmail}).toPromise();
  }

  updateUserProfileImage(data: any) {
    return this.httpClient.post(this.catToolURL + '/update-user-profile-image' , data).toPromise();
  }

  addAccountantPartner(data: any) {
    return this.httpClient.post(this.catToolURL + '/add-accountant-partner' , data).toPromise();
  }

  editAccountantPartner(data: any) {
    return this.httpClient.post(this.catToolURL + '/edit-accountant-partner' , data).toPromise();
  }

  disabledMFA(userEmail: string) {
    return this.httpClient.post(this.catToolURL + '/disableTwoFactorAuthentication' , {userEmail}).toPromise();
  }

  changeUserPricingLevel(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/change-user-pricing-level' , requestBody).toPromise();
  }

  getAllDevelopers(data: any) {
    return this.httpClient.post(this.catToolURL + '/get-all-developers' , data).toPromise();
  }

  getAllDevelopersForExport(data: any) {
    return this.httpClient.post(this.catToolURL + '/get-all-developers-for-export' , data).toPromise();
  }

  getGeoDetails(zipCode: string) {
    return this.httpClient.post(this.catToolURL + '/get-geo-details' , {zipCode}).toPromise();
  }

  isReviewer(data: any) {
    return this.httpClient.post(this.catToolURL + '/is_reviewer' , data).toPromise();
  }

}
