import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { Subject } from 'rxjs';
import { AccountService } from '@app/services/account.service';
import { PopupService } from '@app/services/popup.service';
import { NGXLogger } from 'ngx-logger';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { catchError, takeUntil } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(timezone);

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.css']
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger = new Subject();
  title: string;
  pageHeading: string;

  accountDetails: any;
  startDate: any;
  endDate: any;
  rangeForTransactionGraph: UntypedFormGroup;
  showTransactionGraphError: boolean;
  public lineChartColors: any = [
    {
      borderColor: '#007bff',
      backgroundColor: 'rgb(136, 176, 236)',
      pointBackgroundColor: '#88B0EC',
      pointBorderColor: '#007bff'
    },
  ];
  public lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [
      {
        data: [],
        label: 'Transactions Count',
        ...this.lineChartColors[0]
      }
    ],
    labels: []
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false
  };

  ranges = {
    Today: [dayjs().startOf('day'), dayjs().endOf('day')],
    Yesterday: [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').endOf('day')],
    'Last 7 Days': [dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')],
    'Last 30 Days': [dayjs().subtract(29, 'days').startOf('day'), dayjs().endOf('day')],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  };
  dateRange = {
    start: dayjs().subtract(29, 'days').startOf('day'),
    end: dayjs().endOf('day'),
  };
  selectedDateRange = {
    start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
    end: dayjs().endOf('day').format('MM-DD-YYYY'),
  };
  maxDate = dayjs();
  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private accountService: AccountService,
    private popupService: PopupService,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger
  ) {
    this.pageHeading = 'Account Details';
    this.startDate = dayjs().startOf('month');
    this.endDate = dayjs().endOf('month');
    this.showTransactionGraphError = false;
    this.dateRange = {
      start: dayjs().subtract(29, 'days').startOf('day'),
      end: dayjs().endOf('day'),
    };
    this.selectedDateRange = {
      start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
      end: dayjs().endOf('day').format('MM-DD-YYYY'),
    };
  }
  private componentDestroyed$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `All Accounts | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.route.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.accountDetails = data.accountDetails;
        this.updateDailySnapGraph();
      });
    this.dtOptions = {
      responsive: true,
      bAutoWidth: false,
      lengthMenu: [[10, 15, 20, 50, 100], [10, 15, 20, 50, 100]],
      language: {
        lengthMenu: 'Display _MENU_ User(s)',
        sInfo: 'Showing _START_ to _END_ of _TOTAL_ User(s)',
        sInfoFiltered: '',
        sEmptyTable: 'No user(s) at this time',
        sZeroRecords: 'No user(s) at this time',
        search: '',
        searchPlaceholder: 'Search'
      },
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.rangeForTransactionGraph = new UntypedFormGroup({
      start: new UntypedFormControl(this.startDate),
      end: new UntypedFormControl(this.endDate),
    });
  }

  updateDailySnapGraph() {
    this.showTransactionGraphError = false;
    const format = 'YYYYMMDD';
    const zoneName = dayjs.tz.guess();
    const start = dayjs(this.selectedDateRange.start).tz(zoneName).format(format).replace('-', '').replace('-', '');
    const end = dayjs(this.selectedDateRange.end).tz(zoneName).format(format).replace('-', '').replace('-', '');
    this.accountService.getTransactionsCount({
      accountNumber: this.accountDetails.accountNumber,
      startDate: start,
      endDate: end
    })
      .pipe(
        catchError((error) => {
          this.showTransactionGraphError = true;
          // this.commonUtility.displayErrorToastWithTechDetails('Transactions Graph', error?.error);
          this.logger.error('ERROR: -> AccountDetailsComponent -> updateDailySnapGraph -> error', error);
          this.lineChartData.datasets = [
            {
              data: [],
              label: 'Transactions Count'
            }
          ];
          this.lineChartData.labels = [];
          throw error;
        }),
        takeUntil(this.componentDestroyed$),
      )
      .subscribe((graphData: any) => {
        this.commonUtility.displaySuccessToast('Transaction Graph', 'Transactions data refreshed.');
        this.logger.info('INFO: -> AccountDetailsComponent -> updateDailySnapGraph -> graphData', graphData);
        this.lineChartData = {
          datasets: [
            {
              data: graphData.dataSets,
              label: 'Transactions Count',
              ...this.lineChartColors[0],
              tension: 0.5,
              fill: true
            }
          ],
          labels: graphData.labels
        };
    });
  }

  openAccountExtendTrialPopup(accountNumber: string) {
    this.popupService.openExtendTrialPopup(accountNumber);
  }

  addBillingStatus(accountNumber: string) {
    this.popupService.openBillingStatusPopup(accountNumber);
  }

  datesUpdated(event) {
    if (event && event.startDate && event.endDate) {
      this.selectedDateRange.start = dayjs(event.startDate).format('MM-DD-YYYY');
      this.selectedDateRange.end = dayjs(event.endDate).format('MM-DD-YYYY');
      this.updateDailySnapGraph();
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

}
