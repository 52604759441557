<div class="p-2">
    <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <div class="mb-3 account-filter-group">
                <label for="search"  matTooltip="You can search by Email, Name or PhoneNumber fields">Search</label>
                <input type="text" class="form-control margin-list-box" id="searchinput"
                    (keyup)="onChangeSearchText()" [(ngModel)]="searchString"
                    placeholder="Search...">
                <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                    (click)="clearSearch()"></span>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
            <mat-select [compareWith]="compareFn" class="form-control"
                        panelClass="mat-select-autowidth-panel-class"
                        (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
            </mat-select>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3"></div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-end">
            <div class="mb-3 account-filter-group item-per-page-option">
                Display &nbsp;<mat-select [(ngModel)]="itemsPerPage" (selectionChange)="changeDisplayPerPageItem($event)">
                  <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}</mat-option>
                </mat-select>&nbsp; user(s)
            </div>
            <p class="mb-0">
            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} user(s)
            </p>
            <pagination-controls id="all-users" (pageChange)="getAllUsers($event)" responsive="true"
              previousLabel="Previous"
              nextLabel="Next"
              maxSize="3"
            ></pagination-controls>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 table-responsive">
            <table class="responsive table table-striped table-bordered no-footer hover" id="user-table">
                <thead>
                    <tr>
                    <ng-container *ngFor="let column of columns; let i = index">
                        <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting" [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}" (click)="sortBy(column.label)">{{column.label}}</th>
                    </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users | paginate: { id: 'all-users', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                        <td *ngIf="checkColumnVisibility(columns[0])"><a *ngIf="user.email" [routerLink]="['/users/user-details/', user.email]" routerLinkActive="router-link-active" >{{user.email}}</a><span *ngIf="!user.email" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[1])"><span *ngIf="user.name">{{user.name}}</span><span *ngIf="!user.name" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[2])"><span *ngIf="user.companyName">{{user.companyName}}</span><span *ngIf="!user.companyName" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[3])"><span *ngIf="user.phoneNumber">{{user.phoneNumber}}</span><span *ngIf="!user.phoneNumber" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[4])"><span *ngIf="user.userType" class="badge badge-{{user.userTypeClass}}">{{user.userType}}</span><span *ngIf="!user.userType" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[5])"><span *ngIf="user.externalAccountantStatus" class="badge badge-{{user.externalAccountantClass}}">{{user.externalAccountantStatus}}</span><span *ngIf="!user.externalAccountantStatus" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[6])"><span *ngIf="user.multiFactorStatus" class="badge badge-{{user.multiFactorStatus | lowercase}}-mfa">{{user.multiFactorStatus}}</span><span *ngIf="!user.multiFactorStatus" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[7])"><span *ngIf="user.playgroundCreatedDate">{{user.playgroundCreatedDate}}</span><span *ngIf="!user.playgroundCreatedDate" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[8])"><span *ngIf="user.creationDate">{{user.creationDate}}</span><span *ngIf="!user.creationDate" class="not-available">Not Available</span></td>
                    </tr>
                    <tr *ngIf="!users || users.length === 0" class="text-center not-available">
                        <td colspan="9">No user available at this time.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
