import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Franchisee } from '@app/models/franchisee';
import { AuthenticationService } from '@app/services/authentication.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { FranchiseeService } from '@app/services/franchisee.service';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-inner-header',
  templateUrl: './inner-header.component.html',
  styleUrls: ['./inner-header.component.css']
})
export class InnerHeaderComponent implements OnInit, OnDestroy {
  @Input() toggleSideBarCall: () => void;
  companyName: string;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private ngxService: NgxUiLoaderService,
    private authService: AuthenticationService,
    private commonUtility: CommonUtilityService,
    private franchiseeService: FranchiseeService,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.franchiseeService.getFranchiseeDetails
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((franchiseDetails: Franchisee) => {
        this.logger.info('INFO: -> InnerHeaderComponent -> .subscribe -> franchiseDetails', franchiseDetails);
        this.companyName = franchiseDetails.companyName;
      });

  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  toggleSideBar() {
    this.toggleSideBarCall();
  }

  logout(): void {
    this.ngxService.start();
    this.authService.logout();
    this.ngxService.stop();
    this.ngxService.stopBackground();
    this.commonUtility.displaySuccessToast(`${this.companyName} | Cat Tool`, 'You have successfully logged out.');
  }

}
