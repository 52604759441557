import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DashboardService } from '@app/services/dashboard.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  title: string;
  isFranchiseeAdmin: boolean;
  isVerifiedExternalAccountant: boolean;
  franchiseeAdminOfCode: string[];
  userEmail: string;
  paidAccountsCount = 0;
  pastDueAccountsCount = 0;
  canceledAccountsCount = 0;
  unBilledAccountsCount = 0;
  processingAccountsCount = 0;
  suspendedAccountsCount = 0;
  trialExpiresAccountsCount = 0;
  trialExpiredAccountsCount = 0;
  trialExpiredInNextWeek = [];
  trialExpiredInNextWeekStartDate: string;
  trialExpiredInNextWeekEndDate: string;
  trialExpiredInLastWeek = [];
  trialExpiredInLastWeekStartDate: string;
  trialExpiredInLastWeekEndDate: string;
  private componentDestroyed$: Subject<void> = new Subject();
  displayedStatuses = {};
  statusMapping = [
    { id: 'Suspended', property: 'suspendedAccountsCount' },
    { id: 'Paid', property: 'paidAccountsCount' },
    { id: 'Past Due', property: 'pastDueAccountsCount' },
    { id: 'Cancelled', property: 'canceledAccountsCount' },
    { id: 'Trial Expires', property: 'trialExpiresAccountsCount' },
    { id: 'Trial Expired', property: 'trialExpiredAccountsCount' },
    { id: 'Unbilled', property: 'unBilledAccountsCount' },
    { id: 'Processing', property: 'processingAccountsCount' }
  ];
  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.route.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `Dashboard | ${data.franchisee.companyName}`;
        this.isFranchiseeAdmin = data.userDetails.isFranchiseeAdmin;
        this.isVerifiedExternalAccountant = data.userDetails.isVerifiedExternalAccountant;
        this.franchiseeAdminOfCode = data.userDetails.franchiseeAdminOfCode;
        this.titleService.setTitle(this.title);
        this.userEmail = data.userDetails?.email;
      });

    this.dashboardService.getAccountsCountBasedOnPaymentStatus()
    .pipe(
      takeUntil(this.componentDestroyed$))
    .subscribe(counts => {
      this.statusMapping.forEach(status => {
        const account = counts.count.subscriptionStatusCounts.find(c => c._id === status.id);
        this.displayedStatuses[status.property] = account ? account.count : 0;
      });
    });

    this.dashboardService.getTrialAccountsList('NEXT_WEEK').then(accountDetails => {
      this.trialExpiredInNextWeek = accountDetails.accountsList;
      this.trialExpiredInNextWeekStartDate = accountDetails.startDate;
      this.trialExpiredInNextWeekEndDate = accountDetails.endDate;
    });

    this.dashboardService.getTrialAccountsList('LAST_WEEK').then(accountDetails => {
      this.trialExpiredInLastWeek = accountDetails.accountsList;
      this.trialExpiredInLastWeekStartDate = accountDetails.startDate;
      this.trialExpiredInLastWeekEndDate = accountDetails.endDate;
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

}
