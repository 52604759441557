import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { DataTableDirective } from 'angular-datatables';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-legacy-list',
  templateUrl: './legacy-list.component.html',
  styleUrls: ['./legacy-list.component.css']
})
export class LegacyListComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  title: string;
  pageHeading: string;

  searchString: string;
  selectedPlans: any;
  selectedStatus: any;
  selectedColumns: any;
  selectedSort: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  accounts: [];
  fromShowing: any;
  toShowing: any;

  plansList = [
    {id: 1, label: 'Entrepreneur', value: '0'},
    {id: 2, label: 'Professional', value: '1'},
    {id: 3, label: 'Professional w/eCommerce', value: '2'},
    {id: 4, label: 'Bank Accounting Online', value: '3'},
    {id: 5, label: 'Partner Edition', value: '4'},
    {id: 6, label: 'Startup', value: '7'},
    {id: 7, label: 'WriteUp', value: '8'},
    {id: 8, label: 'Business', value: '9'},
  ];
  statusList = [
    {id: 2, label: 'Live', value: 'live', key: 'live'},
    {id: 3, label: 'Test', value: 'test', key: 'test'},
    {id: 4, label: 'Sandbox', value: 'sandbox', key: 'sandbox'},
    {id: 5, label: 'Delete', value: 'delete', key: 'delete'},
    {id: 6, label: 'Not Available', value: 'null', key: null}
  ];
  columns = [
    {id: 1, name: 'uri', label: 'Account No', sort: 1, hide: 0},
    {id: 2, name: 'email', label: 'User Email', sort: 0, hide: 0},
    {id: 3, name: 'subscription_admin_email', label: 'Subscription Owner', sort: 0, hide: 0},
    {id: 4, name: 'tenant', label: 'Company Name', sort: 0, hide: 0},
    {id: 5, name: 'status', label: 'Status', sort: 0, hide: 0},
    {id: 6, name: 'version', label: 'Plan Name', sort: 0, hide: 0},
    {id: 7, name: 'franchisee', label: 'Franchisee', sort: 0, hide: 0},
    {id: 8, name: 'closing_date', label: 'Closing Date', sort: 0, hide: 0},
    {id: 9, name: 'accepted', label: 'Invitation Accepted', sort: 0, hide: 0},
    {id: 10, name: 'subscriptionStatus', label: 'Payment Status', sort: 0, hide: 0},
    {id: 11, name: 'accountCreation', label: 'Account Creation', sort: 0, hide: 0},
  ];
  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private accountService: AccountService,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger
  ) {
    this.pageHeading = 'Legacy Tenant Lists';

    this.searchString = '';
    this.selectedPlans = [];
    this.selectedStatus = [];
    this.selectedColumns = [
      {id: 1, name: 'uri', label: 'Account No', sort: 1, hide: 0},
      {id: 2, name: 'email', label: 'User Email', sort: 0, hide: 0},
      {id: 3, name: 'subscription_admin_email', label: 'Subscription Owner', sort: 0, hide: 0},
      {id: 4, name: 'tenant', label: 'Company Name', sort: 0, hide: 0},
      {id: 5, name: 'status', label: 'Status', sort: 0, hide: 0},
      {id: 6, name: 'version', label: 'Plan Name', sort: 0, hide: 0},
      {id: 7, name: 'franchisee', label: 'Franchisee', sort: 0, hide: 0},
      {id: 8, name: 'closing_date', label: 'Closing Date', sort: 0, hide: 0},
      {id: 9, name: 'accepted', label: 'Invitation Accepted', sort: 0, hide: 0},
      {id: 10, name: 'subscriptionStatus', label: 'Payment Status', sort: 0, hide: 0},
      {id: 11, name: 'accountCreation', label: 'Account Creation', sort: 0, hide: 0}
    ];
    this.selectedSort = {key: 'Account No', value: 1};

    // default setting
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
  }
  private componentDestroyed$: Subject<void> = new Subject();

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `All Accounts | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllAccounts(1);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  clearSearch() {
    this.searchString = '';
    this.getAllAccounts(this.pageNo);
  }

  onChangeSearchText() {
    this.getAllAccounts(this.pageNo);
  }

  changePlanFilter(event) {
    this.selectedPlans = event;
    this.getAllAccounts(this.pageNo);
  }

  changeStatusFilter(event) {
    this.selectedStatus = event;
    this.getAllAccounts(this.pageNo);
  }

  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllAccounts(this.pageNo);
    }
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }
  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllAccounts(this.pageNo);
  }

  getAllAccounts(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedPlans: this.selectedPlans,
      selectedStatus: this.selectedStatus,
      selectedSort: this.selectedSort
    };
    this.accountService.getAllLegacyAccounts(requestParams).then((accountsListResponse: any) => {
      this.logger.info('INFO: -> LegacyListComponent -> this.accountService.getAllLegacyAccounts -> accountsListResponse',
      accountsListResponse);
      this.accounts = accountsListResponse.accounts;
      if (accountsListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < accountsListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        accountsListResponse.totalCount);
      }
      this.totalCount = accountsListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> LegacyListComponent -> this.accountService.getAllLegacyAccounts -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

}
