<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-7" *ngIf="showLinks">                
                <ul class="socialIcons">
                    <li class="social" *ngIf="facebookURLOn">
                        <a href="{{ facebookURL }}" target="_blank">
                            <i class="fa fa-facebook-f"></i>
                        </a>
                    </li>
                    <li class="social" *ngIf="twitterURLOn">
                        <a href="{{ twitterURL }}" target="_blank">
                            <i class="fa fa-twitter"></i>
                        </a>
                    </li>
                    <li class="social" *ngIf="youtubeURLOn">
                        <a href="{{ youtubeURL }}" target="_blank">
                            <i class="fa fa-youtube"></i>
                        </a>
                    </li>
                    <li class="social" *ngIf="linkedInURLOn">
                        <a href="{{ linkedInURL }}" target="_blank">
                            <i class="fa fa-linkedin"></i>
                        </a>
                    </li>
                    <li *ngIf="inquireNumberOn">Inquire: <a href="tel:{{ inquireNumber }}">{{ inquireNumber }}</a>,</li>
                    <li *ngIf="helpLineNumberOn">Help: <a href="tel:{{ helpLineNumber }}">{{ helpLineNumber }}</a></li>
                </ul>                
            </div>
            <div [ngClass]="{'col-md-5 text-md-right': showLinks, 'col-md-12 text-md-center': !showLinks}" class="copyright" *ngIf="copyrightTermsOn">
                {{ copyrightTerms }}
            </div>
        </div>
    </div>
</footer>