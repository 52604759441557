<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 maintenance-content-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5 class="col-md-8 ps-0">{{pageHeading}}</h5><div class="col-md-4 pe-0 pt-2"><span class="float-end">Warning On/Off: <mat-slide-toggle [(ngModel)]="warningOn" (change)="toggleWarningOnOff()"></mat-slide-toggle></span></div>
            </div>
        </div>
        <div class="ibox col-md-12 maintenance-content-filters-container">
            <div class="ibox-title">
                <form [formGroup]="maintenanceContentForm" (ngSubmit)="saveMaintenanceContent()">
                    <div class="pt-2 pb-2">
                        <div formControlName="html" [ngxSummernote]="config" (blur)="onBlur()"
                            (mediaDelete)="onDelete()" (summernoteInit)="summernoteInit()">
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-primary me-2" type="button" (click)="restoreContentToHtml()">Restore to
                            Default</button>
                        <button class="btn btn-primary me-2" type="button" (click)="getContent()">Restore to Live</button>
                        <button class="btn btn-primary me-2" type="submit" [disabled]="!maintenanceContentForm.valid">Save
                            Changes</button>
                    </div>
                </form>
                <ngx-ui-loader [loaderId]="'maintenance-content-loader'" *ngIf="primaryColor" fgsColor="{{primaryColor}}"
    pbColor="{{primaryColor}}"></ngx-ui-loader>
            </div>
        </div>
    </div>
</div>
