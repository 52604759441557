import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QRCodeResponse } from '@app/models/qrcode-response';
import { MultiFactorService } from '@app/services/multi-factor.service';
import { PopupService } from '@app/services/popup.service';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit, OnDestroy {
  isMFAEnabled: boolean;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private popupService: PopupService,
    private multiFactorService: MultiFactorService,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.route.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.isMFAEnabled = data.userDetails.isMFAEnabled;
    });
  }

  enableMFA(): void {
    this.multiFactorService.generateQRCode().then((response: QRCodeResponse) => {
      this.logger.info('INFO: -> MultifactorSetupComponent -> enableMFA -> response', response);
      this.popupService.openEnableMFAPopup(response.qrCodeUrl, response.secretKey, response.email);
    }).catch(error => {
      this.logger.error('ERROR: -> MultifactorSetupComponent -> enableMFA -> error', error);
    });
  }

  disableMFA(): void {
    this.popupService.openDisableMFAPopup();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
