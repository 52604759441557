import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class RockstarReviewService {
  catToolURL: string = environment.CAT_URL;

  constructor(
    private httpClient: HttpClient
  ) {

  }

  getReviewsByEmail(requestParams: object) {
    return this.httpClient.post(this.catToolURL + '/get-all-rockstar-reviews', requestParams).toPromise();
  }

  dennyReviewToListInRockstar(reviewId: string, userEmail: string) {
    return this.httpClient.post(this.catToolURL + '/denny-review-to-list-on-rockstar', {userEmail, reviewId}).toPromise();
  }

  approveReviewToListInRockstar(reviewId: string, userEmail: string) {
    return this.httpClient.post(this.catToolURL + '/approve-review-to-list-on-rockstar', {userEmail, reviewId}).toPromise();
  }
}
