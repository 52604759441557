<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 reseller-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>
                    <span class="float-left">
                        {{pageHeading}}
                    </span>
                    <span class="float-end">
                        <a [routerLink]="['/partner/reseller/add']" class="btn btn-primary me-2"
                            routerLinkActive="router-link-active" *ngIf="isAccessOfAddEditReseller">
                            <i class="fa fa-plus"></i>
                            Add Reseller
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Reset Filters" (click)="resetFilter();"> <i class="fa fa-filter" aria-hidden="true"></i> Reset
                        </a>
                        <a class="btn btn-export me-2" matTooltip="CSV" (click)="exportAction('csv');">
                            <i class="fa fa-file-code-o"></i>
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Excel" (click)="exportAction('excel');">
                            <i class="fa fa-file-excel-o"></i>
                        </a>
                        <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()">
                            <i class="fa fa fa-refresh"></i>
                        </a>
                    </span>
                </h5>
            </div>
        </div>
        <div class="ibox col-md-12 reseller-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 pe-0">
                        <div class="mb-3 reseller-filter-group">
                            <label for="search"
                                matTooltip="You can search by Name, WidgetId or MarketingUrl fields">Global
                                Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 pe-0">
                        <div class="mb-3 reseller-filter-group">
                            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                            <mat-select [compareWith]="compareFn" class="form-control"
                                        panelClass="mat-select-autowidth-panel-class"
                                (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-end">
                        <div class="mb-3 resellers-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                (selectionChange)="changeDisplayPerPageItem($event)">
                                <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>&nbsp; reseller(s)
                        </div>
                        <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} reseller(s)
                        </p>
                        <pagination-controls id="all-resellers" (pageChange)="getAllResellers($event)" responsive="true"
                            previousLabel="Previous" nextLabel="Next" maxSize="3"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row filtered-by-box">
                <div class="col-md-12 mb-2" *ngIf="searchString">
                    <span class="filter-by-text">Filtered by: </span>
                    <span class="alert alert-success alert-dismissible
                    fade show type-filter ms-2" *ngIf="searchString">
                        <strong>Global Search:</strong> ({{searchString}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                </div>
                <div class="col-md-12">
                    <span class="filter-by-text">Sort by: </span>
                    <span class="alert alert-success alert-dismissible
                    fade show payment-status-filter ms-2">
                        <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending': 'Descending'}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row reseller-result-container bg-white">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <div class="reseller-list-box table-responsive">
                    <table class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover"
                        id="reseller-table">
                        <thead>
                            <tr>
                                <ng-container *ngFor="let column of columns; let i = index">
                                    <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting"
                                        [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1, 'sorting_disabled': column.disableSort}"
                                        (click)="sortBy(column)">{{column.label}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let reseller of resellers | paginate: { id: 'all-resellers', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                                <td *ngIf="checkColumnVisibility(columns[0])">
                                    <span *ngIf="reseller.widgetId">{{reseller.widgetId}}</span>
                                    <span *ngIf="!reseller.widgetId" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[1])">
                                    <span *ngIf="reseller.name">{{reseller.name}}</span>
                                    <span *ngIf="!reseller.name" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[2])">
                                    <span *ngIf="reseller.marketingUrl"><a href="{{reseller.marketingUrl}}" target="_blank">{{reseller.marketingUrl}}</a></span>
                                    <span *ngIf="!reseller.marketingUrl" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[3])"><span
                                        *ngIf="reseller.createdDate">{{reseller.createdDate}}</span><span
                                        *ngIf="!reseller.createdDate" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[4])">
                                    <span *ngIf="reseller.lastUpdatedDate">{{reseller.lastUpdatedDate}}</span>
                                    <span *ngIf="!reseller.lastUpdatedDate" class="not-available">Not
                                        Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[5])">
                                    <a class="btn btn-sm btn-primary me-2" [routerLink]="['/partner/reseller/view/', reseller.id]" routerLinkActive="router-link-active"><i class="fa fa-eye" aria-hidden="true"></i> View</a>
                                    <a class="btn btn-sm btn-primary me-2" [routerLink]="['/partner/reseller/edit/', reseller.id]" routerLinkActive="router-link-active" *ngIf="isAccessOfAddEditReseller"><i class="fa fa-pencil-square" aria-hidden="true"></i> Edit</a>
                                </td>
                            </tr>
                            <tr *ngIf="resellers && resellers.length === 0">
                                <td colspan="6" class="not-available text-center">No reseller available at this
                                    time.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
