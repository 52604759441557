<div class="row p-1 ps-3 pe-3 playground-details-box">
    <div class="col-md-8">
        <div class="row" *ngIf="!playgroundDetails?.playgroundSet">
            <div class="col-md-6" *ngIf="playgroundDetails?.processStarted">
                <strong>OnBoarding Process Start At:</strong>
            </div>
            <div class="col-md-6" *ngIf="playgroundDetails?.processStarted">
                {{playgroundDetails?.onBoardingProcessStartDate}}
            </div>
            <div class="col-md-6">
                <strong>OnBoarding Process Status:</strong>
            </div>
            <div class="col-md-6">
                {{playgroundDetails?.processStarted ? 'InComplete' : 'Not Started'}}
            </div>
        </div>
        <div class="row" *ngIf="playgroundDetails?.playgroundSet">
            <div class="col-md-6">
                <strong>Created Date:</strong>
            </div>
            <div class="col-md-6">
                {{playgroundDetails?.playgroundCreatedDate}}
            </div>
        </div>
        <div class="row" *ngIf="playgroundDetails?.showTrialDaysDetails">
            <div class="col-md-6">
                <strong>Expiration Date:</strong>
            </div>
            <div class="col-md-6">
                {{playgroundDetails?.playgroundZohoSubTrialEndDate}}
            </div>
        </div>
        <div class="row" *ngIf="playgroundDetails?.playgroundSet">
            <div class="col-md-6">
                <strong>Trial Remaining Day(s):</strong>
            </div>
            <div class="col-md-6">
                {{playgroundDetails?.remainingDaysOfTrial === -1 ? 'Unlimited Days' :
                playgroundDetails?.remainingDaysOfTrial}}
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div *ngIf="playgroundDetails?.playgroundSet && playgroundDetails?.showTrialDaysDetails">
            <a class="btn btn-sm btn-primary" (click)="openPlaygroundTrialExtendPopup()">Extend Playground Trial</a>
        </div>
        <p *ngIf="playgroundDetails?.message">
           <strong>Note:</strong> {{playgroundDetails?.message}}
        </p>
    </div>
</div>
