import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-zoho-sub-webhooks-payload-popup',
  templateUrl: './zoho-sub-webhooks-payload-popup.component.html',
  styleUrls: ['./zoho-sub-webhooks-payload-popup.component.css']
})
export class ZohoSubWebhooksPayloadPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<ZohoSubWebhooksPayloadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
