import { Component, OnDestroy, OnInit } from '@angular/core';
import { Franchisee } from '@app/models/franchisee';
import { FranchiseeService } from '@app/services/franchisee.service';
import { environment } from '@env/environment';
import { NGXLogger } from 'ngx-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  logo: string = environment.CBK_LOGO_IMAGE;
  franchiseeCode: string;
  companyName: string;
  accountantDashboardURL: string;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private franchiseeService: FranchiseeService,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.franchiseeService.getFranchiseeDetails
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((franchiseDetails: Franchisee) => {
        this.logger.info('INFO: -> NotFoundComponent -> .subscribe -> franchiseDetails', franchiseDetails);
        this.companyName = franchiseDetails.companyName;
        this.franchiseeCode = franchiseDetails.franchiseeCode;
        this.accountantDashboardURL = franchiseDetails.accountantDashboardURL;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

}
