<form [formGroup]="trialExtendForm" (ngSubmit)="extendTrial()" novalidate>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">{{pageHeading}}</h4>
        <button type="button" class="close" aria-describedby="modal-title" (click)="cancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="userEmail">User Email <span class="required">*</span></label>
                    <input type="text" name="userEmail" class="form-control" formControlName="userEmail"
                        id="userEmail" placeholder="User Email">
                </div>
                <div *ngIf="trialExtendFormControl.userEmail.invalid && (trialExtendFormControl.userEmail.dirty || trialExtendFormControl.userEmail.touched)"
                    class="invalid-feedback">
                    <div *ngIf="trialExtendFormControl.userEmail.errors.required">User Email is
                        required.
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="trialExpirationDate">Trial Expiration Date <span class="required">*</span>
                        {{trialExtendFormControl.trialExpirationDate.value}}</label>
                    <mat-calendar [minDate]="minDate" [(selected)]="selected" (selectedChange)="setDate($event)">
                    </mat-calendar>
                </div>
                <div *ngIf="trialExtendFormControl.trialExpirationDate.invalid && (trialExtendFormControl.trialExpirationDate.dirty || trialExtendFormControl.trialExpirationDate.touched)"
                    class="invalid-feedback">
                    <div *ngIf="trialExtendFormControl.trialExpirationDate.errors.required">Trial Expiration Date is
                        required.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer btn-group">
        <button type="submit" class="btn btn-primary max-with-btn" [disabled]="!trialExtendForm.valid">Extend Trial</button>
        <button type="button" class="btn btn-default max-with-btn ms-2" (click)="cancel()">Cancel</button>
    </div>
</form>
