import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { UserService } from '@app/services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-extend-playground-trial',
  templateUrl: './extend-playground-trial.component.html',
  styleUrls: ['./extend-playground-trial.component.css']
})
export class ExtendPlaygroundTrialComponent implements OnInit {
  userEmail: string;
  trialExtendForm: UntypedFormGroup;
  selected: Date | null;
  minDate = new Date();
  pageHeading: string;
  constructor(
    private modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private utilityService: CommonUtilityService,
    private userService: UserService,
    private router: Router
  ) {
    this.pageHeading = 'Extend Playground Trial';
   }

  ngOnInit(): void {
    this.trialExtendForm = this.fb.group({
      userEmail: [this.userEmail, [Validators.required]],
      trialExpirationDate: ['', [Validators.required]]
    });
  }

  get trialExtendFormControl() {
    return this.trialExtendForm.controls;
  }

  cancel() {
    this.modal.dismiss();
  }

  extendTrial() {
    if (this.trialExtendForm.valid) {
      this.userService.extendPlaygroundTrial(this.trialExtendForm.value).then((response: any) => {
        this.utilityService.displaySuccessToast(this.pageHeading, response.message);
        this.modal.close();
        this.router.navigate(['/users/all'], { queryParams: { search: this.userEmail }});
      }).catch((error: any) => {
        this.utilityService.displayErrorToastWithTechDetails(this.pageHeading, error?.error?.error);
      });
    } else {
      this.utilityService.displayErrorToast(this.pageHeading, 'Please fill all the required field.');
    }
  }

  setDate(event) {
    this.trialExtendForm.get('trialExpirationDate').setValue(event.format('YYYY-MM-DD'));
  }

}
