<div class="rockstar-settings-box">
  <div class="row">
    <div class="ps-0 pe-0" [ngClass]="{'col-md-5': rockStarPublicationFlag, 'col-md-12': !rockStarPublicationFlag}">
      <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
          <mat-panel-title>
            <h5>Rockstar Settings</h5>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="ibox-content border-top-gray pt-3">
          <div class="row">
            <div class="col-md-12"><i
                [ngClass]="{'color-green fa-check': hideEmailForRockStar, 'color-red fa-times': !hideEmailForRockStar}"
                class="fa" aria-hidden="true"></i>
              Hide email in public profile
            </div>
            <div class="col-md-12"><i
                [ngClass]="{'color-green fa-check': hidePhoneNumberForRockStar, 'color-red fa-times': !hidePhoneNumberForRockStar}"
                class="fa" aria-hidden="true"></i>
              Hide phone number in public profile
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 mt-3">
              <h6>Rockstar CustomBooks Certificate Assignment</h6>
              <div class="row">
                <div class="col-sm-9">
                  <label class="core-certificate">1. Core Certificate</label>
                </div>
                <div class="col-sm-3">
                  <mat-slide-toggle [(ngModel)]="rockStarCoreCertificate"
                    (change)="rockStarCertificateAssign(rockStarCoreCertificate,'rockstar_core_certificate')">
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-9">
                  <label>2.
                    eCommerce Certificate</label>
                </div>
                <div class="col-sm-3">
                  <mat-slide-toggle [(ngModel)]="rockStarECommerceCertificate"
                    (change)="rockStarCertificateAssign(rockStarECommerceCertificate,'rockstar_ecommerce_certificate')">
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div class="row">
                <div class="col-sm-9">
                  <label>3.
                    Advance
                    Inventory Certificate</label>
                </div>
                <div class="col-sm-3">
                  <mat-slide-toggle [(ngModel)]="rockStarAdvanceInventoryCertificate"
                    (change)="rockStarCertificateAssign(rockStarAdvanceInventoryCertificate,'rockstar_advance_inventory_certificate')">
                  </mat-slide-toggle>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-9">RockStar Publication</div>
            <div class="col-md-3">
              <mat-slide-toggle [(ngModel)]="rockStarPublicationFlag" (change)="changePublishFlag()">
              </mat-slide-toggle>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <a target="_blank" *ngIf="rockStarPublicationFlag" class="btn btn-primary"
                href="{{rockStarUrl}}/profile/{{rockStarProfileName}}">View RockStar Profile</a>
              <a target="_blank" *ngIf="!rockStarPublicationFlag" class="btn btn-primary disabled">View RockStar
                Profile</a>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="col-md-7 gray-bg pe-0" *ngIf="rockStarPublicationFlag">
      <app-rockstar-review [userEmail]="userEmail"></app-rockstar-review>
    </div>
  </div>
  <ngx-ui-loader [loaderId]="'rockstar-settings-loader'" *ngIf="primaryColor" fgsColor="{{primaryColor}}"
    pbColor="{{primaryColor}}"></ngx-ui-loader>
</div>
