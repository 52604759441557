<div class="p-2">
    <div class="row">
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <div class="mb-3 account-filter-group">
                <label for="search"  matTooltip="You can search by CompanyName, SubscriptionOwnerEmail or AccountNumber fields">Search</label>
                <input type="text" class="form-control margin-list-box" id="searchinput"
                    (keyup)="onChangeSearchText()" [(ngModel)]="searchString"
                    placeholder="Search...">
                <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                    (click)="clearSearch()"></span>
            </div>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3">
            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
            <mat-select [compareWith]="compareFn" class="form-control"
                        panelClass="mat-select-autowidth-panel-class"
                        (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
            </mat-select>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3"></div>
        <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-end">
            <div class="mb-3 account-filter-group item-per-page-option">
                Display &nbsp;<mat-select [(ngModel)]="itemsPerPage" (selectionChange)="changeDisplayPerPageItem($event)">
                  <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}</mat-option>
                </mat-select>&nbsp; account(s)
            </div>
            <p class="mb-0">
            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} accounts
            </p>
            <pagination-controls id="all-accounts" (pageChange)="getAllAccounts($event)" responsive="true"
              previousLabel="Previous"
              nextLabel="Next"
              maxSize="3"
            ></pagination-controls>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="responsive table table-striped table-bordered no-footer hover" id="account-table">
                <thead>
                    <tr>
                    <ng-container *ngFor="let column of columns; let i = index">
                        <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting" [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}" (click)="sortBy(column.label)">{{column.label}}</th>
                    </ng-container>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let account of accounts | paginate: { id: 'all-accounts', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                        <td *ngIf="checkColumnVisibility(columns[0])"><a class="text-underline-hover" *ngIf="account.accountNumber" [routerLink]="['/accounts/account-details', account.accountNumber]" routerLinkActive="router-link-active" >{{account.accountNumber}}</a><span *ngIf="!account.accountNumber" class="not-available">Not Available</span> <i *ngIf="account.isAdministrator" class="fa fa-user-secret ps-2" matTooltip="Admin"></i> <i class="fa fa-dollar color-green ps-2" matTooltip="Subscription Owner" *ngIf="account.isSubscriptionOwner"></i> <i class="fa fa-file-text ps-2" matTooltip="Reports Only" *ngIf="account.reportsOnly"></i></td>
                        <td *ngIf="checkColumnVisibility(columns[1])"><a class="text-underline-hover" *ngIf="account.subscriptionOwnerEmail">{{account.subscriptionOwnerEmail}}</a><span *ngIf="!account.subscriptionOwnerEmail" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[2])"><a class="text-underline-hover" *ngIf="account.companyName">{{account.companyName}}</a><span *ngIf="!account.companyName" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[3])"><span *ngIf="account.status"><span class="acs-{{account.status}}-status list-view-status" matTooltip="{{account.status}}"></span>{{account.status}}</span><span *ngIf="!account.status" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[4])"><span class="badge badge-{{account.planClassName}}">{{account.planName}}</span></td>
                        <td *ngIf="checkColumnVisibility(columns[5])"><span *ngIf="account.franchisee?.code && account.franchisee?.name">{{account.franchisee.code}} - {{account.franchisee.name}}</span><span *ngIf="!account.franchisee?.code || !account.franchisee?.name" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[6])"><span *ngIf="account.closingDate">{{account.closingDate}}</span><span *ngIf="!account.closingDate" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[7])"><div class="subscription-status"><span class="payment-status-{{account.paymentStatus.className}}" matTooltip="{{account.paymentStatus.status === 'Paused' ? 'PausedAt: ' + account.paymentStatus.pausedDate + ', Paused reason: ' + account.paymentStatus.pausedReason + ', Resume date: ' + account.paymentStatus.resumeDate : ''}}">{{account.paymentStatus.status}} </span><span *ngIf="account.paymentStatus.status !== 'Trial Expired' && account.paymentStatus.status !== 'Processing'" class="label-yellow-date" matTooltip="{{account.paymentStatus.dateTooltip}}">{{account.paymentStatus.date}}</span></div></td>
                        <td *ngIf="checkColumnVisibility(columns[8])"><span *ngIf="account.createdDate">{{account.createdDate}}</span><span *ngIf="!account.createdDate" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[9])"><span  class="badge badge-{{account.userDisableStatus | lowercase}}-user-state">{{account.userDisableStatus}}</span><span *ngIf="!account.userDisableStatus" class="not-available">Not Available</span></td>
                        <td *ngIf="checkColumnVisibility(columns[10])" class="cloudPayrollEmployee"><i *ngIf="account.isCloudPayrollEmployee" class="fa fa-check-circle"></i><i *ngIf="!account.isCloudPayrollEmployee" class="fa fa-times-circle-o"></i></td>
                    </tr>
                    <tr *ngIf="accounts && accounts.length === 0">
                        <td colspan="10" class="not-available text-center">No account found at this time.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
