<div class="col-md-12 security-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>Multi-Factor Authentication</h5>
            </div>
        </div>
        <div class="ibox col-md-12 security-filters-container">
            <div class="ibox-title">

                <div class="mb-3">
                    <div *ngIf="!isMFAEnabled">
                        <p>
                            Enable the Multi-Factor Authentication as an additional security feature. This will
                            prevent
                            unauthorized users
                            from accessing your account.
                            You will need to install the Google Authenticator mobile app to generate a security code
                            for
                            access.
                        </p>
                        <p>
                            NOTE: At this time, Google Authenticator is the only option for Multi-Factor
                            Authentication.
                        </p>
                    </div>
                    <div *ngIf="isMFAEnabled">
                        <h4><i class="fa fa-check-circle enabled-mfa"></i> Enabled</h4>
                    </div>
                </div>
            </div>
            <div class="ibox-title">
                <button type="button" class="btn btn-primary me-2" *ngIf="!isMFAEnabled" (click)="enableMFA()">Install
                    and
                    Setup</button>
                <button type="button" class="btn btn-primary me-2" *ngIf="isMFAEnabled"
                    (click)="disableMFA()">Disable</button>
                <button type="button" class="btn btn-default me-2" onclick="window.history.back();">Cancel</button>
            </div>
        </div>
    </div>
</div>
