<div class="row wrapper border-bottom white-bg page-heading" *ngIf="onlyUser">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="profile-box" [ngClass]="{'ps-4 pe-4': onlyUser}">
    <div class="row">
        <div class="col-md-8 profile-details-box">
            <div>
                <mat-accordion multi>
                    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                            <mat-panel-title>
                                <h5>Accounts</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="ibox-content border-top-gray">
                            <app-user-accounts-list [userEmail]="userEmail"></app-user-accounts-list>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="pt-3">
                <mat-accordion multi>
                    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                            <mat-panel-title>
                                <h5>Notifications</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="ibox-content border-top-gray">
                            <app-user-notifications-list [userEmail]="userEmail"></app-user-notifications-list>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="pt-3" *ngIf="customerDetails?.havePlaygroundTab">
                <mat-accordion multi>
                    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                            <mat-panel-title>
                                <h5>Playgrounds</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="ibox-content border-top-gray">
                            <app-user-playground-details [userEmail]="userEmail"></app-user-playground-details>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <div class="pt-3" *ngIf="customerDetails?.externalAccountantStatus">
                <div class="row">
                    <div class="col-md-6">
                        <mat-accordion multi>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                                    <mat-panel-title>
                                        <h5>Services Provided</h5>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content border-top-gray">
                                    <ul *ngFor="let item of servicesProvidedOptions">
                                        <li *ngIf="customerDetails?.servicesProvided[item.control]">
                                            {{item.name}}</li>
                                    </ul>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="col-md-6 ps-1">
                        <mat-accordion multi>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                                    <mat-panel-title>
                                        <h5>Industries Served</h5>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content border-top-gray">
                                    <ul *ngFor="let item of industriesServedOptions">
                                        <li *ngIf="customerDetails?.industriesServed[item.control]">
                                            {{item.name}}</li>
                                    </ul>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
                <div class="row pt-3">
                    <div class="col-md-6">
                        <mat-accordion multi>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                                    <mat-panel-title>
                                        <h5>Professional Designations</h5>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content border-top-gray">
                                    <ul *ngFor="let item of professionalDesignationsOptions">
                                        <li *ngIf="customerDetails?.professionalDesignations[item.control]">
                                            {{item.name}}</li>
                                    </ul>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="col-md-6 ps-1">
                        <mat-accordion multi>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                                    <mat-panel-title>
                                        <h5>Languages Spoken</h5>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content border-top-gray">
                                    <ul *ngFor="let item of languageSpokenOptions">
                                        <li *ngIf="customerDetails?.languagesKnown[item.control]">{{item.name}}
                                        </li>
                                    </ul>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
            <div class="pt-3">
                <div class="row">
                    <div class="col-md-4" *ngIf="customerDetails?.facebookInfo">
                        <mat-accordion multi>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                                    <mat-panel-title>
                                        <h5>Facebook Social Details</h5>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content border-top-gray">
                                    <dl class="row ps-3 pe-3">
                                        <dt class="col-sm-4">Email</dt>
                                        <dd class="col-sm-8" *ngIf="customerDetails?.facebookInfo?.email">
                                            {{customerDetails?.facebookInfo?.email}}</dd>
                                        <dd class="col-sm-8 not-available"
                                            *ngIf="!customerDetails?.facebookInfo?.email">Not Available</dd>
                                        <dt class="col-sm-4">Profile Id</dt>
                                        <dd class="col-sm-8" *ngIf="customerDetails?.facebookInfo?.fb_id">
                                            {{customerDetails?.facebookInfo?.fb_id}}</dd>
                                        <dd class="col-sm-8 not-available"
                                            *ngIf="!customerDetails?.facebookInfo?.fb_id">Not Available</dd>
                                        <dt class="col-sm-4">Profile Name</dt>
                                        <dd class="col-sm-8" *ngIf="customerDetails?.facebookInfo?.fb_name">
                                            {{customerDetails?.facebookInfo?.fb_name}}</dd>
                                        <dd class="col-sm-8 not-available"
                                            *ngIf="!customerDetails?.facebookInfo?.fb_name">Not Available</dd>
                                    </dl>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="col-md-4 ps-1 pe-1" *ngIf="customerDetails?.linkedInInfo">
                        <mat-accordion multi>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                                    <mat-panel-title>
                                        <h5>LinkedIn Social Details</h5>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content border-top-gray">
                                    <dl class="row ps-3 pe-3">
                                        <dt class="col-sm-4">Email</dt>
                                        <dd class="col-sm-8" *ngIf="customerDetails?.linkedInInfo?.email">
                                            {{customerDetails?.linkedInInfo?.email}}</dd>
                                        <dd class="col-sm-8 not-available"
                                            *ngIf="!customerDetails?.linkedInInfo?.email">Not Available</dd>
                                        <dt class="col-sm-4">Profile Id</dt>
                                        <dd class="col-sm-8" *ngIf="customerDetails?.linkedInInfo?.linkedin_id">
                                            {{customerDetails?.linkedInInfo?.linkedin_id}}</dd>
                                        <dd class="col-sm-8 not-available"
                                            *ngIf="!customerDetails?.linkedInInfo?.linkedin_id">Not Available</dd>
                                        <dt class="col-sm-4">Profile Name</dt>
                                        <dd class="col-sm-8" *ngIf="customerDetails?.linkedInInfo?.linkedin_name">
                                            {{customerDetails?.linkedInInfo?.linkedin_name}}</dd>
                                        <dd class="col-sm-8 not-available"
                                            *ngIf="!customerDetails?.linkedInInfo?.linkedin_name">Not Available</dd>
                                    </dl>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="col-md-4">
                        <mat-accordion multi>
                            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                                    <mat-panel-title>
                                        <h5>Google Social Details</h5>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="ibox-content border-top-gray">
                                    <dl class="row ps-3 pe-3">
                                        <dt class="col-sm-4">Email</dt>
                                        <dd class="col-sm-8" *ngIf="customerDetails?.googleInfo?.email">
                                            {{customerDetails?.googleInfo?.email}}</dd>
                                        <dd class="col-sm-8 not-available" *ngIf="!customerDetails?.googleInfo?.email">
                                            Not Available</dd>
                                        <dt class="col-sm-4">Profile Id</dt>
                                        <dd class="col-sm-8" *ngIf="customerDetails?.googleInfo?.gp_id">
                                            {{customerDetails?.googleInfo?.gp_id}}</dd>
                                        <dd class="col-sm-8 not-available" *ngIf="!customerDetails?.googleInfo?.gp_id">
                                            Not Available</dd>
                                        <dt class="col-sm-4">Profile Name</dt>
                                        <dd class="col-sm-8" *ngIf="customerDetails?.googleInfo?.gp_name">
                                            {{customerDetails?.googleInfo?.gp_name}}</dd>
                                        <dd class="col-sm-8 not-available"
                                            *ngIf="!customerDetails?.googleInfo?.gp_name">Not Available</dd>
                                    </dl>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 profile-details-box ps-0">
            <mat-accordion multi>
                <div>
                    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                            <mat-panel-title>
                                <h5>Personal Details</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="ibox-content border-top-gray">
                            <div class="row">
                                <div class="col-md-5 text-center p-3">
                                    <h5>{{customerDetails?.name}}</h5>
                                    <div class="m-b-sm">
                                        <div *ngIf="profileImageFromAws">
                                                <img alt="{{customerDetails?.name}}" class="profile-image"
                                                    [src]="profileImageFromAws" matTooltip="{{customerDetails?.name}} profile image">
                                        </div>
                                        <div *ngIf="!profileImageFromAws">
                                            <div *ngIf="userProfilePic">
                                                <img alt="{{customerDetails?.name}}" class="profile-image"
                                                    [src]="userProfilePic" matTooltip="{{customerDetails?.name}} profile image">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7 text-center p-3">
                                    <p>{{userEmail}}</p>
                                    <div>
                                        <span class="pe-2">
                                            <a matTooltip="https://www.facebook.com/profile.php?id={{customerDetails?.facebookUserId}}"
                                                class="btn btn-social-icon"
                                                [ngClass]="{'active': customerDetails?.facebookUserId}"
                                                href="https://www.facebook.com/profile.php?id={{customerDetails?.facebookUserId}}"
                                                target="_blank">
                                                <i class="fa fa-facebook"></i>
                                            </a>
                                        </span>
                                        <span class="pe-2">
                                            <a matTooltip="https://www.linkedin.com/profile/view?id={{customerDetails?.linkedInUserId}}"
                                                class="btn btn-social-icon"
                                                [ngClass]="{'active': customerDetails?.linkedInUserId}"
                                                href="https://www.linkedin.com/profile/view?id={{customerDetails?.linkedInUserId}}"
                                                target="_blank">
                                                <i class="fa fa-linkedin"></i>
                                            </a>
                                        </span>
                                        <span>
                                            <a matTooltip="https://plus.google.com/{{customerDetails?.googleUserId}}"
                                                class="btn btn-social-icon"
                                                [ngClass]="{'active': customerDetails?.googleUserId}"
                                                href="https://plus.google.com/{{customerDetails?.googleUserId}}/"
                                                target="_blank">
                                                <i class="fa fa-google"></i>
                                            </a>
                                        </span>
                                    </div>
                                    <a class="btn btn-sm btn-primary mt-2" (click)="openComposeMessagePopup(userEmail)"><i class="fa fa-envelope"></i> Send
                                        Message</a>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div id="vertical-timeline" class="vertical-container dark-timeline">
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <a
                                                    *ngIf="customerDetails?.aboutCustomer">{{customerDetails?.aboutCustomer}}</a>
                                                <span *ngIf="!customerDetails?.aboutCustomer" class="not-available">Not
                                                    Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">About</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-usd" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p *ngIf="customerDetails?.userType"><span
                                                    class="badge badge-{{customerDetails.userTypeClass}}">{{customerDetails?.userType}}</span>
                                            </p>
                                            <p *ngIf="!customerDetails?.userType" class="not-available">Not Available
                                            </p>
                                            <span class="vertical-date small text-muted">Pricing Level</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-id-badge" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p *ngIf="customerDetails?.externalAccountantStatus"><span
                                                    class="badge badge-{{customerDetails.externalAccountantClass}}">{{customerDetails?.externalAccountantStatus}}</span>
                                            </p>
                                            <p *ngIf="!customerDetails?.externalAccountantStatus" class="not-available">
                                                Not Available</p>
                                            <span class="vertical-date small text-muted">External accountant
                                                status</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-shield" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p *ngIf="customerDetails?.multiFactorStatus"><span
                                                    class="badge badge-{{customerDetails?.multiFactorStatus | lowercase}}-mfa">{{customerDetails?.multiFactorStatus}}</span>
                                            </p>
                                            <p *ngIf="!customerDetails?.multiFactorStatus" class="not-available">Not
                                                Available</p>
                                            <span class="vertical-date small text-muted">Multi-Factor Status</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-phone-square" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.phoneNumber">{{customerDetails?.phoneNumber}}</span>
                                                <span *ngIf="!customerDetails?.phoneNumber" class="not-available">Not
                                                    Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Phone Number</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.website">{{customerDetails?.website}}</span>
                                                <span *ngIf="!customerDetails?.website" class="not-available">Not
                                                    Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Website</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.country">{{customerDetails?.country}}</span>
                                                <span *ngIf="!customerDetails?.country" class="not-available">Not
                                                    Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Country</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-credit-card-alt" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.customerId">{{customerDetails?.customerId}}</span>
                                                <span *ngIf="!customerDetails?.customerId" class="not-available">Not
                                                    Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Zoho Subscription Customer
                                                Id</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.creationDate">{{customerDetails?.creationDate}}</span>
                                                <span *ngIf="!customerDetails?.creationDate" class="not-available">Not
                                                    Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Creation Date</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.personalDetailsLastUpdatedTime">{{customerDetails?.personalDetailsLastUpdatedTime}}</span>
                                                <span *ngIf="!customerDetails?.personalDetailsLastUpdatedTime"
                                                    class="not-available">Not Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Last Updated Date</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-desktop" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.personalDetailsLastUpdatedIdAddress">{{customerDetails?.personalDetailsLastUpdatedIdAddress}}</span>
                                                <span *ngIf="!customerDetails?.personalDetailsLastUpdatedIdAddress"
                                                    class="not-available">Not Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Last Updated IP Address</span>
                                        </div>
                                        <div class="vertical-timeline-block">
                                            <div class="vertical-timeline-icon">
                                                <i class="fa fa-user" aria-hidden="true"></i>
                                            </div>
                                            <div class="vertical-timeline-content">
                                                <p>
                                                    <span
                                                        *ngIf="customerDetails?.invitedBy">{{customerDetails?.invitedBy}}</span>
                                                    <span *ngIf="!customerDetails?.invitedBy"
                                                        class="not-available">Not Available</span>
                                                </p>
                                                <span class="vertical-date small text-muted">User Invited By</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>

                <div class="mt-3">
                    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                            <mat-panel-title>
                                <h5>Multi-Factor Authentication Settings</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="ibox-content border-top-gray">
                            <div class="row p-2">
                            <div class="col-sm-6">
                                <label matTooltip="Multi-Factor:">Multi-Factor:</label>
                              </div>
                              <div class="col-sm-6">
                                <label> Disabled <mat-slide-toggle [checked]="customerDetails?.multiFactorStatus === 'Enabled'" [disabled]="customerDetails?.multiFactorStatus === 'Disabled'" (change)="disableTwoFactorAuthentication()"> Enabled </mat-slide-toggle></label>
                              </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>

                <div class="pt-3" *ngIf="customerDetails?.externalAccountantStatus === 'Verified'">
                    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                            <mat-panel-title>
                                <h5>Company Details</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="ibox-content border-top-gray">
                            <div class="col-md-12">
                                <div id="vertical-timeline" class="vertical-container dark-timeline">
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-building" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <a
                                                    *ngIf="customerDetails?.company?.name">{{customerDetails?.company?.name}}</a>
                                                <span *ngIf="!customerDetails?.company?.name" class="not-available">Not
                                                    Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Company Name</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <a
                                                    *ngIf="customerDetails?.company?.yearsInBusiness">{{customerDetails?.company?.yearsInBusiness}}</a>
                                                <span *ngIf="!customerDetails?.company?.yearsInBusiness"
                                                    class="not-available">Not Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Year In Business</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-info-circle" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p *ngIf="customerDetails?.company?.about">
                                                {{customerDetails?.company?.about}}</p>
                                            <p *ngIf="!customerDetails?.company?.about" class="not-available">Not
                                                Available</p>
                                            <span class="vertical-date small text-muted">About Company</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-phone-square" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p *ngIf="customerDetails?.company?.phoneNumber">
                                                {{customerDetails?.company?.phoneNumber}}</p>
                                            <p *ngIf="!customerDetails?.company?.phoneNumber" class="not-available">Not
                                                Available</p>
                                            <span class="vertical-date small text-muted">Company Number</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-globe" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.company?.website">{{customerDetails?.company?.website}}</span>
                                                <span *ngIf="!customerDetails?.company?.website"
                                                    class="not-available">Not Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Company Website</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.company?.address">{{customerDetails?.company?.address?.addressLine1}}
                                                    <br>
                                                    {{customerDetails?.company?.address?.city}},
                                                    {{customerDetails?.company?.address?.state}}
                                                    {{customerDetails?.company?.address?.zipCode}} <br>
                                                    {{customerDetails?.company?.address?.country}}
                                                </span>
                                                <span *ngIf="!customerDetails?.company?.address"
                                                    class="not-available">Not Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Company Address</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-clock-o" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.companyDetailsLastUpdateTime">{{customerDetails?.companyDetailsLastUpdateTime}}</span>
                                                <span *ngIf="!customerDetails?.companyDetailsLastUpdateTime"
                                                    class="not-available">Not Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Last Updated Date</span>
                                        </div>
                                    </div>
                                    <div class="vertical-timeline-block">
                                        <div class="vertical-timeline-icon">
                                            <i class="fa fa-desktop" aria-hidden="true"></i>
                                        </div>
                                        <div class="vertical-timeline-content">
                                            <p>
                                                <span
                                                    *ngIf="customerDetails?.companyDetailsLastUpdatedIdAddress">{{customerDetails?.companyDetailsLastUpdatedIdAddress}}</span>
                                                <span *ngIf="!customerDetails?.companyDetailsLastUpdatedIdAddress"
                                                    class="not-available">Not Available</span>
                                            </p>
                                            <span class="vertical-date small text-muted">Last Updated IP Address</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-accordion>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="'profile-loader'" *ngIf="primaryColor" fgsColor="{{primaryColor}}"
        pbColor="{{primaryColor}}"></ngx-ui-loader>
</div>
