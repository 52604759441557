import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { WhiteLabelPartner } from '@app/models/white-label-partner';
import { EMPTY, Observable } from 'rxjs';
import { CommonUtilityService } from './common-utility.service';
import { WhiteLabelPartnerService } from './white-label-partner.service';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelPartnerResolverService {
  id: string;
  constructor(
    private router: Router,
    private whiteLabelPartnerService: WhiteLabelPartnerService,
    private commonUtility: CommonUtilityService,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Promise<object> | Observable<never> {
    this.id = route.paramMap.get('id');
    return this.whiteLabelPartnerService.getWhiteLabelPartnerDetails(this.id)
      .then((response: WhiteLabelPartner) => response)
      .catch((error: any) => {
        this.commonUtility.displayErrorToastWithTechDetails('Get White Label Partner Details', error.error);
        this.router.navigate(['/partner/white-label-partner/all']);
        return EMPTY;
    });
  }
}
