import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  constructor(
    private httpClient: HttpClient
  ) {
  }

  getGoogleMapsApi(): Observable<any> {
    return this.httpClient
      .jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE_MAP.KEY}`, 'callback');
  }
}
