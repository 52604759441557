<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 event-logs-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}
                    <span class="float-end">
                        <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()"><i class="fa fa fa-refresh"></i></a>
                    </span>
                </h5>
            </div>
        </div>
        <div class="ibox col-md-12 event-logs-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 event-logs-filter-group">
                            <label for="searchinput" matTooltip="You can search by User, Account Number fields">Global
                                Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="version" matTooltip="You can select multiple Categories">Category</label> <i
                                class="fa fa-info-circle ms-2" (click)="openCategoriesInfo()"
                                matTooltip="Category Details"></i>
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="!selectedCategory.length"><b>Select Category</b></mat-label>
                                <mat-select [(ngModel)]="selectedCategory" multiple>
                                    <mat-option #allSelectedCategories (click)="toggleAllCategorySelection()"
                                        [value]="0">All</mat-option>
                                    <mat-option *ngFor="let item of categoriesList" [value]="item.value"
                                        (click)="categoryTouchPerOne()">{{item.label}}
                                        <span class="{{item.class}}"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="version" matTooltip="You can select multiple Types">Type</label> <i
                                class="fa fa-info-circle ms-2" (click)="openTypeInfo()" matTooltip="Type Details"></i>
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="!selectedType.length"><b>Select Type</b></mat-label>
                                <mat-select [(ngModel)]="selectedType" multiple>
                                    <mat-option #allSelectedType (click)="toggleAllTypeSelection()" [value]="0">All
                                    </mat-option>
                                    <mat-option *ngFor="let item of typesList" [value]="item.value"
                                        (click)="typeTouchPerOne()">{{item.label}}
                                        <span class="{{item.class}}"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3">
                            <label>Date Range <span class="required">*</span></label>
                            <input class="form-control" ngxDaterangepickerMd [showCustomRangeLabel]="true"
                                [showDropdowns]="true" startKey="start" endKey="end" [ranges]="ranges"
                                [locale]="{applyLabel: 'ok', format: 'MM-DD-YYYY'}" [maxDate]="maxDate"
                                [(ngModel)]="dateRange" [alwaysShowCalendars]="true"
                                [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true"
                                (datesUpdated)="datesUpdated($event)" />
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-1 col-lg-1 pe-0">
                        <div class="mb-3 event-logs-filter-group" *ngIf="activeIndex === 0">
                            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                            <mat-select [compareWith]="compareFn" class="form-control"
                                        panelClass="mat-select-autowidth-panel-class"
                                (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 text-end">
                        <div *ngIf="activeIndex === 0">
                            <div class="mb-3 account-filter-group item-per-page-option">
                              Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                                        (selectionChange)="changeDisplayPerPageItem($event)">
                              <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}
                              </mat-option>
                            </mat-select>&nbsp; event log(s)
                            </div>
                            <p class="mb-0">
                                Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} event log(s)
                            </p>
                            <pagination-controls id="all-event-logs" (pageChange)="getAllEventLogs($event)"
                                responsive="true" previousLabel="Previous" nextLabel="Next" maxSize="3">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="row filtered-by-box">
                    <div class="col-md-12">
                        <span class="filter-by-text">Filtered by: </span>
                        <span class="alert alert-success alert-dismissible
                            fade show date-range-filter ms-2">
                            <strong>Date Range:</strong> {{selectedDateRange.start}} - {{selectedDateRange.end}}
                        </span>
                        <span class="alert alert-success alert-dismissible
                            fade show category-filter ms-2" *ngIf="!selectedCategory.includes(0)">
                            <strong>Category:</strong> ({{selectedCategory.join(', ')}})
                            <button type="button" class="btn filter-close-btn" aria-label="Close"
                                (click)="resetCategorySelection()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </span>
                        <span class="alert alert-success alert-dismissible
                            fade show type-filter ms-2" *ngIf="!selectedType.includes(0)">
                            <strong>Type:</strong> ({{selectedType.join(', ')}})
                            <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="resetTypeSelection()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </span>
                        <span class="alert alert-success alert-dismissible
                            fade show type-filter ms-2" *ngIf="searchString">
                            <strong>Global Search:</strong> ({{searchString}})
                            <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </span>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="activeIndex === 0">
                        <span class="filter-by-text">Sort by: </span>
                        <span class="alert alert-success alert-dismissible
                            fade show payment-status-filter ms-2">
                            <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending':
                            'Descending'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row event-logs-result-container">
        <mat-tab-group #eventLogTabs [dynamicHeight]="true" mat-align-tabs="center" [selectedIndex]="activeIndex"
                       mat-stretch-tabs="false" mat-align-tabs="center"
            (selectedTabChange)="onTabChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">table_chart</mat-icon> Data
                </ng-template>
                <div class="ibox col-md-12 event-logs-table">
                    <div class="ibox-title">
                        <div class="event-logs-list-box table-responsive">
                            <table
                                class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover"
                                id="event-logs-table">
                                <thead>
                                    <tr>
                                        <ng-container *ngFor="let column of columns; let i = index">
                                            <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting"
                                                [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}"
                                                (click)="sortBy(column.label)" [ngStyle]="{'min-width': column.width}">
                                                {{column.label}}</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let eventLog of eventLogs | paginate: { id: 'all-event-logs', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                                        <td *ngIf="checkColumnVisibility(columns[0])">
                                            <span *ngIf="eventLog.createdDate">{{eventLog.createdDate}}</span>
                                            <span *ngIf="!eventLog.createdDate" class="not-available">Not
                                                Available</span>
                                        </td>

                                        <td *ngIf="checkColumnVisibility(columns[1])">
                                            <span *ngIf="eventLog.category">
                                                <span
                                                    class="badge badge-{{eventLog.categoryClass}}-category">{{eventLog.category}}</span>
                                            </span>
                                            <span *ngIf="!eventLog.category" class="not-available">Not Available</span>
                                        </td>

                                        <td *ngIf="checkColumnVisibility(columns[2])">
                                            <span *ngIf="eventLog.type">
                                                <span
                                                    class="badge badge-{{eventLog.typeClass}}-type">{{eventLog.type}}</span>
                                            </span>
                                            <span *ngIf="!eventLog.type" class="not-available">Not Available</span>
                                        </td>

                                        <td *ngIf="checkColumnVisibility(columns[3])">
                                            <span
                                                *ngIf="eventLog.email && eventLog.email !== 'Not Available'">{{eventLog.email}}</span>
                                            <span *ngIf="eventLog.email === 'Not Available' || !eventLog.email"
                                                class="not-available">Not Available</span>
                                        </td>

                                        <td *ngIf="checkColumnVisibility(columns[4])">
                                            <span
                                                *ngIf="eventLog.accountNumber && eventLog.accountNumber !== 'Not Available'">{{eventLog.accountNumber}}</span>
                                            <span
                                                *ngIf="eventLog.accountNumber === 'Not Available' || !eventLog.accountNumber"
                                                class="not-available">Not Available</span>
                                        </td>

                                        <td *ngIf="checkColumnVisibility(columns[5])">
                                            <span *ngIf="eventLog.host">{{eventLog.host}}</span>
                                            <span *ngIf="!eventLog.host" class="not-available">Not Available</span>
                                        </td>

                                        <td *ngIf="checkColumnVisibility(columns[6])">
                                            <span *ngIf="eventLog.ipAddress">{{eventLog.ipAddress | formatIpAddress }}</span>
                                            <span *ngIf="!eventLog.ipAddress" class="not-available">Not Available</span>
                                        </td>

                                        <td *ngIf="checkColumnVisibility(columns[7])">
                                            <span *ngIf="eventLog.browser">{{eventLog.browser}}</span>
                                            <span *ngIf="!eventLog.browser" class="not-available">Not Available</span>
                                        </td>

                                        <td *ngIf="checkColumnVisibility(columns[8])">
                                            <span *ngIf="eventLog.message" [innerHtml]="eventLog.message"></span>
                                            <span *ngIf="!eventLog.message" class="not-available">Not Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[9])">
                                            <span *ngIf="eventLog.whatToDo">{{eventLog.whatToDo}}</span>
                                            <span *ngIf="!eventLog.whatToDo" class="not-available">Not Available</span>
                                        </td>
                                        <td *ngIf="checkColumnVisibility(columns[10])">
                                            <span *ngIf="eventLog.whereItHappened">{{eventLog.whereItHappened}}</span>
                                            <span *ngIf="!eventLog.whereItHappened" class="not-available">Not
                                                Available</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!eventLogs || eventLogs.length === 0" class="text-center">
                                        <td colspan="11">No event log(s) available at this time</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">insert_chart</mat-icon> Graph
                </ng-template>
                <div class="ibox col-md-12">
                    <div class="btn-group pt-3 float-end">
                        <button class="btn btn-sm btn-primary me-2" (click)="changeGraphType('day')">Day</button>
                        <button class="btn btn-sm btn-primary me-2" (click)="changeGraphType('month')">Month</button>
                        <button class="btn btn-sm btn-primary" (click)="changeGraphType('year')">Year</button>
                    </div>
                    <canvas baseChart
                            width="3"
                            height="1"
                            [data]="lineChartData"
                            [options]="lineChartOptions"
                            [legend]="lineChartLegend"
                            [type]="'line'">
                    </canvas>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">map</mat-icon> Map
                </ng-template>
                <div class="ibox col-md-12 p-2">
                    <div class="alert alert-warning" role="alert">
                        {{totalCountForMapPlot}} Event logs available. but only {{totalCountOfPlottedRecords}} have latitude and longitude information to show in map.
                    </div>
                    <div class="map" *ngIf="isGoogleApiReady$ | async">
                      <google-map [options]="googleMapOptions"
                                  width="100%" height="100%">
                        <map-marker
                          *ngFor="let marker of markers"
                          [position]="marker.position"
                          [label]="marker.label">
                        </map-marker>
                      </google-map>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
