import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { PartnerService } from '@app/services/partner.service';
import { UserService } from '@app/services/user.service';
import { environment } from '@env/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-rockstar-settings',
  templateUrl: './rockstar-settings.component.html',
  styleUrls: ['./rockstar-settings.component.css']
})
export class RockstarSettingsComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;

  pageHeading: string;
  primaryColor: string;
  hideEmailForRockStar: boolean;
  hidePhoneNumberForRockStar: boolean;

  rockStarPublicationFlag: boolean;
  rockStarProfileName: string;
  rockStarUrl: string;

  rockStarCoreCertificate: boolean;
  rockStarECommerceCertificate: boolean;
  rockStarAdvanceInventoryCertificate: boolean;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private commonUtility: CommonUtilityService,
    private ngxService: NgxUiLoaderService,
    private partnerService: PartnerService,
    private userService: UserService
  ) {
    this.ngxService.startLoader('rockstar-settings-loader');
    this.hideEmailForRockStar = false;
    this.hidePhoneNumberForRockStar = false;
    this.rockStarCoreCertificate = false;
    this.rockStarECommerceCertificate = false;
    this.rockStarAdvanceInventoryCertificate = false;
    this.rockStarUrl = environment.ROCKSTAR_URL;
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
      });
    if (this.userEmail) {
      this.userService.checkUserIsExists(this.userEmail).then((userDetails: any) => {
        this.hideEmailForRockStar = userDetails?.hideEmailForRockStar;
        this.hidePhoneNumberForRockStar = userDetails?.hidePhoneNumberForRockStar;

        this.rockStarPublicationFlag = userDetails?.rockStarPublicationFlag;
        this.rockStarProfileName = userDetails?.rockStarProfileName;
        this.rockStarCoreCertificate = userDetails?.rockStarCoreCertificate;
        this.rockStarECommerceCertificate = userDetails?.rockStarECommerceCertificate;
        this.rockStarAdvanceInventoryCertificate = userDetails?.rockStarInventoryCertificate;
      }).catch(error => {
        this.commonUtility.displayErrorToast(this.pageHeading, error.error);
      });
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, 'User Email is missing.');
    }
    this.ngxService.stopLoader('rockstar-settings-loader');
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  changePublishFlag() {
    const toastTitle = 'Rockstar Profile Publish';
    const params = {
          userEmail: this.userEmail,
          rockStarPublicationFlag: this.rockStarPublicationFlag
      };
    this.partnerService.changeRockStarPublicationFlag(params).then((response: any) => {
      this.commonUtility.displaySuccessToast(toastTitle, response.message);
      }).catch(error => {
        this.commonUtility.displayErrorToastWithTechDetails(toastTitle, error.error);
      });
  }

  rockStarCertificateAssign(value, action) {
    const toastTitle = 'Rockstar Certificate Assignment';
    const params = {
      userEmail: this.userEmail,
      value,
      type: action
    };
    this.partnerService.rockstarCertificateAssign(params).then((response: any) => {
      this.commonUtility.displaySuccessToast(toastTitle, response.message);
    }).catch(error => {
      this.commonUtility.displayErrorToastWithTechDetails(toastTitle, error.error);
    });
  }

}
