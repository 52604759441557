<mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
    <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
        <mat-panel-title>
            <h5>Rockstar Reviews</h5>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="ibox-content border-top-gray pt-3">
        <div class="row">
            <div class="col-md-12 white-bg">
                <div class="row">
                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="search"
                                matTooltip="You can search by CompanyName, SubscriptionOwnerEmail or AccountNumber fields">Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 pe-0">
                        <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                        <mat-select [compareWith]="compareFn" class="form-control"
                                    panelClass="mat-select-autowidth-panel-class"
                                    (selectionChange)="columnChange()"
                            [(ngModel)]="selectedColumns" multiple>
                            <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                        </mat-select>
                    </div>
                    <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1 pe-0"></div>
                    <div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 text-end">
                        <div class="mb-3 account-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                (selectionChange)="changeDisplayPerPageItem($event)">
                                <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}</mat-option>
                            </mat-select>&nbsp; review(s)
                        </div>
                        <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} review(s)
                        </p>
                        <pagination-controls id="all-reviews" (pageChange)="getAllReviews($event)" responsive="true"
                            previousLabel="Previous" nextLabel="Next" maxSize="3"></pagination-controls>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table class="responsive table table-striped table-bordered no-footer hover" id="all-reviews">
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let column of columns; let i = index">
                                        <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting"
                                            [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}"
                                            (click)="sortBy(column.label)">{{column.label}}</th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let review of reviews | paginate: { id: 'all-reviews', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                                    <td *ngIf="checkColumnVisibility(columns[0])">
                                        <span *ngIf="review.status"
                                            class="badge badge-{{review.statusClass}}-status">{{review.status}}</span>
                                        <span *ngIf="review.status === 'INVITED'" class="label-yellow-date">
                                            {{review.reviewInvitedDate}}
                                        </span>
                                        <span *ngIf="review.status === 'APPROVED'" class="label-yellow-date">
                                            {{review.reviewApprovedDate}}
                                        </span>
                                        <span *ngIf="review.status === 'DENIED'" class="label-yellow-date">
                                            {{review.reviewDeniedDate}}
                                        </span>
                                        <span *ngIf="!review.status" class="not-available">Not Available</span>
                                    </td>
                                    <td *ngIf="checkColumnVisibility(columns[1])">
                                        <span *ngIf="review.reviewerName">{{review.reviewerName}}</span>
                                        <span *ngIf="!review.reviewerName" class="not-available">Not Available</span>
                                    </td>
                                    <td *ngIf="checkColumnVisibility(columns[2])">
                                        <span *ngIf="review.reviewerEmail">{{review.reviewerEmail}}</span>
                                        <span *ngIf="!review.reviewerEmail" class="not-available">Not Available</span>
                                    </td>
                                    <td *ngIf="checkColumnVisibility(columns[3])">
                                        <span *ngIf="review.rating">{{review.rating}}</span>
                                        <span *ngIf="!review.rating" class="not-available">Not Available</span>
                                    </td>
                                   <td *ngIf="checkColumnVisibility(columns[4])">
                                        <span *ngIf="review.reviewDate">{{review.reviewDate}}</span>
                                        <span *ngIf="!review.reviewDate" class="not-available">Not Available</span>
                                    </td>
                                    <td *ngIf="checkColumnVisibility(columns[5])">
                                        <a class="btn btn-sm btn-primary me-2" *ngIf="review.status !== 'INVITED'" (click)="viewReview(review)">View</a>
                                        <a class="btn btn-sm btn-primary me-2" *ngIf="review.status === 'NEW'" (click)="approveReview(review.reviewId)">Approve</a>
                                        <a class="btn btn-sm btn-primary" *ngIf="review.status === 'NEW'" (click)="dennyReview(review.reviewId)">Deny</a>
                                    </td>
                                </tr>
                                <tr *ngIf="reviews && reviews.length === 0">
                                    <td colspan="6" class="not-available text-center">No review found at this time.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>


                    <div class="col-md-12 mt-2 message-box" *ngIf="viewMessage">
                        <span class="review-details-clear" (click)="viewMessage = false"><i class="fa fa-times-circle"></i></span>
                        <p>{{selectedReview.reviewerEmail}} | {{selectedReview.reviewerName}}</p>
                        <p><i class="fa fa-clock-o"></i>  {{selectedReview.reviewDate}}</p>
                        <p [innerHtml]="selectedReview?.message"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-expansion-panel>

