import { Component, OnDestroy } from '@angular/core';
import { fromEvent, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import {
  ColDef,
  GridApi,
  GridOptions,
  IServerSideDatasource,
  IServerSideGetRowsParams, ITooltipParams
} from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { MonitoringService } from '@services/monitoring.service';
import * as moment from 'moment';
import { CellWarningRendererComponent } from './renderers';


@Component({
  selector: 'app-monitoring-view',
  templateUrl: './monitoring-view.component.html',
  styleUrls: ['./monitoring-view.component.css']
})
export class MonitoringViewComponent implements OnDestroy {

  public isLoading = false;
  public gridOptions: GridOptions = {};
  public columnDefs: ColDef[] = [];
  public gridApi: GridApi;
  public gridModules = [ServerSideRowModelModule, MenuModule, ClipboardModule, RangeSelectionModule];

  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private monitoringService: MonitoringService
  ) {
    const commonColDef = {
      cellStyle: { textAlign: 'center' }
    };
    this.gridOptions = {
      domLayout: 'autoHeight',
      columnDefs: this.columnDefs,
      suppressColumnVirtualisation: true,
      suppressDragLeaveHidesColumns: true,
      suppressCsvExport: true,
      suppressExcelExport: true,
      rowSelection: 'single',
      rowModelType: 'serverSide',
      serverSideStoreType: 'partial',
      pagination: true,
      enableRangeSelection: true,
      suppressRowClickSelection: true,
      onGridReady: ({ api }: { api: GridApi }) => {
        this.gridApi = api;
        this.gridApi.setServerSideDatasource(this.dataSource());
        fromEvent(window, 'resize')
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe({
            next: () => api.sizeColumnsToFit()
          });
        api.setColumnDefs(this.columnDefs);
        api.sizeColumnsToFit();
      }
    };
    this.columnDefs = [
      {
        headerName: 'Service',
        field: 'serviceName',
        cellClass: 'qa-service-name',
        minWidth: 200,
        tooltipField: 'uri'
      },
      {
        headerName: 'Overall status',
        field: 'status',
        cellClass: 'qa-overall-status',
        maxWidth: 120,
        cellClassRules: {
          'ag-grid-cell-background-red': (params) => !params.value,
          'ag-grid-cell-background-green': (params) => params.value
        },
        cellRendererFramework: CellWarningRendererComponent,
        valueFormatter: ({ value }) => value ? 'OK' : 'Failed',
        ...commonColDef
      },
      {
        headerName: 'Uptime',
        field: 'uptime',
        cellClass: 'qa-uptime',
        tooltipValueGetter: ({ data }: ITooltipParams): string => {
          if (!data.startDateTime) {
            return 'Not available';
          }
          return `Service started at: ` + moment(data.startDateTime).format('M/D/YY HH:mm');
        },
        maxWidth: 150,
        valueFormatter: ({ value }) => value ? value : 'Not available',
        ...commonColDef
      },
      {
        headerName: 'DB Status',
        field: 'dbStatus',
        cellClass: 'qa-db-status',
        maxWidth: 100,
        cellClassRules: {
          'ag-grid-cell-background-red': (params) => !params.value,
          'ag-grid-cell-background-green': (params) => params.value
        },
        cellRendererFramework: CellWarningRendererComponent,
        valueFormatter: ({ value }) => value ? 'OK' : 'Failed',
        ...commonColDef
      },
      {
        headerName: 'SM Rest Status',
        field: 'smRestStatus',
        cellClass: 'qa-sm-rest-status',
        maxWidth: 130,
        cellClassRules: {
          'ag-grid-cell-background-red': (params) => !params.value,
          'ag-grid-cell-background-green': (params) => params.value
        },
        cellRendererFramework: CellWarningRendererComponent,
        valueFormatter: ({ value }) => value ? 'OK' : 'Failed',
        ...commonColDef
      },
      {
        headerName: 'SM SOAP Status',
        field: 'smSoapStatus',
        cellClass: 'qa-sm-soap-status',
        maxWidth: 130,
        cellClassRules: {
          'ag-grid-cell-background-red': (params) => !params.value,
          'ag-grid-cell-background-green': (params) => params.value
        },
        cellRendererFramework: CellWarningRendererComponent,
        valueFormatter: ({ value }) => value ? 'OK' : 'Failed',
        ...commonColDef
      },
      {
        headerName: 'Last query datetime',
        field: 'queryDate',
        cellClass: 'qa-last-query-date',
        valueFormatter: ({ value }) => value && moment(value).format('M/D/YY HH:mm') || 'Not available',
        ...commonColDef
      },
      {
        headerName: 'Last successful query datetime',
        field: 'lastSuccessQueryDate',
        cellClass: 'qa-last-success-query-date',
        valueFormatter: ({ value }) => (value && moment(value).format('M/D/YY HH:mm')) || 'Not available',
        ...commonColDef
      }];
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  public refreshData(): void {
    this.gridApi.setServerSideDatasource(this.dataSource());
  }

  public dataSource(): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams): void => {
        this.isLoading = true;
        this.gridApi.showLoadingOverlay();

        this.monitoringService.getHealthStatic()
          .pipe(
            catchError(({ error }) => of(error)),
            takeUntil(this.componentDestroyed$))
          .subscribe(({ result }) => {
            this.isLoading = false;
            params.success({
              rowData: result,
              rowCount: result.length
            });
            if (!result.length) {
              this.gridApi.showNoRowsOverlay();
            } else {
              this.gridApi.hideOverlay();
            }
          });
      }
    };
  }
}
