<form [formGroup]="billingStatusForm" (ngSubmit)="changeBillingStatusSubmit()" novalidate>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Change Account Status</h4>
        <button type="button" class="close" aria-describedby="modal-title" (click)="cancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="accountNumber">Account Number <span class="required">*</span></label>
                    <input type="text" name="accountNumber" class="form-control" formControlName="accountNumber"
                        id="accountNumber" placeholder="Account Number">
                </div>
                <div *ngIf="billingFormFormControl.accountNumber.invalid && (billingFormFormControl.accountNumber.dirty || billingFormFormControl.accountNumber.touched)"
                    class="invalid-feedback">
                    <div *ngIf="billingFormFormControl.accountNumber.errors.required">Account Number is
                        required.
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="status">Status <span class="required">*</span></label>
                    <select formControlName="status" class="form-control">
                        <option *ngFor="let item of statusList" [value]="item.key">{{item.label}} <span class="{{item.class}}"></span></option>
                    </select>
                </div>
                <div *ngIf="billingFormFormControl.status.invalid && (billingFormFormControl.status.dirty || billingFormFormControl.status.touched)"
                    class="invalid-feedback">
                    <div *ngIf="billingFormFormControl.status.errors.required">Trial Expiration Date is
                        required.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer btn-group">
        <button type="submit" class="btn btn-primary max-with-btn" [disabled]="!billingStatusForm.valid">Change</button>
        <button type="button" class="btn btn-default max-with-btn ms-2" (click)="cancel()">Cancel</button>
    </div>
</form>
