import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { UserService } from '@app/services/user.service';
import { environment } from '@env/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-change-developers-access',
  templateUrl: './change-developers-access.component.html',
  styleUrls: ['./change-developers-access.component.css']
})
export class ChangeDevelopersAccessComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;
  emailCheckForm: UntypedFormGroup;
  submitted: boolean;
  userEmail: string;

  status: string;

  btnText: string;
  currentUrl: string;
  userExists: boolean;
  newUserMessage = `This is a new user, you can tell him/her to register from developer ` +
    `<a href="${environment.DEVELOPER_URL}/auth/register">sign up</a> portal.`;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private utilityService: CommonUtilityService,
  ) {
    this.currentUrl = router.url || '';
    this.pageHeading = 'Change Developer Partner Access';
    this.emailCheckForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((params: any) => {
        const email = params.id;
        if (email) {
          this.emailCheckForm.patchValue({
            email
          });
        }
      });

    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `${this.pageHeading} | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.emailCheckForm.get('email').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.submitted = false;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  get checkUserForm() {
    return this.emailCheckForm.controls;
  }

  checkEmail() {
    if (this.emailCheckForm.valid) {
      this.userService.checkUserIsExists(this.checkUserForm.email.value).then((userDetails: any) => {
        this.submitted = true;
        this.userEmail = this.checkUserForm.email.value;
        this.status = userDetails.status;
        if (this.status === 'NewUser') {
          this.utilityService.displayErrorToast(this.pageHeading, this.newUserMessage);
        } else {
          this.utilityService.displaySuccessToast(this.pageHeading, 'This is an existing user.');
        }
      }).catch(error => {
        this.utilityService.displayErrorToastWithTechDetails(this.pageHeading, error.error);
      });
    } else {
      this.utilityService.displayErrorToast(this.pageHeading, 'Please fill all the required field.');
    }
  }
}
