import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { ContentManagerService } from '@app/services/content-manager.service';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-maintenance-content',
  templateUrl: './maintenance-content.component.html',
  styleUrls: ['./maintenance-content.component.css']
})
export class MaintenanceContentComponent implements OnInit, OnDestroy {

  public config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear'
          ]
        ]
      ]
    },
    height: '400px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'hr']],
      ['customButtons', ['testBtn']],
      ['view', ['codeview', 'help']]
    ],
    fontSizes: ['8', '9', '10', '11', '12', '14', '18', '24', '36', '44', '56', '64', '76', '84', '96'],
    fontNames: ['Arial', 'Times New Roman', 'Inter', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times', 'MangCau',
      'BayBuomHep', 'BaiSau', 'BaiHoc', 'CoDien', 'BucThu', 'KeChuyen', 'MayChu', 'ThoiDai', 'ThuPhap-Ivy', 'ThuPhap-ThienAn'],
    codeviewFilter: true,
    // eslint-disable-next-line max-len
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true
  };
  title: string;
  primaryColor: string;
  pageHeading: string;

  maintenanceContentForm: UntypedFormGroup;
  defaultContent: string;
  warningOn: boolean;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private titleService: Title,
    private commonUtility: CommonUtilityService,
    private contentManagerService: ContentManagerService,
    private logger: NGXLogger,
    private ngxService: NgxUiLoaderService
  ) {
    this.pageHeading = 'Maintenance Content';
    this.maintenanceContentForm = new UntypedFormGroup({
      html: new UntypedFormControl('', Validators.required)
    });
    this.warningOn = false;
  }

  ngOnInit() {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
        this.title = `Maintenance Content | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getContent();
  }

  getContent() {
    this.ngxService.startLoader('maintenance-content-loader');
    this.contentManagerService.getMaintenanceContent().then((content: any) => {
      this.defaultContent = content.defaultWarningMessage;
      this.warningOn = content.warningOn;
      this.maintenanceContentForm.patchValue({
        html: content.warningMessage
      });
      this.ngxService.stopLoader('maintenance-content-loader');
    }).catch(error => {
      this.commonUtility.displayErrorToastWithTechDetails('Get Maintenance Content', error.error);
      this.ngxService.stopLoader('maintenance-content-loader');
    });
  }

  restoreContentToHtml() {
    this.ngxService.startLoader('maintenance-content-loader');
    if (this.defaultContent) {
      this.maintenanceContentForm.patchValue({
        html: this.defaultContent
      });
      this.ngxService.stopLoader('maintenance-content-loader');
    } else {
      this.commonUtility.displayErrorToast('Restore to Default', 'No default value available for update.');
      this.ngxService.stopLoader('maintenance-content-loader');
    }
  }

  saveMaintenanceContent() {
    const toastTitle = 'Save Maintenance Content';
    this.ngxService.startLoader('maintenance-content-loader');
    if (this.maintenanceContentForm.valid) {
      const maintenanceContent = this.maintenanceContentForm.value.html;
      this.contentManagerService.saveMaintenanceContent(maintenanceContent).then((updatedDetails: any) => {
        this.logger.info('INFO: -> MaintenanceContentComponent -> this.contentManagerService.saveMaintenanceContent -> updatedDetails',
        updatedDetails);
        this.commonUtility.displaySuccessToast(toastTitle, updatedDetails.message);
        this.ngxService.stopLoader('maintenance-content-loader');
      }).catch(error => {
        this.logger.error('ERROR: -> MaintenanceContentComponent -> this.contentManagerService.saveMaintenanceContent -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails(toastTitle, error.error);
        this.ngxService.stopLoader('maintenance-content-loader');
      });
    } else {
      this.commonUtility.displayErrorToast(toastTitle, 'Please fill all the required field.');
      this.ngxService.stopLoader('maintenance-content-loader');
    }
  }

  toggleWarningOnOff() {
    const toastTitle = 'Toggle Warning On/Off';
    this.ngxService.startLoader('maintenance-content-loader');
    this.contentManagerService.toggleWarningOnOff(this.warningOn).then((updatedDetails: any) => {
      this.commonUtility.displaySuccessToast(toastTitle, updatedDetails.message);
      this.ngxService.stopLoader('maintenance-content-loader');
    }).catch(error => {
      this.commonUtility.displayErrorToastWithTechDetails(toastTitle, error.error);
      this.ngxService.stopLoader('maintenance-content-loader');
    });
  }

  get f() {
    return this.maintenanceContentForm.controls;
  }

  public onBlur() {
    // console.log('Blur');
  }

  public onDelete() {
    // console.log('Delete file', file.url);
  }

  public summernoteInit() {
    // console.log(event);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
