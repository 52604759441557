import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EMPTY, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { CommonUtilityService } from './common-utility.service';
import { PopupService } from './popup.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleLoginResolverService {
  constructor(
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private authService: AuthenticationService,
    private popupService: PopupService,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger
  ) { }

  resolve(route: ActivatedRouteSnapshot): Promise<object> | Observable<never> {
    this.ngxService.start();
    return this.authService.googleLogin(route.queryParams).then(response => {
      this.ngxService.stop();
      this.ngxService.stopBackground();
      if (response && response.isMFAEnabled) {
        this.router.navigate(['/']);
        this.popupService.openLoginMFAPopup(response);
      } else if (response && response.token) {
        this.router.navigate(['/dashboard']);
      } else if (response && response.isRedirect) {
        window.location.replace(response.url);
      } else {
        this.router.navigate(['/error']);
      }
      return response;
    }).catch(error => {
      this.logger.warn('ERROR: -> GoogleResolverService -> this.authService.googleLogin -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displayErrorToastWithTechDetails('Login With Google', error?.error);
      this.router.navigate(['/']);
      return EMPTY;
    });
  }
}
