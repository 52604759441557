import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  catToolURL: string = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient
  ) {

  }

  getAllPartners(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/get-all-accountant-partners', requestBody).toPromise();
  }

  getAllPartnersForExport(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/get-all-accountant-partners-for-export', requestBody).toPromise();
  }

  changeRockStarPublicationFlag(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/change-rockstar-publication', requestBody).toPromise();
  }

  rockstarCertificateAssign(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/rockstar-certificate-assign', requestBody).toPromise();
  }

  updatePartnerStatus(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/update-external-accountant-status', requestBody).toPromise();
  }
}
