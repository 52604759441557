import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { ZohoSubWebhookService } from '@app/services/zoho-sub-webhook.service';
import { Subject } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { GetZohoSubWebhookEventsRequest, GetZohoSubWebhookEventsResponse, RetryApiResponse, ZohoSubWebhookEvent } from './zoho-sub-webhooks-event.type';
import { MatDialog } from '@angular/material/dialog';
import { ZohoSubWebhooksHistoryPopupComponent, ZohoSubWebhooksPayloadPopupComponent } from '@components/private/zoho-sub-webhooks';
import { ConfirmationDialogService } from '@app/services/confirmation-dialog.service';

@Component({
  selector: 'app-zoho-sub-webhooks',
  templateUrl: './zoho-sub-webhooks.component.html',
  styleUrls: ['./zoho-sub-webhooks.component.css']
})
export class ZohoSubWebhooksComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;

  searchString: string;
  selectedEventTypes: any;
  selectedStatus: any;
  selectedColumns: any;
  selectedSort: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  webhookEvents: ZohoSubWebhookEvent[];
  fromShowing: any;
  toShowing: any;

  statusList = [
    {id: 1, label: 'Pending', value: 'pending', key: 'pending', class: 'dot-pending-status'},
    {id: 2, label: 'Succeed', value: 'succeed', key: 'succeed', class: 'dot-succeed-status'},
    {id: 3, label: 'Processing', value: 'processing', key: 'processing', class: 'dot-processing-status'},
    {id: 4, label: 'Failed', value: 'failed', key: 'failed', class: 'dot-failed-status'}
  ];
  eventTypeList = [
    {id: 1, label: 'payment_thankyou', value: 'payment_thankyou', key: 'payment_thankyou', class: 'dot-payment_thankyou'},
    {id: 2, label: 'subscription_activation', value: 'subscription_activation', key: 'subscription_activation', class: 'dot-subscription_activation'},
    {id: 3, label: 'subscription_renewed', value: 'subscription_renewed', key: 'subscription_renewed', class: 'dot-subscription_renewed'},
    {id: 4, label: 'invoice_notification', value: 'invoice_notification', key: 'invoice_notification', class: 'dot-invoice_notification'},
    {id: 5, label: 'creditnote_added', value: 'creditnote_added', key: 'creditnote_added', class: 'dot-creditnote_added'},
    {id: 6, label: 'creditnote_refunded', value: 'creditnote_refunded', key: 'creditnote_refunded', class: 'dot-creditnote_refunded'},
    {id: 7, label: 'creditnote_deleted', value: 'creditnote_deleted', key: 'creditnote_deleted', class: 'dot-creditnote_deleted'},
    {id: 8, label: 'payment_method_updated', value: 'payment_method_updated', key: 'payment_method_updated', class: 'dot-payment_method_updated'},
    {id: 9, label: 'subscription_cancelled', value: 'subscription_cancelled', key: 'subscription_cancelled', class: 'dot-subscription_cancelled'},
    {id: 10, label: 'subscription_created', value: 'subscription_created', key: 'subscription_created', class: 'dot-subscription_created'},
    {id: 11, label: 'subscription_upgraded', value: 'subscription_upgraded', key: 'subscription_upgraded', class: 'dot-subscription_upgraded'},
    {id: 12, label: 'subscription_downgraded', value: 'subscription_downgraded', key: 'subscription_downgraded', class: 'dot-subscription_downgraded'},
    {id: 13, label: 'subscription_unpaid', value: 'subscription_unpaid', key: 'subscription_unpaid', class: 'dot-subscription_unpaid'},
    {id: 14, label: 'subscription_paused', value: 'subscription_paused', key: 'subscription_paused', class: 'dot-subscription_paused'},
    {id: 15, label: 'subscription_resumed', value: 'subscription_resumed', key: 'subscription_resumed', class: 'dot-subscription_resumed'},

  ];
  columns = [
    {id: 1, name: 'eventDate', label: 'Event date', sort: -1, hide: 0},
    {id: 2, name: 'eventId', label: 'Event id', sort: 0, hide: 0},
    {id: 3, name: 'eventType', label: 'Event type', sort: 0, hide: 0},
    {id: 4, name: 'status', label: 'Status', sort: 0, hide: 0},
    {id: 5, name: 'tries', label: 'Number of tries', sort: 0, hide: 0},
    {id: 6, name: 'lastUpdatedDate', label: 'Last updated date', sort: 0, hide: 0},
    {id: 7, name: 'action', label: 'Actions', sort: 0, hide: 1}
  ];
  @ViewChild('allSelectedEventType') private allSelectedEventType: MatOption;
  @ViewChild('allSelectedStatus') private allSelectedStatus: MatOption;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private zohoSubWebhookService: ZohoSubWebhookService,
    private commonUtility: CommonUtilityService,
    private confirmationDialogService: ConfirmationDialogService,
    public dialog: MatDialog
  ) {
    this.pageHeading = 'Zoho sub webhook events';
    this.searchString = '';
    this.selectedEventTypes = [...this.eventTypeList.map(item => item.value), 0];
    this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    this.selectedColumns = [
      {id: 1, name: 'eventDate', label: 'Event date', sort: -1, hide: 0},
      {id: 2, name: 'eventId', label: 'Event id', sort: 0, hide: 0},
      {id: 3, name: 'eventType', label: 'Event type', sort: 0, hide: 0},
      {id: 4, name: 'status', label: 'Status', sort: 0, hide: 0},
      {id: 5, name: 'tries', label: 'Number of tries', sort: 0, hide: 0},
      {id: 6, name: 'lastUpdatedDate', label: 'Last updated date', sort: 0, hide: 0},
      {id: 7, name: 'action', label: 'Actions', sort: 0, hide: 1}
    ];
    this.selectedSort = {key: 'Event date', value: -1};
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
  }

  ngOnInit(): void {
    this.route.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `Zoho sub webhooks | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllWebhookEvents(this.pageNo);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  clearSearch(): void {
    this.searchString = '';
    this.getAllWebhookEvents(this.pageNo);
  }

  onChangeSearchText(): void {
    this.getAllWebhookEvents(this.pageNo);
  }

  changeStatusFilter(): void {
    this.getAllWebhookEvents(this.pageNo);
  }

  changeEventTypeFilter(): void {
    this.getAllWebhookEvents(this.pageNo);
  }

  toggleAllTypeStatus(): void {
    if (this.allSelectedStatus.selected) {
      this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    } else {
      this.selectedStatus = [];
    }
    this.changeStatusFilter();
  }

  eventTypeTouchPerOne(): void {
    if (this.allSelectedEventType.selected) {
      this.allSelectedEventType.deselect();
    }
    if (this.selectedEventTypes.length === this.eventTypeList.length) {
      this.allSelectedEventType.select();
    }
    this.changeStatusFilter();
  }

  resetEventTypeSelection(): void {
    this.allSelectedEventType.select();
    this.selectedEventTypes = [...this.eventTypeList.map(item => item.value), 0];
    this.getAllWebhookEvents(this.pageNo);
  }

  toggleAllEventType(): void {
    if (this.allSelectedEventType.selected) {
      this.selectedEventTypes = [...this.eventTypeList.map(item => item.value), 0];
    } else {
      this.selectedEventTypes = [];
    }
    this.changeEventTypeFilter();
  }

  statusTouchPerOne(): void {
    if (this.allSelectedStatus.selected) {
      this.allSelectedStatus.deselect();
    }
    if (this.selectedStatus.length === this.statusList.length) {
      this.allSelectedStatus.select();
    }
    this.changeStatusFilter();
  }

  resetStatusSelection(): void {
    this.allSelectedStatus.select();
    this.selectedStatus = [...this.statusList.map(item => item.value), 0];
    this.getAllWebhookEvents(this.pageNo);
  }

  sortBy(columnName: string) : void{
    if (!columnName) {
      return;
    }
    this.columns.forEach((value) => {
      if (value.label === columnName) {
        value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
        this.selectedSort = {key: value.label, value: value.sort};
      } else {
        value.sort = 0;
      }
    });
    this.getAllWebhookEvents(this.pageNo);
  }

  columnChange(): void {

  }

  compareFn(user1, user2): any {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column: any): any {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  changeDisplayPerPageItem(event: any): void {
    this.itemsPerPage = event.value;
    this.getAllWebhookEvents(this.pageNo);
  }

  getAllWebhookEvents(pageNo: number): void {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams: GetZohoSubWebhookEventsRequest = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedEventTypes: this.selectedEventTypes,
      selectedStatus: this.selectedStatus,
      selectedSort: this.selectedSort,
    };
    this.zohoSubWebhookService.getZohoSubWebhookEvents(requestParams)
      .pipe(
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((webhookEventsListResponse: GetZohoSubWebhookEventsResponse) => {
        this.commonUtility.displaySuccessToast(this.pageHeading, 'Zoho sub webhooks List refreshed.');
        this.webhookEvents = webhookEventsListResponse.webhookEvents;
        if (webhookEventsListResponse.totalCount === 0) {
          this.fromShowing = 0;
          this.toShowing = 0;
        } else {
          pageNo = pageNo - 1;
          this.fromShowing = (pageNo * this.itemsPerPage) + 1;
          this.toShowing = ((pageNo + 1) * this.itemsPerPage < webhookEventsListResponse.totalCount ?
          (pageNo + 1) * this.itemsPerPage :
          webhookEventsListResponse.totalCount);
        }
        this.totalCount = webhookEventsListResponse.totalCount;
      });
  }

  refreshData(): void {
    this.getAllWebhookEvents(this.currentPage);
  }

  retry(eventId: string, status: string): void {
    if(status === 'succeed') {
      this.confirmationDialogService.confirm('Are you sure?', 'You trying to run retry on already succeed webhook, do you want to proceed?')
      .then((confirmed) => {
        if(confirmed) {
          this.retryCall(eventId);
        }
      })
      .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    } else {
      this.retryCall(eventId);
    }

  }

  retryCall(eventId: string) {
    this.zohoSubWebhookService.retry(eventId)
    .pipe(
      filter(res => res.status),
      catchError((error) => {
        console.log('🚀 ~ ZohoSubWebhooksComponent ~ catchError ~ error:', error)
        this.commonUtility.displayErrorToast(error?.title, error?.message);
        throw error;
      }),
      takeUntil(this.componentDestroyed$)
    )
    .subscribe((result: RetryApiResponse) => {
      this.commonUtility.displaySuccessToast(result?.title, result?.message);
    });
  }

  openPayloadDialog(payload: object): void {
    this.dialog.open(ZohoSubWebhooksPayloadPopupComponent, {
      data: payload
    });
  }

  openHistoryDialog(webhook: object): void {
    this.dialog.open(ZohoSubWebhooksHistoryPopupComponent, {
      data: webhook
    });
  }
}
