import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ZohoSubWebhookEvent } from '../zoho-sub-webhooks-event.type';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-zoho-sub-webhooks-history-popup',
  templateUrl: './zoho-sub-webhooks-history-popup.component.html',
  styleUrls: ['./zoho-sub-webhooks-history-popup.component.css']
})
export class ZohoSubWebhooksHistoryPopupComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['tryNo', 'tryDate', 'subtasks', 'response'];
  dataSource = new MatTableDataSource();
  constructor(
    public dialogRef: MatDialogRef<ZohoSubWebhooksHistoryPopupComponent>, @Inject(MAT_DIALOG_DATA) public webhookEvent: ZohoSubWebhookEvent
  )  {

  }

  ngOnInit(): void {
    this.dataSource.data = this.webhookEvent.showHistory;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    const sortState: Sort = {active: 'tryNo', direction: 'desc'};
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
