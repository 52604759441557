import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserService } from '@app/services/user.service';
import { User } from '@app/models/user';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class UserResolverService {

  constructor(
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private userService: UserService,
    private logger: NGXLogger
  ) { }

  resolve(): Promise<object> | Observable<never> {
    return this.userService.getLoggedInUserDetails().then((response: User) => {
      this.logger.info('INFO: -> UserResolverService -> response', response);
      this.userService.changeUserDetails(response);
      return response;
    }).catch(error => {
      this.logger.error('ERROR: -> UserResolverService -> error', error);
      this.router.navigate(['/']);
      return EMPTY;
    });
  }
}
