import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { State } from '@app/models/state';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  api: string = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  getState(): Observable<State[]> {
    const states = getStates();
    return of(states).pipe();
  }

  getStateByCountry(country: string) {
    return this.httpClient.get<State[]>(this.api + `/get-states-list?country=${country}`).toPromise();
  }
}
function getStates() {
  return [
    {
      stateCaps: 'ALABAMA',
      state: 'Alabama',
      abbreviation: 'AL',
      apStyleAbbreviation: 'Ala.'
    },
    {
      stateCaps: 'ALASKA',
      state: 'Alaska',
      abbreviation: 'AK',
      apStyleAbbreviation: 'Alaska'
    },
    {
      stateCaps: 'ARIZONA',
      state: 'Arizona',
      abbreviation: 'AZ',
      apStyleAbbreviation: 'Ariz.'
    },
    {
      stateCaps: 'ARKANSAS',
      state: 'Arkansas',
      abbreviation: 'AR',
      apStyleAbbreviation: 'Ark.'
    },
    {
      stateCaps: 'CALIFORNIA',
      state: 'California',
      abbreviation: 'CA',
      apStyleAbbreviation: 'Calif.'
    },
    {
      stateCaps: 'COLORADO',
      state: 'Colorado',
      abbreviation: 'CO',
      apStyleAbbreviation: 'Colo.'
    },
    {
      stateCaps: 'CONNECTICUT',
      state: 'Connecticut',
      abbreviation: 'CT',
      apStyleAbbreviation: 'Conn.'
    },
    {
      stateCaps: 'DELAWARE',
      state: 'Delaware',
      abbreviation: 'DE',
      apStyleAbbreviation: 'Del.'
    },
    {
      stateCaps: 'FLORIDA',
      state: 'Florida',
      abbreviation: 'FL',
      apStyleAbbreviation: 'Fla.'
    },
    {
      stateCaps: 'GEORGIA',
      state: 'Georgia',
      abbreviation: 'GA',
      apStyleAbbreviation: 'Ga.'
    },
    {
      stateCaps: 'HAWAII',
      state: 'Hawaii',
      abbreviation: 'HI',
      apStyleAbbreviation: 'Hawaii'
    },
    {
      stateCaps: 'IDAHO',
      state: 'Idaho',
      abbreviation: 'ID',
      apStyleAbbreviation: 'Idaho'
    },
    {
      stateCaps: 'ILLINOIS',
      state: 'Illinois',
      abbreviation: 'IL',
      apStyleAbbreviation: 'Ill.'
    },
    {
      stateCaps: 'INDIANA',
      state: 'Indiana',
      abbreviation: 'IN',
      apStyleAbbreviation: 'Ind.'
    },
    {
      stateCaps: 'IOWA',
      state: 'Iowa',
      abbreviation: 'IA',
      apStyleAbbreviation: 'Iowa'
    },
    {
      stateCaps: 'KANSAS',
      state: 'Kansas',
      abbreviation: 'KS',
      apStyleAbbreviation: 'Kan.'
    },
    {
      stateCaps: 'KENTUCKY',
      state: 'Kentucky',
      abbreviation: 'KY',
      apStyleAbbreviation: 'Ky.'
    },
    {
      stateCaps: 'LOUISIANA',
      state: 'Louisiana',
      abbreviation: 'LA',
      apStyleAbbreviation: 'La.'
    },
    {
      stateCaps: 'MAINE',
      state: 'Maine',
      abbreviation: 'ME',
      apStyleAbbreviation: 'Maine'
    },
    {
      stateCaps: 'MARYLAND',
      state: 'Maryland',
      abbreviation: 'MD',
      apStyleAbbreviation: 'Md.'
    },
    {
      stateCaps: 'MASSACHUSETTS',
      state: 'Massachusetts',
      abbreviation: 'MA',
      apStyleAbbreviation: 'Mass.'
    },
    {
      stateCaps: 'MICHIGAN',
      state: 'Michigan',
      abbreviation: 'MI',
      apStyleAbbreviation: 'Mich.'
    },
    {
      stateCaps: 'MINNESOTA',
      state: 'Minnesota',
      abbreviation: 'MN',
      apStyleAbbreviation: 'Minn.'
    },
    {
      stateCaps: 'MISSISSIPPI',
      state: 'Mississippi',
      abbreviation: 'MS',
      apStyleAbbreviation: 'Miss.'
    },
    {
      stateCaps: 'MISSOURI',
      state: 'Missouri',
      abbreviation: 'MO',
      apStyleAbbreviation: 'Mo.'
    },
    {
      stateCaps: 'MONTANA',
      state: 'Montana',
      abbreviation: 'MT',
      apStyleAbbreviation: 'Mont.'
    },
    {
      stateCaps: 'NEBRASKA',
      state: 'Nebraska',
      abbreviation: 'NE',
      apStyleAbbreviation: 'Neb.'
    },
    {
      stateCaps: 'NEVADA',
      state: 'Nevada',
      abbreviation: 'NV',
      apStyleAbbreviation: 'Nev.'
    },
    {
      stateCaps: 'NEW HAMPSHIRE',
      state: 'New Hampshire',
      abbreviation: 'NH',
      apStyleAbbreviation: 'N.H.'
    },
    {
      stateCaps: 'NEW JERSEY',
      state: 'New Jersey',
      abbreviation: 'NJ',
      apStyleAbbreviation: 'N.J.'
    },
    {
      stateCaps: 'NEW MEXICO',
      state: 'New Mexico',
      abbreviation: 'NM',
      apStyleAbbreviation: 'N.M.'
    },
    {
      stateCaps: 'NEW YORK',
      state: 'New York',
      abbreviation: 'NY',
      apStyleAbbreviation: 'N.Y.'
    },
    {
      stateCaps: 'NORTH CAROLINA',
      state: 'North Carolina',
      abbreviation: 'NC',
      apStyleAbbreviation: 'N.C.'
    },
    {
      stateCaps: 'NORTH DAKOTA',
      state: 'North Dakota',
      abbreviation: 'ND',
      apStyleAbbreviation: 'N.D.'
    },
    {
      stateCaps: 'OHIO',
      state: 'Ohio',
      abbreviation: 'OH',
      apStyleAbbreviation: 'Ohio'
    },
    {
      stateCaps: 'OKLAHOMA',
      state: 'Oklahoma',
      abbreviation: 'OK',
      apStyleAbbreviation: 'Okla.'
    },
    {
      stateCaps: 'OREGON',
      state: 'Oregon',
      abbreviation: 'OR',
      apStyleAbbreviation: 'Ore.'
    },
    {
      stateCaps: 'PENNSYLVANIA',
      state: 'Pennsylvania',
      abbreviation: 'PA',
      apStyleAbbreviation: 'Pa.'
    },
    {
      stateCaps: 'RHODE ISLAND',
      state: 'Rhode Island',
      abbreviation: 'RI',
      apStyleAbbreviation: 'R.I.'
    },
    {
      stateCaps: 'SOUTH CAROLINA',
      state: 'South Carolina',
      abbreviation: 'SC',
      apStyleAbbreviation: 'S.C.'
    },
    {
      stateCaps: 'SOUTH DAKOTA',
      state: 'South Dakota',
      abbreviation: 'SD',
      apStyleAbbreviation: 'S.D.'
    },
    {
      stateCaps: 'TENNESSEE',
      state: 'Tennessee',
      abbreviation: 'TN',
      apStyleAbbreviation: 'Tenn.'
    },
    {
      stateCaps: 'TEXAS',
      state: 'Texas',
      abbreviation: 'TX',
      apStyleAbbreviation: 'Texas'
    },
    {
      stateCaps: 'UTAH',
      state: 'Utah',
      abbreviation: 'UT',
      apStyleAbbreviation: 'Utah'
    },
    {
      stateCaps: 'VERMONT',
      state: 'Vermont',
      abbreviation: 'VT',
      apStyleAbbreviation: 'Vt.'
    },
    {
      stateCaps: 'VIRGINIA',
      state: 'Virginia',
      abbreviation: 'VA',
      apStyleAbbreviation: 'Va.'
    },
    {
      stateCaps: 'WASHINGTON',
      state: 'Washington',
      abbreviation: 'WA',
      apStyleAbbreviation: 'Wash.'
    },
    {
      stateCaps: 'WEST VIRGINIA',
      state: 'West Virginia',
      abbreviation: 'WV',
      apStyleAbbreviation: 'W.Va.'
    },
    {
      stateCaps: 'WISCONSIN',
      state: 'Wisconsin',
      abbreviation: 'WI',
      apStyleAbbreviation: 'Wis.'
    },
    {
      stateCaps: 'WYOMING',
      state: 'Wyoming',
      abbreviation: 'WY',
      apStyleAbbreviation: 'Wyo.'
    }
  ];
}

