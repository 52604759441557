import { Component } from '@angular/core';

@Component({
  selector: 'app-invalid-link',
  templateUrl: './invalid-link.component.html',
  styleUrls: ['./invalid-link.component.css']
})
export class InvalidLinkComponent {

  constructor() { }

}
