import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FranchiseeService } from '@app/services/franchisee.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MultiFactorService } from '@app/services/multi-factor.service';
import { Franchisee } from '@app/models/franchisee';
import { environment } from '@env/environment';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { AuthenticationService } from '@app/services/authentication.service';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-multi-factor-login-popup',
  templateUrl: './multi-factor-login-popup.component.html',
  styleUrls: ['./multi-factor-login-popup.component.css']
})
export class MultiFactorLoginPopupComponent implements OnInit, OnDestroy {
  title = 'Multi-Factor Authentication';
  user: User;
  logo: string = environment.CBK_LOGO_LIGHT_IMAGE;
  franchiseeCode: string;
  companyName: string;
  recoveryEmail: string;
  currentState = 'Login';
  states: string[] = ['Login', 'Completed'];
  securityCode: string;
  verificationCode: string;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    public modal: NgbActiveModal,
    private franchiseeService: FranchiseeService,
    private ngxService: NgxUiLoaderService,
    private mfaService: MultiFactorService,
    private authService: AuthenticationService,
    private commonUtility: CommonUtilityService,
    private router: Router,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.franchiseeService.getFranchiseeDetails
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((franchiseDetails: Franchisee) => {
        this.logger.info('INFO: -> MultiFactorLoginPopupComponent -> .subscribe -> franchiseDetails', franchiseDetails);
        this.companyName = franchiseDetails.companyName;
        this.franchiseeCode = franchiseDetails.franchiseeCode;
      });
    this.recoveryEmail = this.user.recoveryEmail;
  }

  closePopupAndRefreshTab() {
    this.modal.close();
  }

  validateMFA() {
    if (this.securityCode) {
      this.verifySecurityCode(this.securityCode);
    } else if (this.verificationCode) {
      this.validateVerificationCode(this.verificationCode);
    } else {
      this.commonUtility.displayErrorToast(this.title, 'Please fill the required fields.');
    }
  }

  sendVerificationEmail() {
    this.ngxService.start();
    this.mfaService.sendSecurityCodeEmail(this.user, this.franchiseeCode).then(response => {
      this.logger.info('INFO: -> MultiFactorLoginPopupComponent -> sendVerificationEmail -> response', response);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displaySuccessToast(this.title, 'Email sent successfully to your recovery email.');
    }).catch(error => {
      this.logger.error('ERROR: -> MultiFactorLoginPopupComponent -> sendVerificationEmail -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  verifySecurityCode(securityCode: string) {
    this.ngxService.start();
    this.mfaService.authenticateWithSecurityCode(this.user.email, this.franchiseeCode, securityCode).then((response: User) => {
      this.logger.info('INFO: -> MultiFactorLoginPopupComponent -> verifySecurityCode -> response', response);
      this.currentState = 'Completed';
      this.authService.setUserSession(response);
      this.modal.close();
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.router.navigate(['/dashboard']);
    }).catch(error => {
      this.logger.info('ERROR: -> MultiFactorLoginPopupComponent -> verifySecurityCode -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  validateVerificationCode(verificationCode: string) {
    this.ngxService.start();
    this.mfaService.authenticateWithRecoveryEmail(this.user.email, this.franchiseeCode, verificationCode).then((response: User) => {
      this.logger.info('INFO: -> MultiFactorDisabledPopupComponent -> disableMFAWithRecoveryEmail -> response', response);
      this.currentState = 'Completed';
      this.authService.setUserSession(response);
      this.ngxService.stop();
      this.modal.close();
      this.ngxService.stopBackground();
      this.router.navigate(['/dashboard']);
    }).catch(error => {
      this.logger.info('ERROR: -> MultiFactorDisabledPopupComponent -> disableMFAWithRecoveryEmail -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
