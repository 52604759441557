import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { UserService } from '@app/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-partner',
  templateUrl: './add-partner.component.html',
  styleUrls: ['./add-partner.component.css']
})
export class AddPartnerComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;
  emailCheckForm: UntypedFormGroup;
  submitted: boolean;
  userEmail: string;

  enableProfileButton: boolean;
  enableStatusButton: boolean;
  enableViewProfileButton: boolean;
  enableDeveloperSettingsButton: boolean;
  enableRockStartSettingsButton: boolean;

  viewProfileSection: boolean;
  editProfileSection: boolean;
  updateStatusSection: boolean;
  rockstarSettingSection: boolean;
  developerSettingSection: boolean;

  developerPortalAccess: boolean;
  userDetails: object;

  btnText: string;
  currentUrl: string;
  userExists: boolean;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private userService: UserService,
    private fb: UntypedFormBuilder,
    private utilityService: CommonUtilityService,
  ) {
    this.currentUrl = router.url || '';
    this.pageHeading = 'Add Accountant Partner';
    this.enableProfileButton = false;
    this.enableStatusButton = false;
    this.enableViewProfileButton = false;
    this.enableDeveloperSettingsButton = false;
    this.enableRockStartSettingsButton = false;

    this.viewProfileSection = false;
    this.editProfileSection = false;
    this.updateStatusSection = false;
    this.rockstarSettingSection = false;
    this.developerSettingSection = false;
    this.userExists = false;
    this.emailCheckForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    if (this.currentUrl.includes('partners/edit')) {
      this.pageHeading = 'Edit Accountant Partner';
    }
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((params: any) => {
        const email = params.id;
        if (email) {
          this.emailCheckForm.patchValue({
            email
          });
          if (this.currentUrl.includes('partners/view')) {
            this.checkEmail();
            this.openSection('VIEW-PROFILE');
          }
          if (this.currentUrl.includes('partners/edit')) {
            this.checkEmail();
            this.openSection('EDIT-PROFILE');
          }
          if (this.currentUrl.includes('partners/update-status')) {
            this.checkEmail();
            this.openSection('UPDATE-STATUS');
          }
        }
      });

    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `${this.pageHeading} | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
    });
    this.emailCheckForm.get('email').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        this.submitted = false;
        this.enableProfileButton = false;
        this.enableStatusButton = false;
        this.enableViewProfileButton = false;
        this.enableDeveloperSettingsButton = false;
        this.enableRockStartSettingsButton = false;
        this.openSection('');
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  get checkUserForm() {
    return this.emailCheckForm.controls;
  }

  checkEmail() {
    if (this.emailCheckForm.valid) {
      this.userService.checkUserIsExists(this.checkUserForm.email.value).then((userDetails: any) => {
        this.submitted = true;
        this.userEmail = this.checkUserForm.email.value;
        this.userExists = false;
        this.developerPortalAccess = userDetails.decoDeveloperAccess;
        this.userDetails = userDetails;
        if (userDetails.status === 'NewUser') {
          this.utilityService.displaySuccessToast(this.pageHeading, 'This is a new user.' );
          this.enableProfileButton = true;
          this.btnText = 'Add Profile';
        } else if (userDetails.status === 'ProfileNotCompleted') {
          this.utilityService.displaySuccessToast(this.pageHeading,
            'This is an existing user with incomplete profile information for verification.');
          this.enableProfileButton = true;
          this.enableViewProfileButton = true;
          this.btnText = 'Edit Profile';
          this.userExists = true;
        } else if (userDetails.status === 'ProfileCompleted') {
          this.utilityService.displaySuccessToast(this.pageHeading,
            'This is an existing user with required profile information for verification.');
          this.enableProfileButton = true;
          this.enableViewProfileButton = true;
          this.enableStatusButton = true;
          this.enableDeveloperSettingsButton = true;
          this.enableRockStartSettingsButton = true;
          this.btnText = 'Edit Profile';
          this.userExists = true;
        }
      }).catch(error => {
        this.utilityService.displayErrorToastWithTechDetails(this.pageHeading, error.error);
      });
    } else {
      this.utilityService.displayErrorToast(this.pageHeading, 'Please fill all the required field.');
    }
  }

  openSection(sectionName) {
    this.viewProfileSection = false;
    this.editProfileSection = false;
    this.updateStatusSection = false;
    this.rockstarSettingSection = false;
    this.developerSettingSection = false;
    switch (sectionName) {
      case 'VIEW-PROFILE':
        this.viewProfileSection = true;
        break;
      case 'EDIT-PROFILE':
        this.editProfileSection = true;
        break;
      case 'UPDATE-STATUS':
          this.updateStatusSection = true;
          break;
      case 'ROCKSTAR-SETTINGS':
        this.rockstarSettingSection = true;
        break;
      case 'DEVELOPER-SETTINGS':
        this.developerSettingSection = true;
        break;
      default:
        break;
    }
  }
}
