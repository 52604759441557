import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '@app/models/customer';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { PopupService } from '@app/services/popup.service';
import { UserService } from '@app/services/user.service';
import { environment } from '@env/environment';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;
  pageHeading: string;
  primaryColor: string;

  currentUrl: string;
  onlyUser: boolean;

  customerDetails: Customer;
  userProfilePic: string;
  profileImageFromAws: string;
  professionalDesignationsOptions = environment.ProfessionalDesignationsOptions;
  languageSpokenOptions = environment.LanguageSpokenOptions;
  industriesServedOptions = environment.IndustriesServedOptions;
  servicesProvidedOptions = environment.ServicesProvidedOptions;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonUtility: CommonUtilityService,
    private ngxService: NgxUiLoaderService,
    private userService: UserService,
    private popupService: PopupService,
    private logger: NGXLogger
  ) {
    this.currentUrl = router.url;
    this.onlyUser = false;
    this.pageHeading = 'User Details';
    this.userProfilePic = 'assets/images/male.png';
    this.route.params
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((params: any) => {
        const email = params.id;
        if (email) {
          this.userEmail = email;
          if (!this.currentUrl.includes('partners/view') &&
          !this.currentUrl.includes('partners/edit') &&
          !this.currentUrl.includes('partners/add') &&
          !this.currentUrl.includes('partners/update-status')) {
            this.onlyUser = true;
          }
        }
      });
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
      });
    if (this.userEmail) {
      this.ngxService.startLoader('profile-loader');
      this.userService.getUserDetails(this.userEmail).then((customerDetails: Customer) => {
        this.logger.info('INFO: -> UserDetailsComponent -> this.userService.getUserDetails -> customerDetails', customerDetails);
        this.customerDetails = customerDetails;
        this.profileImageFromAws = customerDetails?.profileImage;
        if (!this.profileImageFromAws) {
          this.userService.getUserProfilePic(this.userEmail).then((profilePicDetails: any) => {
            this.logger.info('INFO: -> UserDetailsComponent -> this.userService.getUserProfilePic -> profilePicDetails', profilePicDetails);
            if (profilePicDetails.status) {
              this.userProfilePic = 'data:' + profilePicDetails?.data?.content_type + ';base64,' + profilePicDetails?.data?.image;
            }
          }).catch(error => {
            this.logger.error('ERROR: -> UserDetailsComponent -> this.userService.getUserProfilePic -> error', error);
            this.commonUtility.displayErrorToastWithTechDetails('Get Profile Image', error.error);
          });
        }
      }).catch(error => {
        this.logger.error('ERROR: -> UserDetailsComponent -> this.userService.getUserDetails -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails(this.pageHeading, error.error);
      });
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, 'User Email is missing.');
    }
    this.ngxService.stopLoader('profile-loader');
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  disableTwoFactorAuthentication() {
    const title = 'Multi-Factor Disable';
    this.userService.disabledMFA(this.userEmail).then((response: any) => {
      this.logger.info('INFO: -> UserDetailsComponent -> this.userService.disabledMFA -> response', response);
      this.commonUtility.displaySuccessToast(title, response.message);
      if (this.onlyUser) {
        this.router.navigate(['/users/all'], { queryParams: { search: this.userEmail }});
      } else {
        this.router.navigate(['/partners/all'], { queryParams: { search: this.userEmail }});
      }
    }).catch(error => {
      this.logger.error('ERROR: -> UserDetailsComponent -> this.userService.disabledMFA -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
    });
  }

  openComposeMessagePopup(userEmail) {
    this.popupService.openComposeMessagePopup(userEmail);
  }
}
