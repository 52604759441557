import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AlignmentType, Document, Header, Footer, NumberFormat, Packer,
  PageNumber, PageOrientation, Paragraph, ShadingType, Table, TableCell, TableRow, TextRun, VerticalAlign } from 'docx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const CSV_TYPE = 'text/csv';
const CSV_EXTENSION = '.csv';
const DOC_EXTENSION = '.doc';
@Injectable({
  providedIn: 'root'
})
export class ExportService {
  navigator: any;
  constructor() {
    this.navigator = navigator;
  }

  public exportToCsvFile(json: object[], csvFileName: string) {
    if (json && json.length > 0) {
      const separator = ',';
      const keys = Object.keys(json[0]);
      const fileData =
        keys.join(separator) +
        '\n' +
        json.map(row => keys.map(k => {
            let cell = row[k];
            cell = cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');
            if (cell.search(/("|,|\n)/g) >= 0) {
              cell = `"${cell}"`;
            }
            return cell;
          }).join(separator)).join('\n');
      this.saveAsCSVFile(fileData, csvFileName);
    }
  }

  public async exportToDocFile(json: any[], docFileName: string, title: string, companyName: string) {
    if (json && json.length > 0) {
      const headers = Object.keys(json[0]);
      const headerText = new Header({
        children: [
          new Paragraph({
            alignment: AlignmentType.CENTER,
            children: [
              new TextRun({
                text: title,
                color: '0070C0',
                bold: true,
                size: 24,
              })
            ],
          })
        ],
      });
      const footerText = new Footer({
        children: [
          new Paragraph(companyName),
          new Paragraph({
            children: [
              new TextRun({
                children: [PageNumber.CURRENT],
              })
            ],
            alignment: AlignmentType.RIGHT,
          })
        ],
      });
      const headerRow = [];
      headers.forEach(header => {
        headerRow.push(new TableCell({
          children: [new Paragraph(header)],
          verticalAlign: VerticalAlign.TOP,
          shading: {
            fill: 'DDDDDD',
            type: ShadingType.CLEAR,
            color: 'auto',
          },
        }));
      });
      const tableRow = [new TableRow({
        children: headerRow
      })];
      json.forEach(item => {
        const tableCell = [];
        headers.forEach(header => {
          tableCell.push(new TableCell({
            children: [new Paragraph(item[header])],
            verticalAlign: VerticalAlign.TOP
          }));
        });
        tableRow.push(new TableRow({
          children: tableCell
        }));
      });
      const table = new Table({
        rows: tableRow,
      });

      const doc = new Document({
        sections: [{
          properties: {
            page: {
              size: {
                orientation: PageOrientation.LANDSCAPE,
              },
              pageNumbers: {
                start: 1,
                formatType: NumberFormat.DECIMAL,
              },
            },
          },
          headers: {
            default: headerText,
          },
          footers: {
            default: footerText,
          },
          children: [table],
        }],
      });

      const buffer = await Packer.toBlob(doc);
      this.saveAsDocFile(buffer, docFileName);
    }
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  private saveAsCSVFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: CSV_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + CSV_EXTENSION);
  }

  private saveAsDocFile(buffer: any, fileName: string): void {
    FileSaver.saveAs(buffer, fileName + '_export_' + new Date().getTime() + DOC_EXTENSION);
  }
}
