<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Categories</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="mb-3">
        <h5>Event Categories <a href="https://accountingsuite.atlassian.net/wiki/spaces/AP/pages/761725700/Web2Wallet+Event+logs+v2" target="_blank"><i class="fa fa-info-circle"></i></a></h5>
        <table class="responsive table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Category</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span class="dot-lg dot-info-category"></span>Info</td>
                    <td>Message is the information about an event worth recording.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-warning-category"></span>Warning</td>
                    <td>Message is warning and is worth looking into.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-error-category"></span>Error</td>
                    <td>Message is an error or alert to investigate and resolve.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-debug-category"></span>Debug</td>
                    <td></td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-verbose-category"></span>Verbose</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancel</button>
</div>
