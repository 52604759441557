import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { EMPTY, Observable } from 'rxjs';
import { AccountService } from './account.service';
import { CommonUtilityService } from './common-utility.service';

@Injectable({
  providedIn: 'root'
})
export class AccountResolverService {
  accountNumber: string;
  constructor(
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private accountService: AccountService,
    private logger: NGXLogger,
    private commonUtility: CommonUtilityService,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Promise<object> | Observable<never> {
    this.ngxService.start();
    this.accountNumber = route.paramMap.get('id');
    if (this.accountNumber) {
      return this.accountService.getAccountDetails(this.accountNumber).then(response => {
        this.logger.info('INFO: -> ProfileResolverService -> response', response);
        this.ngxService.stop();
        this.ngxService.stopBackground();
        return response;
      }).catch(error => {
        this.logger.info('ERROR: -> AccountDetailsResolverService -> error', error);
        this.ngxService.stop();
        this.ngxService.stopBackground();
        this.commonUtility.displayErrorToast('Account Details', error.error.message);
        this.router.navigate(['/dashboard']);
        return EMPTY;
      });
    } else {
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displayErrorToast('Account Details', 'Account number is not valid.');
      this.router.navigate(['/dashboard']);
      return EMPTY;
    }
  }
}
