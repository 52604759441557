import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { PopupService } from '@app/services/popup.service';
import { UserService } from '@app/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-playground-details',
  templateUrl: './user-playground-details.component.html',
  styleUrls: ['./user-playground-details.component.css']
})
export class UserPlaygroundDetailsComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;
  primaryColor: string;
  pageHeading: string;
  playgroundDetails: any;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger,
    private userService: UserService,
    private popupService: PopupService
  ) {
    this.pageHeading = 'Get Playground Details';
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
      });
    if (this.userEmail) {
      this.userService.getPlaygroundDetails(this.userEmail).then(playgroundDetails => {
        this.logger.info('INFO: -> UserPlaygroundDetailsComponent -> this.userService.getPlaygroundDetails -> playgroundDetails',
        playgroundDetails);
        this.playgroundDetails = playgroundDetails;
      }).catch(error => {
        this.logger.error('ERROR: -> UserPlaygroundDetailsComponent -> this.userService.getPlaygroundDetails -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails(this.pageHeading, error?.error);
      });
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, 'User Email is missing.');
    }
  }

  openPlaygroundTrialExtendPopup() {
    this.popupService.openExtendPlaygroundTrialPopup(this.userEmail);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
