import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { ExportService } from '@app/services/export.service';
import { ResellerService } from '@app/services/reseller.service';
import { NGXLogger } from 'ngx-logger';
import { Location } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BackButtonService } from '@app/services/back-button.service';
import { AuthenticationService } from '@app/services/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-all-resellers',
  templateUrl: './all-resellers.component.html',
  styleUrls: ['./all-resellers.component.css']
})
export class AllResellersComponent implements OnInit, OnDestroy {
  title: string;
  isAccessOfAddEditReseller: boolean;
  cookieKey: string;
  pageHeading: string;
  companyName: string;
  currentUrl: string;

  searchString: string;
  selectedColumns: any;
  selectedSort: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  resellers: [];
  fromShowing: any;
  toShowing: any;
  columns = [
    { id: 1, key: 'widgetId', label: 'Widget Id', sort: 0, hide: 0 },
    { id: 2, key: 'name', label: 'Reseller Name', sort: 0, hide: 0 },
    { id: 3, key: 'marketingUrl', label: 'Marketing URL', sort: 0, hide: 0 },
    { id: 4, key: 'createdDate', label: 'Created Date', sort: -1, hide: 0 },
    { id: 5, key: 'lastUpdatedDate', label: 'Last Updated Date', sort: 0, hide: 0 },
    { id: 6, key: '', label: 'Action', sort: 0, hide: 0, disableSort: true},
  ];
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private resellerService: ResellerService,
    private commonUtility: CommonUtilityService,
    private exportService: ExportService,
    private logger: NGXLogger,
    private ngxService: NgxUiLoaderService,
    private backButtonService: BackButtonService,
    private location: Location,
    private authenticationService: AuthenticationService
  ) {
    this.isAccessOfAddEditReseller = false;
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser && currentUser.isAccessOfAddEditReseller) {
      this.isAccessOfAddEditReseller = true;
    }
    this.currentUrl = router.url;
    this.cookieKey = 'cf_all_reseller_partners';
    this.pageHeading = 'All Resellers';

    // persist filters while click on back/refresh
    const filters: any = this.backButtonService.getFilters(this.cookieKey);
    if (filters) {
      this.setAppliedFilters(filters);
    } else {
      this.setDefaultFilters();
    }

    this.route.queryParams
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params && params.search) {
          this.setDefaultFilters();
          this.searchString = params.search;
          this.getAllResellers(1);
          this.location.replaceState('/partner/reseller/all');
        }
    }
  );
  }

  ngOnInit(): void {
    this.route.parent.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.companyName = data.franchisee.companyName;
        this.title = `All Resellers | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllResellers(this.pageNo);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  clearSearch() {
    this.searchString = '';
    this.getAllResellers(1);
    if (this.route.snapshot.queryParams.search) {
      this.location.replaceState('partner/reseller/all');
    }
  }

  onChangeSearchText() {
    this.getAllResellers(1);
  }

  sortBy(column) {
    if (column && column.label && !column.disableSort) {
      this.columns.forEach((value) => {
        if (value.label === column.label) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = { key: value.label, value: value.sort };
        } else {
          value.sort = 0;
        }
      });
      this.getAllResellers(1);
    }
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(({ id }) => id === column.id);
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllResellers(1);
  }

  getAllResellers(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      currentPage: pageNo,
      selectedColumns: this.selectedColumns,
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedSort: this.selectedSort
    };
    this.backButtonService.setFilters(this.cookieKey, requestParams);
    this.resellerService.getAllResellers(requestParams).then((resellersListResponse: any) => {
      this.logger.info('INFO: -> AllResellersComponent -> this.resellerService.getAllResellers -> resellersListResponse',
      resellersListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'Resellers List refreshed');
      this.resellers = resellersListResponse.resellers;
      if (resellersListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < resellersListResponse.totalCount ?
          (pageNo + 1) * this.itemsPerPage :
          resellersListResponse.totalCount);
      }
      this.totalCount = resellersListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> AllResellersComponent -> this.resellerService.getAllResellers -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  deleteReseller() {

  }

  exportAction(option) {
    this.ngxService.start();
    const fileName = 'all_reseller_partners';
    const requestParams = {
      searchText: this.searchString,
      selectedSort: this.selectedSort,
    };
    this.resellerService.getAllResellersForExport(requestParams).then((resellersListResponse: any) => {
      const resellersList = resellersListResponse.resellers;
      const exportData = [];
      resellersList.forEach(item => {
        const tempJson = {};
        this.selectedColumns.forEach(column => {
          tempJson[column.label] = item[column.key] && item[column.key] !== 'Not Available' ? item[column.key] : '';
        });
        exportData.push(tempJson);
      });
      if (option === 'excel') {
        this.exportService.exportAsExcelFile(exportData, fileName);
      } else if (option === 'csv') {
        this.exportService.exportToCsvFile(exportData, fileName);
      } else if (option === 'doc') {
        this.exportService.exportToDocFile(exportData, fileName, this.pageHeading, this.companyName);
      }
      this.ngxService.stop();
      this.ngxService.stopBackground();
    }).catch(error => {
      this.logger.error('ERROR: -> AllWhiteLabelPartnersComponent -> this.whiteLabelPartnerService.getAllWhiteLabelPartners -> error',
      error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error?.error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
    });
  }

  refreshData() {
    this.getAllResellers(this.currentPage);
  }

  setDefaultFilters() {
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.searchString = '';
    this.selectedColumns = [
      { id: 1, key: 'widgetId', label: 'Widget Id', sort: 0, hide: 0 },
      { id: 2, key: 'name', label: 'Reseller Name', sort: 0, hide: 0 },
      { id: 3, key: 'marketingUrl', label: 'Marketing URL', sort: 0, hide: 0 },
      { id: 4, key: 'createdDate', label: 'Created Date', sort: -1, hide: 0 },
      { id: 5, key: 'lastUpdatedDate', label: 'Last Updated Date', sort: 0, hide: 0 },
      { id: 6, key: '', label: 'Action', sort: 0, hide: 0, disableSort: true},
    ];
    this.selectedSort = { key: 'Created Date', value: -1 };
  }

  setAppliedFilters(filtersValue) {
    this.totalCount = 0;
    this.itemsPerPage = filtersValue?.itemsPerPage;
    this.pageNo = filtersValue?.currentPage;
    this.searchString = filtersValue?.searchText;
    this.selectedColumns = filtersValue?.selectedColumns;
    this.selectedSort = filtersValue?.selectedSort;
  }

  resetFilter() {
    this.backButtonService.removeFilters(this.cookieKey);
    this.setDefaultFilters();
    this.getAllResellers(1);
  }

}
