import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { UserService } from '@app/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-notifications-list',
  templateUrl: './user-notifications-list.component.html',
  styleUrls: ['./user-notifications-list.component.css']
})
export class UserNotificationsListComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;
  primaryColor: string;
  pageHeading: string;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  notifications: [];
  fromShowing: any;
  toShowing: any;
  selectedColumns: any;
  selectedSort: any;
  searchString: string;
  columns = [
    {id: 1, name: 'status', label: 'Notification Type', sort: 0, hide: 0},
    {id: 2, name: 'message', label: 'Message', sort: 0, hide: 0},
    {id: 3, name: 'tenant', label: 'Company Name', sort: 0, hide: 0},
    {id: 4, name: 'createdDate', label: 'Created Date', sort: 1, hide: 0}
  ];
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger,
    private userService: UserService
  ) {
    this.pageHeading = 'Get Notifications List';
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.searchString = '';
    this.selectedColumns = [
      {id: 1, name: 'status', label: 'Notification Type', sort: 0, hide: 0},
      {id: 2, name: 'message', label: 'Message', sort: 0, hide: 0},
      {id: 3, name: 'tenant', label: 'Company Name', sort: 0, hide: 0},
      {id: 4, name: 'createdDate', label: 'Created Date', sort: 1, hide: 0}
    ];
    this.selectedSort = {key: 'Created Date', value: -1};
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
      });
    if (this.userEmail) {
      this.getAllNotifications(1);
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, 'User Email is missing.');
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
  }

  getAllNotifications(pageNo: number): void {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      email: this.userEmail,
      searchText: this.searchString,
      selectedSort: this.selectedSort,
    };
    this.userService.getNotificationsByEmail(requestParams).then((notificationsListResponse: any) => {
      this.logger.info('INFO: -> UserNotificationsListComponent -> this.userService.getNotificationsByEmail -> notificationsListResponse',
      notificationsListResponse);
      this.notifications = notificationsListResponse.notifications;
      if (notificationsListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < notificationsListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        notificationsListResponse.totalCount);
      }
      this.totalCount = notificationsListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> UserNotificationsListComponent -> this.userService.getNotificationsByEmail -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.pageHeading, error.error);
    });
  }

  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllNotifications(this.pageNo);
    }
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllNotifications(this.pageNo);
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  clearSearch(): void {
    this.searchString = '';
    this.getAllNotifications(this.pageNo);
  }

  onChangeSearchText(): void {
    this.getAllNotifications(this.pageNo);
  }

  columnChange(): void {

  }

  compareFn(user1, user2): boolean {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

}
