import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { MessageService } from '@app/services/message.service';
import { NGXLogger } from 'ngx-logger';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { MatTabGroup } from '@angular/material/tabs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@app/models/user';
import { UserService } from '@app/services/user.service';
import { environment } from '@env/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit, OnDestroy {
  public config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear'
          ]
        ]
      ]
    },
    height: '200px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'hr']],
      ['customButtons', ['testBtn']],
      ['view', ['codeview', 'help']]
    ],
    fontSizes: ['8', '9', '10', '11', '12', '14', '18', '24', '36', '44', '56', '64', '76', '84', '96'],
    fontNames: ['Arial', 'Times New Roman', 'Inter', 'Comic Sans MS', 'Courier New', 'Roboto',
      'Times', 'MangCau', 'BayBuomHep', 'BaiSau', 'BaiHoc', 'CoDien', 'BucThu', 'KeChuyen', 'MayChu',
      'ThoiDai', 'ThuPhap-Ivy', 'ThuPhap-ThienAn'],
    codeviewFilter: true,
    // eslint-disable-next-line max-len
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true
  };
  deliveryMethods = [{value:'inbox',text:'Portal - Inbox',disabled: false},{value:'directEmail',text:'Direct user Email',disabled: true}];
  title: string;
  primaryColor: string;
  pageHeading: string;
  CAT_URL = environment.CAT_URL;

  emailHeaderLogo = `${this.CAT_URL}/assets/images/franchisee/000/logo.png`;
  emailHeaderName = environment.COMPANY_NAME;
  emailHeaderUrl = environment.LOGIN_URL;
  FacebookURL = environment.SOCIAL_LINK.FACEBOOK;
  TwitterURL = environment.SOCIAL_LINK.TWITTER;
  LinkedInURL = environment.SOCIAL_LINK.LINKEDIN;
  YoutubeURL = environment.SOCIAL_LINK.FACEBOOK;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  messages: [];
  fromShowing: any;
  toShowing: any;
  selectedColumns: any;
  selectedSort: any;
  searchString: string;

  columns = [
    {id: 0, name: 'sentDate', label: 'Sent Date', sort: -1, hide: 0, width: '110px'},
    {id: 1, name: 'messageType', label: 'Message Type', sort: 0, hide: 0, width: '110px'},
    {id: 2, name: 'from', label: 'From', sort: 0, hide: 0, width: '200px'},
    {id: 3, name: 'to', label: 'To', sort: 0, hide: 0, width: '200px'},
    {id: 4, name: 'messageStatus', label: 'Status', sort: 0, hide: 0, width: '110px'},
    {id: 5, name: 'subject', label: 'Subject', sort: 0, hide: 0, width: '200px'},
    {id: 6, name: 'action', label: 'Action', sort: 0, hide: 0,  width: '130px', disableSort: true}
  ];

  ranges = {
    Today: [dayjs().startOf('day'), dayjs().endOf('day')],
    Yesterday: [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').endOf('day')],
    'Last 7 Days': [dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')],
    'Last 30 Days': [dayjs().subtract(29, 'days').startOf('day'), dayjs().endOf('day')],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  };
  dateRange = {
    start: dayjs().subtract(29, 'days').startOf('day'),
    end: dayjs().endOf('day'),
  };
  selectedDateRange = {
    start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
    end: dayjs().endOf('day').format('MM-DD-YYYY'),
  };
  selectedDateRangeUTC = {
    start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
    end: dayjs().endOf('day').format('MM-DD-YYYY'),
  };
  maxDate = dayjs();
  loggedInUserEmail: string;
  activeIndex: number;
  composeMessageShow: boolean;
  composeMessageForm: UntypedFormGroup;
  viewMessageShow: boolean;
  buttonStatus: boolean;
  messageEdit = false;
  selectedMessage: any;
  toOptionsArray = ['All Paid', 'All Past Due', 'All Cancelled', 'All Trial Expires',
    'All Trial Expired', 'All Verified Accountant Partners'];
  fromOptionArray = ['Policy', 'Security', 'Help', 'Sales', 'Success', 'Legal', 'Product'];
  toOptions = [
    {id: 1, label: 'Individual', value: 'Individual', key: 'Individual'},
    {id: 2, label: 'All Paid', value: 'All Paid', key: 'All Paid'},
    {id: 3, label: 'All Past Due', value: 'All Past Due', key: 'All Past Due'},
    {id: 4, label: 'All Cancelled', value: 'All Cancelled', key: 'All Cancelled'},
    {id: 5, label: 'All Trial Expires', value: 'All Trial Expires', key: 'All Trial Expires'},
    {id: 6, label: 'All Trial Expired', value: 'All Trial Expired', key: 'All Trial Expired'},
    {id: 7, label: 'All Verified Accountant Partners', value: 'All Verified Accountant Partners', key: 'All Verified Accountant Partners'},
  ];
  fromOptions = [
    {id: 1, label: 'Policy', value: 'Policy', key: 'Policy'},
    {id: 2, label: 'Security', value: 'Security', key: 'Security'},
    {id: 3, label: 'Help', value: 'Help', key: 'Help'},
    {id: 4, label: 'Sales', value: 'Sales', key: 'Sales'},
    {id: 5, label: 'Success', value: 'Success', key: 'Success'},
    {id: 6, label: 'Legal', value: 'Legal', key: 'Legal'},
    {id: 7, label: 'Product', value: 'Product', key: 'Product'},
    {id: 8, label: 'Individual', value: 'Individual', key: 'Individual'},
  ];
  @ViewChildren('messagesTabs') messagesTabs: QueryList<MatTabGroup>;
  private componentDestroyed$: Subject<void> = new Subject();
  currentYear = new Date().getFullYear();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private commonUtility: CommonUtilityService,
    private messageService: MessageService,
    private logger: NGXLogger,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    public sanitizer: DomSanitizer
  ) {
    this.composeMessageShow = false;
    this.viewMessageShow = false;
    this.pageHeading = 'All Messages';
    this.activeIndex = 0;
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.searchString = '';
    this.selectedSort = {key: 'Sent Date', value: -1};
    this.selectedColumns = [
      {id: 0, name: 'sentDate', label: 'Sent Date', sort: -1, hide: 0, width: '150px'},
      {id: 1, name: 'messageType', label: 'Message Type', sort: 0, hide: 0, width: '110px'},
      {id: 2, name: 'from', label: 'From', sort: 0, hide: 0, width: '250px'},
      {id: 3, name: 'to', label: 'To', sort: 0, hide: 0, width: '250px'},
      {id: 4, name: 'messageStatus', label: 'Status', sort: 0, hide: 0, width: '110px'},
      {id: 5, name: 'subject', label: 'Subject', sort: 0, hide: 0, width: '300px'},
      {id: 6, name: 'action', label: 'Action', sort: 0, hide: 0, width: '80px'}
    ];

    this.dateRange = {
      start: dayjs().subtract(29, 'days').startOf('day'),
      end: dayjs().endOf('day'),
    };
    this.selectedDateRange = {
      start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
      end: dayjs().endOf('day').format('MM-DD-YYYY'),
    };
    this.selectedDateRangeUTC = {
      start: dayjs().utc().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
      end: dayjs().utc().endOf('day').format('MM-DD-YYYY'),
    };

    this.composeMessageForm = this.fb.group({
      to: ['', Validators.required],
      emailTo: [''],
      subject: ['', Validators.required],
      from: ['', Validators.required],
      emailFrom: ['', Validators.email],
      message: ['', Validators.required],
      deliveryMethod: ['', Validators.required],
    });
    this.userService.getUser
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((userDetails: User) => {
        this.logger.info('INFO: -> SideNavBarComponent -> this.userService.getUser.subscribe -> userDetails', userDetails);
        this.loggedInUserEmail = userDetails.email;
      });
  }

  ngOnInit(): void {
    this.composeMessageForm.get('to').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (value && value === 'Individual') {
          this.composeMessageForm.get('emailTo').setValidators([Validators.required, Validators.email]);
        } else {
          this.composeMessageForm.get('emailTo').clearValidators();
        }
        this.composeMessageForm.get('emailTo').updateValueAndValidity();
      });
    this.composeMessageForm.get('from').valueChanges
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(value => {
        if (value && value === 'Individual') {
          this.composeMessageForm.get('emailFrom').setValidators([Validators.required, Validators.email]);
        } else {
          this.composeMessageForm.get('emailFrom').clearValidators();
        }
        this.composeMessageForm.get('emailFrom').updateValueAndValidity();
      });
    this.route.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
        this.title = `All Messages | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllMessages(this.pageNo);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  toAction(event: string){
    if(event === 'Individual'){
      this.deliveryMethods[1].disabled = false;
      this.composeMessageForm.controls.deliveryMethod.setValue('');
    } else {
      this.deliveryMethods[1].disabled = true;
      this.composeMessageForm.controls.deliveryMethod.setValue('');
    }
  }

  onSubmit(appStatus: string) {
    const title = appStatus === 'DRAFT' ? 'Message saved to draft.' : 'Message sent to review.';
    if (this.composeMessageForm.valid) {
      const reviewMessage = {
        ...this.composeMessageForm.value,
        status: appStatus
      };
      if (this.messageEdit === true && this.selectedMessage) {
        this.selectedMessage.to = reviewMessage.to;
        this.selectedMessage.emailTo = reviewMessage.emailTo;
        this.selectedMessage.from = reviewMessage.from;
        this.selectedMessage.emailFrom = reviewMessage.emailFrom;
        this.selectedMessage.subject = reviewMessage.subject;
        this.selectedMessage.message = reviewMessage.message;
        this.selectedMessage.deliveryMethod = reviewMessage.deliveryMethod;
        this.onUpdateMessage(appStatus);
        this.composeMessageForm.reset();
        this.toggleComposeMessageView();
      } else {
        this.messageService.saveMessage(reviewMessage).then((messageDetails: any) => {
          this.logger.info('INFO: -> MessagesComponent -> this.messageService.sendMessage -> messageDetails', messageDetails);
          this.commonUtility.displaySuccessToast(title,
            `Your message is  ${appStatus === 'REVIEW' ? 'successfully sent to Reviewers' : 'saved as Draft'} `);
          this.viewMessageShow = false;
          this.composeMessageForm.reset();
          this.toggleComposeMessageView();
          this.getAllMessages(1);
        }).catch(error => {
          this.logger.error('ERROR: -> MessagesComponent -> this.messageService.sendMessage -> error', error);
          this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
        });
      }
    } else {
      this.commonUtility.displayErrorToast(title, 'Please fill all the required field.');
    }
  }

  onUpdateMessage(appStatus) {
    const title = 'Request Review';
    if (this.selectedMessage) {
      const updatedMessage = {
        messageId: this.selectedMessage.messageId,
        to: this.selectedMessage.to,
        emailTo: this.selectedMessage.emailTo || '',
        from: this.selectedMessage.from,
        emailFrom: this.selectedMessage.emailFrom || '',
        subject: this.selectedMessage.subject,
        message: this.selectedMessage.message,
        comment: this.selectedMessage.comment,
        deliveryMethod: this.selectedMessage.deliveryMethod,
        status: appStatus
      };
      this.messageService.updateMessage(updatedMessage).then((messageDetails: any) => {
        this.logger.info('INFO: -> MessagesComponent -> ' +
          'this.messageService.sendMessage -> messageDetails', messageDetails);
        this.commonUtility.displaySuccessToast(title,
          `Your message is  ${appStatus === 'REVIEW' ? 'successfully sent to Reviewers' : 'saved as Draft'} `);
        this.selectedMessage = null;
        this.viewMessageShow = false;
        this.getAllMessages(1);
      }).catch(error => {
        this.logger.error('ERROR: -> MessagesComponent -> this.messageService.sendMessage -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
      });
    } else {
      this.commonUtility.displayErrorToast(title, 'Please fill all the required field.');
    }
  }

  onUnsentMessage(message) {
    const title = 'Request Review';
    if (message.messageStatus === 0 || message.messageStatus === 1) {
      const updatedMessage = {
        messageId: message.messageId,
        to: message.to,
        emailTo: message.emailTo || '',
        from: message.from,
        emailFrom: message.emailFrom || '',
        subject: message.subject,
        message: message.message,
        comment: message.comment || '',
        status: 'REVIEW'
      };
      this.messageService.updateMessage(updatedMessage).then((messageDetails: any) => {
        this.logger.info('INFO: -> MessagesComponent -> this.messageService.sendMessage -> messageDetails', messageDetails);
        this.commonUtility.displaySuccessToast(title, 'Your message is successfully unsent.');
        this.selectedMessage = null;
        this.viewMessageShow = false;
        this.getAllMessages(1);
      }).catch(error => {
        this.logger.error('ERROR: -> MessagesComponent -> this.messageService.sendMessage -> error', error);
        this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
      });
    } else {
      this.commonUtility.displayErrorToast(title, 'Please fill all the required field.');
    }
  }

  onTabChange(event: any){
    this.activeIndex = event.index;
    this.messagesTabs.forEach(tab => {
      tab.realignInkBar();
   });
  }

  sortBy(column) {
    if (column && column.label && !column.disableSort) {
      this.columns.forEach((value) => {
        if (value.label === column.label) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllMessages(this.pageNo);
    }
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllMessages(this.pageNo);
  }

  clearSearch() {
    this.searchString = '';
    this.getAllMessages(this.pageNo);
  }

  onChangeSearchText() {
    this.getAllMessages(this.pageNo);
  }

  datesUpdated(event) {
    if (event && event.startDate && event.endDate) {
      this.selectedDateRange.start = dayjs(event.startDate).format('MM-DD-YYYY');
      this.selectedDateRange.end = dayjs(event.endDate).format('MM-DD-YYYY');
      this.selectedDateRangeUTC.start = dayjs.utc(event.startDate).format('MM-DD-YYYY');
      this.selectedDateRangeUTC.end = dayjs.utc(event.endDate).format('MM-DD-YYYY');

      this.getAllMessages(this.pageNo);
    }
  }

  getAllMessages(pageNo: number): void {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedSort: this.selectedSort,
      selectedDateRange: this.selectedDateRangeUTC,
      status: ['DRAFT', 'REVIEW', 0, 1, 'REJECTED']
    };
    this.messageService.getAllMessages(requestParams).then((messagesListResponse: any) => {
      this.logger.info('INFO: -> MessagesComponent -> this.messageService.getAllMessages -> messagesListResponse', messagesListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'Messages List refreshed.');
      this.messages = messagesListResponse.messages.map(message => {
        const msg = message;
        msg.sentDate = message.sentDate;
        return msg;
      });

      if (messagesListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < messagesListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        messagesListResponse.totalCount);
      }
      this.totalCount = messagesListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> MessagesComponent -> this.messageService.getAllMessages -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  toggleComposeMessageView() {
    this.composeMessageShow = !this.composeMessageShow;
    this.setValueNull(this.selectedMessage);
  }

  toggleViewMessageView() {
    this.viewMessageShow = !this.viewMessageShow;
  }

  onEditMessage(selectedMessage) {
    if (selectedMessage) {
      this.selectedMessage = selectedMessage;
      this.messageEdit = true;
      this.composeMessageShow = true;
      this.viewMessageShow = false;
      this.composeMessageForm.controls.to.setValue(
        this.toOptionsArray.includes(this.selectedMessage.to) ? this.selectedMessage.to : 'Individual'
      );
      this.composeMessageForm.controls.emailTo.setValue(
        !this.toOptionsArray.includes(this.selectedMessage.to) ? this.selectedMessage.to : ''
      );
      this.composeMessageForm.controls.from.setValue(
        this.fromOptionArray.includes(this.selectedMessage.from) ? this.selectedMessage.from : 'Individual'
      );
      this.composeMessageForm.controls.emailFrom.setValue(
        !this.fromOptionArray.includes(this.selectedMessage.from) ? this.selectedMessage.from : ''
      );
      this.composeMessageForm.controls.deliveryMethod.setValue(this.selectedMessage.deliveryMethod);
      this.composeMessageForm.controls.message.setValue(this.selectedMessage.message);
      this.composeMessageForm.controls.subject.setValue(this.selectedMessage.subject);
    }
  }

  onPreviewMessageView() {
    if (this.composeMessageForm.valid) {
      const messageTemp = this.composeMessageForm.value;
      messageTemp.sentDate = dayjs.utc(new Date());
      messageTemp.from = this.loggedInUserEmail;
      if (this.selectedMessage && this.selectedMessage.messageId) {
        messageTemp.messageId = this.selectedMessage.messageId;
      }
      this.viewMessageShow = true;
      this.buttonStatus = false;
      this.selectedMessage = messageTemp;

    }
  }

  openViewMessageView(message) {
    this.viewMessageShow = true;
    this.selectedMessage = message;
    this.buttonStatus = true;
  }

  deleteMessage(message) {
    const title = 'Delete Message';
    this.messageService.deleteMessage({messageId: message.messageId}).then((messageDetails: any) => {
      this.logger.info('INFO: -> MessagesComponent -> this.messageService.deleteMessage -> messageDetails', messageDetails);
      this.commonUtility.displaySuccessToast(title, messageDetails.message);
      this.getAllMessages(this.pageNo);
    }).catch(error => {
      this.logger.error('ERROR: -> MessagesComponent -> this.messageService.deleteMessage -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
    });
  }

  convertUTCDateToLocalDate(date) {
    const gmtDateTime = dayjs.utc(date);
    const localDate = gmtDateTime.local().format('MM-DD-YYYY hh:mm A');
    return localDate;
}

 setValueNull(selectedMessage) {
    if (selectedMessage) {
    this.selectedMessage = selectedMessage;
    this.composeMessageForm.controls.to.setValue('');
    this.composeMessageForm.controls.emailTo.setValue('');
    this.composeMessageForm.controls.from.setValue('');
    this.composeMessageForm.controls.emailFrom.setValue('');
    this.composeMessageForm.controls.message.setValue('');
    this.composeMessageForm.controls.subject.setValue('');
    }
 }
  public onBlur() {
    // console.log('Blur');
  }

  public onDelete() {
    // console.log('Delete file', file.url);
  }

  public summernoteInit() {
    // console.log(event);
  }

  refreshData() {
    this.getAllMessages(this.currentPage);
  }

}
