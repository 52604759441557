<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 sync-engine-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}
                    <span class="float-end">
                        <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()"><i class="fa fa fa-refresh"></i></a>
                    </span>
                </h5>
            </div>
        </div>
        <div class="ibox col-md-12 sync-engine-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <div class="mb-3 sync-engine-filter-group">
                            <label for="search" matToolTip="You can search by Event Type, Subscription Id, Customer Id, Customer Name">Global Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <div class="mb-3 sync-engine-filter-group">
                            <label for="version" matTooltip="You can select multiple Status">Status</label>
                            <mat-select [(ngModel)]="selectedStatus" class="form-control"
                                        multiple placeholder="Select Status">
                                <mat-option #allSelectedStatus (click)="toggleAllTypeStatus()" [value]="0">All
                                </mat-option>
                                <mat-option *ngFor="let item of statusList" [value]="item.value"
                                    (click)="statusTouchPerOne()">{{item.label}} <span
                                        class="{{item.class}}"></span>
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <div class="mb-3 sync-engine-filter-group">
                            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                            <mat-select [compareWith]="compareFn" class="form-control"
                                        panelClass="mat-select-autowidth-panel-class"
                                (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">

                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-1 col-lg-1">

                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 text-end">
                        <div class="mb-3 sync-engine-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                (selectionChange)="changeDisplayPerPageItem($event)">
                                <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>&nbsp; processes
                        </div>
                        <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} processes
                        </p>
                        <pagination-controls id="all-processes" (pageChange)="getAllProcesses($event)" responsive="true"
                            previousLabel="Previous" nextLabel="Next" maxSize="3"></pagination-controls>
                    </div>
                </div>
            </div>

            <div class="row filtered-by-box">
                <div class="col-md-12 mb-2" *ngIf="!selectedStatus.includes(0) || searchString">
                    <span class="filter-by-text">Filtered by: </span>
                    <span class="alert alert-success alert-dismissible
                        fade show event-filter ms-2" *ngIf="!selectedStatus.includes(0)">
                        <strong>Status:</strong> ({{selectedStatus.join(', ')}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="resetStatusSelection()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                    <span class="alert alert-success alert-dismissible
                        fade show type-filter ms-2" *ngIf="searchString">
                        <strong>Global Search:</strong> ({{searchString}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                </div>
                <div class="col-md-12">
                    <span class="filter-by-text">Sort by: </span>
                    <span class="alert alert-success alert-dismissible
                        fade show payment-status-filter ms-2">
                        <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending':
                        'Descending'}}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row sync-engine-result-container white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title table-responsive">
                <table class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover"
                    id="processes-table">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let column of columns; let i = index">
                                <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting"
                                    [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}"
                                    (click)="sortBy(column.label)">{{column.label}}</th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let process of processes | paginate: { id: 'all-processes', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                            <td *ngIf="checkColumnVisibility(columns[0])">
                                <span *ngIf="process.createdDate">{{process.createdDate}}</span>
                                <span *ngIf="!process.createdDate" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[1])">
                                <span *ngIf="process.source">{{process.source}}</span>
                                <span *ngIf="!process.source" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[2])">
                                <span *ngIf="process.status"><span
                                        class="badge badge-{{process.statusClass}}-process-status">{{process.status}}</span></span>
                                <span *ngIf="!process.status" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[3])">
                                <span *ngIf="process.tries">{{process.tries}}</span>
                                <span *ngIf="!process.tries" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[4])">
                                <span *ngIf="process.eventType">{{process.eventType}}</span>
                                <span *ngIf="!process.eventType" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[5])">
                                <span *ngIf="process.subscriptionId">{{process.subscriptionId}}</span>
                                <span *ngIf="!process.subscriptionId" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[6])">
                                <span *ngIf="process.customerId">{{process.customerId}}</span>
                                <span *ngIf="!process.customerId" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[7])">
                                <span *ngIf="process.customerName">{{process.customerName}}</span>
                                <span *ngIf="!process.customerName" class="not-available">Not Available</span>
                            </td>
                        </tr>
                        <tr *ngIf="processes && processes.length === 0">
                            <td colspan="8" class="not-available text-center">No process available at this time</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
