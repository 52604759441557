<div class="wrapper border-bottom white-bg page-heading">
    <h2>{{pageHeading}}</h2>
    <ol class="breadcrumb">
        <li>
            <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
        </li>
        <li class="active">
            {{pageHeading}}
        </li>
    </ol>
</div>
<div class="messages-container">
    <div class="row white-bg">
        <div class="ibox col-md-12 p-2">
            <h5>{{pageHeading}}
                <span class="float-end">
                    <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()">
                        <i class="fa fa fa-refresh"></i>
                    </a>
                </span>
            </h5>
        </div>
        <div class="ibox col-md-12 messages-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 pe-0">
                        <div class="mb-3 messages-filter-group">
                            <label for="search"
                                matTooltip="You can search by Message Type, To, Form, Subject fields">Global
                                Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 pe-0">
                        <div class="mb-3">
                            <label>Date Range <span class="required">*</span></label>
                            <input class="form-control" ngxDaterangepickerMd [showCustomRangeLabel]="true"
                                [showDropdowns]="true" startKey="start" endKey="end" [ranges]="ranges"
                                [locale]="{applyLabel: 'ok', format: 'MM-DD-YYYY'}" [maxDate]="maxDate"
                                [(ngModel)]="dateRange" [alwaysShowCalendars]="true"
                                [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true"
                                (datesUpdated)="datesUpdated($event)" />
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 pe-0">
                        <div class="mb-3 email-logs-filter-group">
                            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                            <mat-select [compareWith]="compareFn" 
                                (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple class="form-control" >
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 text-end">
                        <div class="mb-3 account-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                (selectionChange)="changeDisplayPerPageItem($event)">
                                <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>&nbsp; message(s)
                        </div>
                        <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} message(s)
                        </p>
                        <pagination-controls id="all-messages" (pageChange)="getAllMessages($event)" responsive="true"
                            previousLabel="Previous" nextLabel="Next" maxSize="3">
                        </pagination-controls>
                    </div>
                </div>
                <div class="row filtered-by-box">
                    <div class="col-md-12">
                        <span class="filter-by-text">Filtered by: </span>
                        <span class="alert alert-success alert-dismissible
                                fade show date-range-filter ms-2">
                            <strong>Date Range:</strong> {{selectedDateRange.start}} - {{selectedDateRange.end}}
                        </span>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="activeIndex === 0">
                        <span class="filter-by-text">Sort by: </span>
                        <span class="alert alert-success alert-dismissible
                                fade show payment-status-filter ms-2">
                            <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending':
                            'Descending'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="viewMessageShow" class="messages-review row white-bg my-2">
        <div class="ibox col-md-12 p-2">
            <h5> Review Message
                    <a class="cross float-end" (click)="toggleViewMessageView()">
                        <i class="fa fa-times-circle"></i>
                    </a>
            </h5>
        </div>
        <div *ngIf="selectedMessage.messageStatus === 'REJECTED' && selectedMessage?.comment" class="col-md-12">
            <div class="alert alert-danger" role="alert">
                <p>{{selectedMessage?.comment}}</p>
            </div>
        </div>
        <div class="col-md-12 compose-message-form">
            <div class="row">
                <label for="to" class="col-md-2">Date and Time</label>
                <div class="col-md-4">
                    <span class="alert alert-green fade show date-range-filter ms-2">{{convertUTCDateToLocalDate(selectedMessage?.sentDate)}}</span>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-12 compose-message-form">
            <div class="row">
                <label for="to" class="col-md-2">Requested by</label>
                <div class="col-md-4">
                    <span class="alert alert-green fade show date-range-filter ms-2">{{selectedMessage?.from}}</span>
                </div>
            </div>
        </div> -->
        <div class="col-md-12 compose-message-form">
            <div class="row">
                <label for="to" class="col-md-2">To</label>
                <div class="col-md-4">
                    <span class="alert alert-green fade show date-range-filter ms-2"> {{selectedMessage?.to}} </span>
                </div>
            </div>
        </div>
        <div class="col-md-12 compose-message-form">
            <div class="row">
                <label for="to" class="col-md-2">From</label>
                <div class="col-md-4">
                    <span class="alert alert-green fade show date-range-filter ms-2"> {{selectedMessage?.from}} </span>
                </div>
            </div>
        </div>
        <div class="col-md-12 compose-message-form">
            <div class="row">
                <label for="to" class="col-md-2">Subject</label>
                <div class="col-md-4">{{selectedMessage?.subject}}</div>
            </div>
        </div>
        <div class="col-md-12 compose-message-form">
            <div class="row">
                <label for="to" class="col-md-12">Message</label>
                <table class="col-md-12 review-header">
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    <a href="{{ emailHeaderUrl }}">
                                    <img
                                        src="{{emailHeaderLogo}}"
                                        alt="{{emailHeaderName}}"
                                        title="{{emailHeaderName}}"
                                    />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-md-12">
                    <table width="100%" class="review-body">
                        <tbody>
                            <tr>
                                <td>
                                    <div >
                                        <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(selectedMessage?.message)"></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12">
                    <table width="100%" class="review-footer">
                        <tbody>
                            <tr>
                                <td >
                                    <div>
                                        <a href="{{emailHeaderUrl}}" target="_blank">
                                            {{emailHeaderUrl}}
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%" class="review-sub-footer">
                        <tbody>
                            <tr>
                                <td align="center">
                                    <div>
                                        <table align="left" width="50%">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            268 Bush Street, Suite 2829 <br>
                                                            San Francisco, CA 94104
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table align="left" width="50%" class="review-social-icons">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <a href="{{FacebookURL}}" title="Twitter" target="_blank">
                                                            <img src="{{CAT_URL}}/assets/img/email_template_images/facebook@2x.png" alt="Facebook" title="Facebook" width="32">
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href="{{TwitterURL}}" title="Twitter" target="_blank">
                                                            <img src="{{CAT_URL}}/assets/img/email_template_images/twitter@2x.png" alt="Twitter" title="Twitter" width="32">
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href="{{LinkedInURL}}" title="Linkedin" target="_blank">
                                                            <img src="{{CAT_URL}}/assets/img/email_template_images/linkedin@2x.png" alt="Linkedin" title="Linkedin" width="32">
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <a href="{{YoutubeURL}}" title="Youtube" target="_blank">
                                                            <img src="{{CAT_URL}}/assets/img/email_template_images/youtube@2x.png" alt="Youtube" title="Youtube" width="32">
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="center">
                                    <div>
                                        <p>
                                            <a href="#" class="reivew-footer-text">
                                                Unsubscribe From This List
                                            </a> |
                                            <a href="#" class="reivew-footer-text">
                                                Manage Email Preferences
                                            </a>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td align="center" class="review-copyright-text">
                                © {{currentYear}}. All Rights Reserved</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="showRejectComment" class="col-md-12 compose-message-form">
            <label>Reject reason</label>
            <textarea class="form-control" [(ngModel)]="rejectComment" rows="3"></textarea>
        </div>
        <ng-container *ngIf="showRejectComment; else rejectNotOpen">
            <div class="inbox col-md-12 p-2 message-footer">
                <button type="button" (click)="onRejectMessage()" class="btn btn-danger mx-2">
                    <i class="fa fa-ban me-2"></i>
                    Submit and Reject
                </button>
                <button type="button" (click)="showRejectComment = false; rejectComment = '' " class="btn btn-default mx-2">
                    Cancel
                </button>
            </div>
        </ng-container>
        <ng-template #rejectNotOpen>
            <div class="inbox col-md-12 p-2 message-footer">
                <button type="button" class="btn btn-primary" (click)="onUpdateMessage(0)">
                    <i class="fa fa-paper-plane me-2"></i>
                    Send Message
                </button>
                <button *ngIf="!composeMessageShow" type="button" class="btn btn-default mx-2" (click)="onEditMessage(selectedMessage)">
                    <i class="fa fa-pencil me-2"></i>
                    Edit
                </button>
                <button type="button" (click)="onRejectMessage()" class="btn btn-danger mx-2">
                    <i class="fa fa-ban me-2"></i>
                    Reject
                </button>
                <button type="button" class="btn btn-default" (click)="viewMessageShow = false; showRejectComment = false">
                    Close
                </button>
            </div>
        </ng-template>
    </div>
    <div *ngIf="composeMessageShow" class="row messages-result-container white-bg mb-2">
        <form #form="ngForm" [formGroup]="composeMessageForm">
            <div class="ibox col-md-12 p-2">
                <h5>Compose Message
                    <span class="float-end">
                        <a (click)="toggleComposeMessageView()">
                            <i class="fa fa-times-circle"></i>
                        </a>
                    </span>
                </h5>
            </div>
            <div class="col-md-12 row compose-message-form">
                <div class="col-md-6 mb-3">
                    <label for="to">To <span class="required">*</span></label>
                    <mat-select name="to" class="form-control" formControlName="to" (selectionChange)="toAction($event.value)">
                        <mat-option *ngFor="let item of toOptions" [value]="item.value">{{item.label}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-md-6 mb-3" *ngIf="composeMessageForm.get('to').value === 'Individual'">
                    <label for="emailTo">Email To <span class="required">*</span></label>
                    <input type="email" name="email" class="form-control" formControlName="emailTo">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="subject">Subject
                        <span class="required">*</span>
                    </label>
                    <input type="subject" name="subject" class="form-control" formControlName="subject">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="">From <span class="required">*</span></label>
                    <mat-select name="from" class="form-control" formControlName="from">
                        <mat-option *ngFor="let item of fromOptions" [value]="item.value">{{item.label}}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-md-6 mb-3" *ngIf="composeMessageForm.get('from').value === 'Individual'">
                    <label for="email">Email From<span class="required">*</span></label>
                    <input type="email" name="emailFrom" class="form-control" formControlName="emailFrom">
                </div>
                <div class="col-md-6 mb-3">
                    <label for="">Method of delivery <span class="required">*</span></label>
                    <mat-select class="form-control" formControlName="deliveryMethod" multiple>
                        <mat-option *ngFor="let deliveryMethod of deliveryMethods" [value]="deliveryMethod.value" [disabled]="deliveryMethod.disabled">{{deliveryMethod.text}}</mat-option>
                    </mat-select>
                </div>
                <div class="col-md-12 mb-3">
                    <label for="message" class="col-md-1">Message
                        <span class="required">*</span>
                    </label>
                    <div class="col-md-6" formControlName="message" [ngxSummernote]="config" (blur)="onBlur()"
                        (mediaDelete)="onDelete()" (summernoteInit)="summernoteInit()">
                    </div>
                </div>
            </div>
            <div class="ibox col-md-12 p-2 message-footer">
                <button type="button" class="btn btn-default mx-2" (click)="onPreviewMessageView()"
                    [disabled]="!composeMessageForm.valid">
                    <i class="fa fa-eye me-2"></i> Preview Message
                </button>
                <button type="button" class="btn btn-default mx-2" (click)="composeMessageShow = false">
                    Close
                </button>
                <button type="button" class="btn btn-primary" (click)="onUpdateMessage(0)">
                    <i class="fa fa-paper-plane me-2"></i>
                    Send Message
                </button>
            </div>
        </form>
    </div>
    <div class="row messages-result-container white-bg">
        <div class="ibox col-md-12 messages-table">
            <div class="ibox-title">
                <div class="messages-list-box table-responsive">
                    <table class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover"
                        id="messages-logs-table">
                        <thead>
                            <tr>
                                <ng-container *ngFor="let column of columns; let i = index">
                                    <th
                                        *ngIf="checkColumnVisibility(column)"
                                        class="sort-icon table-sorting"
                                        [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1, 'sorting_disabled': column.disableSort}"
                                        (click)="sortBy(column)"
                                        [ngStyle]="{'min-width': column.width}">
                                        {{column.label}}
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let message of messages | paginate: { id: 'all-messages', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                              
                                <td *ngIf="checkColumnVisibility(columns[0])">
                                    <span *ngIf="message.sentDate">{{convertUTCDateToLocalDate(message.sentDate)}}</span>
                                    <span *ngIf="!message.sentDate" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[1])">
                                    <span *ngIf="message.from">{{message.from}}</span>
                                    <span *ngIf="!message.from" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[2])">
                                    <span *ngIf="message.to">{{message.to}}</span>
                                    <span *ngIf="!message.to" class="not-available">Not Available</span>
                                </td>


                                <td *ngIf="checkColumnVisibility(columns[3])">
                                    <span *ngIf="message.subject">{{message.subject}}</span>
                                    <span *ngIf="!message.subject" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[4])">
                                    <a class="btn btn-sm btn-primary me-1" (click)="openViewMessageView(message)">
                                    <i class="fa fa-eye" aria-hidden="true"></i> View</a>
                                </td>
                            </tr>
                            <tr *ngIf="!messages || messages.length === 0" class="text-center not-available">
                                <td colspan="8">No message available at this time</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
