import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Country } from '@app/models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  api: string = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  getCountriesList(onlyBlocked = false) {
    let params = new HttpParams();
    if(onlyBlocked) {
      params = params.append('onlyBlocked', onlyBlocked);
    }
    return this.httpClient.get<Country[]>(this.api + '/get-country-list', { params }).toPromise();
  }
}
