import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { EMPTY, Observable } from 'rxjs';
import { Franchisee } from '../models/franchisee';
import { FranchiseeService } from './franchisee.service';

@Injectable({
  providedIn: 'root'
})
export class FranchiseeResolverService {
  url: string = location.origin;
  constructor(
    private router: Router,
    private franchiseeService: FranchiseeService,
    private logger: NGXLogger
  ) { }

  resolve(): Promise<object> | Observable<never> {
    return this.franchiseeService.getDetailsByURL(this.url).then((response: Franchisee) => {
      this.logger.info('INFO: -> FranchiseeResolverService -> response', response);
      this.franchiseeService.changeFranchiseeDetails(response);
      return response;
    }).catch(error => {
      this.logger.error('ERROR: -> FranchiseeResolverService -> error', error);
      this.router.navigate(['/invalid-link']);
      return EMPTY;
    });
  }
}
