import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { MessageService } from '@app/services/message.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-compose-message',
  templateUrl: './compose-message.component.html',
  styleUrls: ['./compose-message.component.css']
})
export class ComposeMessageComponent implements OnInit {
  public config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear'
          ]
        ]
      ]
    },
    height: '200px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'hr']],
      ['customButtons', ['testBtn']],
      ['view', ['codeview', 'help']]
    ],
    fontSizes: ['8', '9', '10', '11', '12', '14', '18', '24', '36', '44', '56', '64', '76', '84', '96'],
    fontNames: ['Arial', 'Times New Roman', 'Inter', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times',
      'MangCau', 'BayBuomHep', 'BaiSau', 'BaiHoc', 'CoDien', 'BucThu', 'KeChuyen', 'MayChu', 'ThoiDai',
      'ThuPhap-Ivy', 'ThuPhap-ThienAn'],
    codeviewFilter: true,
    // eslint-disable-next-line max-len
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true
  };
  userEmail: string;
  composeMessageForm: UntypedFormGroup;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonUtility: CommonUtilityService,
    private messageService: MessageService,
    private logger: NGXLogger,
    private fb: UntypedFormBuilder,
    public sanitizer: DomSanitizer,
    private modal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.composeMessageForm = this.fb.group({
      to: ['Individual', Validators.required],
      email: [this.userEmail, Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  onSubmit() {
    const title = 'Send Message';
    if (this.composeMessageForm.valid) {
      this.messageService.sendMessage(this.composeMessageForm.value).then((messageDetails: any) => {
        this.commonUtility.displaySuccessToast(title, messageDetails.message);
        this.cancel();
        this.router.navigate(['/messages']);
      }).catch(error => {
        this.commonUtility.displayErrorToastWithTechDetails(title, error.error);
      });
    } else {
      this.commonUtility.displayErrorToast(title, 'Please fill all the required field.');
    }
  }

  cancel() {
    this.modal.dismiss();
  }

}
