import { Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WhiteLabelPartner } from '@app/models/white-label-partner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-white-label-partner-details',
  templateUrl: './white-label-partner-details.component.html',
  styleUrls: ['./white-label-partner-details.component.css']
})
export class WhiteLabelPartnerDetailsComponent implements OnDestroy {
  pageHeading: string;
  title: string;
  whiteLabelPartnerDetails: WhiteLabelPartner;
  domains: string;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
  ) {
    this.pageHeading = 'White Label Partner Details';
    this.route.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.whiteLabelPartnerDetails = data?.whiteLabelPartnerDetails;
        this.domains = (this.whiteLabelPartnerDetails?.domainURLs || '').split(',').join(', <br>');
      });
    this.route.parent.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `${this.pageHeading} | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

}
