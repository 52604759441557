<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 white-label-partner-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <div class="col-md-6 p-0 display-flex">
                    <h5>{{pageHeading}}</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="row ibox white-label-partner-filters-container">
        <div class="col-md-8 p-0">
            <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                    <mat-panel-title>
                        <h5>Accounts</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="ibox-content border-top-gray">
                    <app-white-label-partner-accounts [franchiseeCode]="whiteLabelPartnerDetails?.code"></app-white-label-partner-accounts>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-elevation-z0 mt-2" [expanded]="true">
                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                    <mat-panel-title>
                        <h5>Login Template</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="ibox-content border-top-gray p-2">
                    <div *ngIf="whiteLabelPartnerDetails?.login" [innerHtml]="whiteLabelPartnerDetails?.login">
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="mat-elevation-z0 mt-2" [expanded]="true">
                <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                    <mat-panel-title>
                        <h5>Maintenance Content</h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="ibox-content border-top-gray p-2">
                    <div *ngIf="whiteLabelPartnerDetails?.warningMessage" [innerHtml]="whiteLabelPartnerDetails?.warningMessage">
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="col-md-4 pe-0">
            <mat-accordion multi>
                <div>
                    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                            <mat-panel-title>
                                <h5>Company Details</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="ibox-content border-top-gray">
                            <div class="row">
                                <ul class="list-group p-2 pe-4 ps-4 col-md-12">
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.companyName"><span
                                            class="text-key"><i class="fa fa-building"></i> Company Name:</span>
                                        {{whiteLabelPartnerDetails?.companyName}}</li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.code"><span
                                            class="text-key"><i class="fa fa-code"></i> Code:</span>
                                        {{whiteLabelPartnerDetails?.code}}</li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.companyURL"><span
                                            class="text-key"><i class="fa fa-globe"></i> Company URL:</span> <a
                                            href="{{whiteLabelPartnerDetails?.companyURL}}">{{whiteLabelPartnerDetails?.companyURL}}</a>
                                    </li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.adminEmail"><span
                                            class="text-key"><i class="fa fa-envelope"></i> Admin Email:</span>
                                        {{whiteLabelPartnerDetails?.adminEmail}}</li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.contactNumber"><span
                                            class="text-key"><i class="fa fa-phone"></i> Contact Number:</span>
                                        {{whiteLabelPartnerDetails?.contactNumber}}</li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.inquireNumber"><span
                                            class="text-key"><i class="fa fa-phone"></i> Inquiry Number:</span>
                                        {{whiteLabelPartnerDetails?.inquireNumber}}</li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.helpLineNumber"><span
                                            class="text-key"><i class="fa fa-phone"></i> HelpLine Number:</span>
                                        {{whiteLabelPartnerDetails?.helpLineNumber}}</li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.supportEmail"><span
                                            class="text-key"><i class="fa fa-envelope"></i> Support Email:</span>
                                        {{whiteLabelPartnerDetails?.supportEmail}}</li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.physicalAddress"><span
                                            class="text-key"><i class="fa fa-address-card"></i> Address:</span>
                                        <p class="text-values">
                                            {{whiteLabelPartnerDetails?.physicalAddress?.addressLine1}}
                                            <br>
                                            {{whiteLabelPartnerDetails?.physicalAddress?.city}},
                                            {{whiteLabelPartnerDetails?.physicalAddress?.state}}
                                            {{whiteLabelPartnerDetails?.physicalAddress?.zipCode}}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mat-elevation-z0 mt-2" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                            <mat-panel-title>
                                <h5>Setup Details</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="ibox-content border-top-gray">
                            <div class="row">
                                <ul class="list-group p-2 pe-4 ps-4 col-md-12">
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.domainURLs"><span
                                            class="text-key"><i class="fa fa-link"></i> Domain URL:</span>
                                        <p class="text-values" [innerHtml]="domains"></p>
                                    </li>
                                    <li class="list-group-item"
                                        *ngIf="whiteLabelPartnerDetails?.accountantDashboardURL">
                                        <span class="text-key"><i class="fa fa-link"></i> Partner Portal URL:</span>
                                        <p class="text-values">{{whiteLabelPartnerDetails?.accountantDashboardURL}}</p>
                                    </li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.clientDashboardURL">
                                        <span class="text-key"><i class="fa fa-link"></i> Client Portal URL:</span>
                                        <p class="text-values">{{whiteLabelPartnerDetails?.clientDashboardURL}}</p>
                                    </li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.payProjectURL"><span
                                            class="text-key"><i class="fa fa-link"></i> Payment URL:</span>
                                        <p class="text-values">{{whiteLabelPartnerDetails?.payProjectURL}}</p>
                                    </li>
                                    <li class="list-group-item" *ngIf="whiteLabelPartnerDetails?.intacsProjectURL"><span
                                            class="text-key"><i class="fa fa-link"></i> Activation URL:</span>
                                        <p class="text-values">{{whiteLabelPartnerDetails?.intacsProjectURL}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mat-elevation-z0 mt-2" [expanded]="true">
                        <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                            <mat-panel-title>
                                <h5>Features Details</h5>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="ibox-content border-top-gray">
                            <div class="row">
                                <ul class="list-group p-2 pe-4 ps-4 col-md-12">
                                    <li class="list-group-item">
                                        <span class="text-key"><i class="fa fa-facebook-official"></i> Login with Facebook </span> <i class="fa" [ngClass]="{'fa-check green-icon': whiteLabelPartnerDetails?.loginWithFacebook, 'fa-times red-icon': !whiteLabelPartnerDetails?.loginWithFacebook}"></i>
                                    </li>
                                    <li class="list-group-item">
                                        <span class="text-key"><i class="fa fa-google"></i> Login with Google </span> <i class="fa" [ngClass]="{'fa-check green-icon': whiteLabelPartnerDetails?.loginWithGoogle, 'fa-times red-icon': !whiteLabelPartnerDetails?.loginWithGoogle}"></i>
                                    </li>
                                    <li class="list-group-item">
                                        <span class="text-key"><i class="fa fa-linkedin-square"></i> Login with LinkedIn </span> <i class="fa" [ngClass]="{'fa-check green-icon': whiteLabelPartnerDetails?.loginWithLinkedIn, 'fa-times red-icon': !whiteLabelPartnerDetails?.loginWithLinkedIn}"></i>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-accordion>
        </div>
    </div>
</div>
