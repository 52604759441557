<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 reseller-container">
    <div class="row reseller-filters-container">
        <div class="col-xs-6 col-sm-6 col-md-8 col-lg-8 ps-0">
            <mat-accordion multi>
                <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                    <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                        <mat-panel-title>
                            <h5>Free Trial Form Script</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="content-box">
                        <div class="p-3 highlight">
                            <div class="bd-clipboard">
                                <button type="button" class="btn-clipboard" matTooltip="Copy to clipboard"
                                    [cdkCopyToClipboard]="resellerDetails?.freeTrialScript">Copy</button>
                            </div>
                            <pre>
                                <code class="language-html" data-lang="html">
                                    {{resellerDetails?.freeTrialScript}}
                                </code>
                            </pre>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                    <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                        <mat-panel-title>
                            <h5>Subscribe Now Form Script</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="content-box">
                        <div class="p-3 highlight">
                            <div class="bd-clipboard">
                                <button type="button" class="btn-clipboard" matTooltip="Copy to clipboard"
                                    [cdkCopyToClipboard]="resellerDetails?.subscribeNowScript">Copy</button>
                            </div>
                            <pre>
                                {{resellerDetails?.subscribeNowScript}}
                            </pre>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                    <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                        <mat-panel-title>
                            <h5>Users List <small> who came from {{resellerDetails?.widgetId}} marketing page</small> </h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="content-box">
                        <app-reseller-user-list [resellerId]="resellerDetails?.widgetId"></app-reseller-user-list>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="col-xs-4 col-sm-6 col-md-4 col-lg-4 p-0">
            <mat-accordion multi>
                <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                    <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
                        <mat-panel-title>
                            <h5>Reseller Details</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="ibox-content">
                        <ul class="list-group p-0 col-md-12">
                            <li class="list-group-item" *ngIf="resellerDetails?.name">
                                <span class="text-key">
                                    <i class="fa fa-building"></i> Reseller Name:
                                </span>
                                {{resellerDetails?.name}}
                            </li>
                            <li class="list-group-item" *ngIf="resellerDetails?.widgetId">
                                <span class="text-key">
                                    <i class="fa fa-code"></i> Widget Id:
                                </span>
                                {{resellerDetails?.widgetId}}
                            </li>
                            <li class="list-group-item" *ngIf="resellerDetails?.marketingUrl"><span
                                    class="text-key"><i class="fa fa-globe"></i> Marketing URL:</span> <a
                                    href="{{resellerDetails?.marketingUrl}}" target="_blank">{{resellerDetails?.marketingUrl}}</a>
                            </li>
                        </ul>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
