import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { RateLimitResponse } from '@app/models/rate-limit-response';

@Injectable({
  providedIn: 'root'
})
export class RateLimitService {
  api: string = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  private rateLimitResponse = new BehaviorSubject<RateLimitResponse>(null);
  getRateLimitResponse = this.rateLimitResponse.asObservable();

  getRateLimit() {
    return this.httpClient.get<RateLimitResponse>(this.api + '/getRateLimitDetails').toPromise();
  }

  changeRateLimit(rateLimitResponse: RateLimitResponse) {
    this.rateLimitResponse.next(rateLimitResponse);
  }
}
