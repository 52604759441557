<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 developers-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}
                    <a (click)="changeViewType('LIST')" class="ms-2"
                            [ngClass]="viewType === 'LIST' ? 'btn btn-default acs-view-custom acs-view-active' : 'btn btn-default acs-view-custom'"
                            #tooltip="matTooltip" matTooltip="List view" [matTooltipPosition]="'above'">
                            <i class="fa fa-list" aria-hidden="true"></i>
                        </a>
                        <a (click)="changeViewType('GRID')" class="ms-2"
                            [ngClass]="viewType === 'GRID' ? 'btn btn-default acs-view-custom acs-view-active' : 'btn btn-default acs-view-custom'"
                            #tooltip="matTooltip" matTooltip="Grid view" [matTooltipPosition]="'above'">
                            <i class="fa fa-th-large" aria-hidden="true"></i>
                        </a>
                    <span class="float-end">
                        <a class="btn btn-primary me-2" matTooltip="Update developer access" [routerLink]="['/partner/developer/change-developer-access']" routerLinkActive="router-link-active" >
                            Update developer access
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Reset Filters" (click)="resetFilter();"> <i class="fa fa-filter" aria-hidden="true"></i> Reset
                        </a>
                        <a class="btn btn-export me-2" matTooltip="CSV" (click)="exportAction('csv');"> <i
                                class="fa fa-file-code-o"></i>
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Excel" (click)="exportAction('excel');"> <i
                                class="fa fa-file-excel-o"></i>
                        </a>
                        <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()"><i
                                class="fa fa fa-refresh"></i></a>
                    </span>
                </h5>
            </div>
        </div>
        <div class="ibox col-md-12 developers-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="search"  matTooltip="You can search by User Name, User Email">Global Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString"
                                placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="select-user-type" matTooltip="Select Pricing Level">Pricing Level</label>
                            <mat-select  class="form-control" [(ngModel)]="selectedUserType" multiple>
                                <mat-option #allSelectedUserType (click)="toggleAllUserType()" [value]="0">All
                                </mat-option>
                                <mat-option *ngFor="let item of userTypeOptions" [value]="item.name" (click)="userTypeTouchPerOne()">{{item.label}} <span class="{{item.class}}"></span></mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="select-user-type" matTooltip="Select Developer Type">Developer Type</label>
                            <mat-select  class="form-control" [(ngModel)]="selectedDeveloperType" multiple>
                                <mat-option #allSelectedDeveloperType (click)="toggleAllDeveloperType()" [value]="0">All
                                </mat-option>
                                <mat-option *ngFor="let item of developerTypeOptions" [value]="item.name" (click)="developerTypeTouchPerOne()">{{item.label}} <span class="{{item.class}}"></span></mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group" *ngIf="viewType === 'LIST'">
                            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                            <mat-select [compareWith]="compareFn" class="form-control"
                                        panelClass="mat-select-autowidth-panel-class"
                                        [(ngModel)]="selectedColumns" multiple>
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-end">
                        <div class="mb-3 account-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage" (selectionChange)="changeDisplayPerPageItem($event)">
                              <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}</mat-option>
                            </mat-select>&nbsp; developer(s)
                          </div>
                          <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} developer(s)
                          </p>
                          <pagination-controls id="all-developers" (pageChange)="getAllDevelopers($event)" responsive="true"
                          previousLabel="Previous"
                          nextLabel="Next"
                          maxSize="3"
                          ></pagination-controls>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-12">
            <div class="row filtered-by-box">
                <div class="col-md-12 mb-2" *ngIf="searchString || !selectedUserType.includes(0)">
                    <span class="filter-by-text">Filtered by: </span>
                    <span class="alert alert-success alert-dismissible
                        fade show event-filter ms-2" *ngIf="!selectedUserType.includes(0)">
                        <strong>Pricing Level:</strong> ({{selectedUserType.join(', ')}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="resetUserTypeSelection()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                    <span class="alert alert-success alert-dismissible
                        fade show type-filter ms-2" *ngIf="searchString">
                        <strong>Global Search:</strong> ({{searchString}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                </div>
                <div class="col-md-12">
                    <span class="filter-by-text">Sort by: </span>
                    <span class="alert alert-success alert-dismissible
                        fade show payment-status-filter ms-2">
                        <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending': 'Descending'}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="row user-result-container mt-3" [ngClass]="{'white-bg': viewType === 'LIST'}">
        <div class="ibox col-md-12">
            <div [ngClass]="{'ibox-title': viewType === 'LIST'}">
                <div *ngIf="viewType === 'LIST'" class="user-list-box table-responsive">
                    <table class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover" id="account-table">
                        <thead>
                            <tr>
                            <ng-container *ngFor="let column of columns; let i = index">
                                <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting" [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1, 'sorting_disabled': column.disableSort}" (click)="sortBy(column)">{{column.label}}</th>
                            </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let user of developers | paginate: { id: 'all-developers', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                                <td *ngIf="checkColumnVisibility(columns[0])"><a *ngIf="user.email" [routerLink]="['/users/user-details/', user.email]" routerLinkActive="router-link-active" >{{user.email}}</a><span *ngIf="!user.email" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[1])"><span *ngIf="user.name">{{user.name}}</span><span *ngIf="!user.name" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[2])"><span *ngIf="user.phoneNumber">{{user.phoneNumber}}</span><span *ngIf="!user.phoneNumber" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[3])"><span *ngIf="user.userType" class="badge badge-{{user.userTypeClass}}">{{user.userType}}</span><span *ngIf="!user.userType" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[4])"><span *ngIf="user.developerType" class="badge badge-{{user.developerType | lowercase}}-type">{{user.developerType}}</span><span *ngIf="!user.developerType" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[5])"><span *ngIf="user.preDeveloperAssignedDate" >{{user.preDeveloperAssignedDate}}</span><span *ngIf="!user.preDeveloperAssignedDate" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[6])"><span *ngIf="user.developerAssignedDate">{{user.developerAssignedDate}}</span><span *ngIf="!user.developerAssignedDate" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[7])"><span *ngIf="user.developerAssignedBy">{{user.developerAssignedBy}}</span><span *ngIf="!user.developerAssignedBy" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[8])"><span *ngIf="user.externalAccountantStatus" class="badge badge-{{user.externalAccountantClass}}">{{user.externalAccountantStatus}}</span><span *ngIf="!user.externalAccountantStatus" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[9])"><span *ngIf="user.multiFactorStatus" class="badge badge-{{user.multiFactorStatus | lowercase}}-mfa">{{user.multiFactorStatus}}</span><span *ngIf="!user.multiFactorStatus" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[10])"><span *ngIf="user.playgroundCreatedDate">{{user.playgroundCreatedDate}}</span><span *ngIf="!user.playgroundCreatedDate" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[11])"><span *ngIf="user.creationDate">{{user.creationDate}}</span><span *ngIf="!user.creationDate" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[12])"><span *ngIf="user.developerType != 'DEVELOPER'">
                                    <a class="btn btn-sm btn-primary" (click)="changeDeveloperPortalAccess(user.email)"><i class="fa fa-thumbs-up" aria-hidden="true"></i> Approve</a>
                                </span></td>
                            </tr>
                            <tr *ngIf="!developers || developers.length === 0" class="text-center">
                                <td colspan="12">No developer available at this time.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="viewType === 'GRID'" class="row grid-view">
                    <div class="col-md-4 p-1" *ngFor="let user of developers | paginate: { id: 'all-developers', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                    <div class="ibox white-bg">
                      <div class="ibox-content card-min-height">
                          <div class="row">
                              <div class="col-md-4 text-center">
                                <img *ngIf="user?.profileImageURL" alt="image" class="user-image" src="{{user?.profileImageURL}}">
                                <img *ngIf="!user?.profileImageURL" alt="image" class="user-image" src="assets/images/male.png">
                                {{user?.developerType}}
                              </div>
                              <div class="col-md-8 pt-4">
                                <div class="grid-user-type" *ngIf="user.userType">
                                    <span class="{{user.userTypeClass}}">{{user.userType}}</span>
                                </div>
                                <p class="user-grid-item">
                                    <i class="fa fa-id-card-o user-profile-icon" aria-hidden="true" matTooltip="Name"></i>
                                    <span *ngIf="user.name"></span>{{user.name}}
                                    <span *ngIf="!user.name" class="not-avilable">Not Available</span>
                                </p>
                                <p class="user-grid-item">
                                    <i class="fa fa-envelope-o user-profile-icon" matTooltip="Email"></i>
                                    <span *ngIf="user.email"><a class="text-underline-hover" [routerLink]="['/users/user-details/', user.email]" routerLinkActive="router-link-active" >{{user.email}}</a></span>
                                    <span *ngIf="!user.email" class="not-avilable">Not Available</span>
                                </p>
                                <p class="user-grid-item">
                                    <i class="fa fa-phone-square user-profile-icon" matTooltip="Phone Number"></i>
                                    <span *ngIf="user.phoneNumber"></span>{{user.phoneNumber}}
                                    <span *ngIf="!user.phoneNumber" class="not-avilable">Not Available</span>
                                </p>
                              </div>
                          </div>
                      </div>
                    </div>
                    </div>
                    <div *ngIf="developers && developers.length === 0" class="col-md-12 not-available text-center white-bg">
                        No user available at this time.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
