import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@app/models/user';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  socket: any;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  api: string = !environment.production ? environment.CAT_URL : '';
  constructor(
    private router: Router,
    private httpClient: HttpClient,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string, franchiseeCode: string) {
    return this.httpClient.post<any>(this.api + '/auth', { email, password, franchiseeCode})
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
        }
        return user;
    })).toPromise();
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  changeLoggedInUser(user: User) {
    if (user && user.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
    }
  }

  loginWithGoogle(clientId: string, redirectURL: string) {
    // tslint:disable-next-line: prefer-const
    const googleSignUplink = new URL(environment.GOOGLE.API_URL);
    googleSignUplink.searchParams.set('client_id', clientId);
    googleSignUplink.searchParams.set('redirect_uri', redirectURL);
    googleSignUplink.searchParams.set('scope', environment.GOOGLE.SCOPE);
    googleSignUplink.searchParams.set('include_granted_scopes', 'true');
    googleSignUplink.searchParams.set('access_type', 'offline');
    googleSignUplink.searchParams.set('response_type', 'code');
    window.open(googleSignUplink.href, '_self');
  }

  signUpWithFacebook(clientId: string, redirectURL: string) {
    // tslint:disable-next-line: prefer-const
    const facebookSignUplink = new URL(environment.FACEBOOK.API_URL);
    facebookSignUplink.searchParams.set('client_id', clientId);
    facebookSignUplink.searchParams.set('redirect_uri', redirectURL);
    facebookSignUplink.searchParams.set('scope', environment.FACEBOOK.SCOPE);
    facebookSignUplink.searchParams.set('response_type', 'code');
    window.open(facebookSignUplink.href, '_self');
  }

  loginWithLinkedIn(clientId: string, redirectURL: string) {
    // tslint:disable-next-line: prefer-const
    const facebookSignUplink = new URL(environment.LINKEDIN.API_URL);
    facebookSignUplink.searchParams.set('client_id', clientId);
    facebookSignUplink.searchParams.set('redirect_uri', redirectURL);
    facebookSignUplink.searchParams.set('scope', environment.LINKEDIN.SCOPE);
    facebookSignUplink.searchParams.set('response_type', 'code');
    window.open(facebookSignUplink.href, '_self');
  }

  linkedInLogin(requestData: any) {
    return this.httpClient.post<any>(this.api + '/linkedInLogin', requestData)
    .pipe(map(user => {
      // login successful if there's a jwt token in the response
      if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
      }
      return user;
  })).toPromise();
  }

  facebookLogin(requestData: any) {
    return this.httpClient.post<any>(this.api + '/facebookLogin', requestData)
    .pipe(map(user => {
      // login successful if there's a jwt token in the response
      if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
      }
      return user;
  })).toPromise();
  }

  googleLogin(requestData: any) {
    return this.httpClient.post<any>(this.api + '/googleLogin', requestData)
    .pipe(map(user => {
      // login successful if there's a jwt token in the response
      if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
      }
      return user;
  })).toPromise();
  }

  userLogin(token: string) {
    return this.httpClient.post<any>(this.api + '/userLogin', {token})
    .pipe(map(user => {
      // login successful if there's a jwt token in the response
      if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
      }
      return user;
  })).toPromise();
  }

  setUserSession(user: User) {
    if (user && user.token) {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
    }
  }
}
