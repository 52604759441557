import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-change-billing-status',
  templateUrl: './change-billing-status.component.html',
  styleUrls: ['./change-billing-status.component.css']
})
export class ChangeBillingStatusComponent implements OnInit {
  accountNumber: string;
  billingStatusForm: UntypedFormGroup;
  selectedStatus: string;
  pageHeading: string;
  statusList = [
    { id: 2, label: 'Live', value: 'Live', key: 'live', class: 'dot dot-live-status' },
    { id: 3, label: 'Test', value: 'Test', key: 'test', class: 'dot dot-test-status' },
    { id: 4, label: 'Sandbox', value: 'Sandbox', key: 'sandbox', class: 'dot dot-sandbox-status' },
    { id: 5, label: 'Delete', value: 'Delete', key: 'delete', class: 'dot dot-delete-status' },
  ];
  constructor(
    private router: Router,
    private modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private utilityService: CommonUtilityService,
    private accountService: AccountService,
    private logger: NGXLogger
  ) {
    this.pageHeading = 'Change Billing Status';
  }

  ngOnInit(): void {
    this.billingStatusForm = this.fb.group({
      accountNumber: [this.accountNumber, [Validators.required]],
      status: [this.selectedStatus, [Validators.required]]
    });
  }

  get billingFormFormControl() {
    return this.billingStatusForm.controls;
  }

  cancel() {
    this.modal.dismiss();
  }

  changeBillingStatusSubmit() {
    if (this.billingStatusForm.valid) {
      this.accountService.changeAccountType(this.billingStatusForm.value).then((response: any) => {
        this.logger.info('INFO: -> ChangeBillingStatusComponent -> this.accountService.changeAccountType -> response', response);
        this.utilityService.displaySuccessToast(this.pageHeading, response.message);
        this.modal.close();
        this.router.navigate(['/accounts/all'], { queryParams: { search: this.accountNumber }});
      }).catch((error: any) => {
        this.logger.error('ERROR: -> ChangeBillingStatusComponent -> this.accountService.changeAccountType -> error', error);
        this.utilityService.displayErrorToastWithTechDetails(this.pageHeading, error.error);
      });
    } else {
      this.utilityService.displayErrorToast(this.pageHeading, 'Please fill all the required fields.');
    }
  }

}
