<div class="dashboard-container">
    <div class="col-md-12 ps-1 pe-1">
        <div class="row">
            <div class="col-md-3 ibox-padding">
                <div class="ibox">
                    <div class="ibox-title paid-border-color">
                        <h5 class="paid-color">Paid</h5>
                    </div>
                    <div class="ibox-content">
                        <h1 class="card-title pricing-card-title">{{displayedStatuses['paidAccountsCount'] || 0}} </h1>
                        <div class="float-end paid-color open-link" [routerLink]="'/accounts/all/Paid'"><i class="fa fa-external-link"></i> All Paid</div>
                        <small class="text-muted">Account(s)</small>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ibox-padding">
                <div class="ibox">
                    <div class="ibox-title past-due-border-color">
                        <h5 class="past-due-color">Past Due</h5>
                    </div>
                    <div class="ibox-content">
                        <h1 class="card-title pricing-card-title">{{displayedStatuses['pastDueAccountsCount'] || 0}}</h1>
                        <div class="float-end past-due-color open-link" [routerLink]="'/accounts/all/Past Due'"><i class="fa fa-external-link"></i> All Past Due</div>
                        <small class="text-muted">Account(s)</small>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ibox-padding">
                <div class="ibox">
                    <div class="ibox-title suspended-border-color">
                        <h5 class="suspended-color">Suspended</h5>
                    </div>
                    <div class="ibox-content">
                        <h1 class="card-title pricing-card-title">{{displayedStatuses['suspendedAccountsCount'] || 0}}</h1>
                        <div class="float-end suspended-color open-link" [routerLink]="'/accounts/all/Suspended'"><i class="fa fa-external-link"></i> All Suspended</div>
                        <small class="text-muted">Account(s)</small>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ibox-padding">
                <div class="ibox">
                    <div class="ibox-title canceled-border-color">
                        <h5 class="canceled-color">Cancelled</h5>
                    </div>
                    <div class="ibox-content">
                        <h1 class="card-title pricing-card-title">{{displayedStatuses['canceledAccountsCount'] || 0}}</h1>
                        <div class="float-end canceled-color open-link" [routerLink]="'/accounts/all/Cancelled'"><i class="fa fa-external-link"></i> All Cancelled</div>
                        <small class="text-muted">Account(s)</small>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ibox-padding">
                <div class="ibox">
                    <div class="ibox-title un-billed-border-color">
                        <h5 class="un-billed-color">Unbilled</h5>
                    </div>
                    <div class="ibox-content">
                        <h1 class="card-title pricing-card-title">{{displayedStatuses['unBilledAccountsCount'] || 0}}</h1>
                        <div class="float-end un-billed-color open-link" [routerLink]="'/accounts/all/Unbilled'"><i class="fa fa-external-link"></i> All Unbilled</div>
                        <small class="text-muted">Account(s)</small>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ibox-padding">
                <div class="ibox">
                    <div class="ibox-title processing-border-color">
                        <h5 class="processing-color">Processing</h5>
                    </div>
                    <div class="ibox-content">
                        <h1 class="card-title pricing-card-title">{{displayedStatuses['processingAccountsCount'] || 0}}</h1>
                        <div class="float-end processing-color open-link" [routerLink]="'/accounts/all/Processing'"><i class="fa fa-external-link"></i> All Processing</div>
                        <small class="text-muted">Account(s)</small>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ibox-padding">
                <div class="ibox">
                    <div class="ibox-title trial-expires-border-color">
                        <h5 class="trial-expires-color">Trial Expires</h5>
                    </div>
                    <div class="ibox-content">
                        <h1 class="card-title pricing-card-title">{{displayedStatuses['trialExpiresAccountsCount'] || 0}}</h1>
                        <div class="float-end trial-expires-color open-link" [routerLink]="'/accounts/all/Trial Expires'"><i class="fa fa-external-link"></i> All Trial Expires</div>
                        <small class="text-muted">Account(s)</small>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ibox-padding">
                <div class="ibox">
                    <div class="ibox-title trial-expired-border-color">
                        <h5 class="trial-expired-color">Trial Expired</h5>
                    </div>
                    <div class="ibox-content">
                        <h1 class="card-title pricing-card-title">{{displayedStatuses['trialExpiredAccountsCount'] || 0}}</h1>
                        <div class="float-end trial-expired-color open-link" [routerLink]="'/accounts/all/Trial Expired'"><i class="fa fa-external-link"></i> All Trial Expired</div>
                        <small class="text-muted">Account(s)</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 ps-1 pe-1">
        <div class="row">
            <div class="col-md-6 ibox-padding">
                <div class="ibox trial-expires-next-week-box">
                    <mat-accordion multi>
                        <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h5><span class="badge bg-info">Next Week</span> Accounts expire next week. <a [routerLink]="['/accounts/all/', 'Trial Expires', 'Next']" routerLinkActive="router-link-active" >Click</a> </h5><span class="float-end"><i class="fa fa-clock-o"></i> {{trialExpiredInNextWeekStartDate}} to {{trialExpiredInNextWeekEndDate}}</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="ibox-content">
                            <table class="table table-hover mb-0">
                              <thead>
                              <tr>
                                <th>Plan Name</th>
                                <th>Trial Expiration Date</th>
                                <th>Account Number</th>
                                <th>Subscription Owner</th>
                              </tr>
                              </thead>
                              <tbody>
                              <ng-container *ngIf="trialExpiredInNextWeek.length > 0">
                                <tr *ngFor="let trial of trialExpiredInNextWeek">
                                  <td><span class="badge badge-{{trial.className}}">{{trial.planName}}</span></td>
                                  <td><i class="fa fa-clock-o"></i> {{trial.formattedTrialExpiration}}</td>
                                  <td><a class="text-underline-hover" [routerLink]="['/accounts/account-details/', trial.accountNumber]">{{trial.accountNumber}}</a></td>
                                  <td *ngIf="trial.subscriptionAdminEmail">
                                    <a class="text-underline-hover" [routerLink]="['/users/user-details/', trial.subscriptionAdminEmail]">{{trial.subscriptionAdminEmail}}</a></td>
                                  <td *ngIf="!trial.subscriptionAdminEmail">Not Available</td>
                                </tr>
                              </ng-container>
                              <tr *ngIf="trialExpiredInNextWeek.length == 0">
                                <td colspan="4" class="text-center">No Account</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>
                </div>
            </div>
            <div class="col-md-6 ibox-padding">
                <div class="ibox trial-expires-next-week-box">
                    <mat-accordion multi>
                        <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h5><span class="badge bg-info text-white">Last Week</span> Accounts expired last week. <a [routerLink]="['/accounts/all/', 'Trial Expired', 'Last']" routerLinkActive="router-link-active" >Click</a> </h5> <span class="float-end"><i class="fa fa-clock-o"></i> {{trialExpiredInLastWeekEndDate}} to {{trialExpiredInLastWeekStartDate}}</span>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="ibox-content">
                            <table class="table table-hover mb-0">
                                <thead>
                                <tr>
                                  <th>Plan Name</th>
                                  <th>Trial Expiration Date</th>
                                  <th>Account Number</th>
                                  <th>Subscription Owner</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container *ngIf="trialExpiredInLastWeek.length > 0">
                                  <tr *ngFor="let trial of trialExpiredInLastWeek">
                                    <td><span class="badge badge-{{trial.className}}">{{trial.planName}}</span></td>
                                    <td><i class="fa fa-clock-o"></i> {{trial.formattedTrialExpiration}}</td>
                                    <td><a [routerLink]="['/accounts/account-details/', trial.accountNumber]">{{trial.accountNumber}}</a></td>
                                    <td *ngIf="trial.subscriptionAdminEmail">
                                      <a [routerLink]="['/users/user-details/', trial.subscriptionAdminEmail]">{{trial.subscriptionAdminEmail}}</a></td>
                                    <td *ngIf="!trial.subscriptionAdminEmail">Not Available</td>
                                  </tr>
                                </ng-container>
                                <tr *ngIf="trialExpiredInLastWeek.length == 0">
                                  <td colspan="4" class="text-center">No Account</td>
                                </tr>
                                </tbody>
                              </table>
                          </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
    </div>
</div>
