<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 account-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>
                    <span class="float-left">
                        {{pageHeading}}
                        <a (click)="changeViewType('LIST')" class="ms-2"
                            [ngClass]="viewType === 'LIST' ? 'btn btn-default acs-view-custom acs-view-active' : 'btn btn-default acs-view-custom'"
                            #tooltip="matTooltip" matTooltip="List view" [matTooltipPosition]="'above'">
                            <i class="fa fa-list" aria-hidden="true"></i>
                        </a>
                        <a (click)="changeViewType('GRID')" class="ms-2"
                            [ngClass]="viewType === 'GRID' ? 'btn btn-default acs-view-custom acs-view-active' : 'btn btn-default acs-view-custom'"
                            #tooltip="matTooltip" matTooltip="Grid view" [matTooltipPosition]="'above'">
                            <i class="fa fa-th-large" aria-hidden="true"></i>
                        </a>
                    </span>
                    <span class="float-end">
                        <a class="btn btn-export me-2" matTooltip="Reset Filters" (click)="resetFilter();"> <i class="fa fa-filter" aria-hidden="true"></i> Reset
                        </a>
                        <a class="btn btn-export me-2" matTooltip="CSV" (click)="exportAction('csv');"> <i class="fa fa-file-code-o"></i>
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Excel" (click)="exportAction('excel');"> <i class="fa fa-file-excel-o"></i>
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Refresh Data" (click)="refreshData()"><i class="fa fa fa-refresh"></i></a>
                    </span>
               </h5>

            </div>
        </div>
        <div class="ibox col-md-12 account-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-3 pe-0" [ngClass]="{'col-md-1 col-lg-1': trialExpirationFilter, 'col-md-3 col-lg-3': !trialExpirationFilter}">
                        <div class="mb-2 account-filter-group">
                            <label for="search"  matTooltip="You can search by CompanyName, SubscriptionOwnerEmail or AccountNumber fields">Global Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString"
                                placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                        <div class="account-filter-group">
                            <label for="version" matTooltip="You can select multiple PlanNames">Plan Name</label>
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="!selectedPlans.length"><b>Select Plan</b></mat-label>
                                <mat-select [(ngModel)]="selectedPlans" multiple panelClass="mat-select-autowidth-panel-class">
                                    <mat-option #allSelectedPlan (click)="toggleAllTypePlan()" [value]="0">All</mat-option>
                                    <mat-option *ngFor="let item of plansList" [value]="item.value" (click)="planTouchPerOne()">{{item.label}} <span class="{{item.class}}"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 pe-0">
                        <div class="account-filter-group">
                            <label for="version" matTooltip="You can select multiple Payment Status">Payment Status</label>
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="!selectedPaymentStatus.length"><b>Select Payment Status</b></mat-label>
                                <mat-select [(ngModel)]="selectedPaymentStatus" multiple>
                                    <mat-option #allSelectedPaymentStatus (click)="toggleAllTypePaymentStatus()" [value]="0">All</mat-option>
                                    <mat-option *ngFor="let item of paymentStatusList" [value]="item.value" (click)="paymentStatusTouchPerOne()">{{item.label}} <span class="{{item.class}}"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="account-filter-group">
                            <label for="version" matTooltip="You can select multiple PlanNames">Interval</label>
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="!selectedInterval.length"><b>Select Interval</b></mat-label>
                                <mat-select [(ngModel)]="selectedInterval" multiple panelClass="mat-select-autowidth-panel-class">
                                    <mat-option #allSelectedInterval (click)="toggleAllInternal()" [value]="0">All</mat-option>
                                    <mat-option *ngFor="let item of interval" [value]="item.value" (click)="intervalTouchPerOne()">{{item.label}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 pe-0">
                        <div class="account-filter-group">
                            <label for="version" matTooltip="You can select multiple Status">Status</label>
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="!selectedStatus.length"><b>Select Status</b></mat-label>
                                <mat-select [(ngModel)]="selectedStatus" multiple>
                                    <mat-option #allSelectedStatus (click)="toggleAllTypeStatus()" [value]="0">All
                                    </mat-option>
                                    <mat-option *ngFor="let item of statusList" [value]="item.value" (click)="statusTouchPerOne()">{{item.label}} <span class="{{item.class}}"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="account-filter-group" *ngIf="viewType === 'LIST'">
                          <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                          <mat-form-field appearance="outline" class="column-field">
                            <mat-select [compareWith]="compareFn"
                                        panelClass="mat-select-autowidth-panel-class"
                                        (selectionChange)="columnChange($event)" [(ngModel)]="selectedColumns" multiple>
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-3 col-md-1 col-lg-1 pe-0 ps-0" *ngIf="trialExpirationFilter">
                        <div class="mb-3 account-filter-group">
                            <label>Expiration Date <span class="required">*</span></label>
                            <input class="form-control" ngxDaterangepickerMd [showCustomRangeLabel]="true"
                                [showDropdowns]="true" startKey="start" endKey="end" [ranges]="ranges"
                                [locale]="{applyLabel: 'ok', format: 'MM-DD-YYYY'}"
                                [(ngModel)]="dateRange" [alwaysShowCalendars]="true"
                                [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true"
                                (datesUpdated)="datesUpdated($event)">
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-3 col-md-3 col-lg-3 text-left pt-3">
                        <div class="mb-3 account-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage" (selectionChange)="changeDisplayPerPageItem($event)">
                              <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}</mat-option>
                            </mat-select>&nbsp; account(s)
                          </div>
                          <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} account(s)
                          </p>
                          <pagination-controls id="all-accounts" (pageChange)="getAllAccounts($event)" responsive="true"
                          previousLabel="Previous"
                          nextLabel="Next"
                          maxSize="3"
                          ></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="row filtered-by-box">
                <div class="col-md-12 mb-2" *ngIf="searchString || !selectedPaymentStatus.includes(0) || !selectedPlans.includes(0) || !selectedStatus.includes(0)">
                    <span class="filter-by-text">Filtered by: </span>
                    <span class="alert alert-success alert-dismissible fade show event-filter ms-2"
                          *ngIf="!selectedPaymentStatus.includes(0)">
                        <strong>Payment Status:</strong> ({{selectedPaymentStatus.join(', ')}})
                        <button type="button" class="btn filter-close-btn" data-bs-dismiss="alert" aria-label="Close"
                                (click)="resetPaymentStatusSelection()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                    <span class="alert alert-success alert-dismissible
                        fade show event-filter ms-2" *ngIf="trialExpirationFilter">
                        <strong>Trial Expiration Date:</strong> ({{selectedDateRange.start + ' - ' + selectedDateRange.end}})
                    </span>
                    <span class="alert alert-success alert-dismissible
                        fade show event-filter ms-2" *ngIf="!selectedPlans.includes(0)">
                        <strong>Plan:</strong> ({{selectedPlans.join(', ')}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="resetPlanSelection()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                    <span class="alert alert-success alert-dismissible
                        fade show event-filter ms-2" *ngIf="!selectedStatus.includes(0)">
                        <strong>Status:</strong> ({{selectedStatus.join(', ')}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="resetStatusSelection()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                    <span class="alert alert-success alert-dismissible
                        fade show type-filter ms-2" *ngIf="searchString">
                        <strong>Global Search:</strong> ({{searchString}})
                        <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </span>
                </div>
                <div class="col-md-12">
                    <span class="filter-by-text">Sort by: </span>
                    <span class="alert alert-success fade show payment-status-filter ms-2">
                        <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending': 'Descending'}}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="row account-result-container" [ngClass]="{'white-bg': viewType === 'LIST'}">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <div *ngIf="viewType === 'LIST'" class="account-list-box table-responsive">
                    <table class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover" id="account-table">
                        <thead>
                            <tr>
                            <ng-container *ngFor="let column of columns; let i = index">
                                <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting" [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}" (click)="sortBy(column.label)">{{column.label}}</th>
                            </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let account of accounts | paginate: { id: 'all-accounts', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                                <td *ngIf="checkColumnVisibility(columns[0])"><a *ngIf="account.accountNumber" [routerLink]="['/accounts/account-details', account.accountNumber]" routerLinkActive="router-link-active" >{{account.accountNumber}}</a><span *ngIf="!account.accountNumber" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[1])"><a *ngIf="account.subscriptionOwnerEmail" [routerLink]="['/users/user-details', account.subscriptionOwnerEmail]" routerLinkActive="router-link-active" >{{account.subscriptionOwnerEmail}}</a><span *ngIf="!account.subscriptionOwnerEmail" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[2])"><a *ngIf="account.companyName">{{account.companyName}}</a><span *ngIf="!account.companyName" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[3])"><span *ngIf="account.status && account.status !== 'Not Available'"><span class="acs-{{account.statusClass}}-status list-view-status" matTooltip="{{account.status}}"></span>{{account.status}}</span><span *ngIf="!account.status || account.status === 'Not Available'" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[4])"><span *ngIf="account.planName && account.planName !== 'Not Available'" class="badge badge-{{account.planClassName}}">{{account.planName}}</span><span *ngIf="!account.planName || account.planName === 'Not Available'" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[5])"><span *ngIf="account.interval && account.interval !== 'Not Available'">{{account.interval}}</span><span *ngIf="!account.interval || account.interval === 'Not Available'" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[6])"><span *ngIf="account.franchisee?.name">{{account.franchisee.name}}</span><span *ngIf="!account.franchisee?.name" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[7])"><span *ngIf="account.closingDate">{{account.closingDate}}</span><span *ngIf="!account.closingDate" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[8])"><div class="subscription-status"><span class="payment-status-{{account.paymentStatus.className}}" matTooltip="{{account.paymentStatus.status === 'Paused' ? 'PausedAt: ' + account.paymentStatus.pausedDate + ', Paused reason: ' + account.paymentStatus.pausedReason + ', Resume date: ' + account.paymentStatus.resumeDate : ''}}">{{account.paymentStatus.status}} </span><span *ngIf="account.paymentStatus.status !== 'Trial Expired'" class="label-yellow-date" matTooltip="{{account.paymentStatus.dateTooltip}}">{{account.paymentStatus.date}}</span></div></td>
                                <td *ngIf="checkColumnVisibility(columns[9])"><span *ngIf="account.createdDate">{{account.createdDate}}</span><span *ngIf="!account.createdDate" class="not-available">Not Available</span></td>
                                <td *ngIf="checkColumnVisibility(columns[10])"><span *ngIf="account.resellerId">{{account.resellerId}}</span><span *ngIf="!account.resellerId" class="not-available">Not Available</span></td>
                            </tr>
                            <tr *ngIf="accounts && accounts.length === 0">
                                <td colspan="9" class="not-available text-center">No account available at this time.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="viewType === 'GRID'" class="row grid-view">
                    <div class="col-md-4 p-1" *ngFor="let account of accounts | paginate: { id: 'all-accounts', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                    <div class="payment-status-{{account.paymentStatus.className}}-border ibox white-bg">
                      <div class="ibox-title">
                        <span class="float-end status-title">
                            <span *ngIf="account.paymentStatus.status" class="payment-status-{{account.paymentStatus.className}}">{{account.paymentStatus.status}}</span>
                            <span *ngIf="account.paymentStatus.status !== 'Trial Expired'" class="label-yellow-date">{{account.paymentStatus.date}}</span>
                        </span>
                        <span *ngIf="account.status && account.status !== 'Not Available'" class="acs-{{account.statusClass}}-status float-end" matTooltip="{{account.status}}"></span>
                        <h5>
                            <span *ngIf="account.companyName" class="company-name-title" matTooltip="{{account.companyName}}">{{account.companyName}}</span>
                            <span *ngIf="!account.companyName" class="not-available">Not Available</span>
                        </h5>
                      </div>
                      <div class="ibox-content card-min-height">
                        <div class="mb-2">
                          <span class="font-weight-normal">Plan Name:</span>
                          <div *ngIf="account.planName && account.planName !== 'Not Available'" class="float-end badge badge-{{account.planClassName}}">{{account.planName}} - {{account.interval}}</div>
                          <div *ngIf="!account.planName || account.planName === 'Not Available'" class="float-end not-available">Not Available</div>
                        </div>

                        <div class="mb-2">
                          <span class="font-weight-normal">Account Number:</span>
                          <div *ngIf="account.accountNumber" class="float-end"><a class="text-underline-hover" [routerLink]="['/accounts/account-details/', account.accountNumber]" routerLinkActive="router-link-active">{{account.accountNumber}}</a></div>
                          <div *ngIf="!account.accountNumber" class="float-end not-available">Not Available</div>
                        </div>

                        <div class="mb-2">
                          <span class="font-weight-normal">Subscription Owner:</span>
                          <div *ngIf="!account.subscriptionOwnerEmail" class="float-end not-available">Not Available</div>
                          <div *ngIf="account.subscriptionOwnerEmail" class="float-end"><a class="text-underline-hover" [routerLink]="['/users/user-details/', account.subscriptionOwnerEmail]" routerLinkActive="router-link-active" >{{account.subscriptionOwnerEmail}}</a></div>
                        </div>

                        <div class="mb-2">
                          <span class="font-weight-normal">Closing Date:</span>
                          <div *ngIf="account.closingDate" class="float-end">{{account.closingDate}}</div>
                          <div *ngIf="!account.closingDate" class="float-end not-available">Not Available</div>
                        </div>

                        <div class="mb-2">
                           <span class="font-weight-normal">Reseller Id:</span>
                           <div *ngIf="account.resellerId" class="float-end">{{account.resellerId}}</div>
                           <div *ngIf="!account.resellerId" class="float-end not-available">Not Available</div>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div *ngIf="accounts && accounts.length === 0" class="col-md-12 not-available text-center white-bg">
                        No account available at this time.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
