import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-extend-trial',
  templateUrl: './extend-trial.component.html',
  styleUrls: ['./extend-trial.component.css']
})
export class ExtendTrialComponent implements OnInit {
  accountNumber: string;
  trialExtendForm: UntypedFormGroup;
  selected: Date | null;
  minDate = new Date();
  pageHeading: string;
  constructor(
    private router: Router,
    private modal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private utilityService: CommonUtilityService,
    private accountService: AccountService,
    private logger: NGXLogger
  ) {
    this.pageHeading = 'Extend Trial';
   }

  ngOnInit(): void {
    this.trialExtendForm = this.fb.group({
      accountNumber: [this.accountNumber, [Validators.required]],
      trialExpirationDate: ['', [Validators.required]]
    });
  }

  get trialExtendFormControl() {
    return this.trialExtendForm.controls;
  }

  cancel() {
    this.modal.dismiss();
  }

  extendTrial() {
    if (this.trialExtendForm.valid) {
      this.accountService.setNewTrialDate(this.trialExtendForm.value).then((response: any) => {
        this.logger.info('INFO: -> ExtendTrialComponent -> this.accountService.extendTrial -> response', response);
        this.utilityService.displaySuccessToast(this.pageHeading, response.message);
        this.modal.close();
        this.router.navigate(['/accounts/all'], { queryParams: { search: this.accountNumber }});
      }).catch((error: any) => {
        this.logger.error('ERROR: -> ExtendTrialComponent -> this.accountService.extendTrial -> error', error);
        this.utilityService.displayErrorToastWithTechDetails(this.pageHeading, error.error);
      });
    } else {
      this.utilityService.displayErrorToast(this.pageHeading, 'Please fill all the required field.');
    }
  }

  setDate(event) {
    this.trialExtendForm.get('trialExpirationDate').setValue(event.format('YYYY-MM-DD'));
  }

}
