import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '@app/services/account.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-white-label-partner-accounts',
  templateUrl: './white-label-partner-accounts.component.html',
  styleUrls: ['./white-label-partner-accounts.component.css']
})
export class WhiteLabelPartnerAccountsComponent implements OnInit, OnDestroy {
  @Input() franchiseeCode: string;
  primaryColor: string;
  pageHeading: string;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  accounts: [];
  fromShowing: any;
  toShowing: any;
  selectedColumns: any;
  selectedSort: any;
  searchString: string;
  columns = [
    {id: 1, name: 'uri', label: 'Account No', sort: 1, hide: 0},
    {id: 2, name: 'subscription_admin_email', label: 'Subscription Owner', sort: 0, hide: 0},
    {id: 3, name: 'tenant', label: 'Company Name', sort: 0, hide: 0},
    {id: 4, name: 'status', label: 'Status', sort: 0, hide: 0},
    {id: 5, name: 'version', label: 'Plan Name', sort: 0, hide: 0},
    {id: 6, name: 'franchisee', label: 'Franchisee', sort: 0, hide: 0},
    {id: 7, name: 'closing_date', label: 'Closing Date', sort: 0, hide: 0},
    {id: 8, name: '', label: 'Payment Status', sort: 0, hide: 0},
    {id: 9, name: 'accountCreation', label: 'Account Creation', sort: 0, hide: 0},
    {id: 10, name: 'userDisableStatus', label: 'Status', sort: 0, hide: 0},
  ];
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger,
    private accountService: AccountService
  ) {
    this.pageHeading = 'Get Accounts List';
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.searchString = '';
    this.selectedColumns = [
      {id: 1, name: 'uri', label: 'Account No', sort: 1, hide: 0},
      {id: 3, name: 'tenant', label: 'Company Name', sort: 0, hide: 0},
      {id: 4, name: 'status', label: 'Status', sort: 0, hide: 0},
      {id: 5, name: 'version', label: 'Plan Name', sort: 0, hide: 0},
      {id: 8, name: '', label: 'Payment Status', sort: 0, hide: 0},
      {id: 10, name: 'userDisableStatus', label: 'Status', sort: 0, hide: 0}
    ];
    this.selectedSort = {key: 'Account No', value: 1};
  }

  ngOnInit(): void {
    this.route.parent.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.primaryColor = data.franchisee.primaryColor;
      });
    if (this.franchiseeCode) {
      this.getAllAccounts(1);
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, 'User Email is missing.');
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  getAllAccounts(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      franchiseeCode: this.franchiseeCode,
      searchText: this.searchString,
      selectedSort: this.selectedSort,
    };
    this.accountService.getAccountsByFranchisee(requestParams).then((accountsListResponse: any) => {
      this.accounts = accountsListResponse.accounts;
      if (accountsListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < accountsListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        accountsListResponse.totalCount);
      }
      this.totalCount = accountsListResponse.totalCount;
    }).catch(error => {
      this.commonUtility.displayErrorToastWithTechDetails(this.pageHeading, error.error);
    });
  }

  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllAccounts(this.pageNo);
    }
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllAccounts(this.pageNo);
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  clearSearch() {
    this.searchString = '';
    this.getAllAccounts(this.pageNo);
  }

  onChangeSearchText() {
    this.getAllAccounts(this.pageNo);
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

}
