<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 all-white-label-partner-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <div class="col-md-6 p-0 display-flex">
                    <h5>{{pageHeading}}</h5>
                </div>
                <div class="col-md-6 p-0">
                    <span class="float-end">
                        <a [routerLink]="['/partner/white-label-partner/add']" class="btn btn-primary me-2"
                            routerLinkActive="router-link-active">
                            <i class="fa fa-plus"></i>
                            Add White Label Partner
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Reset Filters" (click)="resetFilter();"> <i class="fa fa-filter" aria-hidden="true"></i> Reset
                        </a>
                        <a class="btn btn-export me-2" matTooltip="CSV" (click)="exportAction('csv');"> <i class="fa fa-file-code-o"></i>
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Excel" (click)="exportAction('excel');"> <i class="fa fa-file-excel-o"></i>
                        </a>
                        <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()"><i class="fa fa fa-refresh"></i>
                        </a>
                    </span>
                </div>
            </div>
        </div>
        <div class="ibox col-md-12 all-white-label-partner-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <div class="mb-3 all-white-label-partner-filter-group">
                            <label for="search" matTooltip="You can search by Name, Code, Company Name, Admin Email">Global Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" class="search-clear-icon" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">
                        <div class="mb-3 all-white-label-partner-filter-group">
                            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                            <mat-select [compareWith]="compareFn"
                                        panelClass="mat-select-autowidth-panel-class"
                                        class="form-control" [(ngModel)]="selectedColumns"
                                multiple>
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">

                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2">

                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-end">
                        <div class="mb-3 all-white-label-partner-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                (selectionChange)="changeDisplayPerPageItem($event)">
                                <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>&nbsp; partner(s)
                        </div>
                        <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} partner(s)
                        </p>
                        <pagination-controls id="all-partners" (pageChange)="getAllWhiteLabelPartners($event)" responsive="true"
                            previousLabel="Previous" nextLabel="Next" maxSize="3"></pagination-controls>
                    </div>
                </div>
                <div class="row filtered-by-box">
                    <div class="col-md-12" *ngIf="searchString">
                        <span class="filter-by-text">Filtered by: </span>
                        <span class="alert alert-success alert-dismissible
                            fade show type-filter ms-2" *ngIf="searchString">
                            <strong>Global Search:</strong> ({{searchString}})
                            <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </span>
                    </div>
                    <div class="col-md-12 mt-2">
                        <span class="filter-by-text">Sort by: </span>
                        <span class="alert alert-success alert-dismissible
                            fade show payment-status-filter ms-2">
                            <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending': 'Descending'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row all-white-label-partner-result-container mt-3 white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <div class="user-list-box table-responsive">
                    <table class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover"
                        id="white-label-partners-table">
                        <thead>
                            <tr>
                                <ng-container *ngFor="let column of columns; let i = index">
                                    <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting"
                                        [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1, 'sorting_disabled': column.disableSort}"
                                        (click)="sortBy(column)">{{column.label}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let partner of partners | paginate: { id: 'all-partners', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                                <td *ngIf="checkColumnVisibility(columns[0])">
                                    <span *ngIf="partner.code">{{partner.code}}</span>
                                    <span *ngIf="!partner.code" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[1])">
                                    <span *ngIf="partner.companyName">{{partner.companyName}}</span>
                                    <span *ngIf="!partner.companyName" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[2])">
                                    <span *ngIf="partner.adminEmail">{{partner.adminEmail}}</span>
                                    <span *ngIf="!partner.adminEmail" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[3])">
                                    <span *ngIf="partner.createdDate">{{partner.createdDate}}</span>
                                    <span *ngIf="!partner.createdDate" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[4])">
                                    <a class="btn btn-sm btn-primary me-2" [routerLink]="['/partner/white-label-partner/view/', partner.id]" routerLinkActive="router-link-active" ><i class="fa fa-eye" aria-hidden="true"></i> View</a>
                                    <a class="btn btn-sm btn-primary" [routerLink]="['/partner/white-label-partner/edit/', partner.id]"><i class="fa fa-pencil-square" aria-hidden="true"></i> Edit</a>
                                </td>
                            </tr>
                            <tr *ngIf="!partners || partners.length === 0" class="text-center">
                                <td colspan="5" class="not-available">No white label partner available at this time</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
