import { Component, OnDestroy, OnInit } from '@angular/core';
import { Franchisee } from '@app/models/franchisee';
import { User } from '@app/models/user';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { FranchiseeService } from '@app/services/franchisee.service';
import { MultiFactorService } from '@app/services/multi-factor.service';
import { UserService } from '@app/services/user.service';
import { environment } from '@env/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-multi-factor-disable-popup',
  templateUrl: './multi-factor-disable-popup.component.html',
  styleUrls: ['./multi-factor-disable-popup.component.css']
})
export class MultiFactorDisablePopupComponent implements OnInit, OnDestroy {
  logo: string = environment.CBK_LOGO_LIGHT_IMAGE;
  franchiseeCode: string;
  companyName: string;
  recoveryEmail: string;
  currentState = 'Disable';
  states: string[] = ['Disable', 'Completed'];

  securityCode: string;
  verificationCode: string;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    public modal: NgbActiveModal,
    private franchiseeService: FranchiseeService,
    private ngxService: NgxUiLoaderService,
    private multiFactorService: MultiFactorService,
    private userService: UserService,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.franchiseeService.getFranchiseeDetails
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((franchiseDetails: Franchisee) => {
        this.companyName = franchiseDetails.companyName;
        this.franchiseeCode = franchiseDetails.franchiseeCode;
      });
    this.userService.getUser
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((response: User) => {
        this.recoveryEmail = response.recoveryEmail;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  closePopupAndRefreshTab(currentState: string) {
    this.modal.close();
    if (currentState === 'Completed') {
      window.location.reload();
    }
  }

  disableMultiFactor() {
    if (this.securityCode) {
      this.disableMFAWithSecurityCode(this.securityCode);
    } else if (this.verificationCode) {
      this.disableMFAWithRecoveryEmail(this.verificationCode);
    } else {
      this.commonUtility.displayErrorToast('Disable MFA', 'Please fill the required fields.');
    }
  }

  sendVerificationEmail() {
    this.ngxService.start();
    this.multiFactorService.sendVerificationCodeEmail().then(response => {
      this.logger.info('INFO: -> MultiFactorDisabledPopupComponent -> sendVerificationEmail -> response', response);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displaySuccessToast('Disable MFA', 'Email sent successfully to your recovery email.');
    }).catch(error => {
      this.logger.error('ERROR: -> MultiFactorDisabledPopupComponent -> sendVerificationEmail -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displayErrorToastWithTechDetails('Disable MFA', error.error);
    });
  }

  disableMFAWithSecurityCode(securityCode: string) {
    this.ngxService.start();
    this.multiFactorService.disableMFAWithSecurityCode(securityCode).then(response => {
      this.logger.info('INFO: -> MultiFactorDisabledPopupComponent -> disableMFAWithSecurityCode -> response', response);
      this.currentState = 'Completed';
      this.ngxService.stop();
      this.ngxService.stopBackground();
    }).catch(error => {
      this.logger.error('ERROR: -> MultiFactorDisabledPopupComponent -> disableMFAWithSecurityCode -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displayErrorToastWithTechDetails('Disable MFA', error.error);
    });
  }

  disableMFAWithRecoveryEmail(verificationCode: string) {
    this.ngxService.start();
    this.multiFactorService.disableMFAWithRecoveryEmail(verificationCode).then(response => {
      this.logger.info('INFO: -> MultiFactorDisabledPopupComponent -> disableMFAWithRecoveryEmail -> response', response);
      this.currentState = 'Completed';
      this.ngxService.stop();
      this.ngxService.stopBackground();
    }).catch(error => {
      this.logger.info('ERROR: -> MultiFactorDisabledPopupComponent -> disableMFAWithRecoveryEmail -> error', error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.commonUtility.displayErrorToastWithTechDetails('Disable MFA', error.error);
    });
  }

}
