<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 notifications-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}
                    <span class="float-end">
                        <a class="btn btn-export me-2" matTooltip="CSV" (click)="exportAction('csv');"> <i
                                class="fa fa-file-code-o"></i>
                        </a>
                        <a class="btn btn-export me-2" matTooltip="Excel" (click)="exportAction('excel');"> <i
                                class="fa fa-file-excel-o"></i>
                        </a>
                        <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()"><i
                                class="fa fa fa-refresh"></i></a>
                    </span>
                </h5>
            </div>
        </div>
        <div class="ibox col-md-12 notifications-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="search"
                                matTooltip="You can search by Notification Type, Message,  User Email or Company Name fields">Global
                                Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="version" matTooltip="You can select multiple Notification Type">Invitations
                                Type</label>
                            <mat-form-field appearance="outline">
                                <mat-label *ngIf="!selectedNotificationType.length"><b>Select Invitations Type</b>
                                </mat-label>
                                <mat-select [(ngModel)]="selectedNotificationType" multiple>
                                    <mat-option #allSelectedNotificationType (click)="toggleAllTypeNotificationType()"
                                        [value]="0">All</mat-option>
                                    <mat-option *ngFor="let item of notificationTypeList" [value]="item.value"
                                        (click)="notificationTypeTouchPerOne()">{{item.label}} <span
                                            class="{{item.class}}"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2 pe-0">
                        <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                        <mat-select [compareWith]="compareFn" class="form-control"
                                    panelClass="mat-select-autowidth-panel-class"
                            (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                            <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                        </mat-select>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-end">
                        <div class="mb-3 account-filter-group item-per-page-option">
                            Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                (selectionChange)="changeDisplayPerPageItem($event)">
                                <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>&nbsp; notification(s)
                        </div>
                        <p class="mb-0">
                            Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} notification(s)
                        </p>
                        <pagination-controls id="all-notifications" (pageChange)="getAllInvitations($event)"
                            responsive="true" previousLabel="Previous" nextLabel="Next" maxSize="3">
                        </pagination-controls>
                    </div>
                </div>

                <div class="row filtered-by-box">
                    <div class="col-md-12 mb-2" *ngIf="searchString || !selectedNotificationType.includes(0)">
                        <span class="filter-by-text">Filtered by: </span>
                        <span class="alert alert-success alert-dismissible
                            fade show type-filter ms-2" *ngIf="searchString">
                            <strong>Global Search:</strong> ({{searchString}})
                            <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </span>
                        <span class="alert alert-success alert-dismissible
                        fade show event-filter ms-2" *ngIf="!selectedNotificationType.includes(0)">
                            <strong>Invitations Type:</strong> ({{selectedNotificationType.join(', ')}})
                            <button type="button" class="btn filter-close-btn" aria-label="Close"
                                (click)="resetNotificationTypeSelection()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </span>
                    </div>
                    <div class="col-md-12">
                        <span class="filter-by-text">Sort by: </span>
                        <span class="alert alert-success alert-dismissible
                            fade show payment-status-filter ms-2">
                            <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending':
                            'Descending'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row white-bg mt-3">
        <div class="ibox col-md-12 notifications-result-container">
            <div class="ibox-title table-responsive">
                <table class="responsive table table-striped table-bordered no-footer hover" id="all-notifications">
                    <thead>
                        <tr>
                            <ng-container *ngFor="let column of columns; let i = index">
                                <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting"
                                    [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}"
                                    (click)="sortBy(column.label)">{{column.label}}</th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let invitation of invitations | paginate: { id: 'all-notifications', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">

                            <td *ngIf="checkColumnVisibility(columns[1])">
                                <span *ngIf="invitation.invited_user">
                                    {{invitation.invited_user}}
                                </span>
                                <span *ngIf="!invitation.invited_user" class="not-available">
                                    Not Available
                                </span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[2])">
                                <span *ngIf="invitation.invited_date">{{invitation.invited_date}}</span>
                                <span *ngIf="!invitation.invited_date" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[3])">
                                <span *ngIf="invitation.invited_by">{{invitation.invited_by}}</span>
                                <span *ngIf="!invitation.invited_by" class="not-available">Not Available</span>
                            </td>
                            <td *ngIf="checkColumnVisibility(columns[0])">
                                <span *ngIf="invitation.status === 'ACCEPTED' " class="app-badge accepted">
                                    {{invitation.status}}
                                </span>
                                <span *ngIf="invitation.status === 'PENDING' " class="app-badge pending">
                                    {{invitation.status}}
                                </span>
                                <span *ngIf="invitation.status === 'CLOSED' " class="app-badge closed">
                                    {{invitation.status}}
                                </span>
                                <span *ngIf="!invitation.status" class="app-badge not-available">Not Available</span>
                            </td>
                        </tr>
                        <tr *ngIf="invitations && invitations.length === 0">
                            <td colspan="5" class="not-available text-center">No Invitation found at this time.
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
