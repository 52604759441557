import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Franchisee } from '../models/franchisee';

@Injectable({
  providedIn: 'root'
})
export class FranchiseeService {
  api: string = environment.CAT_URL;
  private franchiseeDetails = new BehaviorSubject<Franchisee>(null);
  getFranchiseeDetails = this.franchiseeDetails.asObservable();
  constructor(
    private httpClient: HttpClient
  ) { }

  changeFranchiseeDetails(franchisee: Franchisee) {
    this.franchiseeDetails.next(franchisee);
  }

  getDetailsByURL(url: string) {
    return this.httpClient.post<Franchisee>(this.api + '/getFranchiseeDetailsByUrl', { FranchiseeDomainURL: url}).toPromise();
  }
}
