import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BackButtonService } from '@app/services/back-button.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { ExportService } from '@app/services/export.service';
import { UserService } from '@app/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-all-subscription-owners',
  templateUrl: './all-subscription-owners.component.html',
  styleUrls: ['./all-subscription-owners.component.css']
})
export class AllSubscriptionOwnersComponent implements OnInit, OnDestroy {
  title: string;
  cookieKey: string;
  pageHeading: string;
  viewType: string;
  companyName: string;
  searchString: string;
  selectedColumns: any;
  selectedSort: any;
  selectedUserType: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  subscriptionOwners: [];
  fromShowing: any;
  toShowing: any;
  columns = [
    { id: 1, key: 'email', name: 'email', label: 'Sub Owner Email', sort: 0, hide: 0 },
    { id: 2, key: 'name', name: 'name', label: 'Sub Owner Name', sort: 0, hide: 0 },
    { id: 3, key: 'phoneNumber', name: 'phoneNumber', label: 'Phone Number', sort: 0, hide: 0 },
    { id: 4, key: 'userType', name: 'userType', label: 'Pricing Level', sort: 0, hide: 0 },
    { id: 5, key: 'externalAccountantStatus', name: 'externalAccountantStatus', label: 'External Accountant Status', sort: 0, hide: 0 },
    { id: 6, key: 'multiFactorStatus', name: 'multiFactorStatus', label: 'Multi-Factor Status', sort: 0, hide: 0 },
    { id: 7, key: 'playgroundCreatedDate', name: 'playgroundCreatedDate', label: 'Playground Created Date', sort: 0, hide: 0 },
    { id: 8, key: 'creationDate', name: 'creationDate', label: 'Creation Date', sort: -1, hide: 0 },
    { id: 9, key: 'listOfAllAccounts', name: 'listOfAccounts', label: 'Account(s) List', sort: 0, hide: 0, disableSort: true }
  ];
  userTypeOptions = [
    { id: 1, name: 'Customers - Default', label: 'Customers - Default', class: 'dot-customers---default' },
    { id: 2, name: 'Customers - Default 2024', label: 'Customers - Default 2024', class: 'dot-customers---default-2024' },
    { id: 3, name: 'Customers - Early Adopters', label: 'Customers - Early Adopters', class: 'dot-customers---early-adopters' },
    { id: 4, name: 'Partners - Fan Club', label: 'Partners - Fan Club', class: 'dot-partners---fan-club' },
    { id: 5, name: 'Partners - Verified 2024', label: 'Partners - Verified 2024', class: 'dot-partners---verified-2024' },
    { id: 6, name: 'Partners - Verified', label: 'Partners - Verified', class: 'dot-partners---verified' }
    // { id: 7, name: 'Partner - Reseller', label: 'Partner - Reseller', class: 'dot-partner---reseller' }
  ];
  @ViewChild('allSelectedUserType') private allSelectedUserType: MatOption;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private userService: UserService,
    private commonUtility: CommonUtilityService,
    private exportService: ExportService,
    private logger: NGXLogger,
    private ngxService: NgxUiLoaderService,
    private backButtonService: BackButtonService,
    private location: Location
  ) {
    // default setting
    this.cookieKey = 'cf_all_subscription_owners';
    this.pageHeading = 'All Subscription Owner';
    this.viewType = 'LIST';

    // persist filters while click on back/refresh
    const filters: any = this.backButtonService.getFilters(this.cookieKey);
    if (filters) {
      this.setAppliedFilters(filters);
    } else {
      this.setDefaultFilters();
    }
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.companyName = data.franchisee.companyName;
        this.title = `${this.pageHeading} | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllSubscriptionOwners(1);
  }

  changeViewType(viewType) {
    this.viewType = viewType;
  }

  clearSearch() {
    this.searchString = '';
    this.getAllSubscriptionOwners(1);
  }

  onChangeSearchText() {
    this.getAllSubscriptionOwners(1);
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllSubscriptionOwners(1);
  }

  changeUserType() {
    this.getAllSubscriptionOwners(1);
  }

  toggleAllUserType() {
    if (this.allSelectedUserType.selected) {
      this.selectedUserType = [...this.userTypeOptions.map(item => item.name), 0];
    } else {
      this.selectedUserType = [];
    }
    this.changeUserType();
  }

  userTypeTouchPerOne() {
    if (this.allSelectedUserType.selected) {
      this.allSelectedUserType.deselect();
    }
    if (this.selectedUserType.length === this.userTypeOptions.length) {
      this.allSelectedUserType.select();
    }
    this.changeUserType();
  }

  resetUserTypeSelection() {
    this.allSelectedUserType.select();
    this.selectedUserType = [...this.userTypeOptions.map(item => item.name), 0];
    this.changeUserType();
  }

  sortBy(column) {
    if (column && column.label && !column.disableSort) {
      this.columns.forEach((value) => {
        if (value.label === column.label) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = { key: value.label, value: value.sort };
        } else {
          value.sort = 0;
        }
      });
      this.getAllSubscriptionOwners(1);
    }
  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  getAllSubscriptionOwners(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      currentPage: pageNo,
      selectedColumns: this.selectedColumns,
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedSort: this.selectedSort,
      selectedUserType: this.selectedUserType
    };
    this.backButtonService.setFilters(this.cookieKey, requestParams);
    this.userService.getAllSubscriptionOwners(requestParams).then((subscriptionOwnersListResponse: any) => {
      this.logger.info('INFO: -> AllSubscriptionOwnersComponent -> ' +
        'this.userService.getAllSubscriptionOwners -> subscriptionOwnersListResponse', subscriptionOwnersListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'Subscription Owners List refreshed.');
      this.subscriptionOwners = subscriptionOwnersListResponse.users;
      if (subscriptionOwnersListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < subscriptionOwnersListResponse.totalCount ?
          (pageNo + 1) * this.itemsPerPage :
          subscriptionOwnersListResponse.totalCount);
      }
      this.totalCount = subscriptionOwnersListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> AllSubscriptionOwnersComponent -> this.userService.getAllSubscriptionOwners -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  exportAction(option) {
    this.ngxService.start();
    const fileName = 'all_subscription_owners';
    const requestParams = {
      searchText: this.searchString,
      selectedSort: this.selectedSort,
      selectedUserType: this.selectedUserType
    };
    this.userService.getAllSubscriptionOwnersForExport(requestParams).then((subscriptionOwnersListResponse: any) => {
      const exportData = [];
      const subscriptionOwners = subscriptionOwnersListResponse.users;
      subscriptionOwners.forEach(item => {
        const tempJson = {};
        this.selectedColumns.forEach(column => {
          if (column.key === 'listOfAllAccounts') {
            tempJson[column.label] = item[column.key] ? item[column.key].join(', ') : '';
          } else {
            tempJson[column.label] = item[column.key] && item[column.key] !== 'Not Available' ? item[column.key] : '';
          }
        });
        exportData.push(tempJson);
      });
      if (option === 'excel') {
        this.exportService.exportAsExcelFile(exportData, fileName);
      } else if (option === 'csv') {
        this.exportService.exportToCsvFile(exportData, fileName);
      } else if (option === 'doc') {
        this.exportService.exportToDocFile(exportData, fileName, this.pageHeading, this.companyName);
      }
      this.ngxService.stop();
      this.ngxService.stopBackground();
    }).catch(error => {
      this.logger.error('ERROR: -> AllSubscriptionOwnersComponent -> this.userService.getAllSubscriptionOwners -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error?.error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
    });
  }

  refreshData() {
    this.getAllSubscriptionOwners(this.currentPage);
  }

  setDefaultFilters() {
    this.searchString = '';
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.selectedSort = { key: 'Creation Date', value: -1 };
    this.selectedUserType = [...this.userTypeOptions.map(item => item.name), 0];
    this.selectedColumns = [
      { id: 1, key: 'email', name: 'email', label: 'Sub Owner Email', sort: 0, hide: 0 },
      { id: 2, key: 'name', name: 'name', label: 'Sub Owner Name', sort: 0, hide: 0 },
      { id: 3, key: 'phoneNumber', name: 'phoneNumber', label: 'Phone Number', sort: 0, hide: 0 },
      { id: 4, key: 'userType', name: 'userType', label: 'Pricing Level', sort: 0, hide: 0 },
      { id: 5, key: 'externalAccountantStatus', name: 'externalAccountantStatus', label: 'External Accountant Status', sort: 0, hide: 0 },
      { id: 6, key: 'multiFactorStatus', name: 'multiFactorStatus', label: 'Multi-Factor Status', sort: 0, hide: 0 },
      { id: 7, key: 'playgroundCreatedDate', name: 'playgroundCreatedDate', label: 'Playground Created Date', sort: 0, hide: 0 },
      { id: 8, key: 'creationDate', name: 'creationDate', label: 'Creation Date', sort: -1, hide: 0 },
      { id: 9, key: 'listOfAllAccounts', name: 'listOfAccounts', label: 'Account(s) List', sort: 0, hide: 0, disableSort: true }
    ];
  }

  setAppliedFilters(filtersValue) {
    this.totalCount = 0;
    this.itemsPerPage = filtersValue?.itemsPerPage;
    this.pageNo = filtersValue?.currentPage;
    this.searchString = filtersValue?.searchText;
    this.selectedColumns = filtersValue?.selectedColumns;
    this.selectedSort = filtersValue?.selectedSort;
    this.selectedUserType = filtersValue?.selectedUserType;
  }

  resetFilter() {
    this.backButtonService.removeFilters(this.cookieKey);
    this.setDefaultFilters();
    this.getAllSubscriptionOwners(1);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
