<div class="container login-container">
    <div class="row">

        <div class="col-md-6">
            <div class="cat-image">
                <img src="assets/images/cat.png">
            </div>
        </div>
        <div class="col-md-4">
            <div class="login-form">
                <div class="warningMessage alert alert-danger" role="alert" [hidden]="!warningOn">
                    <p [innerHTML]="warningMessage"></p>
                    <p class="mb-0"></p>
                </div>
                <div class="socialLinks d-grid">
                   <!--  <a class="btn btn-social btn-linkedin" *ngIf="loginWithLinkedInOn" (click)="signUpWithLinkedIn()">
                        <i class="fa fa-linkedin"></i> Sign in with LinkedIn
                    </a>     -->
                    <a class="btn btn-social btn-google-plus" *ngIf="loginWithGoogleOn" (click)="signUpWithGoogle()">
                        <i class="fa fa-google"></i> Sign in with Google
                    </a>
                   <!--  <a class="btn btn-social btn-facebook" *ngIf="loginWithFacebookOn" (click)="signUpWithFacebook()">
                        <i class="fa fa-facebook-f"></i> Sign in with Facebook
                    </a> -->
                </div>
                <div class="divider" *ngIf="loginWithFacebookOn || loginWithGoogleOn || loginWithLinkedInOn">
                    <span class="fa">OR</span>
                </div>
                <div class="form">
                    <form name="login" [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
                        <div class="mb-3">
                            <label>Email address <span class="required-icon">*</span></label>
                            <input type="email" autocomplete="off" required email class="form-control" placeholder="Email Address" formControlName="email"  [ngClass]="{'is-valid': formControls.email.valid, 'is-invalid': (formControls.email.dirty && formControls.email.touched && formControls.email.invalid)}">
                            <div *ngIf="formControls.email.invalid && (formControls.email.dirty && formControls.email.touched)">
                                <div class="invalid-feedback" *ngIf="formControls.email.errors.email">
                                    Enter the valid email address.
                                </div>
                                <div class="invalid-feedback" *ngIf="formControls.email.errors.required">
                                    Email address is required.
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label>Password <span class="required-icon">*</span></label>
                            <show-hide-password size="md" btnStyle="primary" [btnOutline]="false">
                                <input type="password" autocomplete="off" name="userPassword" required class="form-control" formControlName="password" placeholder="Password" [ngClass]="{'is-valid': formControls.password.valid, 'is-invalid': (formControls.password.dirty && formControls.password.touched && formControls.password.invalid)}">
                            </show-hide-password>
                            <div *ngIf="formControls.password.invalid && (formControls.password.dirty && formControls.password.touched)">
                                <div class="invalid-feedback" *ngIf="formControls.password.errors.required">
                                    Password is required.
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-check-label">
                                <input type="checkbox" class="checkbox-input" formControlName="rememberMe"> Remember My Email
                            </label>
                        </div>
                        <div class="mb-3 d-grid" *ngIf="!rateLimitExceeded">
                            <button type="submit" class="btn login-btn">Login</button>
                        </div>
                        <div class="mb-3 d-grid" *ngIf="rateLimitExceeded">
                            <button type="button" class="btn login-btn disabled"><i class="fa fa-clock-o" aria-hidden="true"></i> {{ counter | formatTime }}</button>
                            <div class="warningMessage alert alert-danger mt-2" role="alert">
                                <p>Note: Your account has been locked for 15 minutes because you have reached the maximum number of invalid logon attempt. You can also click the Forgot/Change Password link.</p>
                                <p class="mb-0"></p>
                            </div>
                        </div>
                        <div class="mb-3 d-grid">
                            <a class="forgot-password-link" href="#" [routerLink]="['/forgot-password']">Forgot/Change Password? </a>
                            <a class="text-underline-hover" href="{{ getStartedHelpLink }}" *ngIf="getStartedHelpOn">Need Help Getting Started? </a>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>
</div>
