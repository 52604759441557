import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { LogService } from '@app/services/log.service';
import * as dayjs from 'dayjs';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sm-logs',
  templateUrl: './sm-logs.component.html',
  styleUrls: ['./sm-logs.component.css']
})
export class SmLogsComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;

  searchString: string;
  selectedCategory: any;
  selectedType: any;
  selectedColumns: any;
  selectedSort: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  smLogs: [];
  fromShowing: any;
  toShowing: any;

  categoriesList = [
    {id: 1, label: 'SOAP', value: 'SOAP', key: 'soap', class: 'dot dot-soap-category'},
    {id: 2, label: 'REST', value: 'REST', key: 'rest', class: 'dot dot-rest-category'},
  ];

  typesList = [
    {id: 1, label: 'SUCCESS', value: 'SUCCESS', key: 'success', class: 'dot dot-success-type'},
    {id: 2, label: 'ERROR', value: 'ERROR', key: 'error', class: 'dot dot-error-type'},
  ];
  columns = [
    {id: 0, name: 'createdDate', label: 'Created date', sort: -1, hide: 0, width: '90px'},
    {id: 1, name: 'serviceType', label: 'Service type', sort: 0, hide: 0, width: '60px'},
    {id: 2, name: 'type', label: 'Type', sort: 0, hide: 0, width: '60px'},
    {id: 3, name: 'url', label: 'URL', sort: 0, hide: 0, width: '150px'},
    {id: 4, name: 'requestParameter', label: 'Request', sort: 0, hide: 0, width: '85px'},
    {id: 5, name: 'response', label: 'Response', sort: 0, hide: 0, width: '85px'},
    {id: 6, name: 'responseTime', label: 'Response time', sort: 0, hide: 0, width: '85px'},
    {id: 7, name: 'browser', label: 'Browser', sort: 0, hide: 0, width: '85px'},
    {id: 8, name: 'os', label: 'OS', sort: 0, hide: 0},
    {id: 9, name: 'device', label: 'Device', sort: 0, hide: 0},
    {id: 10, name: 'ipAddress', label: 'Ip address', sort: 0, hide: 0},
  ];
  ranges = {
    Today: [dayjs().startOf('day'), dayjs().endOf('day')],
    Yesterday: [dayjs().subtract(1, 'days').startOf('day'), dayjs().subtract(1, 'days').endOf('day')],
    'Last 7 Days': [dayjs().subtract(6, 'days').startOf('day'), dayjs().endOf('day')],
    'Last 30 Days': [dayjs().subtract(29, 'days').startOf('day'), dayjs().endOf('day')],
    'This Month': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Last Month': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [dayjs().subtract(3, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
  };
  dateRange = {
    start: dayjs().subtract(29, 'days').startOf('day'),
    end: dayjs().endOf('day'),
  };
  selectedDateRange = {
    start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
    end: dayjs().endOf('day').format('MM-DD-YYYY'),
  };
  maxDate = dayjs();
  @ViewChild('allSelectedCategories') private allSelectedCategories: MatOption;
  @ViewChild('allSelectedType') private allSelectedType: MatOption;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private logService: LogService,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger
  ) {
    this.pageHeading = 'Service Manager Logs';
    this.searchString = '';
    this.selectedType = [...this.typesList.map(item => item.value), 0];
    this.selectedCategory = [...this.categoriesList.map(item => item.value), 0];
    this.selectedColumns = [
      {id: 0, name: 'createdDate', label: 'Created date', sort: -1, hide: 0, width: '90px'},
      {id: 1, name: 'serviceType', label: 'Service type', sort: 0, hide: 0, width: '60px'},
      {id: 2, name: 'type', label: 'Type', sort: 0, hide: 0, width: '60px'},
      {id: 3, name: 'url', label: 'URL', sort: 0, hide: 0, width: '150px'},
      {id: 4, name: 'requestParameter', label: 'Request', sort: 0, hide: 0, width: '85px'},
      {id: 5, name: 'response', label: 'Response', sort: 0, hide: 0, width: '85px'},
      {id: 6, name: 'responseTime', label: 'Response time', sort: 0, hide: 0, width: '85px'},
    ];
    this.selectedSort = {key: 'Created Date', value: -1};
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;

    this.dateRange = {
      start: dayjs().subtract(29, 'days').startOf('day'),
      end: dayjs().endOf('day'),
    };
    this.selectedDateRange = {
      start: dayjs().subtract(29, 'days').startOf('day').format('MM-DD-YYYY'),
      end: dayjs().endOf('day').format('MM-DD-YYYY'),
    };
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.title = `Service manager Logs | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllSMLogs(this.pageNo);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  // #region SearchRelatedFun
  clearSearch() {
    this.searchString = '';
    this.getAllSMLogs(this.pageNo);
  }

  onChangeSearchText() {
    this.getAllSMLogs(this.pageNo);
  }
  // #endregion

  // #region CategoriesFilterFun
  changeCategoryFilter() {
    this.getAllSMLogs(this.pageNo);
  }

  toggleAllCategorySelection() {
    if (this.allSelectedCategories.selected) {
      this.selectedCategory = [...this.categoriesList.map(item => item.value), 0];
    } else {
      this.selectedCategory = [];
    }
    this.changeCategoryFilter();
  }

  categoryTouchPerOne() {
    if (this.allSelectedCategories.selected) {
      this.allSelectedCategories.deselect();
    }
    if (this.selectedCategory.length === this.categoriesList.length) {
        this.allSelectedCategories.select();
    }
    this.changeCategoryFilter();
  }

  resetCategorySelection() {
    this.allSelectedCategories.select();
    this.selectedCategory =
        [...this.categoriesList.map(item => item.value), 0];
    this.changeCategoryFilter();
  }

  changesTypeFilter() {
    this.getAllSMLogs(this.pageNo);
  }

  // #region TableFunctions
  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllSMLogs(this.pageNo);
    }
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllSMLogs(this.pageNo);
  }

  resetTypeSelection() {
    this.allSelectedType.select();
    this.selectedType =
        [...this.typesList.map(item => item.value), 0];
    this.changesTypeFilter();
  }

  toggleAllTypeSelection() {
    if (this.allSelectedType.selected) {
      this.selectedType = [...this.typesList.map(item => item.value), 0];
    } else {
      this.selectedType = [];
    }
    this.changesTypeFilter();
  }

  typeTouchPerOne() {
    if (this.allSelectedType.selected) {
      this.allSelectedType.deselect();
    }
    if (this.selectedType.length === this.typesList.length) {
        this.allSelectedType.select();
    }
    this.changesTypeFilter();
  }

  refreshData() {
    this.getAllSMLogs(this.currentPage);
  }
  // #endregion

  datesUpdated(event) {
    if (event && event.startDate && event.endDate) {
      this.selectedDateRange.start = dayjs(event.startDate).format('MM-DD-YYYY');
      this.selectedDateRange.end = dayjs(event.endDate).format('MM-DD-YYYY');
      this.getAllSMLogs(this.pageNo);
    }
  }

  // #region MainFUnctions
  getAllSMLogs(pageNo: number): void {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedServiceType: this.selectedCategory,
      selectedType: this.selectedType,
      selectedSort: this.selectedSort,
      selectedDateRange: this.selectedDateRange
    };
    this.logService.getAllSMLogs(requestParams).then((smLogsListResponse: any) => {
      this.logger.info('INFO: -> SMLogsComponent -> this.logService.getAllSMLogs -> smLogsListResponse', smLogsListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'Service manager logs List refreshed.');
      this.smLogs = smLogsListResponse.smLogs;
      if (smLogsListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < smLogsListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        smLogsListResponse.totalCount);
      }
      this.totalCount = smLogsListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> SMLogsComponent -> this.logService.getAllSMLogs -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

}
