<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 sm-logs-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}
                    <span class="float-end">
                        <a class="btn btn-export" matTooltip="Refresh Data" (click)="refreshData()"><i
                                class="fa fa fa-refresh"></i></a>
                    </span>
                </h5>
            </div>
        </div>
        <div class="ibox col-md-12 sm-logs-filters-container">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 sm-logs-filter-group">
                            <label for="search" matTooltip="You can search by URL fields">Global
                                Search</label>
                            <input type="text" class="form-control margin-list-box" id="searchinput"
                                (keyup)="onChangeSearchText()" [(ngModel)]="searchString" placeholder="Search...">
                            <span id="searchclear" [ngClass]="searchString.length ? 'fa fa-times-circle' : ''"
                                (click)="clearSearch()"></span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="version" matTooltip="You can select multiple Service type">Service type</label>
                            <mat-form-field class="form-control setAlign">
                                <mat-label *ngIf="!selectedCategory.length"><b>Select Service type</b></mat-label>
                                <mat-select [(ngModel)]="selectedCategory" multiple>
                                    <mat-option #allSelectedCategories (click)="toggleAllCategorySelection()"
                                        [value]="0">All</mat-option>
                                    <mat-option *ngFor="let item of categoriesList" [value]="item.value"
                                        (click)="categoryTouchPerOne()">{{item.label}}
                                        <span class="{{item.class}}"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3 account-filter-group">
                            <label for="version" matTooltip="You can select multiple Types">Type</label>
                            <mat-form-field class="form-control setAlign">
                                <mat-label *ngIf="!selectedType.length"><b>Select Type</b></mat-label>
                                <mat-select [(ngModel)]="selectedType" multiple>
                                    <mat-option #allSelectedType (click)="toggleAllTypeSelection()" [value]="0">All
                                    </mat-option>
                                    <mat-option *ngFor="let item of typesList" [value]="item.value"
                                        (click)="typeTouchPerOne()">{{item.label}}
                                        <span class="{{item.class}}"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-2 col-lg-2 pe-0">
                        <div class="mb-3">
                            <label>Date Range <span class="required">*</span></label>
                            <input class="form-control" ngxDaterangepickerMd [showCustomRangeLabel]="true"
                                [showDropdowns]="true" startKey="start" endKey="end" [ranges]="ranges"
                                [locale]="{applyLabel: 'ok', format: 'MM-DD-YYYY'}" [maxDate]="maxDate"
                                [(ngModel)]="dateRange" [alwaysShowCalendars]="true"
                                [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true"
                                (datesUpdated)="datesUpdated($event)" />
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-1 col-lg-1 pe-0">
                        <div class="mb-3 sm-logs-filter-group">
                            <label for="select-columns" matTooltip="Columns hide/show">Columns</label>
                            <mat-select [compareWith]="compareFn" class="form-control"
                                        panelClass="mat-select-autowidth-panel-class"
                                (selectionChange)="columnChange()" [(ngModel)]="selectedColumns" multiple>
                                <mat-option *ngFor="let item of columns" [value]="item">{{item.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3 text-end">
                        <div>
                            <div class="mb-3 account-filter-group item-per-page-option">
                                Display &nbsp;<mat-select [(ngModel)]="itemsPerPage"
                                    (selectionChange)="changeDisplayPerPageItem($event)">
                                    <mat-option *ngFor="let item of itemsPerPageOptions" [value]="item">{{item}}
                                    </mat-option>
                                </mat-select>&nbsp; sm log(s)
                            </div>
                            <p class="mb-0">
                                Showing {{fromShowing}} to {{toShowing}} of {{totalCount}} sm log(s)
                            </p>
                            <pagination-controls id="all-sm-logs" (pageChange)="getAllSMLogs($event)"
                                responsive="true" previousLabel="Previous" nextLabel="Next" maxSize="3">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="row filtered-by-box">
                    <div class="col-md-12">
                        <span class="filter-by-text">Filtered by: </span>
                        <span class="alert alert-success alert-dismissible
                            fade show date-range-filter ms-2">
                            <strong>Date Range:</strong> {{selectedDateRange.start}} - {{selectedDateRange.end}}
                        </span>
                        <span class="alert alert-success alert-dismissible
                            fade show category-filter ms-2" *ngIf="!selectedCategory.includes(0)">
                            <strong>Service type:</strong> ({{selectedCategory.join(', ')}})
                            <button type="button" class="btn filter-close-btn" aria-label="Close"
                                (click)="resetCategorySelection()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </span>
                        <span class="alert alert-success alert-dismissible
                            fade show type-filter ms-2" *ngIf="!selectedType.includes(0)">
                            <strong>Type:</strong> ({{selectedType.join(', ')}})
                            <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="resetTypeSelection()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </span>
                        <span class="alert alert-success alert-dismissible
                            fade show type-filter ms-2" *ngIf="searchString">
                            <strong>Global Search:</strong> ({{searchString}})
                            <button type="button" class="btn filter-close-btn" aria-label="Close" (click)="clearSearch()">
                                <span aria-hidden="true">×</span>
                            </button>
                        </span>
                    </div>
                    <div class="col-md-12 mt-2">
                        <span class="filter-by-text">Sort by: </span>
                        <span class="alert alert-success alert-dismissible
                            fade show payment-status-filter ms-2">
                            <strong>{{selectedSort.key}}:</strong> {{selectedSort.value === 1 ? 'Ascending':
                            'Descending'}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row sm-logs-result-container">
        <div class="ibox col-md-12 sm-logs-table p-0">
            <div class="ibox-title">
                <div class="sm-logs-list-box table-responsive">
                    <table class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover"
                        id="sm-logs-table">
                        <thead>
                            <tr>
                                <ng-container *ngFor="let column of columns; let i = index">
                                    <th *ngIf="checkColumnVisibility(column)" class="sort-icon table-sorting"
                                        [ngClass]="{'sorting-asc': column.sort === 1, 'sorting-desc' : column.sort == -1}"
                                        (click)="sortBy(column.label)" [ngStyle]="{'min-width': column.width}">
                                        {{column.label}}</th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                *ngFor="let smLog of smLogs | paginate: { id: 'all-sm-logs', itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalCount}">
                                <td *ngIf="checkColumnVisibility(columns[0])">
                                    <span *ngIf="smLog.createdDate">{{smLog.createdDate}}</span>
                                    <span *ngIf="!smLog.createdDate" class="not-available">Not
                                        Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[1])">
                                    <span *ngIf="smLog.serviceType">
                                        <span
                                            class="badge badge-{{smLog.serviceTypeClass}}-service-type">{{smLog.serviceType}}</span>
                                    </span>
                                    <span *ngIf="!smLog.serviceType" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[2])">
                                    <span *ngIf="smLog.type">
                                        <span class="badge badge-{{smLog.typeClass}}-type">{{smLog.type}}</span>
                                    </span>
                                    <span *ngIf="!smLog.type" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[3])">
                                    <span *ngIf="smLog.url">{{smLog.url}}</span><br>
                                    <span *ngIf="smLog.functionName">Service name: {{smLog.functionName}}</span>
                                    <span *ngIf="!smLog.url" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[4])">
                                    <span *ngIf="smLog.requestParameter">{{smLog.requestParameter | json}}</span>
                                    <span *ngIf="!smLog.requestParameter" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[5])">
                                    <span *ngIf="smLog.response">{{smLog.response}}</span>
                                    <span *ngIf="!smLog.response" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[6])">
                                    <span *ngIf="smLog.responseTime">{{smLog.responseTime}}</span>
                                    <span *ngIf="!smLog.responseTime" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[7])">
                                    <span *ngIf="smLog.browser">{{smLog.browser}}</span>
                                    <span *ngIf="!smLog.browser" class="not-available">Not Available</span>
                                </td>

                                <td *ngIf="checkColumnVisibility(columns[8])">
                                    <span *ngIf="smLog.os">{{smLog.os}}</span>
                                    <span *ngIf="!smLog.os" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[9])">
                                    <span *ngIf="smLog.device">{{smLog.device}}</span>
                                    <span *ngIf="!smLog.device" class="not-available">Not Available</span>
                                </td>
                                <td *ngIf="checkColumnVisibility(columns[10])">
                                    <span *ngIf="smLog.ipAddress">{{smLog.ipAddress}}</span>
                                    <span *ngIf="!smLog.ipAddress" class="not-available">Not
                                        Available</span>
                                </td>
                            </tr>
                            <tr *ngIf="!smLogs || smLogs.length === 0" class="text-center">
                                <td colspan="11">No sm log(s) available at this time</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
