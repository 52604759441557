<mat-sidenav-container class="sidebar-container">
  <mat-sidenav
    #sidenav
    mode="side"
    opened
    class="left-sidenav"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0"
    [ngStyle]="{'width': toggleSidebarBoolean? '200px': '50px'}"
  >
    <div class="mini-side-nav-bar" *ngIf="!toggleSidebarBoolean">
      <div class="user-image-content-box">
        <img class="user-image" src="{{loggedInUserProfilePic}}">
      </div>
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" [routerLink]="['/dashboard']"><i class="fa fa-th-large"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link"><i class="fa fa-address-card"></i></a>
          <ul id="accounts" class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/accounts/all']">All Accounts</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/accounts/register']">Register Test Account</a></li>
            <li class="nav-li-action"><a class="nav-a-action" (click)="openExtendTrialPopup()">Legacy Extend Trial</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/accounts/legacy_accounts']">Legacy Tenant List</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link"><i class="fa fa-users"></i></a>
          <ul id="accounts" class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/users/all']">All Users</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/users/subscription-owners']">All Subscription Owners</a></li>
            <li class="nav-li-action"><a class="nav-a-action" (click)="openExtendPlaygroundTrialPopup()">Extend Playground Trial</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link"><i class="fa fa-handshake-o"></i></a>
          <ul id="accounts" class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/partners/all']" routerLinkActive="router-link-active">All Accountant Partners</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/partner/white-label-partner/all']" routerLinkActive="router-link-active">All White label Partners</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/partner/reseller/all']" routerLinkActive="router-link-active">All Resellers</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/partner/developer/all']" routerLinkActive="router-link-active">All Developer Partners</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link"><i class="fa fa-pencil-square"></i></a>
          <ul id="accounts" class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/content-manager/login']" routerLinkActive="router-link-active" >Login Side HTML</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/content-manager/maintenance']">Maintenance HTML</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link"><i class="fa fa-bar-chart-o"></i></a>
          <ul id="accounts" class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/logs/email-logs']" routerLinkActive="router-link-active" >Email Logs</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/logs/event-logs']" routerLinkActive="router-link-active">Event Logs</a></li>
            <!-- <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/logs/service-manager-logs']" routerLinkActive="router-link-active">Service Manager Logs</a></li> -->
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/notifications']" routerLinkActive="router-link-active" ><i class="fa fa-bell"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/invitations']" routerLinkActive="router-link-active" ><i class="fa fa-envelope-o"></i></a>
        </li>
        <li class="nav-item" (click)="toggleSubMenu($event)">
          <a class="nav-link"><i class="fa fa-comments"></i>
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/messages']" routerLinkActive="router-link-active">All Messages</a></li>
            <li *ngIf="isUserAReviewer" class="nav-li-action"><a class="nav-a-action" [routerLink]="['/messages-review']" routerLinkActive="router-link-active">Pending Review</a></li>
          </ul>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['/messages']" routerLinkActive="router-link-active" ><i class="fa fa-comments"></i></a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/sync-engine']" routerLinkActive="router-link-active" ><i class="fa fa-refresh"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/zoho-sub-webhooks']" routerLinkActive="router-link-active" matTooltip="Zoho sub webhooks"><i class="fa fa-calendar"></i></a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/monitoring']" routerLinkActive="router-link-active"><i class="fa fa-television"></i></a>
        </li>

      </ul>
    </div>
    <div class="full-side-nav-bar" *ngIf="toggleSidebarBoolean">
      <div class="user-image-content-box">
        <img class="user-image" src="{{loggedInUserProfilePic}}"> {{loggedInUserName}}
      </div>
      <ul class="navbar-nav">
        <li class="nav-item" (click)="toggleSubMenu($event)">
          <a class="nav-link" [routerLink]="['/dashboard']"><i class="fa fa-th-large"></i> Dashboard</a>
        </li>
        <li class="nav-item" (click)="toggleSubMenu($event)">
          <a class="nav-link"><i class="fa fa-address-card"></i> Accounts
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/accounts/all']" routerLinkActive="router-link-active" >All Accounts</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/accounts/register']">Register Test Account</a></li>
            <li class="nav-li-action"><a class="nav-a-action" (click)="openExtendTrialPopup()">Legacy Extend Trial</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/accounts/legacy_accounts']">Legacy Tenant List</a></li>
          </ul>
        </li>
        <li class="nav-item" (click)="toggleSubMenu($event)">
          <a class="nav-link"><i class="fa fa-users"></i> Users
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/users/all']">All Users</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/users/subscription-owners']">All Subscription Owners</a></li>
            <li class="nav-li-action"><a class="nav-a-action" (click)="openExtendPlaygroundTrialPopup()">Extend Playground Trial</a></li>
          </ul>
        </li>
        <li class="nav-item" (click)="toggleSubMenu($event)">
          <a class="nav-link"><i class="fa fa-handshake-o"></i> Partners
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/partners/all']" routerLinkActive="router-link-active" >All Accountant Partners</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/partner/white-label-partner/all']">All White label Partners</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/partner/reseller/all']">All Resellers</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/partner/developer/all']">All Developer Partners</a></li>
          </ul>
        </li>
        <li class="nav-item" (click)="toggleSubMenu($event)">
          <a class="nav-link"><i class="fa fa-pencil-square"></i> Content
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/content-manager/login']" routerLinkActive="router-link-active" >Login Side HTML</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/content-manager/maintenance']" routerLinkActive="router-link-active" >Maintenance HTML</a></li>
          </ul>
        </li>
        <li class="nav-item" (click)="toggleSubMenu($event)">
          <a class="nav-link"><i class="fa fa-bar-chart-o"></i> Logs
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/logs/email-logs']" routerLinkActive="router-link-active">Email Logs</a></li>
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/logs/event-logs']" routerLinkActive="router-link-active">Event Logs</a></li>
            <!-- <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/logs/service-manager-logs']" routerLinkActive="router-link-active">Service Manager Logs</a></li> -->
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/notifications']" routerLinkActive="router-link-active" ><i class="fa fa-bell"></i> Notifications</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/invitations']" routerLinkActive="router-link-active" ><i class="fa fa-envelope-o"></i> Invitations</a>
        </li>
        <li class="nav-item" (click)="toggleSubMenu($event)">
          <a class="nav-link"><i class="fa fa-comments"></i> Inbox
            <span class="fa arrow"></span>
          </a>
          <ul class="nav nav-second-level">
            <li class="nav-li-action"><a class="nav-a-action" [routerLink]="['/messages']" routerLinkActive="router-link-active">All Messages</a></li>
            <li *ngIf="isUserAReviewer" class="nav-li-action"><a class="nav-a-action" [routerLink]="['/messages-review']" routerLinkActive="router-link-active">Pending Messages</a></li>
          </ul>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" [routerLink]="['/messages']" routerLinkActive="router-link-active" ><i class="fa fa-comments"></i> Inbox</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/sync-engine']" routerLinkActive="router-link-active"><i class="fa fa-refresh"></i> Sync Engine</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/zoho-sub-webhooks']" routerLinkActive="router-link-active"><i class="fa fa-calendar"></i> Zoho sub webhooks</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/monitoring']" routerLinkActive="router-link-active"><i class="fa fa-television"></i> Monitoring</a>
        </li>

      </ul>
    </div>
  </mat-sidenav>

  <mat-sidenav-content [ngStyle]="{'margin-left': toggleSidebarBoolean? '200px': '50px'}">
    <app-inner-header [toggleSideBarCall]="toggleSideBarCall"></app-inner-header>
    <router-outlet></router-outlet>
    <app-inner-footer></app-inner-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
