import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatIpAddress'
})
export class FormatIpAddressPipe implements PipeTransform {

  transform(value: string): string {
    if (value === '::1') {
      return 'localhost';
    }
    return (value || '').replace('::ffff:', '');
  }

}
