import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { AgGridBaseRendererComponent } from '../../../../helpers';

@Component({
  selector: 'app-cell-warning-renderer-component',
  styleUrls: ['./cell-warning.renderer.scss'],
  templateUrl: './cell-warning.renderer.html'
})
export class CellWarningRendererComponent extends AgGridBaseRendererComponent {

  public params: ICellRendererParams;
  public message = '';

  constructor() {
    super();
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.assignMessageFieldValue();
  }

  private assignMessageFieldValue(): void {
    const fieldName = this.params.colDef.field;
    switch (fieldName) {
      case 'smSoapStatus':
        this.message = this.params.data.smSoapStatusMessage;
        break;
      case 'smRestStatus':
        this.message = this.params.data.smRestStatusMessage;
        break;
      case 'dbStatus':
        this.message = this.params.data.dbMessage;
        break;
      case 'status':
        this.message = this.params.data.message;
        break;
      default:
        break;
    }
  }

}
