import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RateLimitResponse } from '@app/models/rate-limit-response';
import { CountdownService } from '@app/services/countdown.service';
import { FranchiseeService } from '@app/services/franchisee.service';
import { RateLimitService } from '@app/services/rate-limit.service';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Franchisee } from '@app/models/franchisee';
import { AuthenticationService } from '@app/services/authentication.service';
import { User } from '@app/models/user';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { PopupService } from '@app/services/popup.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  returnUrl: string;
  franchiseeCode: string;
  editableTemplate: string;
  warningOn: boolean;
  warningMessage: string;
  userEmail: string;
  userPassword: string;
  title: string;
  loginForm: UntypedFormGroup;
  submitted: boolean;
  loginWithFacebookOn: boolean;
  loginWithGoogleOn: boolean;
  loginWithLinkedInOn: boolean;
  getStartedHelpOn: boolean;
  getStartedHelpLink: string;

  googleClientId: string;
  googleLoginRedirectURL: string;

  facebookClientId: string;
  facebookLoginRedirectURL: string;

  linkedInClientId: string;
  linedInLoginRedirectURL: string;

  rateLimitResponse: RateLimitResponse;
  countDown: Subscription;
  counter = 900;
  tick = 1000;
  rateLimitExceeded = false;
  disabledAccounts: [];
  rememberMe: string;
  rememberEmail: string;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private franchiseeService: FranchiseeService,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private rateLimitService: RateLimitService,
    private countdownService: CountdownService,
    private logger: NGXLogger,
    private cookieService: CookieService,
    private popupService: PopupService,
    private authenticationService: AuthenticationService,
    private commonUtilityService: CommonUtilityService
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/dashboard']);
    }
  }

  ngOnInit(): void {
    this.franchiseeService.getFranchiseeDetails
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((franchiseDetails: Franchisee) => {
        this.logger.info('INFO: -> LoginComponent -> .subscribe -> franchiseDetails', franchiseDetails);
        this.franchiseeCode = franchiseDetails.franchiseeCode;
        this.editableTemplate = franchiseDetails.editableHTML;
        this.warningOn = franchiseDetails.warningOn;
        this.warningMessage = franchiseDetails.warningMessage;
        this.loginWithFacebookOn = franchiseDetails.loginWithFacebookOn;
        this.loginWithGoogleOn = franchiseDetails.loginWithGoogleOn;
        this.loginWithLinkedInOn = franchiseDetails.loginWithLinkedInOn;
        this.getStartedHelpOn = franchiseDetails.getStartedHelpOn;
        this.getStartedHelpLink = franchiseDetails.getStartedHelpLink;

        this.googleClientId = franchiseDetails.googleClientId;
        this.googleLoginRedirectURL = franchiseDetails.loginWithGoogle;

        this.linkedInClientId = franchiseDetails.linkedInClientId;
        this.linedInLoginRedirectURL = franchiseDetails.loginWithLinkedIn;

        this.facebookClientId = franchiseDetails.fbAppId;
        this.facebookLoginRedirectURL = franchiseDetails.loginWithFacebook;

        this.title = `Login | ${franchiseDetails.companyName}`;
        this.titleService.setTitle(this.title);
      });

    this.rateLimitService.getRateLimitResponse
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((rateLimitResponse: RateLimitResponse) => {
        this.logger.info('INFO: -> LoginComponent -> .subscribe -> rateLimitResponse', rateLimitResponse);
        this.rateLimitResponse = rateLimitResponse;
        if (this.rateLimitResponse.counter >= 5) {
          this.counter = this.rateLimitResponse.timer;
          this.logger.info('INFO: -> LoginComponent -> .subscribe -> this.counter', this.counter);
          // disable login button or start time count down
          this.rateLimitExceeded = true;
          this.countDown = this.countdownService
            .getCounter(this.tick)
            .pipe(
              takeUntil(this.componentDestroyed$))
            .subscribe(() => {
              if (this.counter === 0) {
                // enable login button or hide time count down
                this.rateLimitExceeded = false;
                this.countDown = null;
              } else {
                this.counter--;
              }
            });
        }
      });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/dashboard';

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberMe: ['']
    });

    this.rememberMe = this.cookieService.get('rememberMe');
    this.rememberEmail = this.cookieService.get('rememberEmail');
    if (this.rememberMe && this.rememberMe === 'true') {
      this.loginForm.patchValue({
        email: this.rememberEmail,
        password: '',
        rememberMe: true
      });
    }
  }

  get formControls() { return this.loginForm.controls; }

  onSubmit() {
    this.ngxService.start();
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.ngxService.stop();
      this.ngxService.stopBackground();
      return;
    }

    if (this.formControls.email.value &&
       this.formControls.password.value) {

        if (this.formControls.rememberMe.value) {
          this.cookieService.set('rememberMe', 'true', 2);
          this.cookieService.set('rememberEmail', this.formControls.email.value, 2);
        }

        this.authenticationService.login(
          this.formControls.email.value,
          this.formControls.password.value,
          this.franchiseeCode
        ).then((response: User) =>  {
          this.logger.info('INFO: -> LoginComponent -> onSubmit -> response', response);
          this.ngxService.stop();
          this.ngxService.stopBackground();
          if (response && response.isMFAEnabled) {
            this.router.navigate(['/']);
            this.popupService.openLoginMFAPopup(response);
          } else if (response && response.token) {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/error']);
          }
        }).catch(error => {
          this.logger.error('ERROR: -> LoginComponent -> onSubmit -> error', error);
          this.rateLimitService.getRateLimit().then((rateLimitResponse: RateLimitResponse) => {
            this.rateLimitService.changeRateLimit(rateLimitResponse);
          });
          this.commonUtilityService.displayErrorToastWithTechDetails(this.title, error.error);
        });
    } else {
      this.ngxService.stop();
      this.ngxService.stopBackground();
      this.toastr.error(this.title, 'Email & Password are required fields.');
    }
  }

  signUpWithGoogle() {
    this.ngxService.start();
    this.authenticationService.loginWithGoogle(this.googleClientId, this.googleLoginRedirectURL);
    this.ngxService.stop();
    this.ngxService.stopBackground();
  }

  signUpWithFacebook() {
    this.ngxService.start();
    this.authenticationService.signUpWithFacebook(this.facebookClientId, this.facebookLoginRedirectURL);
    this.ngxService.stop();
    this.ngxService.stopBackground();
  }

  signUpWithLinkedIn() {
    this.ngxService.start();
    this.authenticationService.loginWithLinkedIn(this.linkedInClientId, this.linedInLoginRedirectURL);
    this.ngxService.stop();
    this.ngxService.stopBackground();
  }

  openForgotPassword() {

  }

  ngOnDestroy() {
    this.countDown = null;
    this.componentDestroyed$.next();
  }

}
