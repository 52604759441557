import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { CommonUtilityService } from './common-utility.service';
import { ResellerService } from './reseller.service';

@Injectable({
  providedIn: 'root'
})
export class ResellerResolverService {
  id: string;
  constructor(
    private router: Router,
    private resellerService: ResellerService,
    private commonUtility: CommonUtilityService,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Promise<object> | Observable<never> {
    this.id = route.paramMap.get('id');
    return this.resellerService.getDetails(this.id).then((response: any) => response).catch((error: any) => {
      this.commonUtility.displayErrorToastWithTechDetails('Get Reseller Details', error?.error);
      this.router.navigate(['/partner/reseller/all']);
      return EMPTY;
    });
  }
}
