import { Component, OnDestroy, OnInit } from '@angular/core';
import { Franchisee } from '@app/models/franchisee';
import { FranchiseeService } from '@app/services/franchisee.service';
import { NGXLogger } from 'ngx-logger';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
  companyName: string;
  copyrightTerms: string;
  helpLineNumber: string;
  inquireNumber: string;
  linkedInURL: string;
  youtubeURL: string;
  twitterURL: string;
  facebookURL: string;
  copyrightTermsOn: boolean;
  helpLineNumberOn: boolean;
  inquireNumberOn: boolean;
  linkedInURLOn: boolean;
  youtubeURLOn: boolean;
  twitterURLOn: boolean;
  facebookURLOn: boolean;
  showLinks: boolean;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private franchiseeService: FranchiseeService,
    private logger: NGXLogger
  ) { }

  ngOnInit(): void {
    this.franchiseeService.getFranchiseeDetails
      .pipe(
        filter((franchiseDetails) => {
          this.logger.info('INFO: -> FooterComponent -> ngOnInit -> franchiseDetails', franchiseDetails);
          return !!franchiseDetails;
        }),
        takeUntil(this.componentDestroyed$))
      .subscribe((franchiseDetails: Franchisee) => {
        this.companyName = franchiseDetails.companyName;
        this.copyrightTermsOn = franchiseDetails.copyrightTermsOn;
        this.copyrightTerms = franchiseDetails.copyrightTerms;
        this.linkedInURLOn = franchiseDetails.linkedinLinkOn;
        this.linkedInURL = franchiseDetails.linkedInURL;
        this.youtubeURLOn = franchiseDetails.youtubeLinkOn;
        this.youtubeURL = franchiseDetails.youtubeURL;
        this.twitterURLOn = franchiseDetails.twitterLinkOn;
        this.twitterURL = franchiseDetails.twitterURL;
        this.facebookURLOn = franchiseDetails.facebookLinkOn;
        this.facebookURL = franchiseDetails.facebookURL;
        this.helpLineNumberOn = franchiseDetails.helplineNumberOn;
        this.helpLineNumber = franchiseDetails.helplineNumber;
        this.inquireNumberOn = franchiseDetails.inquireNumberOn;
        this.inquireNumber = franchiseDetails.inquireNumber;

        this.showLinks = this.facebookURLOn ||
        this.twitterURLOn ||
        this.youtubeURLOn ||
        this.linkedInURLOn ||
        this.inquireNumberOn ||
        this.helpLineNumberOn;
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }
}
