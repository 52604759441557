import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from '@app/models/user';
import { PopupService } from '@app/services/popup.service';
import { UserService } from '@app/services/user.service';
import { NGXLogger } from 'ngx-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  styleUrls: ['./side-nav-bar.component.css']
})
export class SideNavBarComponent implements OnInit, OnDestroy {
  toggleSidebarBoolean = false;
  loggedInUserName = '';
  loggedInUserProfilePic = '';
  loggedInUserEmail = '';
  isUserAReviewer = false;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private userService: UserService,
    private logger: NGXLogger,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.userService.getUser
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((userDetails: User) => {
        this.logger.info('INFO: -> SideNavBarComponent -> this.userService.getUser.subscribe -> userDetails', userDetails);
        this.loggedInUserName = userDetails.firstname + ' ' + userDetails.lastname;
        this.loggedInUserProfilePic = userDetails.profile_image;
        this.loggedInUserEmail = userDetails.email;
        this.userService.isReviewer(userDetails).then((data: boolean) => {
          this.isUserAReviewer = data;
        });
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  toggleSubMenu(event) {
    const target = event.target || event.srcElement || event.currentTarget;
    $(target).closest('li').toggleClass('active');
  }

  toggleSideBarCall = (): void => {
    this.toggleSidebarBoolean = !this.toggleSidebarBoolean;
  }

  openExtendTrialPopup() {
    this.popupService.openExtendTrialPopup('');
  }

  openExtendPlaygroundTrialPopup() {
    this.popupService.openExtendPlaygroundTrialPopup('');
  }

}
