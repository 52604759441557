import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '@app/services/authentication.service';
import { environment } from '@env/environment';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
const moment = require('moment');
import 'moment-timezone';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  cyrillicPattern = environment.CYRILLICPATTERN;
  constructor(
    private authenticationService: AuthenticationService,
    private toastr: ToastrService,
    private ngxLoader: NgxUiLoaderService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler):
  Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | HttpEvent<any> | any> {
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;
    const isApiUrl = request.url.startsWith(environment.CAT_URL);
    const isIntacsUrl = request.url.startsWith(environment.INTCBK_URL);
    if (isLoggedIn && (isApiUrl || isIntacsUrl)) {
      if (request.url.includes('update-user-profile-image') ||
      request.url.includes('upload-reseller-company-logo') ||
      request.url.includes('upload-reseller-signed-doc')) {
        request = request.clone({
          setHeaders: {
              Authorization: `Bearer ${currentUser.token}`,
              'time-zone': moment.tz.guess()
          },
        });
      } else {
        request = request.clone({
          setHeaders: {
              Authorization: `Bearer ${currentUser.token}`,
              'Content-Type': 'application/json',
              'time-zone': moment.tz.guess()
          },
        });
      }
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse) {
        switch ((error as HttpErrorResponse).status) {
          case 400:
            return this.handle400Error(request, error);
          case 401:
            return this.handle401Error();
          case 403:
            return this.handle403Error();
          case 404:
            return this.handle404Error(request, error);
          case 422:
            return this.handle422Error(request, error);
          case 429:
            return this.handle429Error(request, error);
          case 500:
          case 503:
            return this.handle500Error(request, error);
          default:
            this.handleUnknownError(request, error);
        }
      } else {
        return this.handleUnknownError(request, error);
      }
    }));
  }

    // bad Request
  public handle400Error(request: HttpRequest<any>, error: HttpErrorResponse) {
    this.ngxLoader.stop();
    this.ngxLoader.stopBackground();
    // this.toastr.error('Oops Something went wrong. Please try again latter.', 'Internal Server Error!');

    return throwError(error);
  }

  // unauthorized Request
  public handle401Error() {
    // this.toastr.error('Oops it looks like you have run out of your session. Please login again.', 'Session Timeout!');
    return this.authenticationService.logout() as any;
  }

  // forbidden Request
  public handle403Error() {
    // this.toastr.error('Oops it looks like you have run out of your session. Please login again.', 'Session Timeout!');
    return this.authenticationService.logout() as any;
  }

  // not Found
  public handle404Error(request: HttpRequest<any>, error: HttpErrorResponse) {
    this.ngxLoader.stop();
    this.ngxLoader.stopBackground();
    // this.toastr.error('Oops Something went wrong. Please try again latter.', 'Internal Server Error!');

    return throwError(error);
  }

  // validation Error
  public handle422Error(request: HttpRequest<any>, error: HttpErrorResponse) {
    this.ngxLoader.stop();
    this.ngxLoader.stopBackground();
    if (error.error.error) {
      error.error.errors.forEach(err => {
        if ( this.cyrillicPattern.test(err.msg)) {
          this.toastr.error('The application has encountered an unknown error. ' +
            'Our technical staff have been automatically notified and will be looking into this asap.', error.error.message);
        } else {
           this.toastr.error(err.msg, error.error.message);
        }
      });
    }
    return throwError(error);
  }

  public handle429Error(request: HttpRequest<any>, error: HttpErrorResponse) {
    this.ngxLoader.stop();
    this.ngxLoader.stopBackground();
    return throwError(error);
  }

  // internal Server Error
  public handle500Error(request: HttpRequest<any>, error: HttpErrorResponse) {
    this.ngxLoader.stop();
    this.ngxLoader.stopBackground();
    return throwError(error);
  }

  // unknown Server Error
  public handleUnknownError(request: HttpRequest<any>, error: HttpErrorResponse) {
    this.ngxLoader.stop();
    this.ngxLoader.stopBackground();
    // this.toastr.error('Oops Something went wrong. Please try again latter.', 'Internal Server Error!');

    return throwError(error);
  }

}
