import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

export class AgGridBaseRendererComponent implements ICellRendererAngularComp {

  public params: ICellRendererParams | any;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    const cellClass = this.getCellClass();
    if (cellClass) {
      this.params.eGridCell.classList.add(cellClass);
    }
  }

  public getCellClass(): string | null {
    return null;
  }

  public refresh(): boolean {
    return false;
  }
}
