import { Injectable } from '@angular/core';
export const acsTheme = {
  'primary-color': '#FECB00',
  'background-color': '#FFFFFF',
  'text-color': '#FECB00'
};
@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor() { }

  setTheme(theme: object) {
    Object.keys(theme).forEach(k =>
      document.documentElement.style.setProperty(`--${k}`, theme[k])
    );
  }
}
