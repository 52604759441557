import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { RockstarReviewService } from '@app/services/rockstar-review.service';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-rockstar-review',
  templateUrl: './rockstar-review.component.html',
  styleUrls: ['./rockstar-review.component.css']
})
export class RockstarReviewComponent implements OnInit {
  @Input() userEmail: string;

  pageHeading: string;
  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  reviews: [];
  fromShowing: any;
  toShowing: any;
  selectedColumns: any;
  selectedSort: any;
  searchString: string;
  columns = [
    {id: 1, name: 'status', label: 'Status', sort: 0, hide: 0},
    {id: 2, name: 'reviewerName', label: 'Reviewer Name', sort: 0, hide: 0},
    {id: 3, name: 'reviewerEmail', label: 'Reviewer Email', sort: 0, hide: 0},
    {id: 4, name: 'rating', label: 'Rating', sort: 0, hide: 0},
    {id: 6, name: 'reviewDate', label: 'Review Date', sort: -1, hide: 0},
    {id: 7, name: '', label: 'Action', sort: 0, hide: 0},
  ];
  viewMessage: boolean;
  selectedReview: any;
  constructor(
    private route: ActivatedRoute,
    private commonUtility: CommonUtilityService,
    private logger: NGXLogger,
    private rockstarReviewService: RockstarReviewService,
    private ngxService: NgxUiLoaderService
  ) {
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.searchString = '';
    this.selectedColumns = [
      {id: 1, name: 'status', label: 'Status', sort: 0, hide: 0},
      {id: 2, name: 'reviewerName', label: 'Reviewer Name', sort: 0, hide: 0},
      {id: 3, name: 'reviewerEmail', label: 'Reviewer Email', sort: 0, hide: 0},
      {id: 4, name: 'rating', label: 'Rating', sort: 0, hide: 0},
      {id: 6, name: 'reviewDate', label: 'Review Date', sort: -1, hide: 0},
      {id: 7, name: '', label: 'Action', sort: 0, hide: 0},
    ];
    this.selectedSort = {key: 'Review Date', value: -1};
    this.viewMessage = false;
    this.selectedReview = null;
  }

  ngOnInit(): void {
    if (this.userEmail) {
      this.getAllReviews(1);
    } else {
      this.commonUtility.displayErrorToast(this.pageHeading, 'User Email is missing.');
    }
  }

  getAllReviews(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      email: this.userEmail,
      searchText: this.searchString,
      selectedSort: this.selectedSort,
    };
    this.rockstarReviewService.getReviewsByEmail(requestParams).then((reviewsListResponse: any) => {
      this.reviews = reviewsListResponse.reviews;
      if (reviewsListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < reviewsListResponse.totalCount ?
        (pageNo + 1) * this.itemsPerPage :
        reviewsListResponse.totalCount);
      }
      this.totalCount = reviewsListResponse.totalCount;
    }).catch(error => {
      this.commonUtility.displayErrorToastWithTechDetails(this.pageHeading, error.error);
    });
  }

  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = {key: value.label, value: value.sort};
        } else {
          value.sort = 0;
        }
      });
      this.getAllReviews(this.pageNo);
    }
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllReviews(this.pageNo);
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  clearSearch() {
    this.searchString = '';
    this.getAllReviews(this.pageNo);
  }

  onChangeSearchText() {
    this.getAllReviews(this.pageNo);
  }

  columnChange() {

  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  dennyReview(reviewId: string) {
    this.ngxService.startLoader('rockstar-settings-loader');
    const title = 'Reject Review to List on Rockstar';
    this.rockstarReviewService.dennyReviewToListInRockstar(reviewId, this.userEmail).then((messageDetails: any) => {
      this.commonUtility.displaySuccessToast(title, messageDetails.message);
      this.getAllReviews(1);
      this.ngxService.stopLoader('rockstar-settings-loader');
    }).catch(error => {
      this.commonUtility.displayErrorToastWithTechDetails(title, error?.error);
      this.ngxService.stopLoader('rockstar-settings-loader');
    });
  }

  approveReview(reviewId: string) {
    this.ngxService.startLoader('rockstar-settings-loader');
    const title = 'Approve Review to List on Rockstar';
    this.rockstarReviewService.approveReviewToListInRockstar(reviewId, this.userEmail).then((messageDetails: any) => {
      this.logger.info('INFO: -> RockstarReviewComponent -> this.rockstarReviewService.approveReviewToListInRockstar -> messageDetails',
      messageDetails);
      this.commonUtility.displaySuccessToast(title, messageDetails.message);
      this.getAllReviews(1);
      this.ngxService.stopLoader('rockstar-settings-loader');
    }).catch(error => {
      this.logger.error('ERROR: -> RockstarReviewComponent -> this.rockstarReviewService.approveReviewToListInRockstar -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(title, error?.error);
      this.ngxService.stopLoader('rockstar-settings-loader');
    });
  }

  viewReview(review: any) {
    this.viewMessage = true;
    this.selectedReview = review;
  }

}
