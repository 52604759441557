import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  apiUrl = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient,
  ) { }

  getAllMessages(requestBody: object) {
    return this.httpClient.post<any>(this.apiUrl + '/get-all-messages', requestBody).toPromise();
  }

  sendMessage(requestBody: object) {
    return this.httpClient.post<any>(this.apiUrl + '/send-message', requestBody).toPromise();
  }

  saveMessage(requestBody: object) {
    return this.httpClient.post<any>(this.apiUrl + '/save-message', requestBody).toPromise();
  }

  updateMessage(requestBody: object) {
    console.log(requestBody);
    return this.httpClient.post<any>(this.apiUrl + '/update-message', requestBody).toPromise();
  }

  deleteMessage(requestBody: object) {
    return this.httpClient.post<any>(this.apiUrl + '/delete-message', requestBody).toPromise();
  }
}
