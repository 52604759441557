import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-email-log-status-info',
  templateUrl: './email-log-status-info.component.html',
  styleUrls: ['./email-log-status-info.component.css']
})
export class EmailLogStatusInfoComponent {

  constructor(
    public modal: NgbActiveModal
  ) { }

}
