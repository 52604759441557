import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-reseller-details',
  templateUrl: './reseller-details.component.html',
  styleUrls: ['./reseller-details.component.css']
})
export class ResellerDetailsComponent implements OnInit, OnDestroy {
  title: string;
  pageHeading: string;
  companyName: string;
  resellerDetails: any;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title
  ) {
    this.pageHeading = 'Reseller Details';
    this.route.data
      .pipe(
        filter((data) => !!data),
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.resellerDetails = data?.resellerDetails;
        this.pageHeading = data?.pageHeading;
    });
  }

  ngOnInit(): void {
    this.route.parent.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.companyName = data.franchisee.companyName;
        this.title = `${this.pageHeading} | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

}
