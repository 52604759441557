<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 partner-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>{{pageHeading}}</h5>
            </div>
        </div>
        <div class="ibox col-md-12 partner-box p-0">
            <div class="ibox-title">
                <div class="row">
                    <div class="col-md-12 partner-padding">
                        <form class="check-user-form"
                            [ngClass]="{'was-validated': (checkUserForm.email.dirty || checkUserForm.email.touched)}"
                            [formGroup]="emailCheckForm" (ngSubmit)="checkEmail()" novalidate>
                            <div class="mt-2">
                                <label for="email">Email address <span class="required">*</span></label>
                            </div>
                            <div class="mb-3 ps-2">
                                <input type="email" name="email" class="form-control" formControlName="email" id="email"
                                    placeholder="name@example.com">
                                <div *ngIf="checkUserForm.email.invalid && (checkUserForm.email.dirty || checkUserForm.email.touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="checkUserForm.email.errors.required">Email address is required.</div>
                                    <div *ngIf="checkUserForm.email.errors.email">Please enter a valid email address.
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 ps-2">
                                <button type="submit" class="btn btn-primary"
                                    [disabled]="!emailCheckForm.valid">OK</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row white-bg mt-2 partner-section-box" *ngIf="submitted && status !== 'NewUser'">
        <div class="ibox col-md-12">
            <div class="ibox-title text-center">
                <h5>Developer Settings for {{checkUserForm.email.value}}</h5>
            </div>
        </div>
        <div class="ibox col-md-12 partner-section-result">
            <div>
                <app-developer-settings [(userEmail)]="userEmail"></app-developer-settings>
            </div>
        </div>
    </div>
    <div class="row white-bg mt-2 partner-section-box" *ngIf="submitted && status === 'NewUser'">
        <div class="ibox col-md-12">
            <div class="ibox-title text-center" [innerHtml]="newUserMessage">

            </div>
        </div>
    </div>
</div>
