import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class SyncEngineService {
  apiUrl = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient,
  ) { }

  getAllProcesses(requestBody: object): Promise<any> {
    return this.httpClient.post<any>(this.apiUrl + '/get-all-processes', requestBody).toPromise();
  }
}
