import { HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';
import { ValidationErrors, AsyncValidator, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
    selector: '[appResellerWidgetIdUniquenessCheck]',
    providers: [
        {provide: NG_ASYNC_VALIDATORS, useExisting: ResellerWidgetIdUniquenessCheckDirective, multi: true}
    ]
})
export class ResellerWidgetIdUniquenessCheckDirective implements AsyncValidator {
    api: string = location.origin;
    constructor(
        private http: HttpClient
    ) {
        if (this.api.includes('localhost') ||
            this.api.includes('127.0.0.1')) {
            this.api = environment.CAT_URL;
        }
    }

    validate(control): Observable<ValidationErrors | null> {
        const id = control?._parent?.value?.id;
        const code = control?._parent?.value?.code;
        console.log(control?._parent);
        let url = `${this.api}/check-widget-id-uniqueness?widgetId=${control.value}`;
        if(id) {
            url += `&id=${id}`;
        }
        if(code) {
            url += `&code=${code}`;
        }
        return this.http.get<boolean>(url)
            .pipe(
                map((isUsed) =>
                    // null no error, object for error
                     isUsed ? null : {
                        widgetIdValidator: 'WidgetId already used.'
                    }
                )
            );
    }
}

