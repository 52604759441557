<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Events</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="mb-3">
        <p>Events are generated when email is processed by SendGrid and email service providers.</p>
        <h5>Delivery events <a href="https://docs.sendgrid.com/for-developers/tracking-events/event#delivery-events" target="_blank"><i class="fa fa-info-circle"></i></a></h5>
        <p>Delivery events indicate the status of email delivery to the recipient.</p>
        <table class="responsive table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Event</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span class="dot-lg dot-processed-event"></span>Processed</td>
                    <td>Message has been received and is ready to be delivered.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-dropped-event"></span>Dropped</td>
                    <td>SendGrid will drop an email when the contact on that email is in one of your suppression groups, the recipient email previously bounced, or that recipient has marked your email as spam.                      You may see the following drop reasons: Invalid SMTPAPI header, Spam Content (if Spam Checker app is enabled), Unsubscribed Address, Bounced Address, Spam Reporting Address, Invalid, Recipient List over Package Quota</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-delivered-event"></span>Delivered</td>
                    <td>Message has been successfully delivered to the receiving server.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-deferred-event"></span>Deferred</td>
                    <td>Receiving server temporarily rejected the message.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-bounce-event"></span>Bounce</td>
                    <td>The receiving server could not or would not accept the message. If a recipient has previously unsubscribed from your emails, your attempt to send to them is bounced.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-blocked-event"></span>Blocked</td>
                    <td>When your IP address has been blocked by an ISP or messaging organization. Blocks are less severe than bounces and do not result in permanent suppressions: subsequent sends to blocked email addresses are not automatically suppressed.</td>
                </tr>
            </tbody>
        </table>

        <h5>Engagement events <a href="https://docs.sendgrid.com/for-developers/tracking-events/event#engagement-events" target="_blank"><i class="fa fa-info-circle"></i></a></h5>
        <p>Engagement events indicate how the recipient is interacting with the email.</p>
        <table class="responsive table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Event</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span class="dot-lg dot-open-event"></span>Open</td>
                    <td>The response generated by a recipient opening an email.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-click-event"></span>Click</td>
                    <td>Whenever a recipient clicks one of the Click Tracked links in your email. In the Email History,
                        SendGrid displays the date, time, and the URL for the link that was clicked.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-spam-report-event"></span>Spam Report</td>
                    <td>Whenever a recipient marks your email as spam and their mail server tells us about it.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-unsubscribe-event"></span>Unsubscribe</td>
                    <td>Whenever a recipient unsubscribes from your emails.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-group-unsubscribe-event"></span>Group Unsubscribe</td>
                    <td>Whenever a recipient unsubscribes from a suppression group.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-group-resubscribe-event"></span>Group Resubscribe</td>
                    <td>When a recipient resubscribes themselves to a suppression group.</td>
                </tr>

            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancel</button>
</div>
