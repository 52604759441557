import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { QRCodeResponse } from '@app/models/qrcode-response';
import { User } from '@app/models/user';

@Injectable({
  providedIn: 'root'
})
export class MultiFactorService {
  api: string = location.origin;
  constructor(
    private httpClient: HttpClient
  ) {
    if (this.api.includes('localhost') ||
    this.api.includes('127.0.0.1')) {
      this.api = environment.CAT_URL;
    }
  }

  generateQRCode() {
    return this.httpClient.get<QRCodeResponse>(this.api + '/generateTwoStepAuthQRCode').toPromise();
  }

  validateSecurityCode(secretKey: string, token: string) {
    return this.httpClient.post(this.api + '/verifyTwoStepAuthCode', {secretKey, token}).toPromise();
  }

  addRecoveryEmail(recoveryEmail: string) {
    return this.httpClient.post(this.api + '/saveTwoStepAuthRecoveryEmail', {recoveryEmailAddress: recoveryEmail}).toPromise();
  }

  verifyRecoveryEmail(verificationCode: string) {
    return this.httpClient.post(this.api + '/verifyTwoStepAuthRecoverEmail', { verificationCode }).toPromise();
  }

  disableMFAWithRecoveryEmail(verificationCode: string) {
    return this.httpClient.post(this.api + '/disableTwoStepAuthWithRecoveryEmail', { verificationCode }).toPromise();
  }

  disableMFAWithSecurityCode(securityCode: string) {
    return this.httpClient.post(this.api + '/disableTwoStepAuthWithSecurityCode', { securityCode }).toPromise();
  }

  sendVerificationCodeEmail() {
    return this.httpClient.get(this.api + '/sendVerificationCodeForDisableTwoStepAuthOnRecoveryEmail').toPromise();
  }

  sendSecurityCodeEmail(user: User, franchiseeCode: string) {
    return this.httpClient.post(this.api + '/sendVerificationCodeToRecoveryEmailForAuthentication', {
      email: user.email,
      franchiseeCode
    }).toPromise();
  }

  authenticateWithRecoveryEmail(email: string, franchiseeCode: string, verificationCode: string) {
    return this.httpClient.post(this.api + '/authenticateWithRecoveryEmail', { email, franchiseeCode, verificationCode }).toPromise();
  }

  authenticateWithSecurityCode(email: string, franchiseeCode: string, securityCode: string) {
    return this.httpClient.post(this.api + '/checkTwoStepAuthCodeIsValid', { email, franchiseeCode, securityCode }).toPromise();
  }
}
