import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  apiUrl = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient,
  ) { }

  getAllAccounts(requestBody: object) {
    return this.httpClient.post<any>(this.apiUrl + '/get_all_companies', requestBody).toPromise();
  }

  getAllAccountsForExport(requestBody: object) {
    return this.httpClient.post<any>(this.apiUrl + '/get_all_companies_for_export', requestBody).toPromise();
  }

  registerAccount(requestBody: object) {
    return this.httpClient.post<any>(this.apiUrl + '/register-account', requestBody).toPromise();
  }

  setNewTrialDate(requestBody: object) {
    return this.httpClient.post<any>(this.apiUrl + '/set-new-trial-date', requestBody).toPromise();
  }

  getAccountDetails(accountNumber: string) {
    return this.httpClient.post<any>(this.apiUrl + '/get-account-details', {accountNumber}).toPromise();
  }

  getTransactionsCount(requestParameters: object): Observable<any> {
    return this.httpClient.post<any>(this.apiUrl + '/get-account-transaction-count', requestParameters);
  }

  getAllLegacyAccounts(requestParameters: object) {
    return this.httpClient.post<any>(this.apiUrl + '/get-legacy-accounts', requestParameters).toPromise();
  }

  getAccountsByEmail(requestParameters: object) {
    return this.httpClient.post<any>(this.apiUrl + '/get-accounts-list-by-email', requestParameters).toPromise();
  }

  getAccountsByFranchisee(requestParameters: object) {
    return this.httpClient.post<any>(this.apiUrl + '/get-accounts-list-by-franchisee', requestParameters).toPromise();
  }

  changeAccountType(requestParameters: object) {
    return this.httpClient.post<any>(this.apiUrl + '/change-account-type', requestParameters).toPromise();
  }
}
