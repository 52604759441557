import { Address } from './address';
import { FacebookKey } from './facebook-key';
import { GoogleKey } from './google-key';
import { LinkedInKey } from './linked-in-key';
import { RbgColor } from './rbg-color';

export class WhiteLabelPartner {
    code: string;
    companyName: string;
    adminEmail: string;
    companyURL?: string;
    contactNumber?: string;
    contactNumberExt?: string;
    workingHours?: string;
    inquireNumber?: string;
    helpLineNumber?: string;
    supportEmail?: string;
    helpEmail?: string;
    physicalAddress?: Address;
    loginWithFacebook: boolean;
    loginWithLinkedIn: boolean;
    loginWithGoogle: boolean;
    linkedInKey?: LinkedInKey;
    facebookKey?: FacebookKey;
    googleKey?: GoogleKey;
    getStartedHelpLink?: string;
    showGetStartedHelpLink: boolean;
    showNotificationMenu: boolean;
    showInboxMenu: boolean;
    showZohoAccount: boolean;
    showHelpLink: boolean;
    showContactUsLink: boolean;
    showMultiEntityReport: boolean;
    showAddNewClient: boolean;
    showPlayground: boolean;
    showPersonalDetails: boolean;
    showCompanyDetails: boolean;
    showMFASetup: boolean;
    contactUsLink?: string;
    samlCert?: string;
    samlUrl?: string;
    samlKey?: string;
    zohoSubCustomerPortalLink?: string;
    zohoHelpDeskKey?: string;
    zohoHelpDeskURL?: string;
    primaryColor?: RbgColor;
    textColor?: RbgColor;
    backgroundColor?: RbgColor;
    primaryButtonBackgroundColor?: RbgColor;
    primaryButtonTextColor?: RbgColor;
    primaryButtonHoverBackgroundColor?: RbgColor;
    primaryButtonHoverTextColor?: RbgColor;
    secondaryButtonBackgroundColor?: RbgColor;
    secondaryButtonTextColor?: RbgColor;
    secondaryButtonHoverBackgroundColor?: RbgColor;
    secondaryButtonHoverTextColor?: RbgColor;
    loginButtonBackgroundColor?: RbgColor;
    loginButtonTextColor?: RbgColor;
    loginButtonHoverBackgroundColor?: RbgColor;
    loginButtonHoverTextColor?: RbgColor;

    header?: string;
    footer?: string;
    login?: string;
    warningMessage?: string;
    warningOn?: boolean;
    domainURLs?: string;
    accountantDashboardURL?: string;
    clientDashboardURL?: string;
    intacsProjectURL?: string;
    payProjectURL?: string;
}
