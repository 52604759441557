import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BackButtonService } from '@app/services/back-button.service';
import { CommonUtilityService } from '@app/services/common-utility.service';
import { ExportService } from '@app/services/export.service';
import { PartnerService } from '@app/services/partner.service';
import { NGXLogger } from 'ngx-logger';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-all-partners',
  templateUrl: './all-partners.component.html',
  styleUrls: ['./all-partners.component.css']
})
export class AllPartnersComponent implements OnInit, OnDestroy {
  title: string;
  cookieKey: string;
  pageHeading: string;
  viewType: string;
  companyName: string;
  searchString: string;
  selectedAccountantStatus: any;
  selectedUserType: any;
  selectedColumns: any;
  selectedSort: any;

  totalCount: number;
  itemsPerPage: number;
  pageNo: number;
  currentPage: number;
  startingPoint: number;
  itemsPerPageOptions = [10, 25, 50, 100];
  partners: [];
  fromShowing: any;
  toShowing: any;

  accountantStatusOptions = [
    { id: 1, name: 'Verified', value: 'Verified', class: 'dot-partner-status-verified' },
    { id: 2, name: 'Pending', value: 'Pending', class: 'dot-partner-status-pending' },
    { id: 3, name: 'Rejected', value: 'Rejected', class: 'dot-partner-status-rejected' },
  ];
  userTypeOptions = [
    { id: 1, name: 'Customers - Default', label: 'Customers - Default', class: 'dot-customers---default' },
    { id: 2, name: 'Customers - Default 2024', label: 'Customers - Default 2024', class: 'dot-customers---default-2024' },
    { id: 3, name: 'Customers - Early Adopters', label: 'Customers - Early Adopters', class: 'dot-customers---early-adopters' },
    { id: 4, name: 'Partners - Fan Club', label: 'Partners - Fan Club', class: 'dot-partners---fan-club' },
    { id: 5, name: 'Partners - Verified 2024', label: 'Partners - Verified 2024', class: 'dot-partners---verified-2024' },
    { id: 6, name: 'Partners - Verified', label: 'Partners - Verified', class: 'dot-partners---verified' }
    // { id: 7, name: 'Partner - Reseller', label: 'Partner - Reseller', class: 'dot-partner---reseller' }
  ];
  columns = [
    { id: 1, key: 'email', name: 'email', label: 'Accountant Email', sort: 0, hide: 0 },
    { id: 2, key: 'name', name: 'name', label: 'Accountant Name', sort: 0, hide: 0 },
    { id: 3, key: 'phoneNumber', name: 'phoneNumber', label: 'Phone Number', sort: 0, hide: 0 },
    { id: 4, key: 'userType', name: 'userType', label: 'Pricing Level', sort: 0, hide: 0 },
    { id: 5, key: 'externalAccountantStatus', name: 'externalAccountantStatus', label: 'External Accountant Status', sort: 0, hide: 0 },
    { id: 6, key: 'multiFactorStatus', name: 'multiFactorStatus', label: 'Multi-Factor Status', sort: 0, hide: 0 },
    { id: 7, key: 'playgroundCreatedDate', name: 'playgroundCreatedDate', label: 'Playground Created Date', sort: 0, hide: 0 },
    { id: 8, key: 'creationDate', name: 'creationDate', label: 'Creation Date', sort: -1, hide: 0 }
  ];
  @ViewChild('allSelectedAccountantType') private allSelectedAccountantType: MatOption;
  @ViewChild('allSelectedUserType') private allSelectedUserType: MatOption;
  private componentDestroyed$: Subject<void> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private partnerService: PartnerService,
    private commonUtility: CommonUtilityService,
    private exportService: ExportService,
    private logger: NGXLogger,
    private ngxService: NgxUiLoaderService,
    private backButtonService: BackButtonService,
    private location: Location
  ) {
    this.pageHeading = 'All Accountant Partners';
    this.viewType = 'LIST';
    this.cookieKey = 'cf_all_accountant_partners';

    // persist filters while click on back/refresh
    const filters: any = this.backButtonService.getFilters(this.cookieKey);
    if (filters) {
      this.setAppliedFilters(filters);
    } else {
      this.setDefaultFilters();
    }

    this.route.queryParams
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe(params => {
        if (params && params.search) {
          this.setDefaultFilters();
          this.searchString = params.search;
          this.getAllPartners(1);
          this.location.replaceState('/partners/all');
        }
      });
  }

  ngOnInit(): void {
    this.route.parent.parent.data
      .pipe(
        takeUntil(this.componentDestroyed$))
      .subscribe((data) => {
        this.companyName = data.franchisee.companyName;
        this.title = `${this.pageHeading} | ${data.franchisee.companyName}`;
        this.titleService.setTitle(this.title);
      });
    this.getAllPartners(this.pageNo);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
  }

  changeViewType(viewType) {
    this.viewType = viewType;
  }

  clearSearch() {
    this.searchString = '';
    this.getAllPartners(this.pageNo);
  }

  onChangeSearchText() {
    this.getAllPartners(this.pageNo);
  }

  changeDisplayPerPageItem(event) {
    this.itemsPerPage = event.value;
    this.getAllPartners(this.pageNo);
  }

  sortBy(columnName) {
    if (columnName) {
      this.columns.forEach((value) => {
        if (value.label === columnName) {
          value.sort = (value.sort === 0) ? 1 : (value.sort === 1) ? -1 : (value.sort === -1) ? 1 : 0;
          this.selectedSort = { key: value.label, value: value.sort };
        } else {
          value.sort = 0;
        }
      });
      this.getAllPartners(this.pageNo);
    }
  }

  compareFn(user1, user2) {
    return user1 && user2 ? user1.id === user2.id : user1 === user2;
  }

  checkColumnVisibility(column) {
    return this.selectedColumns.find(element => column.id === element.id);
  }

  changeUserType() {
    this.getAllPartners(this.pageNo);
  }

  toggleAllUserType() {
    if (this.allSelectedUserType.selected) {
      this.selectedUserType = [...this.userTypeOptions.map(item => item.name), 0];
    } else {
      this.selectedUserType = [];
    }
    this.changeUserType();
  }

  userTypeTouchPerOne() {
    if (this.allSelectedUserType.selected) {
      this.allSelectedUserType.deselect();
    }
    if (this.selectedUserType.length === this.userTypeOptions.length) {
      this.allSelectedUserType.select();
    }
    this.changeUserType();
  }

  resetUserTypeSelection() {
    this.allSelectedUserType.select();
    this.selectedUserType = [...this.userTypeOptions.map(item => item.name), 0];
    this.changeUserType();
  }

  changeAccountantStatusFilter() {
    this.getAllPartners(this.pageNo);
  }

  toggleAllAccountantType() {
    if (this.allSelectedAccountantType.selected) {
      this.selectedAccountantStatus = [...this.accountantStatusOptions.map(item => item.name), 0];
    } else {
      this.selectedAccountantStatus = [];
    }
    this.changeAccountantStatusFilter();
  }

  accountantTypeTouchPerOne() {
    if (this.allSelectedAccountantType.selected) {
      this.allSelectedAccountantType.deselect();
    }
    if (this.selectedAccountantStatus.length === this.accountantStatusOptions.length) {
      this.allSelectedAccountantType.select();
    }
    this.changeAccountantStatusFilter();
  }

  resetAccountantTypeSelection() {
    this.allSelectedAccountantType.select();
    this.selectedAccountantStatus = [...this.userTypeOptions.map(item => item.name), 0];
    this.changeAccountantStatusFilter();
  }

  getAllPartners(pageNo: number) {
    this.currentPage = pageNo;
    this.startingPoint = this.currentPage * this.itemsPerPage - this.itemsPerPage;

    const requestParams = {
      currentPage: pageNo,
      selectedColumns: this.selectedColumns,
      itemsPerPage: this.itemsPerPage,
      startingPoint: this.startingPoint,
      searchText: this.searchString,
      selectedSort: this.selectedSort,
      selectedUserType: this.selectedUserType,
      selectedAccountantStatus: this.selectedAccountantStatus
    };
    this.backButtonService.setFilters(this.cookieKey, requestParams);
    this.partnerService.getAllPartners(requestParams).then((partnersListResponse: any) => {
      this.logger.info('INFO: -> AllPartnersComponent -> this.partnerService.getAllPartners -> partnersListResponse', partnersListResponse);
      this.commonUtility.displaySuccessToast(this.pageHeading, 'Accountant Partners List refreshed.');
      this.partners = partnersListResponse.users;
      if (partnersListResponse.totalCount === 0) {
        this.fromShowing = 0;
        this.toShowing = 0;
      } else {
        pageNo = pageNo - 1;
        this.fromShowing = (pageNo * this.itemsPerPage) + 1;
        this.toShowing = ((pageNo + 1) * this.itemsPerPage < partnersListResponse.totalCount ?
          (pageNo + 1) * this.itemsPerPage :
          partnersListResponse.totalCount);
      }
      this.totalCount = partnersListResponse.totalCount;
    }).catch(error => {
      this.logger.error('ERROR: -> AllPartnersComponent -> this.partnerService.getAllPartners -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error.error);
    });
  }

  exportAction(option) {
    this.ngxService.start();
    const fileName = 'all_accountant_partners';
    const requestParams = {
      searchText: this.searchString,
      selectedSort: this.selectedSort,
      selectedUserType: this.selectedUserType,
      selectedAccountantStatus: this.selectedAccountantStatus
    };
    this.partnerService.getAllPartnersForExport(requestParams).then((partnersListResponse: any) => {
      const partnersList = partnersListResponse.users;
      const exportData = [];
      partnersList.forEach(item => {
        const tempJson = {};
        this.selectedColumns.forEach(column => {
          if (typeof item[column.key] === 'object') {
            tempJson[column.label] = item[column.key][column.key1] &&
              item[column.key][column.key1] !== 'Not Available' ? item[column.key][column.key1] : '';
          } else {
            tempJson[column.label] = item[column.key] && item[column.key] !== 'Not Available' ? item[column.key] : '';
          }
        });
        exportData.push(tempJson);
      });
      if (option === 'excel') {
        this.exportService.exportAsExcelFile(exportData, fileName);
      } else if (option === 'csv') {
        this.exportService.exportToCsvFile(exportData, fileName);
      } else if (option === 'doc') {
        this.exportService.exportToDocFile(exportData, fileName, this.pageHeading, this.companyName);
      }
      this.ngxService.stop();
      this.ngxService.stopBackground();
    }).catch(error => {
      this.logger.error('ERROR: -> AllPartnersComponent -> this.partnerService.getAllPartners -> error', error);
      this.commonUtility.displayErrorToastWithTechDetails(this.title, error?.error);
      this.ngxService.stop();
      this.ngxService.stopBackground();
    });
  }

  refreshData() {
    this.getAllPartners(this.currentPage);
  }

  setDefaultFilters() {
    this.searchString = '';
    this.totalCount = 0;
    this.itemsPerPage = 10;
    this.pageNo = 1;
    this.selectedSort = { key: 'Creation Date', value: -1 };
    this.selectedUserType = [...this.userTypeOptions.map(item => item.name), 0];
    this.selectedAccountantStatus = [...this.accountantStatusOptions.map(item => item.name), 0];
    this.selectedColumns = [
      { id: 1, key: 'email', name: 'email', label: 'Accountant Email', sort: 0, hide: 0 },
      { id: 2, key: 'name', name: 'name', label: 'Accountant Name', sort: 0, hide: 0 },
      { id: 3, key: 'phoneNumber', name: 'phoneNumber', label: 'Phone Number', sort: 0, hide: 0 },
      { id: 4, key: 'userType', name: 'userType', label: 'Pricing Level', sort: 0, hide: 0 },
      { id: 5, key: 'externalAccountantStatus', name: 'externalAccountantStatus', label: 'External Accountant Status', sort: 0, hide: 0 },
      { id: 6, key: 'multiFactorStatus', name: 'multiFactorStatus', label: 'Multi-Factor Status', sort: 0, hide: 0 },
      { id: 7, key: 'playgroundCreatedDate', name: 'playgroundCreatedDate', label: 'Playground Created Date', sort: 0, hide: 0 },
      { id: 8, key: 'creationDate', name: 'creationDate', label: 'Creation Date', sort: -1, hide: 0 }
    ];
  }

  setAppliedFilters(filtersValue) {
    this.totalCount = 0;
    this.itemsPerPage = filtersValue?.itemsPerPage;
    this.pageNo = filtersValue?.currentPage;
    this.searchString = filtersValue?.searchText;
    this.selectedColumns = filtersValue?.selectedColumns;
    this.selectedSort = filtersValue?.selectedSort;
    this.selectedUserType = filtersValue?.selectedUserType;
    this.selectedAccountantStatus = filtersValue?.selectedAccountantStatus;
  }

  resetFilter() {
    this.backButtonService.removeFilters(this.cookieKey);
    this.setDefaultFilters();
    this.getAllPartners(1);
  }

}
