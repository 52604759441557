<form [formGroup]="changePricingForm" (ngSubmit)="changePricingLevel()" novalidate>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">{{pageHeading}}</h4>
        <button type="button" class="close" aria-describedby="modal-title" (click)="cancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="userEmail">User Email <span class="required">*</span></label>
                    <input type="text" name="userEmail" class="form-control" formControlName="userEmail"
                        id="userEmail" placeholder="User Email" readonly>
                </div>
                <div *ngIf="changePricingFormControl.userEmail.invalid && (changePricingFormControl.userEmail.dirty || changePricingFormControl.userEmail.touched)"
                    class="invalid-feedback">
                    <div *ngIf="changePricingFormControl.userEmail.errors.required">User Email is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="pricingLevel">Pricing Level <span class="required">*</span></label>
                    <select class="form-control" formControlName="pricingLevel">
                        <option *ngFor="let item of userTypeOptions" [value]="item.name">{{item.label}} <span class="{{item.class}}"></span></option>
                    </select>
                </div>
                <div *ngIf="changePricingFormControl.pricingLevel.invalid && (changePricingFormControl.pricingLevel.dirty || changePricingFormControl.pricingLevel.touched)"
                    class="invalid-feedback">
                    <div *ngIf="changePricingFormControl.pricingLevel.errors.required">Pricing Level is required.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer btn-group">
        <button type="submit" class="btn btn-primary max-with-btn" [disabled]="!changePricingForm.valid">Change</button>
        <button type="button" class="btn btn-default max-with-btn ms-2" (click)="cancel()">Cancel</button>
    </div>
</form>
