<div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-md-12">
        <h2>{{pageHeading}}</h2>
        <ol class="breadcrumb">
            <li>
                <a routerLink="/dashboard" class="text-underline-hover">Dashboard</a>
            </li>
            <li class="active">
                {{pageHeading}}
            </li>
        </ol>
    </div>
</div>
<div class="col-md-12 account-container">
    <div class="row white-bg">
        <div class="ibox col-md-12">
            <div class="ibox-title">
                <h5>
                    <span *ngIf="accountDetails.companyName">{{accountDetails?.companyName}}</span>
                    <span *ngIf="!accountDetails.companyName" class="not-available">Not Available</span>
                    <br><small>{{accountDetails.accountNumber}}</small>
                </h5>
            </div>
        </div>
    </div>
    <div class="row account-details-container mt-3">
        <div class="col-xs-6 col-sm-6 col-md-8 col-lg-8 ps-0">
            <mat-accordion multi>
                <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>User List</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="content-box">
                        <div class="p-3">
                            <table datatable [dtOptions]="dtOptions"
                                class="responsive table table-striped table-bordered dataTable no-footer dtr-inline hover">
                                <thead>
                                    <tr>
                                        <td>User Email</td>
                                        <td>Verified Email</td>
                                        <td></td>
                                        <td>Added Date</td>
                                        <td>Employee</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of accountDetails.userList">
                                        <td><i class="fa" [matTooltip]="user.disabledUser ? 'Disabled' : 'Active'"
                                                [ngClass]="{'fa-ban disabled-user': user.disabledUser, 'fa-check active-user': !user.disabledUser}"></i>
                                            {{user.email}}</td>
                                        <td>{{user.verifiedEmail}}</td>
                                        <td>
                                            <i *ngIf="user.administrator" class="fa fa-user-circle-o administrator-icon"
                                                matTooltip="Administrator"></i>
                                            <i *ngIf="user.isSubscriptionOwner" class="fa fa-usd subscription-icon ps-1"
                                                matTooltip="Subscription Owner"></i>
                                        </td>
                                        <td>{{user.addedDate}}</td>
                                        <td class="cloudPayrollEmployee">
                                            <i *ngIf="user.isCloudPayrollEmployee" class="fa fa-check-circle"></i>
                                            <i *ngIf="!user.isCloudPayrollEmployee" class="fa fa-times-circle-o"></i>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>Transactions Count</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="content-box transactions-count">
                        <div class="ibox-content">
                            <div class="col-md-12 pt-2 pb-2">
                                <div class="row">
                                    <div class="col-md-2">
                                        <label>Date Range <span class="required">*</span></label>
                                    </div>
                                    <div class="col-md-6">
                                        <input class="form-control" ngxDaterangepickerMd [showCustomRangeLabel]="true"
                                            [showDropdowns]="true" startKey="start" endKey="end" [ranges]="ranges"
                                            [locale]="{applyLabel: 'Plot Graph', format: 'MM-DD-YYYY'}" [maxDate]="maxDate"
                                            [(ngModel)]="dateRange" [alwaysShowCalendars]="true"
                                            [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true"
                                            (datesUpdated)="datesUpdated($event)" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12" *ngIf="showTransactionGraphError">
                                        <div class="required text-center p-3">
                                            No data for selected period
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                      <canvas baseChart
                                              width="400"
                                              height="400"
                                              [data]="lineChartData"
                                              [options]="lineChartOptions"
                                              [legend]="true"
                                              [type]="'line'">
                                      </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="col-xs-4 col-sm-6 col-md-4 col-lg-4 p-0">
            <mat-accordion multi>
                <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h5>Account Details</h5>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="ibox-content">
                        <div id="vertical-timeline" class="vertical-container dark-timeline">
                            <div class="vertical-timeline-block">
                                <div class="vertical-timeline-icon">
                                    <i class="fa fa-home" aria-hidden="true"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <p>
                                        <a *ngIf="accountDetails.franchisee.code || accountDetails.franchisee.name">{{accountDetails?.franchisee?.code}}
                                            | {{accountDetails?.franchisee?.name}}</a>
                                        <span *ngIf="!accountDetails.franchisee.code && !accountDetails.franchisee.name"
                                            class="not-available">Not Available</span>
                                    </p>
                                    <span class="vertical-date small text-muted">Franchisee</span>
                                </div>
                            </div>
                            <div class="vertical-timeline-block">
                                <div class="vertical-timeline-icon">
                                    <i class="fa fa-list" aria-hidden="true"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <p *ngIf="accountDetails.accountNumber">{{accountDetails?.accountNumber}}</p>
                                    <p *ngIf="!accountDetails.accountNumber" class="not-available">Not Available</p>
                                    <span class="vertical-date small text-muted"> Account number</span>
                                </div>
                            </div>
                            <div class="vertical-timeline-block">
                                <div class="vertical-timeline-icon">
                                    <i class="fa fa-credit-card" aria-hidden="true"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <p>
                                        <span
                                            *ngIf="accountDetails.subscriptionOwnerEmail"><a [routerLink]="['/users/user-details/', accountDetails.subscriptionOwnerEmail]" routerLinkActive="router-link-active" >{{accountDetails.subscriptionOwnerEmail}}</a></span>
                                        <span *ngIf="!accountDetails.subscriptionOwnerEmail" class="not-available">Not
                                            Available</span>
                                    </p>
                                    <span class="vertical-date small text-muted">Subscription Owner</span>
                                </div>
                            </div>
                            <div class="vertical-timeline-block">
                                <div class="vertical-timeline-icon">
                                    <i class="fa fa-bell" aria-hidden="true"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <p>
                                        <a *ngIf="accountDetails.status && accountDetails.status !== 'Not Available'" class="">{{accountDetails.status}}</a>
                                        <span *ngIf="!accountDetails.status || accountDetails.status === 'Not Available'" class="not-available">Not Available</span>
                                        <span *ngIf="!accountDetails.status || accountDetails.status === 'Not Available'" class="float-end">
                                            <a class="btn btn-sm btn-primary" (click)="addBillingStatus(accountDetails?.accountNumber)"><i class="fa fa-plus"></i> Add</a>
                                        </span>
                                    </p>
                                    <span class="vertical-date small text-muted"> Account Status</span>
                                </div>
                            </div>
                            <div class="vertical-timeline-block">
                                <div class="vertical-timeline-icon">
                                    <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <p>
                                        <span *ngIf="accountDetails.planName"
                                            class="badge badge-{{accountDetails.planClassName}}">{{accountDetails.planName}}</span>
                                        <span *ngIf="!accountDetails.planName" class="not-available">Not
                                            Available</span>
                                    </p>
                                    <span class="vertical-date small text-muted"> Plan Name</span>
                                </div>
                            </div>
                            <div class="vertical-timeline-block">
                                <div class="vertical-timeline-icon">
                                    <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <p>
                                        <span
                                            class="payment-status-{{accountDetails.paymentStatus.className}}" matTooltip="{{accountDetails.paymentStatus.status === 'Paused' ? 'PausedAt: ' + accountDetails.paymentStatus.pausedDate + ', Paused reason: ' + accountDetails.paymentStatus.pausedReason + ', Resume date: ' + accountDetails.paymentStatus.resumeDate : ''}}">{{accountDetails.paymentStatus.status}}</span>
                                        <span *ngIf="accountDetails.paymentStatus.status !== 'Trial Expired'"
                                            class="label-yellow-date" matTooltip="{{accountDetails.paymentStatus.dateTooltip}}">{{accountDetails.paymentStatus.date}}</span>

                                            <span class="pull-right" *ngIf="accountDetails.paymentStatus.status === 'Trial Expired' || accountDetails.paymentStatus.status === 'Trial Expires'">
                                                <a class="btn btn-sm btn-primary" (click)="openAccountExtendTrialPopup(accountDetails?.accountNumber)">Extend Trial</a>
                                            </span>
                                    </p>
                                    <span class="vertical-date small text-muted"> Plan Status</span>
                                </div>
                            </div>
                            <div class="vertical-timeline-block">
                                <div class="vertical-timeline-icon">
                                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <p>
                                        <span *ngIf="accountDetails.createdDate">{{accountDetails.createdDate}}</span>
                                        <span *ngIf="!accountDetails.createdDate" class="not-available">Not
                                            Available</span>
                                    </p>
                                    <span class="vertical-date small text-muted"> Closing Date</span>
                                </div>
                            </div>
                            <div class="vertical-timeline-block"
                                *ngIf="accountDetails.paymentStatus.status === 'Paid' || accountDetails.paymentStatus.status === 'Past Due' || accountDetails.paymentStatus.status === 'Canceled'">
                                <div class="vertical-timeline-icon">
                                    <i class="fa fas fa-address-book" aria-hidden="true"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <p>
                                        <span *ngIf="accountDetails.customerId">{{accountDetails.customerId}}</span>
                                        <span *ngIf="!accountDetails.customerId" class="not-available">Not
                                            Available</span>
                                    </p>
                                    <span class="vertical-date small text-muted"> Zoho Sub Customer Id</span>
                                </div>
                            </div>
                            <div class="vertical-timeline-block"
                                *ngIf="accountDetails.paymentStatus.status === 'Paid' || accountDetails.paymentStatus.status === 'Past Due' || accountDetails.paymentStatus.status === 'Canceled'">
                                <div class="vertical-timeline-icon">
                                    <i class="fa fa-university" aria-hidden="true"></i>
                                </div>
                                <div class="vertical-timeline-content">
                                    <p>
                                        <span
                                            *ngIf="accountDetails.subscriptionId">{{accountDetails.subscriptionId}}</span>
                                        <span *ngIf="!accountDetails.subscriptionId" class="not-available">Not
                                            Available</span>
                                    </p>
                                    <span class="vertical-date small text-muted"> Zoho Sub Subscription Id</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
