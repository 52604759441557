import { Component, OnInit, Inject } from '@angular/core';
import { Franchisee } from './models/franchisee';
import { FranchiseeService } from './services/franchisee.service';
import { Title } from '@angular/platform-browser';
import { ThemeService } from './services/theme.service';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  franchiseeCode: string;
  primaryColor: string;
  title: string;
  url: string = location.origin;
  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument,
    private franchiseeService: FranchiseeService,
    private titleService: Title,
    private themeService: ThemeService,
    private router: Router,
    private logger: NGXLogger
  ) {
  }

  ngOnInit(): void {
    this.franchiseeService.getDetailsByURL(this.url).then((franchiseDetails: Franchisee) => {
      this.logger.info('INFO: -> AppComponent -> this.franchiseeService.getDetailsByURL -> franchiseDetails', franchiseDetails);
      this.franchiseeService.changeFranchiseeDetails(franchiseDetails);
      this.franchiseeCode = franchiseDetails.franchiseeCode;
      this.title = `CatTool | ${franchiseDetails.companyName}`;
      this.titleService.setTitle(this.title);
      this.themeService.setTheme({
        'primary-color': franchiseDetails.primaryColor,
        'background-color': franchiseDetails.backgroundColor,
        'text-color': franchiseDetails.textColor,
        'primary-btn-background-color': franchiseDetails.primaryBtnBackgroundColor,
        'primary-btn-text-color': franchiseDetails.primaryBtnTextColor,
        'primary-btn-hover-background-color': franchiseDetails.primaryBtnHoverBackgroundColor,
        'primary-btn-hover-text-color': franchiseDetails.primaryBtnHoverTextColor,

        'secondary-btn-background-color': franchiseDetails.secondaryBtnBackgroundColor,
        'secondary-btn-text-color': franchiseDetails.secondaryBtnTextColor,
        'secondary-btn-hover-background-color': franchiseDetails.secondaryBtnHoverBackgroundColor,
        'secondary-btn-hover-text-color': franchiseDetails.secondaryBtnHoverTextColor,

        'login-btn-background-color': franchiseDetails.loginBtnBackgroundColor,
        'login-btn-text-color': franchiseDetails.loginBtnTextColor,
        'login-btn-hover-background-color': franchiseDetails.loginBtnHoverBackgroundColor,
        'login-btn-hover-text-color': franchiseDetails.loginBtnHoverTextColor,
      });
      this.primaryColor = franchiseDetails.primaryColor;
      this.document.getElementById('appFavicon').setAttribute('href', 'assets/images/franchisee/' + this.franchiseeCode + '/favicon.ico');
    }).catch(error => {
      this.logger.error('ERROR: -> AppComponent -> this.franchiseeService.getDetailsByURL-> error', error);
      this.router.navigate(['/invalid-link']);
    });
  }
}
