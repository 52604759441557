<form [formGroup]="composeMessageForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Compose Message</h4>
        <button type="button" class="close" aria-describedby="modal-title" (click)="cancel()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12 compose-message-form">
                <div class="mb-3">
                    <label for="email">Email <span class="required">*</span></label>
                    <input type="email" name="email" class="form-control" formControlName="email">
                </div>
                <div class="mb-3">
                    <label for="subject">Subject <span class="required">*</span></label>
                    <input type="subject" name="subject" class="form-control" formControlName="subject">
                </div>
                <div class="mb-3">
                    <label for="message">Message <span class="required">*</span></label>
                    <div class="form-control" formControlName="message" [ngxSummernote]="config"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer btn-group">
        <button type="submit" class="btn btn-primary max-with-btn" [disabled]="!composeMessageForm.valid">Send</button>
        <button type="button" class="btn btn-default max-with-btn ms-2" (click)="cancel()">Cancel</button>
    </div>
</form>
