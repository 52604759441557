import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {
  constructor(
    private cookieService: CookieService,
  ) {

  }

  getFilters(cookieKey: string) {
    const encodedValue: string = localStorage.getItem(cookieKey);
    if (encodedValue) {
      const filters = JSON.parse(atob(encodedValue));
      return filters;
    } else {
      return null;
    }
  }

  setFilters(cookieKey: string, filters: object) {
    const encodedValue: string = btoa(JSON.stringify(filters));
    localStorage.setItem(cookieKey, encodedValue);
  }

  removeFilters(cookieKey: string) {
    localStorage.removeItem(cookieKey);
  }
}
