import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class WhiteLabelPartnerService {
  catToolURL: string = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient
  ) {

  }

  getAllWhiteLabelPartners(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/get-all-white-label-partners', requestBody).toPromise();
  }

  getAllWhiteLabelPartnersForExport(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/get-all-white-label-partners-for-export', requestBody).toPromise();
  }

  getWhiteLabelPartnerDetails(id: string) {
    return this.httpClient.post(this.catToolURL + '/get-white-label-partner-details', {id}).toPromise();
  }

  addWhiteLabelPartner(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/add-white-label-partner', requestBody).toPromise();
  }

  editWhiteLabelPartner(requestBody: object) {
    return this.httpClient.post(this.catToolURL + '/edit-white-label-partner', requestBody).toPromise();
  }
}
