<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Type</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="mb-3">
        <h5>Event types <a href="https://accountingsuite.atlassian.net/wiki/spaces/AP/pages/761725700/Web2Wallet+Event+logs+v2" target="_blank"><i class="fa fa-info-circle"></i></a></h5>
        <table class="responsive table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Category</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><span class="dot-lg dot-dev-type"></span>Dev</td>
                    <td>Technical message or issues where you will surely need IT assistance.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-login-type"></span>Login</td>
                    <td>Login related activities.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-logout-type"></span>Logout</td>
                    <td>Logout related activities.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-profile-type"></span>Profile</td>
                    <td>Profile page activities</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-pay-type"></span>Pay</td>
                    <td>Payment/Subscription related activities.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-plan-type"></span>Plan</td>
                    <td>Upgrade/downgrade activities.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-notification-type"></span>Notification</td>
                    <td>Notification related activities.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-admin-type"></span>Admin</td>
                    <td>Admin activities from Cat like extend trial etc.</td>
                </tr>
                <tr>
                    <td><span class="dot-lg dot-account-type"></span>Account</td>
                    <td>Account related activities.</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modal.dismiss()">Cancel</button>
</div>
