import { Component } from '@angular/core';

@Component({
  selector: 'app-change-user-type',
  templateUrl: './change-user-type.component.html',
  styleUrls: ['./change-user-type.component.css']
})
export class ChangeUserTypeComponent {

  constructor() { }

}
