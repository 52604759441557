import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentManagerService {
  catToolURL: string = environment.CAT_URL;
  constructor(
    private httpClient: HttpClient
  ) { }

  getLoginContent() {
    return this.httpClient.get(this.catToolURL + '/get-login-content').toPromise();
  }

  saveLoginContent(loginContent: string) {
    return this.httpClient.post(this.catToolURL + '/save-login-content', {loginContent}).toPromise();
  }

  getMaintenanceContent() {
    return this.httpClient.get(this.catToolURL + '/get-maintenance-content').toPromise();
  }

  saveMaintenanceContent(maintenanceContent: string) {
    return this.httpClient.post(this.catToolURL + '/save-maintenance-content', {maintenanceContent}).toPromise();
  }

  toggleWarningOnOff(warningOn: boolean) {
    return this.httpClient.post(this.catToolURL + '/toggle-maintenance-on-off', {warningOn}).toPromise();
  }
}
