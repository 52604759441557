<div class="col-md-12 modal-popup">
    <div class="row" [ngSwitch]="currentState">
      <div class="col-md-4 left-side-box">
        <div class="col-md-12">
          <div class="row">
            <div class="acs-logo">
              <img class="img-fluid" src="assets/images/franchisee/{{ franchiseeCode }}/{{ logo }}"
                alt="{{ companyName }}">
            </div>
          </div>
        </div>
        <div *ngFor="let state of states; let i = index;">
          <div *ngIf="state != 'Completed'" class="row" [ngClass]="{
                      'stage-link-complete': states.indexOf(currentState) > i,
                      'stage-link-incomplete': states.indexOf(currentState) < i,
                      'stage-link-active':  states.indexOf(currentState) == i
                  }">
            <div class="col-md-9 col-sm-9 col-xs-9">
              <h3>{{ state }}</h3>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <i class="float-end fa fa-2x" [ngClass]="{
                              'fa-check-circle': states.indexOf(currentState) > i,
                              'fa-square-o':  states.indexOf(currentState) <= i
                          }"></i>
            </div>
          </div>
          <hr class="divider" *ngIf="state != 'Completed' && i+2 != states.length">
        </div>
      </div>
      <div class="col-md-8">
          <div class="acs-modal-header modal-header">
            <h4 class="modal-title">Multi-Factor Authentication</h4>
            <button type="button" class="btn-close" aria-describedby="modal-title" (click)="closePopupAndRefreshTab()">
              <span aria-hidden="true"></span>
            </button>
          </div>
          <div class="modal-body" *ngSwitchCase="'Login'">
            <div class="row">
                <div class="col-md-6 right-border">
                    <form>
                        <p class="mb-3">Enter the 6 digits security code generated by Google Authenticator App.</p>
                        <div class="d-flex justify-content-center top-margin">
                            <input type="tel" class="form-control mb-3 index-box" placeholder="Security code" name="securityCode"
                            [(ngModel)]="securityCode" required minlength="6" #securityCodeRef="ngModel">
                        </div>
                        <div *ngIf="securityCodeRef.invalid && !securityCodeRef.pristine && (securityCodeRef.touched || securityCodeRef.dirty) " class="alert alert-danger">
                        <div *ngIf="securityCodeRef.errors?.required">
                            Invalid Security Code.
                        </div>
                        <div *ngIf="securityCodeRef.errors?.minlength">
                            Security Code must be at least 6 characters long.
                        </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-6 app-before-or">
                    <form>
                        <p class="mb-3">Send Verification Code to {{ recoveryEmail }}</p>
                        <div class="d-flex justify-content-center">
                            <button type="button" class="btn-primary mb-3" (click)="sendVerificationEmail()">Send Verification Code</button>
                        </div>
                        <p class="mb-3">Please enter your verification code</p>
                        <div class="d-flex justify-content-center">
                            <input type="tel" class="form-control mb-3 index-box" placeholder="Verification code" name="verificationCode"
                            [(ngModel)]="verificationCode" required minlength="6" #verificationCodeRef="ngModel">
                        </div>
                        <div *ngIf="verificationCodeRef.invalid && !verificationCodeRef.pristine && (verificationCodeRef.touched || verificationCodeRef.dirty) " class="alert alert-danger">
                            <div *ngIf="verificationCodeRef.errors?.required">
                            Invalid verification Code.
                            </div>
                            <div *ngIf="verificationCodeRef.errors?.minlength">
                            Verification Code must be at least 6 characters long.
                            </div>
                        </div>
                    </form>
                </div>
            </div>
          </div>
          <div class="modal-body" *ngSwitchCase="'Completed'">
            <div class="d-flex justify-content-center">
              <i class="fa fa-check-circle check-box"></i>
            </div>
          </div>
          <div class="modal-body" *ngSwitchDefault></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary float-end" (click)="validateMFA()" *ngIf="currentState !== 'Completed'">Login</button>
          </div>
      </div>
    </div>
  </div>
