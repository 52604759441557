import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetZohoSubWebhookEventsRequest, GetZohoSubWebhookEventsResponse, RetryApiResponse } from '@app/components/private/zoho-sub-webhooks';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZohoSubWebhookService {
  catToolURL: string;
  syncEngineTaskRunnerURL: string;
  constructor(
    private httpClient: HttpClient
  ) {
    this.catToolURL = environment.CAT_URL;
    this.syncEngineTaskRunnerURL = environment.SYNC_ENGINE_TASK_RUNNER_URL;
  }

  getZohoSubWebhookEvents(requestParams: GetZohoSubWebhookEventsRequest): Observable<GetZohoSubWebhookEventsResponse> {
    return this.httpClient.post<GetZohoSubWebhookEventsResponse>(this.catToolURL + '/get-all-zoho-sub-webhook-events', requestParams);
  }

  retry(eventId: string): Observable<RetryApiResponse> {
    return this.httpClient.post<RetryApiResponse>(this.syncEngineTaskRunnerURL + '/retry-webhook', { eventId });
  }
}
