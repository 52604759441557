<div class="col-md-12 modal-popup">
    <div class="row" [ngSwitch]="currentState">
      <div class="col-md-4 left-side-box">
        <div class="col-md-12">
          <div class="row">
            <div class="acs-logo">
              <img class="img-fluid" src="assets/images/franchisee/{{ franchiseeCode }}/{{ logo }}"
                alt="{{ companyName }}">
            </div>
          </div>
        </div>
        <div *ngFor="let state of states; let i = index;">
          <div *ngIf="state !== 'Completed'" class="row" [ngClass]="{
                      'stage-link-complete': states.indexOf(currentState) > i,
                      'stage-link-incomplete': states.indexOf(currentState) < i,
                      'stage-link-active':  states.indexOf(currentState) == i
                  }">
            <div class="col-md-9 col-sm-9 col-xs-9">
              <h3>{{ state }}</h3>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-3">
              <i class="float-end fa fa-2x" [ngClass]="{
                              'fa-check-circle': states.indexOf(currentState) > i,
                              'fa-square-o':  states.indexOf(currentState) <= i
                          }"></i>
            </div>
          </div>
          <hr class="divider" *ngIf="state != 'Completed' && i+2 != states.length">
        </div>
      </div>
      <div class="col-md-8">
          <div class="acs-modal-header modal-header">
            <h4 class="modal-title" *ngIf="currentState !== 'Completed'">{{ currentState }}</h4>
            <h4 class="modal-title" *ngIf="currentState === 'Completed'">Multi-Factor Authentication</h4>
            <button type="button" class="btn-close" aria-describedby="modal-title" (click)="closePopupAndRefreshTab(currentState)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" *ngSwitchCase="'Get Started'">
            <form name="get-started-form">
              <ol>
                <li>Install the <a href="https://support.google.com/accounts/answer/1066447" target="_blank">Google
                    Authenticator App</a> on your phone.
                </li>
                <li>
                  Open the Google Authenticator App, and tap "BEGIN
                  SETUP".
                </li>
                <li>
                  Select "Scan barcode" to scan the QR code below.
                </li>
                <li>
                  When the barcode is scanned successfully, you will see
                  the security code to use for authentication.
                </li>
              </ol>
              <div class="text-center">
                <img *ngIf="qrCode" src="{{qrCode}}" alt="" class="img-thumbnail bar-code-image">
              </div>
              <h4 class="text-center">OR</h4>
              <p class="text-center">
                <a (click)="switchManualOption()" class="link-manual-option">Enter your key manually</a>
              </p>
              <div *ngIf="manualKeysVisibility">
                <div class="mb-3 row">
                  <label class="col-sm-4 form-label">Account Name</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control" [(ngModel)]="email" name="email">
                  </div>
                </div>
                <div class="mb-3 row">
                  <label class="col-sm-4 form-label">Secret Key</label>
                  <div class="col-sm-8">
                    <input type="text" readonly class="form-control" [(ngModel)]="manualKey" name="key">
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-body" *ngSwitchCase="'Security Code'">
            <form name="Auth_form">
              <p>Enter the security code generated by the Google
                Authenticator App.</p>
              <p>Security code must be at least 6 digits.</p>
              <div class="d-flex justify-content-center">
                <input type="tel" class="form-control input-box mb-3" placeholder="Security code" name="securityCode"
                  [(ngModel)]="securityCode" required minlength="6" #securityCodeRef="ngModel">
              </div>
              <div *ngIf="securityCodeRef.invalid && !securityCodeRef.pristine && (securityCodeRef.touched || securityCodeRef.dirty) " class="alert alert-danger">
                <div *ngIf="securityCodeRef.errors?.required">
                  Invalid Security Code.
                </div>
                <div *ngIf="securityCodeRef.errors?.minlength">
                  Security Code must be at least 6 characters long.
                </div>
              </div>
            </form>
          </div>
          <div class="modal-body" *ngSwitchCase="'Recovery Email'">
            <p>Please enter a recovery email address to use in case you do not
              have access to Google Authenticator App.</p>
            <p>This email
              address cannot be the same as your primary email address for the
              account.</p>
            <div class="d-flex justify-content-center">
              <div class="input-group input-box mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-envelope-o"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Recovery email address" name="recoveryEmail" aria-label="Recovery email address" required email [(ngModel)]="recoveryEmail" #recoveryEmailRef="ngModel">
              </div>
            </div>
            <div *ngIf="recoveryEmailRef.invalid && !recoveryEmailRef.pristine && ( recoveryEmailRef.touched || recoveryEmailRef.dirty)" class="alert alert-danger">
              <div *ngIf="recoveryEmailRef.errors?.required">
                Recovery email address is required.
              </div>
              <div *ngIf="recoveryEmailRef.errors?.email">
                Invalid Recovery email address, Please enter a valid email address.
              </div>
              <div *ngIf="recoveryEmail === email">
                Recovery email address did not same as primary email address.
              </div>
            </div>
          </div>
          <div class="modal-body" *ngSwitchCase="'Verify Email'">
            <form>
              <p>Please enter the 6 digits code that was sent to your
                recovery email address.</p>
              <div class="d-flex justify-content-center">
                <input type="tel" class="form-control input-box mb-3" placeholder="Verification code" name="verificationCode"
                  [(ngModel)]="verificationCode" required minlength="6" #verificationCodeRef="ngModel">
              </div>
              <div *ngIf="verificationCodeRef.invalid && !verificationCodeRef.pristine && (verificationCodeRef.touched || verificationCodeRef.dirty) " class="alert alert-danger">
                <div *ngIf="verificationCodeRef.errors?.required">
                  Invalid verification Code.
                </div>
                <div *ngIf="verificationCodeRef.errors?.minlength">
                  Verification Code must be at least 6 characters long.
                </div>
              </div>
            </form>
          </div>
          <div class="modal-body" *ngSwitchCase="'Completed'">
            <p> You are all Setup!<br>Next time you sign into your account,
              you will be prompted to enter security code from the Google
              Authenticator App.</p>
            <div class="d-flex justify-content-center">
              <i class="fa fa-check-circle check-box"></i>
            </div>
          </div>
          <div class="modal-body" *ngSwitchDefault></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary float-start" (click)="changeState(currentState, 0)" *ngIf="currentState !== 'Completed' && states.indexOf(currentState) != 0">Back</button>
            <button type="button" class="btn btn-primary float-end" (click)="changeState(currentState, 1)" *ngIf="currentState !== 'Completed'">Next</button>
            <button type="button" class="btn btn-primary float-end" (click)="closePopupAndRefreshTab(currentState)" *ngIf="currentState === 'Completed'">Finish</button>
          </div>
      </div>
    </div>
  </div>
